import React from 'react';
import styles from './PaymentTable.module.scss';
import CustomTable from '../../../Common/CustomTable';
import AvatarIcon from '../../../Common/AvatarIcon';
import { ReactComponent as WhatsappIcon } from '../../../../assets/svg/icons/whatsapp.svg';
import { ReactComponent as PhoneIcon } from '../../../../assets/svg/icons/chiamate.svg';
import { ReactComponent as EyeIcon } from '../../../../assets/svg/eye_gray.svg';
import { ReactComponent as CalendarColor } from '../../../../assets/svg/icons/calendar-color.svg';
import { ReactComponent as ArrowRight } from '../../../../assets/svg/arrow-right.svg';

import { ReactComponent as MailIcon } from '../../../../assets/svg/icons/mail.svg';
import { modalActions } from '../../../Modals/store';
import { useDispatch } from 'react-redux';
import IconButton from '../../../Common/Buttons/IconButton';
import clx from 'classnames';
import { setCategory } from '../constants';
import dayjs from 'dayjs';
import { Button } from '@mui/material';
import clsx from 'clsx';

export default ({
  values,
  isMobile,
  setPage,
  total,
  serverSidePagination = true,
  disableRimborsa = false,
  pageRows = 20,
}) => {

  const data = React.useMemo(() => values, [values]);
  const dispatch = useDispatch();
  const columns = React.useMemo(
    () =>
      isMobile
        ? [
            {
              Header: '',
              accessor: 'actions',
              Cell: ({ row: { original } }) => {
                const today = dayjs().format('YYYY-MM-DD');
                const data = dayjs(original.data_prenotazione).format('YYYY-MM-DD');
                const diff = dayjs(data).diff(today, 'day');
                return (
                  <div className={styles.payment_mobile_card} 
                    style={{borderLeft: `6px solid ${diff < 0 ? '#858AF9' : 'transparent'}`, borderRadius: '4px', paddingLeft: '5px'}}
                  >
                    <div className={styles.payment_info}>
                      <section className={styles.section_info}>
                        <div className={styles.head}>
                          <div className={styles.date}>
                            <div className={styles.calendar}>
                              <CalendarColor width={'17px'} height={'18px'} />
                            </div>
                            <div className={styles.payment_date}>
                              {dayjs(original.created_at).format('DD/MM/YYYY')}
                            </div>
                          </div>
                          <div className={styles.payment_name}>{original.clientName}</div>
                        </div>
                        <div className={styles.payment_services}>
                          {`${original.servizi[0].nomeServizio} ${
                            original.servizi.length > 1 ? `+ ${original.servizi.length - 1}` : ''
                          }`}
                        </div>
                      </section>
                      {!disableRimborsa && (
                        <section className={styles.section_actions}>
                          <div className={styles.info}>
                            <div
                              className={styles.payment_value}
                              style={{
                                background: original.totalePagato < 0 ? '#FF6A881A' : '#65CE8F1A',
                                color: original.totalePagato < 0 ? '#FF6A88' : '#65CE8F',
                              }}
                            >
                              €{original.totalePagato.toFixed(2)}
                            </div>
                            {/* <div className={styles.payment_refund} onClick={() => {
                            dispatch(
                              modalActions.OPEN_MODAL.SUCCESS({
                                type: 'refundModal',
                                props: { ...original, setCategory },
                              }),
                            );
                          }}>
                            Rimborsa
                          </div> */}
                          </div>
                          <div className={styles.detail_arrow}>
                            <ArrowRight
                              className={styles.icon}
                              onClick={() => {
                                dispatch(
                                  modalActions.OPEN_MODAL.SUCCESS({
                                    type: 'showInfoPaymentModal',
                                    props: { pagamento: original, setCategory },
                                  }),
                                );
                              }}
                            />
                          </div>
                        </section>
                      )}
                    </div>
                  </div>
                );
              },
            },
          ]
        : [
            {
              Header: 'Data Pagamento',
              accessor: 'created_at',
              Cell: ({ row: { original } }) => {
                const today = dayjs().format('YYYY-MM-DD');
                const data = dayjs(original.data_prenotazione).format('YYYY-MM-DD');
                const diff = dayjs(data).diff(today, 'day');
                return (
                  <div className={styles.payment}>
                    <div className={styles.payment_info}>
                      <div style={{borderLeft: `4px solid ${diff < 0 ? '#858AF9' : 'transparent'}`, borderRadius: '4px', height: '35px'}}/>
                      <div className={styles.payment_date}>
                        {dayjs(original.created_at).format('DD/MM/YYYY')}
                      </div>
                    </div>
                  </div>
                );
              },
            },
            {
              Header: 'Cliente',
              accessor: 'cliente',
              Cell: ({ row: { original } }) => {
                return (
                  <div className={styles.name_cell_container}>
                    {
                      !(
                        <AvatarIcon name={original.clientName} icon={original.icon} size={'33px'} />
                      )
                    }
                    <div className={styles.payment_info} style={{ marginLeft: '0px' }}>
                      <div className={styles.name}>{original.clientName}</div>
                    </div>
                  </div>
                );
              },
            },
            {
              Header: 'Servizio',
              accessor: 'Servizio',
              Cell: ({ row: { original } }) => {
                return (
                  <div className={styles.payment}>
                    <div className={styles.payment_info}>
                      <div className={styles.payment_services}>
                        {original.servizi?.map((x) => x.nomeServizio).join(', ')}
                      </div>
                    </div>
                  </div>
                );
              },
            },
            {
              Header: 'Poltrona',
              accessor: 'Poltrona',
              Cell: ({ row: { original } }) => {
                return (
                  <div className={styles.payment}>
                    <div className={styles.payment_info}>
                      <div className={styles.payment_services}>
                        {original.poltrona?.nome_referente}
                      </div>
                    </div>
                  </div>
                );
              },
            },
            {
              Header: 'Data Prenotazione',
              accessor: 'data_prenotazione',
              Cell: ({ row: { original } }) => {
                return (
                  <div className={styles.payment}>
                    <div className={styles.payment_info}>
                      <div className={styles.payment_date}>
                        {original.data_prenotazione
                          ? dayjs(original.data_prenotazione).format('DD/MM/YYYY')
                          : 'non disponibile'}
                      </div>
                    </div>
                  </div>
                );
              },
            },
            {
              Header: 'Pagato',
              accessor: 'importo',
              Cell: ({ row: { original } }) => {
                return (
                  <div className={styles.payment}>
                    <div className={styles.payment_info}>
                      <div
                        className={styles.payment_value}
                        style={{
                          color: original.totalePagato < 0 ? '#FF6A88' : '#65CE8F',
                        }}
                      >
                        €{original.totalePagato?.toFixed(2)}
                      </div>
                    </div>
                  </div>
                );
              },
            },
            {
              Header: 'Coupon',
              accessor: 'voucher',
              Cell: ({ row: { original } }) => {
                return (
                  <div className={styles.payment}>
                    <div className={styles.payment_info}>
                      <div
                        className={styles.payment_value}
                        style={{
                          color: original.voucher == 0 ? '#AEAEB2' : '#1f1f1f',
                        }}
                      >
                        {original.voucher > 0 ? `€${original.voucher?.toFixed(2)}`: '-'}
                      </div>
                    </div>
                  </div>
                );
              },
            },
            {
              Header: '',
              accessor: '*',
              Cell: ({ row: { original } }) => {
                return (
                  <div className={styles.payment}>
                    <div
                      className={styles.payment_info}
                      style={{
                        justifyContent: 'flex-start',
                        marginRight: '10px',
                      }}
                    >
                      {/* {
                      !disableRimborsa &&
                      <Button
                        disabled={!original.rimborsabile}
                        onClick={() => {
                          dispatch(
                            modalActions.OPEN_MODAL.SUCCESS({
                              type: 'refundModal',
                              props: { ...original, setCategory },
                            }),
                          );
                        }}
                        variant='contained'
                        className={clsx(styles.button_allow, styles.button_allow)}
                      >
                        Rimborsa
                      </Button>
                    } */}
                      <IconButton
                        svg={<EyeIcon />}
                        type={'default'}
                        size={'32px'}
                        onClick={() => {
                          dispatch(
                            modalActions.OPEN_MODAL.SUCCESS({
                              type: 'showInfoPaymentModal',
                              props: { pagamento: original, setCategory },
                            }),
                          );
                        }}
                      ></IconButton>
                    </div>
                  </div>
                );
              },
            },
          ],
    [isMobile],
  );

  return (
    <div
      className={clx(styles.table_card, {
        [styles.table_card_mobile]: isMobile,
      })}
    >
      <CustomTable
        data={data}
        columns={columns}
        pageCount={total}
        setPaginationValue={setPage}
        serverSidePagination={serverSidePagination}
        isMobile={isMobile}
        pageRows={pageRows}
      />
    </div>
  );
};
