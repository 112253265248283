import styles from "./Security.module.scss";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import TitleSectionBack from "../../../Common/TitleSectionBack";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";

import TextField from "../../../Common/TextField";
import { Button } from "@mui/material";
import { authActions } from "../../store";
import CollapsibleCard from "../../../Common/CollapsibleCard";
import { getActiveSalon } from "../../store/selectors";
import useWindowSize from "../../../../utils/useWindowSize";
import clx from "classnames";


const Security = () => {
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize()
  const { pwdPrenotazione, name } = useSelector(getActiveSalon());
  const initialValuesPassword = {
    oldPwd: '',
    newPwd: '',
    newPwdConfirm: '',
  }

  const validationSchemaPassword = {
    oldPwd: yup
      .string('Inserisci la tua Password')
      .required('Password è richiesta'),
    newPwd: yup.string().required('Password is required'),
    newPwdConfirm: yup.string()
      .oneOf([yup.ref('newPwd'), null], 'Passwords must match')
  };

  const initialValuesSecretWord = {
    pwdPrenotazione: pwdPrenotazione || '',
    nomeSalone: name || '',
  }

  const validationSchemaSecretWord = {
    pwdPrenotazione: yup
      .string('Inserisci la tua pwdPrenotazione'),
  };

  return (
    <div className={clx(styles.salon_container, {
      [styles.salon_container_mobile]: isMobile
    })
    }>
      <TitleSectionBack
        title={'Sicurezza'}
        description={'Titolo Sezione indietro'}
      />
      <Formik
        initialValues={initialValuesPassword}
        validationSchema={yup.object().shape(validationSchemaPassword)}
        enableReinitialize={true}
        onSubmit={(values) => {
          const { newPwdConfirm, ...req } = values
          dispatch(authActions.EDIT_SALON_PASSWORD.REQUEST(req))
        }}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form className={styles.salon_card}>
              <div className={styles.form_container}>
                <Field
                  name={'oldPwd'}
                  id={'oldPwd'}
                  label="Password attuale"
                  component={TextField}
                  height={'50px'}
                  type="password"
                  showPassword={true}
                />
                <Field
                  name={'newPwd'}
                  id={'newPwd'}
                  label="Nuova password"
                  component={TextField}
                  height={'50px'}
                  type="password"
                  showPassword={true}
                />
                <Field
                  name={'newPwdConfirm'}
                  id={'newPwdConfirm'}
                  label="Conferma password"
                  component={TextField}
                  height={'50px'}
                  type="password"
                  showPassword={true}
                />
              </div>
              <div className={styles.security_actions}>
                <Button
                  className={styles.confirm_button}
                  variant="contained"
                  type={'submit'}
                >
                  Salva
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
      <div className={styles.keyword_info}>
        <div className={styles.title}>
          Parola chiave
        </div>
        <div className={styles.description}>
          Proteggi il tuo salone dalle prenotazioni fasulle impostando una parola di sicurezza da comunicare solo ai
          tuoi clienti
        </div>
      </div>
      <CollapsibleCard
        title={'Parola chiave'}
        isOpenDefault={false}
      >
        <Formik
          initialValues={initialValuesSecretWord}
          validationSchema={yup.object().shape(validationSchemaSecretWord)}
          enableReinitialize={true}
          onSubmit={(values) => {
            dispatch(authActions.EDIT_SALON_INFO.REQUEST(values))
          }}
        >
          {({ values, setFieldValue }) => {
            return (
              <Form className={styles.keyword_form}>
                <Field
                  name={'pwdPrenotazione'}
                  id={'pwdPrenotazione'}
                  component={TextField}
                  height={'50px'}
                  placeholder={'Imposta la parola chiave'}
                />
                <div className={styles.keyword_actions}>
                  <Button
                    className={styles.confirm_button}
                    variant="contained"
                    type={'submit'}
                  >
                    Salva
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </CollapsibleCard>

    </div>
  );
}

export default Security;