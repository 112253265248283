import styles from './CollapsibleCard.module.scss';
import React, { useState } from 'react';
import clx from 'classnames';
import { ReactComponent as ArrowIcon } from '../../../assets/svg/icons/arrow_r.svg';
export default ({ children, title, isOpenDefault = true }) => {
  const [isOpen, setIsOpen] = useState(isOpenDefault);
  return (
    <div className={clx(styles.collapsible_card, { [styles['collapsible_card_close']]: !isOpen })}>
      <div className={styles.card_header} onClick={() => setIsOpen((isOpen) => !isOpen)}>
        <div className={styles.title}>{title}</div>
        <ArrowIcon style={{ transform: `rotate(${isOpen ? 90 : -90}deg)` }} />
      </div>
      <div className={styles.card_content}>{children}</div>
    </div>
  );
};
