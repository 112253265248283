import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { NAV_BAR_ROUTES } from './constants';
import NavItem from './containers/NavItem';
import styles from './SideBar.module.scss';
import clx from 'classnames';
import useWindowSize from '../../utils/useWindowSize';
import { ReactComponent as ZbLogo } from '../../assets/svg/zb-logo.svg';
import { ReactComponent as ZLogoNoText } from '../../assets/svg/logo-noText-black.svg';
import { ReactComponent as LogOut } from 'assets/svg/icons/esci.svg';
import { ReactComponent as Worker } from 'assets/svg/icons/armchair_blue.svg';
import { ufSaloniBannerPayments } from '../../utils/salonToken';
import { getActiveSalon } from 'Containers/Auth/store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { SUBSECTIONS } from 'Containers/Settings/containers/AccountsManager/constants';
import { getMyAccountStaff } from 'Containers/Settings/store/selectors';
import { authActions } from 'Containers/Auth/store';
import clsx from 'clsx';


const SideBar = ({ sideBarIsOpen, setSideBarIsOpen }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeItem, setActiveItem] = useState('');
  const { isMobile } = useWindowSize();
  const activeSalon = useSelector(getActiveSalon());
  const myAccount = useSelector(getMyAccountStaff());
  const [permissions, setPermissions] = useState([]);
  const [navBarRoutes, setNavBarRoutes] = useState(NAV_BAR_ROUTES);


  useEffect(() => {
    if (location) {
      setActiveItem(location.pathname);
    }

    if (myAccount.permessi?.length && localStorage.getItem('staff')) {
      let allowed = myAccount.permessi.map((perm) => {
        let sub = SUBSECTIONS.find(sub => sub.id === perm.sezione);
        return sub?.link || '';
      });
      setPermissions(allowed);
    }

  }, [location]);

  useEffect(() => {
    if(localStorage.getItem('staff')){
      setNavBarRoutes(NAV_BAR_ROUTES.filter(route=> permissions.includes(route.link)));

      let currentPath = location.pathname.match(/payments/) ? '/payments' : location.pathname;
      if(!permissions.includes(currentPath)){
        navigate(permissions[0]);
      }
    }
  }, [permissions, myAccount.permessi]);


  return (
    <aside
      className={clx(styles.sidebar__wrapper, {
        [styles.sidebar__wrapper__close]: !sideBarIsOpen && !isMobile,
        [styles.sidebar__wrapper__close_mobile]: !sideBarIsOpen && isMobile,
        [styles.sidebar__wrapper__mobile]: isMobile,
      })}
    >
      <div
        onClick={() => setSideBarIsOpen((i) => (isMobile ? !i : i))}
        className={styles.sidebar__inner}
      >
        <nav className={styles['nav-bar']}>
          <div className={styles.sidebar__logo}>
            {sideBarIsOpen ? <ZbLogo /> : <ZLogoNoText width={'30px'} height={'auto'} />}
          </div>
          {localStorage.getItem('staff') && <div style={{marginBottom: '20px', border:'1px solid black', flexWrap: 'wrap', marginLeft: !sideBarIsOpen ? '5px' : '' }} className={styles.navItem}> 
            <Worker/> {myAccount.nome_referente}
          </div>}
          <ul>
            {navBarRoutes.map((navItem, idx) => {
              if (navItem.label === 'Pagamenti') {
                /* if (
                  !ufSaloniBannerPayments.includes(activeSalon.idsalone) &&
                  activeSalon.disable_payment === 1
                )
                  return null;
                else */
                return (
                  <NavItem
                    key={idx}
                    navItem={navItem}
                    activeItem={activeItem}
                    isOpen={sideBarIsOpen}
                  />
                );
              } else {
                return (
                  <NavItem
                    key={idx}
                    navItem={navItem}
                    activeItem={activeItem}
                    isOpen={sideBarIsOpen}
                  />
                );
              }
            })}
          </ul>
         {localStorage.getItem('staff') && 
            <div className={clsx(styles.navItem, styles.navItem_exit)} onClick={()=> dispatch(authActions.LOG_OUT.REQUEST())}>
              <LogOut height={'30px'} width={'40px'} />
              {sideBarIsOpen && 'Esci'}
            </div>
         }
        </nav>
      </div>
    </aside>
  );
};

export default SideBar;
