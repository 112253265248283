import styles from './CustomTimePicker.module.scss';

import { LocalizationProvider } from '@mui/x-date-pickers2/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers2/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers2/TimePicker';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import CustomInput from '../CustomInput';
import useWindowSize from '../../../utils/useWindowSize';
import { CalendarIcon } from '@mui/x-date-pickers2';
import CustomTimePickerDesktop from './Desktop';
import CustomTimePickerMobile from './Mobile';

const CustomTimePicker = ({
  value,
  setValue,
  placeholder,
  title,
  icon,
  withIcon,
  minTime,
  maxTime,
  error,
  disabled,
  timeStep,
  ...rest
}) => {
  const { isMobile } = useWindowSize();
  const params = { value, setValue, placeholder, title, icon, withIcon, minTime, maxTime, error, disabled, timeStep, ...rest };

  if (!isMobile) return <CustomTimePickerDesktop {...params} />;
  return <CustomTimePickerMobile {...params} />;
};

export default CustomTimePicker;
