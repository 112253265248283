import jwtDecode from 'jwt-decode';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {authActions} from '../Containers/Auth/store';
import {getActiveSalonToken, setActiveSalonById} from "./salonToken";
import {getActiveSalonId} from "../Containers/Auth/store/selectors";

function useAuth() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const activeSalonId = useSelector(getActiveSalonId());

  useEffect(() => {
    const activeSalonToken = getActiveSalonToken()
    if (activeSalonToken) {
      const activeSalonDecode = jwtDecode(activeSalonToken);
      const salons = JSON.parse(localStorage.getItem('salons'));
      const activeSalons = salons.filter(salon => +salon.eliminato === 0)
      const date = new Date().getTime();
      if (activeSalonDecode.exp > date) {
        localStorage.removeItem('salons');
        dispatch(authActions.LOG_OUT.SUCCESS());
        setIsLoading(false);
      } else {
        if (+activeSalonDecode.idSalone !== activeSalonId) {
          dispatch(authActions.SIGN_IN.SUCCESS({
            activeSalonId: +activeSalonDecode.idSalone,
            salons: activeSalons
          }));
        }
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      dispatch(authActions.LOG_OUT.SUCCESS());
    }
  }, [dispatch, activeSalonId]);

  return {isLoading};
}

export default useAuth;
