import React from 'react';
import styles from "./ThirdInfoStep.module.scss";
import ForwardButton from "../../customComponents/ForwardButton";
import {authActions} from "../../../../../store";
import {useDispatch} from "react-redux";

export const ThirdInfoStepContainer = () => {
  const dispatch = useDispatch();

  return (
    <div className={styles.second_info_step_container}>
      <h2>
        Ottimo, servizi scelti... ricorda che in
        <br/>
        seguito potrai personalizzare
        <br/>
        ulteriormente questi servizi,
        <br/>
        aggiungendo <span className={styles.blue_inject}>durata</span> e <span className={styles.gold_inject}>costo</span>
      </h2>
      <ForwardButton
        onAction={() => dispatch(authActions.GET_ACTIVE_SIGN_UP_STEP.SUCCESS(12))}
      />
    </div>
  )
}
