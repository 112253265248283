import React from 'react';
import styles from "./ForwardButton.module.scss";
import {ReactComponent as ArrowRight} from '../../../../../../../assets/svg/arrow-right.svg';
import {Button} from "@mui/material";
import clx from "classnames";

export default (
  {
    onAction,
    type = 'button',
    disabled
  }
) => {
  return (
    <Button
      disabled={disabled}
      type={type}
      onClick={onAction}
      className={clx(styles.button_container, {
        [styles.button_container_disabled]: disabled
      })}
    >
      <span className={styles.button_title}>Avanti</span>
      <div className={styles.button_icon}>
        <ArrowRight/>
      </div>
    </Button>
  )
}
