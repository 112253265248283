import React, { useRef, useState } from 'react';
import styles from './SchedulerAddTaskAndQueueButtons.module.scss';
import dayjs from 'dayjs';
import { Button } from '@mui/material';
import clx from 'classnames';
import { modalActions } from '../../../Modals/store';
import { useDispatch, useSelector } from 'react-redux';
import { getCalendarQueue } from '../../store/selectors';
import { ReactComponent as Person } from '../../../../assets/svg/icons/person_blue.svg';
import { ReactComponent as PlusIcon } from '../../../../assets/svg/icons/plus_button.svg';
import useOutsideClick from '../../../../utils/useOutsideClick';
import { useSearchParams } from 'react-router-dom';

const SchedulerAddTaskAndQueueButtons = ({ numberOfActiveDays }) => {
  const dispatch = useDispatch();
  const calendarQueue = useSelector(getCalendarQueue());
  const dropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchParams, _] = useSearchParams();
  const date = searchParams?.get('startDay') || dayjs().format('YYYY-MM-DD');
  const isOlder = dayjs(date).isBefore(dayjs().format('YYYY-MM-DD'));

  useOutsideClick(dropdownRef, () => {
    if (isDropdownOpen) setIsDropdownOpen(false);
  });

  return (
    <div
      className={clx(styles.queue_action_container, {
        // [styles.queue_action_container_hide]: numberOfActiveDays !== 1,
      })}
    >
      {!!calendarQueue?.clientsLength && (
        <Button
          variant='contained'
          className={styles.queue_button}
          disabled={calendarQueue?.clientsLength < 1}
          onClick={() =>
            dispatch(
              modalActions.OPEN_MODAL.SUCCESS({
                type: 'clientsQueueModal',
                props: calendarQueue,
              }),
            )
          }
        >
          <Person />
          <div className={styles.queue_button_value}>
            {calendarQueue?.clientsLength} clienti in coda
          </div>
        </Button>
      )}
      <div
        ref={dropdownRef}
        className={clx(styles.create_task_selector, {
          [styles['create_task_selector_close']]: !isDropdownOpen,
        })}
      >
        <PlusIcon
          className={styles.queue_button_icon}
          height='60px'
          width='60px'
          onClick={() => setIsDropdownOpen((value) => !value)}
        />
        <div className={clx(styles.tooltip)} style={{ display: isDropdownOpen ? 'flex' : 'none' }}>
          <Button
            variant='contained'
            disabled={isOlder}
            onClick={() =>
              dispatch(
                modalActions.OPEN_MODAL.SUCCESS({
                  type: 'breakModal',
                  props: {},
                }),
              )
            }
            className={styles.tooltip_pause}
          >
            Prendi una Pausa
          </Button>
          <Button
            variant='contained'
            onClick={() =>
              dispatch(
                modalActions.OPEN_MODAL.SUCCESS({
                  type: 'addTaskModal',
                  props: {},
                }),
              )
            }
            className={styles.tooltip_new}
          >
            Nuova Prenotazione
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SchedulerAddTaskAndQueueButtons;
