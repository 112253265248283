/* eslint-disable no-undef */
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Loader from "../../shared/Loader/Loader"
import { START_LOADER } from "../../shared/Loader/store/constants"
import { getActiveSalonToken } from "../../utils/salonToken"
import Spinner from "../Common/Spinner"
import { shopActions } from "./store"
import { getShopToken } from "./store/selectors"

export function Shop() {
        const dispatch = useDispatch()
        const { shopToken } = useSelector(getShopToken())
        useEffect(() => {
                dispatch(shopActions.GET_TOKEN_SHOP.REQUEST())
        }, [dispatch])
        if (!shopToken) return <div><Spinner /></div>
        const url = process.env.REACT_APP_SHOP + '?client=' + shopToken

        return (<>
                <iframe
                        src={url}
                        style={{
                                flex: 1,
                                height: '100%',
                                width: '100%',
                                border: 'none'
                        }}
                />
        </>
        )
}