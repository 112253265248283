import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { clientActions } from '../../../Clients/store';
import { getClientsShort } from '../../../Clients/store/selectors';
import {
  getCalendarSlots,
  getCalendarWorkers,
  getCalendarWorkerServices,
  getTaskById,
} from '../../store/selectors';
import InputSelector from '../../../Common/CustomSelector/InputSelector';
import MultiplySelector from '../../../Common/CustomSelector/MultiplySelector';
import TextField from '../../../Common/TextField';
import { ReactComponent as Calendar } from '../../../../assets/svg/icons/calendar_blue.svg';
import { ReactComponent as Scissors } from '../../../../assets/svg/icons/scissors_blue.svg';
import { ReactComponent as Person } from '../../../../assets/svg/icons/person_blue.svg';
import { ReactComponent as Armchair } from '../../../../assets/svg/icons/armchair_blue.svg';
import { ReactComponent as Bin } from '../../../../assets/svg/icons/trash_red.svg';
import CustomDateTimePicker from '../../../Common/CustomDateTimePicker';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import styles from './TaskModal.module.scss';
import Switcher from '../../../Common/Switcher';
import HoveredInfoIcon from '../../../Common/HoveredInfoIcon';
import ButtonSelector from '../../../Common/CustomSelector/ButtonSelector';
import { Button } from '@mui/material';
import CustomTimePicker from '../../../Common/CustomTimePicker';
import clx from 'classnames';
import CustomDatePicker from '../../../Common/CustomDatePicker';
import { schedulerActions } from '../../store';
import ConfirmDeleteModal from '../../../Common/ConfirmDeleteModal';
import useWindowSize from '../../../../utils/useWindowSize';
import ConfirmSecondaryModal from '../../../Common/ConfirmSecondaryModal';
import PartialTask from './Container/PartialTask';
import EmptyTask from './Container/EmptyTask';

const TaskModal = (props) => {
  const { isMobile } = useWindowSize();
  const clients = useSelector(getClientsShort());

  if (props?.taskValue.task)
    return <PartialTask {...props} isMobile={isMobile} clients={clients} />;
  return <EmptyTask {...props} isMobile={isMobile} clients={clients} />;
};

export default TaskModal;
