import React from 'react';
import styles from './NewClient.module.scss';
import useWindowSize from 'utils/useWindowSize';
import { useDispatch } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { Button } from '@mui/material';
import TextField from '../../../Common/TextField';
import * as yup from 'yup';
import clsx from 'clsx';
import { clientActions } from 'Containers/Clients/store';

const NewClientModal = ({ salone, closeModal }) => {
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = {
    name: yup.string('Inserisci il nome').required('Il Nome è obbligatorio'),
    surname: yup.string('Inserisci il cognome').required('Il Cognome è obbligatorio'),
    email: yup.string('Inserisci email').email('Inserisci una email valida'),
    cellulare: yup
      .string('Inserisci numero di cellulare')
      .matches(phoneRegExp, 'Inserisci un numero di cellulare valido'),
    showRequired: yup.boolean().when(['email', 'cellulare'], {
      is: (email, cellulare) => !email && !cellulare,
      then: (schema) => schema.required('Insersci almeno una email o un cellulare'),
      otherwise: (schema) => schema.optional(),
    }),
  };

  const initialValues = {
    salone: salone.idsalone,
    name: '',
    surname: '',
    email: '',
    cellulare: '',
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={yup.object().shape(validationSchema)}
        onSubmit={(values) => {
          const req = {
            salone: values.salone,
            nome: values.name,
            cognome: values.surname,
            email: values.email ?? '',
            cellulare: values.cellulare ?? '',
          };

          dispatch(clientActions.ADD_CLIENT.REQUEST(req));
          return closeModal();
        }}
      >
        {({ errors }) => (
          <Form
            className={clsx(styles.client_modal_wrapper, {
              [styles.client_modal_wrapper_mobile]: isMobile,
            })}
          >
            <div className={styles.body}>
              <Field
                name={'name'}
                id={'name'}
                label='Nome'
                component={TextField}
                height={'50px'}
                margin={'0px 0px 30px 0px'}
                placeholder={'Inserisci un nome'}
              />
              <Field
                name={'surname'}
                id={'surname'}
                label='Cognome'
                component={TextField}
                height={'50px'}
                margin={'0px 0px 30px 0px'}
                placeholder={'Inserisci un cognome'}
              />
              {!!errors['showRequired'] && (
                <span className={styles.error_text}>{errors['showRequired']}</span>
              )}
              <Field
                name={'email'}
                id={'email'}
                label='Email'
                component={TextField}
                height={'50px'}
                margin={'0px 0px 30px 0px'}
                placeholder={'Inserisci email'}
              />
              <Field
                name={'cellulare'}
                id={'cellulare'}
                label='Cellulare'
                component={TextField}
                height={'50px'}
                margin={'0px 0px 30px 0px'}
                placeholder={'Inserisci numero di cellulare'}
              />
            </div>
            <div className={clsx(styles.actions_container, styles.footer)}>
              <Button className={styles.confirm_button} variant='contained' type={'submit'}>
                Salva
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default NewClientModal;
