import { ROUTES } from '../../../routes/constants';
import { ReactComponent as Home } from '../../../assets/svg/icons/home.svg';
import { ReactComponent as Calendar } from '../../../assets/svg/icons/calendario.svg';
import { ReactComponent as Barber_pole } from '../../../assets/svg/icons/barber_pole.svg';
import { ReactComponent as Message } from '../../../assets/svg/icons/messaggi.svg';
import { ReactComponent as Bell } from '../../../assets/svg/icons/campanella.svg';
import { ReactComponent as Statistics } from '../../../assets/svg/icons/statistiche.svg';
import { ReactComponent as Shop } from '../../../assets/svg/icons/shop.svg';
import { ReactComponent as Articles } from '../../../assets/svg/icons/articoli.svg';
import { ReactComponent as Settings } from '../../../assets/svg/icons/impostazioni.svg';
import { ReactComponent as Client } from '../../../assets/svg/icons/profilo.svg';
import { ReactComponent as Payment } from '../../../assets/svg/icons/portafogli.svg';

import PaymentList from 'Containers/Payments/containers/PaymentList';

export const NAV_BAR_ROUTES = [
  {
    label: 'Home',
    link: ROUTES.HOME,
    icon: <Home />,
    newTab: false,
  },
  {
    label: 'Calendario',
    link: ROUTES.SCHEDULER,
    icon: <Calendar />,
    newTab: false,
  },
  {
    label: 'Gestione salone',
    link: ROUTES.SALON_MANAGEMENT,
    icon: <Barber_pole />,
    newTab: false,
  },
  {
    icon: <Client />,
    label: 'Clienti',
    link: `${ROUTES.CLIENTS}`,
    newTab: false,
  },
  {
    icon: <Payment />,
    label: 'Pagamenti',
    link: `${ROUTES.PAYMENTS}`,
    newTab: false,
  },
  {
    label: 'Comunicazioni',
    link: ROUTES.COMMUNICATIONS,
    icon: <Message />,
    newTab: false,
  },
  {
    label: 'Notifiche',
    link: ROUTES.NOTIFICATION,
    icon: <Bell />,
    newTab: false,
  },

  {
    label: 'Statistiche',
    link: ROUTES.STATISTICS,
    icon: <Statistics />,
    newTab: false,
  },
  {
    label: 'Shop',
    link: ROUTES.SHOP,
    icon: <Shop />,
    newTab: false,
  },
  {
    label: 'Articoli',
    link: process.env.REACT_APP_BLOG,
    icon: <Articles />,
    newTab: true,
  },
  {
    label: 'Impostazioni',
    link: ROUTES.SETTINGS,
    icon: <Settings />,
    newTab: false,
  },
];
