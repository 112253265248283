import clx from 'classnames';
import { useNavigate } from 'react-router-dom';
import styles from './NavItem.module.scss';

const addNomediv = (label) => {

  switch (label) {
    case 'Pagamenti':
      return 'payment';
    case 'Home':
      return 'home';
    default:
      return null;
  }
}

const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};
const NavItem = ({ navItem, activeItem, isOpen }) => {
  const navigate = useNavigate();

  return (
    <li
      className={clx(styles.nav__item, {
        [styles['nav-item__active']]: activeItem?.includes(navItem.link),
      })}
      data-nomediv={addNomediv(navItem.label)}
    >
      <div className={styles.vertical_marker} />
      <span
        className={styles.item__inner}
        onClick={() =>
          navItem.newTab
            ? openInNewTab(navItem.link)
            : activeItem !== navItem.link && navigate(navItem.link)
        }
      >
        {navItem.icon}
        {isOpen && <span>{navItem.label}</span>}
      </span>
    </li>
  );
};

export default NavItem;
