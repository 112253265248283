import React, { useState } from 'react';
import styles from './DaySelector.module.scss';
import dayjs from 'dayjs';
import { Button } from '@mui/material';
import { ReactComponent as ArrowRIcon } from '../../../../../../assets/svg/icons/arrow_r.svg';
import { ReactComponent as ArrowLIcon } from '../../../../../../assets/svg/icons/arrow_l.svg';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers1';
import { AdapterDayjs } from '@mui/x-date-pickers1/AdapterDayjs';
import useWindowSize from 'utils/useWindowSize';

const DaySelector = ({
  searchParams,
  setSearchParams,
  startDay,
  numberOfActiveDays,
  activeDays,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useWindowSize();
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        open={isOpen}
        onClose={() => setIsOpen(false)}
        renderInput={({ inputRef, inputProps, InputProps }) => {
          return (
            <div className={styles.calendar_day_selector} ref={inputRef}>
              <Button
                disableElevation
                className={styles.calendar_arrow_button}
                onClick={() => {
                  searchParams.set(
                    'startDay',
                    dayjs(startDay).subtract(+numberOfActiveDays, 'day').format('YYYY-MM-DD'),
                  );
                  setSearchParams(searchParams);
                }}
                variant='contained'
              >
                <ArrowRIcon />
              </Button>
              <div className={styles.day_value} onClick={() => setIsOpen(!isOpen)}>
                {activeDays.length > 1
                  ? `${dayjs(activeDays[0]).format('D MMMM YYYY')} - ${dayjs(
                      activeDays[activeDays.length - 1],
                    ).format('D MMMM YYYY')}`
                  : dayjs(activeDays[0]).format(
                      isMobile ? 'dddd,  D MMMM YYYY' : 'ddd,  D MMMM YYYY',
                    )}
              </div>
              <Button
                disableElevation
                className={styles.calendar_arrow_button}
                onClick={() => {
                  searchParams.set(
                    'startDay',
                    dayjs(startDay).add(numberOfActiveDays, 'day').format('YYYY-MM-DD'),
                  );
                  setSearchParams(searchParams);
                }}
                variant='contained'
              >
                <ArrowLIcon />
              </Button>
            </div>
          );
        }}
        label='DatePicker'
        value={dayjs(activeDays[0], 'YYYY-MM-DD')}
        ampm={false}
        onChange={(newValue) => {
          const newDate = dayjs(newValue)
            .add(numberOfActiveDays - 1, 'day')
            .format('YYYY-MM-DD');
          searchParams.set('startDay', newDate);
          setSearchParams(searchParams);
        }}
      />
    </LocalizationProvider>
  );
};

export default DaySelector;
