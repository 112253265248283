import clx from 'classnames';
import { useEffect, useState } from 'react';

import { ReactComponent as EyeIcon } from '../../../assets/svg/eye.svg';
import { ReactComponent as MailIcon } from '../../../assets/svg/icons/mail.svg';
import { ReactComponent as LockIcon } from '../../../assets/svg/icons/lock.svg';

import styles from './TextField.module.scss';
const TextField = ({
  field,
  form,
  className,
  label,
  width = '100%',
  inputWidth = '100%',
  labeledTag,
  margin = '0 0 10px 0',
  type = 'text',
  inputColor = '#F4F4F4',
  inputDisabledColor = '#F4F4F4',
  textColor = '',
  borderRadius = '8px',
  border = 'none',
  textarea = false,
  checkBoxAsRadio = false,
  height = '42px',
  showPassword = false,
  showIcon = false,
  iconComponent,
  ...props
}) => {
  const [inputType, setInputType] = useState(type);
  const { touched, errors } = form || {};
  const isError =
    touched && errors
      ? field?.name.split('.').reduce(
        (acc, item) =>
          acc.errors[item] && acc.touched[item]
            ? {
              errors: acc.errors[item],
              touched: acc.touched[item],
              errorText: acc.errors[item],
            }
            : {
              errors,
              touched,
              errorText: '',
            },
        { errors, touched, errorText: '' },
      )
      : '';

  useEffect(() => {
    setInputType(type);
  }, [type]);

  return (
    <div
      className={clx(styles.field__wrapper, styles[`${className}`], {
        [styles['full-height']]: textarea && height === '100%',
      })}
      style={{ margin, width, opacity: props.disabled ? 0.4 : 1 }}
    >
      {label && !checkBoxAsRadio ? (
        <label className={styles['field__label']} htmlFor={field?.name}>
          {label}
        </label>
      ) : (
        labeledTag
      )}
      {checkBoxAsRadio ? (
        <div className={styles.checkbox_as_radio_wrapper}>
          <input
            type={'checkbox'}
            className={clx(styles['field__radio'], { [styles['error-radio']]: isError })}
            {...field}
            {...props}
            checked={field.value}
          />
          <label className={styles['radio__label']} htmlFor={field?.name}>
            {label}
          </label>
        </div>
      ) : textarea ? (
        <textarea
          className={clx(styles['field__textarea'], {
            [styles['error-textarea']]: !!isError.errorText,
          })}
          {...field}
          {...props}
          style={{
            borderRadius,
            border,
            height,
            background: `${props.disabled ? inputDisabledColor : inputColor}`,
            textColor
          }}
        />
      ) : (
        <div
          className={clx(styles['input-group'], { [styles['error-field']]: !!isError.errorText })}
          style={{
            borderRadius,
            height,
            border,
            width: inputWidth,
            background: `${props.disabled ? inputDisabledColor : inputColor}`,
            gap: '10px'
          }}
        >
          {!!showIcon && (<div className={styles['input_icon']}>
            {type === 'email' && (
              <MailIcon className={styles['icon']} />
            )}
            {type === 'password' && (
              <LockIcon className={styles['icon']} />
            )}
          </div>)}
          <input
            className={styles['field__input']}
            type={inputType}
            autoComplete='new-password'
            {...field}
            {...props}
            style={{
              background: `${props.disabled ? inputDisabledColor : inputColor}`,
              color: textColor
            }}
          />
          {showPassword && (
            <EyeIcon
              className={
                !isError.errorText ? styles['show-pwd-icon'] : styles['show-pwd-icon__red']
              }
              onClick={() => {
                if (inputType === 'password') {
                  setInputType('text');
                } else {
                  setInputType('password');
                }
              }}
            />
          )}
          {iconComponent}
        </div>
      )}
      {!!isError.errorText && <div className={styles.error}>{isError.errorText}</div>}
    </div>
  );
};

export default TextField;
