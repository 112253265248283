import React, { useState } from 'react';
import styles from "./SalonClosuresDaysSelectorStep.module.scss";
import { authActions } from "../../../../../store";
import { useDispatch } from "react-redux";
import { SalonWorkTimeStepWrapper } from "../SalonWorkTimeStepWrapper";
import WeekDaySelector from "../../../../../../Common/WeekDaySelector";

export const SalonClosuresDaysSelectorStepContainer = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState([])
  const [isAllDays, setIsAllDays] = useState(false)

  return (
    <SalonWorkTimeStepWrapper
      title={'In quali giorni ti riposi?'}
      action={() => {
        dispatch(authActions.SET_SALON_WORK_TIMES.REQUEST(
          { closuresDays: isAllDays ? [] : value },
          () => dispatch(authActions.GET_ACTIVE_SIGN_UP_STEP.SUCCESS(8))
        ))
      }}
    >
      <div className={styles.salon_picker}>
        <div className={styles.selector_container}>
          <WeekDaySelector
            isMultiply={true}
            dayIndex={isAllDays ? [] : value}
            setDayIndex={!isAllDays ? setValue : () => { }}
            title={'Seleziona uno o più giorni di chiusura per il tuo salone'}
            likeButton={true}
          />
        </div>
        <label className={styles.checkbox_container}>
          Nessuna chiusura
          <input
            className={styles.closures_checkbox}
            checked={isAllDays}
            onChange={(e) => setIsAllDays(e.target.checked)} type="checkbox"
          />
        </label>
      </div>
    </SalonWorkTimeStepWrapper>
  )
}