import React from 'react';
import styles from "./WelcomeStep.module.scss";
import LogoImage from "../../../../../../assets/images/logoWhite.png";
import ForwardButton from "../customComponents/ForwardButton";
import {useDispatch} from "react-redux";
import {authActions} from "../../../../store";

export const WelcomeStepContainer = () => {
  const dispatch = useDispatch();
  return (
    <div className={styles.welcome_step_container}>
      <div className={styles.welcome_step_content}>
        <div className={styles.welcome_step_description}>
          Mancano solo 3 piccoli dettagli per completare il tuo salone. Vediamo insieme come impostare orari, collaboratori e servizi
        </div>
        <ForwardButton onAction={() => dispatch(authActions.GET_ACTIVE_SIGN_UP_STEP.SUCCESS(2))}/>
      </div>
    </div>
  )
}

export const WelcomeStepFirstPreview = () => {
  return (
    <div className={styles.welcome_step_first_preview}>
        <div/>
        <h1>Benvenuto in <span>zetabarber</span></h1>
        <div className={styles.logo_container}>
          <img src={LogoImage} alt="logo" className={styles.logo}/>
        </div>
    </div>
  )
}