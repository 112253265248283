import clx from 'classnames';
import { ReactComponent as CloseIcon } from '../../../assets/svg/cross.svg';
import styles from './Modal.module.scss';
import ReactDOM from 'react-dom';
import useWindowSize from '../../../utils/useWindowSize';

const RenderModal = (props) => {
  return ReactDOM.createPortal(props.children, document.getElementById('modal-root'));
};

const Modal = ({
  isModalOpen,
  setIsModalOpen,
  children,
  height = 'auto',
  width = '',
  maxHeight = 'auto',
  maxWidth = '100%',
  showCloseBtn = false,
  isError = false,
  title = '',
  subTitle = '',
  borderRadius = '20px',
  fullScreen = false,
}) => {
  const { isMobile } = useWindowSize();

  return (
    <>
      {!!isModalOpen && (
        <RenderModal>
          <div
            className={clx(styles['modal__wrapper'], {
              [styles.is_error]: isError,
            })}
          >
            <div
              className={clx(styles['modal'], {
                [styles.modal_mobile]: isMobile && !isError,
              })}
              style={{
                width,
                maxWidth,
                height: isMobile && fullScreen ? '100dvh' : height,
                maxHeight,
                borderRadius: isMobile && fullScreen ? '0px' : borderRadius,
              }}
            >
              {title || showCloseBtn ? (
                <>
                  <div>
                    <div className={styles.modal__header}>
                      <div className={styles.modal__title}>
                        {title}
                        <span className={styles.modal__title_bold}>{subTitle}</span>
                      </div>

                      {showCloseBtn && (
                        <div
                          className={styles.close_button}
                          style={{ padding: 10 }}
                          src={CloseIcon}
                          alt='close'
                          loading='lazy'
                          onClick={setIsModalOpen}
                        >
                          <CloseIcon />
                        </div>
                      )}
                    </div>
                    <hr />
                  </div>
                </>
              ) : null}
              {children}
            </div>
          </div>
        </RenderModal>
      )}
    </>
  );
};

export default Modal;
