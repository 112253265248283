import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './accountModal.module.scss';
import { Field, Form, Formik } from 'formik';
import { Button } from '@mui/material';
import { salonManagementActions } from 'Containers/SalonManagement/store';
import TextField from 'Containers/Common/TextField';
import { ReactComponent as Armchair } from 'assets/svg/icons/armchair_blue.svg';
import * as yup from 'yup';
import ButtonSelector from 'Containers/Common/CustomSelector/ButtonSelector';
import clsx from 'clsx';
import useWindowSize from 'utils/useWindowSize';
import InputSelector from 'Containers/Common/CustomSelector/InputSelector';
import { getWorkers } from 'Containers/SalonManagement/store/selectors';
import { getIconUrl, imageEntities } from 'utils/ImagesCreator';
import SectionsButtonSelector from '../SectionsButtonSelector';
import { SUBSECTIONS } from '../constants';
import { getAccountsWorkers } from 'Containers/Settings/store/selectors';
import { settingsActions } from 'Containers/Settings/store';
import { toastActions } from 'Containers/Toasts/store';

export default ({ closeModal, account, }) => {
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const workers = useSelector(getWorkers());
  const accountsWorkers = useSelector(getAccountsWorkers());

  useEffect(() => {
    if(!workers.length) {
        dispatch(salonManagementActions.GET_WORKERS.REQUEST());
    }
  }, []);
  
  const validationSchema = {
    email: yup.string().email('Inserisci un indirizzo email valido').required('Campo obbligatorio'),
    poltrona: yup.number().required('Campo obbligatorio'),
    settori: yup.array().min(1, 'Seleziona almeno un settore').required('Seleziona almeno un settore'),
  };

  const initialValues = {
    email: account?.email || '',
    poltrona: account?.poltrona?.idpoltrona || '',
    settori: account?.permessi || [],
  };

  const getWorkersOptions = (poltrona) => {
    if(account?.idUtenteBusiness === undefined) {
        return workers.filter(w => !accountsWorkers.includes(w.idpoltrona)).map((worker) => ({
            name: worker.nome_referente,
            value: worker.idpoltrona,
            icon: getIconUrl(worker.idpoltrona, imageEntities.worker),
        }));
    }
    else {
        return workers.map((worker) => ({
            name: worker.nome_referente,
            value: worker.idpoltrona,
            icon: getIconUrl(worker.idpoltrona, imageEntities.worker),
        }));
    }
  };

  const handleClose = () => {
    closeModal();
    dispatch(toastActions.OPEN_TOAST.SUCCESS({
        type: 'worker',
        props: { title:  'Account staff!', message: account?.idUtenteBusiness ? 'Account modificato con successo' : 'Account aggiunto con successo', warning: false },
    }))
  }

return (
    <Formik
        initialValues={initialValues}
        validationSchema={yup.object().shape(validationSchema)}
        onSubmit={(values) => {
            const req = {
                email: values.email,
                poltrona: values.poltrona,
                nome_referente: workers.find(w => w.idpoltrona === values.poltrona)?.nome_referente,
                permessi: values.settori.map((settore) => {
                    let permesso = settore;
                    delete permesso.fields;
                    return permesso;
                }),
            };

            if (account?.idUtenteBusiness !== undefined && account?.idUtenteBusiness !== null) {
                dispatch(settingsActions.EDIT_ACCOUNT_STAFF.REQUEST({idUtenteBusiness: account.idUtenteBusiness, ...req}, handleClose));
            }
            else {
                dispatch(settingsActions.ADD_ACCOUNT_STAFF.REQUEST(req, handleClose));
            }
        }}
    >
        {({ values, setFieldValue, touched, setFieldTouched, errors }) => (
            <Form
                className={clsx(styles.account_modal, {
                    [styles.account_modal_mbl]: isMobile,
                })}
            >
                <div className={styles.body}>
                    <Field
                        name={'email'}
                        id={'email'}
                        label='Email'
                        component={TextField}
                        height={'50px'}
                        margin={'0px'}
                        placeholder={'Inserisci la tua email'}
                        error={touched.email && errors.email}
                    />
                    <InputSelector
                     options={getWorkersOptions(values.poltrona)}
                      value={values.poltrona}
                      setValue={(value) => {
                        setFieldValue('poltrona', value);
                        setFieldTouched('poltrona', false);
                      }}
                      placeholder={'Seleziona la poltrona'}
                      title={'Assegna Poltrona'}
                      icon={<Armchair />}
                      withIcon={true}
                      error={touched.poltrona && errors.poltrona}
                      isEmptyMessage={'Non ci sono poltrone disponibili'}
                    />
                    <SectionsButtonSelector
                        title={'Seleziona i settori a cui puo’ accedere l’utente'}
                        sections={SUBSECTIONS}
                        selectedList={values.settori || []}
                        setSelectedList={(selected) => setFieldValue('settori', selected)}
                    />
                </div>
                <hr />
                <div className={clsx(styles.actions_container, styles.footer)}>
                    <Button className={styles.confirm_button} variant='contained' type={'submit'}>
                        Salva
                    </Button>
                </div>
            </Form>
        )}
    </Formik>
);
};
