import styles from './EditSuccessModal.module.scss';
import {ReactComponent as Success} from '../../../../assets/svg/icons/green_success.svg';
import React from "react";

const ConfirmModal = (
  {
    title,
  }) => {
  return (
    <div className={styles.modal__content}>
      <Success/>
      <div className={styles.title}>{title}</div>
    </div>
  )
}

export default ConfirmModal;
