import styles from './WeekDaySelector.module.scss';
import dayjs from 'dayjs';
import clx from 'classnames';
import useWindowSize from '../../../utils/useWindowSize';

const WeekDaySelector = ({
  dayIndex,
  setDayIndex,
  title,
  isMultiply,
  error,
  likeButton = false,
}) => {
  const weekDays = [1, 2, 3, 4, 5, 6, 0];
  const { isMobile } = useWindowSize();
  const selectValueHandler = (day) => {
    if (isMultiply) {
      const isIncludeValue = dayIndex.includes(day);
      if (isIncludeValue) {
        setDayIndex(dayIndex.filter((value) => value !== day));
      } else {
        setDayIndex([...dayIndex, day]);
      }
    } else {
      setDayIndex(day);
    }
  };
  return (
    <div
      className={clx(styles.day_selector_wrapper, {
        [styles.day_selector_wrapper_like_button]: likeButton,
        [styles.day_selector_wrapper_mobile]: isMobile,
      })}
    >
      {title && <div className={styles.day_selector_title}>{title}</div>}
      <div className={styles.day_selector_item_container}>
        {weekDays.map((day) => {
          return (
            <div
              onClick={() => selectValueHandler(day)}
              key={day}
              className={clx(styles.day_selector_item, {
                [styles.day_selector_item_active]: dayIndex?.includes(day),
              })}
            >
              <div className={styles.item_value}>
                {dayjs()
                  .day(day)
                  .format(likeButton ? 'dddd' : 'dd')}
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.error_message}>{error}</div>
    </div>
  );
};

export default WeekDaySelector;
