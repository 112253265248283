import React from 'react';
import styles from './salonIbanModal.module.scss';
import useWindowSize from 'utils/useWindowSize';
import { useDispatch } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { Button } from '@mui/material';
import TextField from '../../../Common/TextField';
import * as yup from 'yup';
import clsx from 'clsx';
import { authActions } from 'Containers/Auth/store';

const EditSalonIbanModal = ({ activeSalon, closeModal = () => {} }) => {
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();
  const ibanRegex =
    /^([A-Z]{2}[0-9]{2})(?=(?:[A-Z0-9]){9,30}$)((?:[A-Z0-9]{3,5}){2,7})([A-Z0-9]{1,3})?$/;

  const validationSchema = {
    titolare_iban: yup
      .string("Inserisci l'intestatario del conto")
      .required('Questo campo è obbligatorio'),
    iban: yup.string('Inserisci numero iban').matches(ibanRegex, 'Iban non valido').required('Obbligatorio'),
  };

  const initialValues = {
    salone: activeSalon.idsalone,
    titolare_iban: activeSalon.titolare_iban ?? '',
    iban: activeSalon.iban ?? '',
    nomeSalone: activeSalon.nome_salone,
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={yup.object().shape(validationSchema)}
        onSubmit={(values) => {
          const req = {
            salone: values.salone,
            titolare_iban: values.titolare_iban ?? '',
            iban: values.iban ?? '',
            nomeSalone: values.nomeSalone,
          };

          dispatch(authActions.EDIT_SALON_INFO.REQUEST(req));
          return closeModal();
        }}
      >
        {({ errors }) => (
          <Form
            className={clsx(styles.client_modal_wrapper, {
              [styles.client_modal_wrapper_mobile]: isMobile,
            })}
          >
            <div className={styles.body}>
              <Field
                name={'titolare_iban'}
                id={'titolare_iban'}
                label='Intestatario del conto'
                component={TextField}
                height={'50px'}
                margin={'0px 0px 30px 0px'}
                placeholder={"Inserisci l'intestatario del conto"}
              />
              <Field
                name={'iban'}
                id={'iban'}
                label='Iban'
                component={TextField}
                height={'50px'}
                margin={'0px 0px 30px 0px'}
                placeholder={'Inserisci numero iban'}
              />
            </div>
            <div className={clsx(styles.actions_container, styles.footer)}>
              <Button className={styles.confirm_button} variant='contained' type={'submit'}>
                Salva
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EditSalonIbanModal;
