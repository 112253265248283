import { getActiveSalon } from "Containers/Auth/store/selectors";
import React, { useState } from "react";
import styles from './fatturazione.module.scss';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useWindowSize from "utils/useWindowSize";
import TextField from "Containers/Common/TextField";
import { Form, Formik, Field } from "formik";
import * as yup from 'yup';
import clsx from "clsx";
import { Button } from "@mui/material";
import { ReactComponent as SettingIcon } from 'assets/svg/settings.svg';
import SelectorMenu from "Containers/Common/CustomSelector/SelectorMenu";
import { authActions } from "Containers/Auth/store";

const codicePaese = [
    {
        label: 'Austria',
        value: 'AT',
        regex: /^((AT)?U[0-9]{8})?$/
    },
    {
        label: 'Belgio',
        value: 'BE',
        regex: /^((BE)?0[0-9]{9})?$/
    },
    {
        label: 'Bulgaria',
        value: 'BG',
        regex: /^((BG)?[0-9]{9,10})?$/
    },
    {
        label: 'Cipro',
        value: 'CY',
        regex: /^((CY)?[0-9]{8}L)?$/
    },
    {
        label: 'Croazia',
        value: 'HR',
        regex: /^((HR)?[0-9]{11})?$/
    },
    {
        label: 'Danimarca',
        value: 'DK',
        regex: /^((DK)?[0-9]{8})?$/
    },
    {
        label: 'Estonia',
        value: 'EE',
        regex: /^((EE)?[0-9]{9})?$/
    },
    {
        label: 'Finlandia',
        value: 'FI',
        regex: /^((FI)?[0-9]{8})?$/
    },
    {
        label: 'Francia',
        value: 'FR',
        regex: /^((FR)?[0-9A-Z]{2}[0-9]{9})?$/
    },
    {
        label: 'Germania',
        value: 'DE',
        regex: /^((DK)?[0-9]{9})?$/
    },
    {
        label: 'Grecia',
        value: 'EL',
        regex: /^((EL|GR)?[0-9]{9})?$/
    },
    {
        label: 'Irlanda',
        value: 'IE',
        regex: /^((IE)?[0-9]S[0-9]{5}L)?$/
    },
    {
        label: 'Italia',
        value: 'IT',
        regex: /^((IT)?[0-9]{11})?$/
    },
    {
        label: 'Lettonia',
        value: 'LV',
        regex: /^((LV)?[0-9]{11})?$/
    },
    {
        label: 'Lituania',
        value: 'LT',
        regex: /^((LT)?([0-9]{9,12}))?$/
    },
    {
        label: 'Lussemburgo',
        value: 'LU',
        regex: /^((LU)?[0-9]{8})?$/
    },
    {
        label: 'Malta',
        value: 'MT',
        regex: /^((MT)?[0-9]{8})?$/
    },
    {
        label: 'Paesi Bassi',
        value: 'NL',
        regex: /^((NL)?[0-9]{9}B[0-9]{2})?$/
    },
    {
        label: 'Polonia',
        value: 'PL',
        regex: /^((PL)?[0-9]{10})?$/
    },
    {
        label: 'Portogallo',
        value: 'PT',
        regex: /^((PT)?[0-9]{9})?$/
    },
    {
        label: 'Regno Unito',
        value: 'GB',
        regex: /^((GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3}))?$/
    },
    {
        label: 'Repubblica Ceca',
        value: 'CZ',
        regex: /^((CZ)?[0-9]{8,10})?$/
    },
    {
        label: 'Romania',
        value: 'RO',
        regex: /^((RO)?[0-9]{2,10})?$/
    },
    {
        label: 'Romania',
        value: 'RO',
        regex: /^((RO)?[0-9]{2,10})?$/
    },
]


const StripeForm = () => {
    const dispatch = useDispatch();
    const { isMobile } = useWindowSize();
    const navigate = useNavigate();
    const activeSalon = useSelector(getActiveSalon());
    //p.iva tester IT12526760967
    const [value, setValue] = useState('IT')
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [regExp, setRegExp] = useState(/^((IT)?[0-9]{11})?$/);
    const { cusStripe } = activeSalon;
    const stateRegex = /^([A-Z]{2})?$/


    const validationSchema = {
        pec: yup
            .string('Inserisci una pec valida')
            .required('Questo campo è obbligatorio'),
        phone: yup
            .string('Inserisci numero di cellulare')
            .min(9, 'Formato non valido')
            .required('Questo campo è obbligatorio'),
        pIva: yup
            .string('Inserisci la partita iva')
            .matches(codicePaese.find(vat => vat.value === 'IT').regex, 'Partita iva non valida'),
        city: yup
            .string('Inserisci città')
            .required('Questo campo è obbligatorio'),
        country: yup
            .string('Inserisci una pec valida')
            .required('Questo campo è obbligatorio'),
        line1: yup
            .string('Inserisci una pec valida')
            .required('Questo campo è obbligatorio'),
        line2: yup
            .string('Inserisci una pec valida')
            .required('Questo campo è obbligatorio'),
        postal_code: yup
            .string('Inserisci una pec valida')
            .required('Questo campo è obbligatorio'),
        state: yup
            .string('Inserisci una pec valida')
            .matches(stateRegex, "Regione non valida (utilizza il formato 'RM')")
            .required('Questo campo è obbligatorio'),
    };

    const initialValues = {
        pec: '',
        codiceVat: 'eu_vat',
        pIva: activeSalon['P.IVA'] ?? '',
        nomeSalone: activeSalon.nome_salone ?? '',
        phone: activeSalon.tel ?? '',
        city: '',
        country: 'IT',
        line1: '',
        line2: '',
        postal_code: '',
        state: '',
    };


    return (<>
        <Formik
            initialValues={initialValues}
            validationSchema={yup.object().shape(validationSchema)}
            onSubmit={(values) => {
                const customer = {
                    name: values.nomeSalone,
                    phone: values.phone,
                    email: values.pec,
                    address: {
                        city: values.city,
                        country: values.country,
                        line1: values.line1,
                        line2: values.line2,
                        postal_code: values.postal_code,
                        state: values.state,
                    },
                    tax_id_data: [{
                        type: values.codiceVat,
                        value: values.pIva.includes('IT') ? values.pIva : `IT${values.pIva}`,
                    }],
                };
                dispatch(authActions.CREATE_CUSTOMER.REQUEST({ customer }, () => window.location.reload()))
            }}
        >
            {({ errors }) => (
                <Form
                    className={clsx(styles.container, { [styles.container_mobile]: isMobile })}
                >
                    <div className={styles.body}>
                        <div className={styles.field}>
                            <Field
                                name={'nomeSalone'}
                                id={'nomeSalone'}
                                label='Ragione Sociale'
                                component={TextField}
                                height={'50px'}
                                margin={'0px 0px 30px 0px'}
                                placeholder={'Ragione Sociale'}
                            />
                        </div>
                        <div className={styles.field}>
                            <Field
                                name={'phone'}
                                id={'phone'}
                                label='Telefono fisso'
                                component={TextField}
                                height={'50px'}
                                margin={'0px 0px 30px 0px'}
                                placeholder={'Telefono Fisso'}
                            />
                        </div>
                        <div className={styles.field}>
                            <Field
                                name={'pec'}
                                id={'pec'}
                                label='Pec'
                                component={TextField}
                                height={'50px'}
                                margin={'0px 0px 30px 0px'}
                                placeholder={'zetabarber@pec.it'}
                            />
                        </div>
                        <div className={styles.field}>
                            <Field
                                name={'pIva'}
                                id={'pIva'}
                                label='Partita Iva'
                                component={TextField}
                                height={'50px'}
                                margin={'0px 0px 30px 0px'}
                                placeholder={'Inserisci qui la partita iva'}
                            />
                        </div>
                        <div style={{ borderBottom: '1px solid #7878784d', marginBottom: '20px', paddingBottom: '10px', width: '100%' }}>Indirizzo di fatturazione</div>
                        <div className={styles.field} style={{ width: '35%' }}>
                            <Field
                                name={'city'}
                                id={'city'}
                                label='Città'
                                component={TextField}
                                height={'50px'}
                                margin={'0px 0px 30px 0px'}
                                placeholder={'Città'}
                            />
                        </div>
                        <div className={styles.field} style={{ width: '20%' }}>
                            <Field
                                name={'country'}
                                id={'country'}
                                label='Paese'
                                component={TextField}
                                height={'50px'}
                                margin={'0px 0px 30px 0px'}
                                placeholder={'IT'}
                            />
                        </div>
                        <div className={clsx(styles.field, { [styles.field_little]: !isMobile })}>
                            <Field
                                name={'line1'}
                                id={'line1'}
                                label='Via - Piazza'
                                component={TextField}
                                height={'50px'}
                                margin={'0px 0px 30px 0px'}
                                placeholder={'Via'}
                            />
                        </div>
                        <div className={clsx(styles.field, styles.field_little)}>
                            <Field
                                name={'line2'}
                                id={'line2'}
                                label='Civico'
                                component={TextField}
                                height={'50px'}
                                margin={'0px 0px 30px 0px'}
                                placeholder={'Civico'}
                            />
                        </div>
                        <div className={styles.field} style={{ width: '30%' }}>
                            <Field
                                name={'postal_code'}
                                id={'postal_code'}
                                label='Cap'
                                component={TextField}
                                height={'50px'}
                                margin={'0px 0px 30px 0px'}
                                placeholder={'Cap'}
                            />
                        </div>
                        <div className={clsx(styles.field, styles.field_little)}>
                            <Field
                                name={'state'}
                                id={'state'}
                                label='Provincia'
                                component={TextField}
                                height={'50px'}
                                margin={'0px 0px 30px 0px'}
                                placeholder={'Provincia'}
                            />
                        </div>
                    </div>
                    <div className={clsx(styles.actions_container, styles.footer)}>
                        <Button className={styles.confirm_button} variant='contained' type={'submit'}>
                            <SettingIcon className={styles.icon} height='17px' width='17px' />
                            Aggiungi dati fatturazione
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    </>);
}

export default StripeForm;