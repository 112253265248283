import Modal from '../Modal';
import styles from './ConfirmModal.module.scss';
import { Button } from '@mui/material';
import React from 'react';

const ConfirmModal = ({ isModalOpen, setIsModalOpen, title, content, action }) => (
  <Modal
    width='450px'
    // height={content ? '300px' : '230px'}
    isModalOpen={isModalOpen}
    setIsModalOpen={setIsModalOpen}
  >
    <div className={styles.modal__content}>
      <div className={styles.title}>{title}</div>
      {content}
      <div className={styles.modal__actions}>
        <Button onClick={() => setIsModalOpen(false)} className={styles.return_button}>
          No
        </Button>
        <Button className={styles.confirm_button} variant='contained' onClick={action}>
          Si
        </Button>
      </div>
    </div>
  </Modal>
);

export default ConfirmModal;
