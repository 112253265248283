import { settingsTypes } from './actions';

const initialState = {
  customerPortal: '',
  bill: {},
  plans: {},
  invoices: {},
  products: {},
  accounts: [],
  myAccount: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case settingsTypes.CREATE_CUSTOMER_PORTAL.SUCCESS:
      return {
        ...state,
        customerPortal: action.payload,
      };
    case settingsTypes.GET_CUSTOMER_BILL.SUCCESS:
      return {
        ...state,
        bill: action.payload,
      };
    case settingsTypes.GET_CUSTOMER_PLAN.SUCCESS:
      return {
        ...state,
        plans: action.payload,
      };
    case settingsTypes.GET_CUSTOMER_PRODUCTS.SUCCESS:
      return {
        ...state,
        products: action.payload,
      };
    case settingsTypes.GET_CUSTOMER_INVOICE.SUCCESS:
      return {
        ...state,
        invoices: action.payload,
      };
      case settingsTypes.ACCEPT_TERMS.SUCCESS:
      return {
        ...state,
        products: {...state.products, acceptedTerms: true},
      };
      case settingsTypes.GET_ACCOUNTS_STAFF.SUCCESS:
      return {
        ...state,
        accounts: action.payload,
      };
      case settingsTypes.ADD_ACCOUNT_STAFF.SUCCESS:
      return {
        ...state,
        accounts: [...state.accounts, action.payload],
      };
      case settingsTypes.EDIT_ACCOUNT_STAFF.SUCCESS:
      return {
        ...state,
        accounts: state.accounts.map((account) => {
          if (account.idUtenteBusiness === action.payload.idUtenteBusiness) {
            return action.payload;
          }
          return account;
        }),
      };
      case settingsTypes.DELETE_ACCOUNT_STAFF.SUCCESS:
      return {
        ...state,
        accounts: state.accounts.filter((account) => account.idUtenteBusiness !== action.payload.idUtenteBusiness),
      };
      case settingsTypes.GET_MY_ACCOUNT_STAFF.SUCCESS:
      return {
        ...state,
        myAccount: action.payload,
      };
    default:
      return { ...state };
  }
};
