import React from 'react';
import styles from "./FinalStep.module.scss";

export const FinalStepContainer = () => {
  return (
    <div className={styles.welcome_step_first_preview}>
      <h1>Buon <span className={styles.blue_inject}>lavoro</span> <span className={styles.gold_inject}>barber</span></h1>
    </div>
  )
}

export const FinalStepFirstPreview = () => {
  return (
    <div className={styles.final_step_first_preview}>
      <h2>
        <span className={styles.blue_inject}>Congratulazioni</span> hai completato
        <br/>
        tutti gli step iniziali, ora puoi
        <br/>
        utilizzare al <span className={styles.gold_inject}>meglio</span> la piattaforma
      </h2>
    </div>
  )
}
