import { call, put, take, takeLatest } from 'redux-saga/effects';

import { Api } from '../../../entries/ApiTransport';
import sagaAssessor from '../../../utils/sagaAssessor';
import { paymentTypes, clientActions, paymentActions } from './actions';
import { toastActions } from 'Containers/Toasts/store';

const api = Api.getInstance();

const getPayments = ({ payload, callback, options }) =>
  sagaAssessor(
    () =>
      function* () {
        const {
          data: { pagamenti, total_rows, total_euro, total_row_euro, total_payment },
        } = yield call(() => api.post('/business/pagamenti/all', payload));
        yield put(paymentActions.GET_PAYMENTS.SUCCESS({ pagamenti, total_rows, total_euro, total_rows_euro: total_row_euro, total_payment }));
        callback?.(pagamenti);
      },
    (err) => paymentActions.GET_PAYMENTS.FAILED(err),
  );
const getPayment = ({ payload, callback, options }) =>
  sagaAssessor(
    () =>
      function* () {
        const { idTransazione, importo, idPayment } = payload;
        const { data } = yield call(() =>
          api.get('/business/pagamenti/getPrenById?id=' + idPayment, {
            data: {
              idTransazione,
              importo,
            },
          }),
        );
        yield put(paymentActions.GET_PAYMENT.SUCCESS({ data }));
        callback?.(data);
      },
    (err) => paymentActions.GET_PAYMENT.FAILED(err),
  );
const requestRefund = ({ payload, callback, options }) =>
  sagaAssessor(
    () =>
      function* () {
        const { idTransazione, importo } = payload;
        const { data } = yield call(() =>
          api.post('/business/pagamenti/rimborso', {
            idTransazione,
            importo,
          }),
        );
        yield put(paymentActions.REQUEST_REFUND.SUCCESS(data));
        callback?.(data);
      },
    (err) => paymentActions.REQUEST_REFUND.FAILED(err),
  );

const getBonifici = ({ payload, callback, options }) =>
  sagaAssessor(
    () =>
      function* () {
        const {
          data: { bonifici },
        } = yield call(() => api.get('/business/pagamenti/bonifici'));
        yield put(paymentActions.GET_BONIFICI.SUCCESS({ bonifici }));
        callback?.(bonifici);
      },
    (err) => paymentActions.GET_BONIFICI.FAILED(err),
  );
const sendRequestBonifici = ({ payload, callback, options }) =>
  sagaAssessor(
    () =>
      function* () {
        const {data} = yield call(() => api.post('/business/pagamenti/richiesta', payload));
        yield put(paymentActions.GET_BONIFICI.SUCCESS(data));
        yield put(
          toastActions.OPEN_TOAST.SUCCESS({
            type: 'payment',
            props: { title: 'La tua richiesta per il bonifico è stata inviata!' },
          }),
        );
        callback?.();
      },
    (err) => paymentActions.SEND_REQUEST.FAILED(err),
  );

export default function* () {
  yield takeLatest(paymentTypes.GET_PAYMENTS.REQUEST, getPayments);
  yield takeLatest(paymentTypes.REQUEST_REFUND.REQUEST, requestRefund);
  yield takeLatest(paymentTypes.GET_PAYMENT.REQUEST, requestRefund);
  yield takeLatest(paymentTypes.GET_BONIFICI.REQUEST, getBonifici);
  yield takeLatest(paymentTypes.SEND_REQUEST.REQUEST, sendRequestBonifici);
}
