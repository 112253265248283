import { call, put, takeLatest } from 'redux-saga/effects';

import { Api } from '../../../entries/ApiTransport';
import sagaAssessor from '../../../utils/sagaAssessor';
import { homeActions, homeTypes } from './actions';

const api = Api.getInstance();

const getHomeStatistic = ({ ...options }) =>
  sagaAssessor(
    () =>
      function* () {
        const {
          data: { statSalone },
        } = yield call(() => api.get('/business/saloni/getHomeStat'));
        if (statSalone) {
          localStorage.setItem('statSalone', JSON.stringify(statSalone));
        }
        yield put(homeActions.GET_HOME_STAT.SUCCESS(statSalone));
      },
    (err) => homeActions.GET_HOME_STAT.FAILED(err),
  );

const getQueue = () =>
  sagaAssessor(
    () =>
      function* () {
        const {
          data: { listaAttesa },
        } = yield call(() => api.get('/business/getQueue'));
        yield put(homeActions.GET_QUEUE.SUCCESS(listaAttesa));
      },
    (err) => homeActions.GET_QUEUE.FAILED(err),
  );

const getOnboardingData = () =>
  sagaAssessor(
    () =>
      function* () {
        const {data} = yield call(async () => await api.get('/business/onboardingData'));
        yield put(homeActions.GET_ONBOARDING.SUCCESS(data));
      },
    (err) => homeActions.GET_ONBOARDING.FAILED(err),
  );

export default function* () {
  yield takeLatest(homeTypes.GET_HOME_STAT.REQUEST, getHomeStatistic);
  yield takeLatest(homeTypes.GET_QUEUE.REQUEST, getQueue);
  yield takeLatest(homeTypes.GET_ONBOARDING.REQUEST, getOnboardingData);
}
