import { Switch } from "@mui/material";
import { styled } from '@mui/material/styles';

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: 'white !important',
            '& + .MuiSwitch-track': {
            backgroundColor: '#858AF9', //theme.palette.mode === 'dark' ? '#858AF9' : '#65C466',
            opacity: 1,
            border: 0,
            },
            // '&.Mui-disabled + .MuiSwitch-track': {
            //   opacity: 0.5,
            // },
        },
        // '&.Mui-focusVisible .MuiSwitch-thumb': {
        //     color: '#fff',
        //     border: '6px solid #fff',
        // },
        //   '&.Mui-disabled .MuiSwitch-thumb': {
        //     color:
        //       theme.palette.mode === 'light'
        //         ? theme.palette.grey[100]
        //         : theme.palette.grey[600],
        //   },
        //   '&.Mui-disabled + .MuiSwitch-track': {
        //     opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        //   },
        },
        '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
        color: 'white',
        },
        '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#E0E0E0',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
        },
    }));

export default IOSSwitch;
