import styles from './StatisticContainer.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { getStatistic } from '../../store/selectors';
import { getActiveSalonId } from '../../../Auth/store/selectors';
import { statisticActions } from '../../store';
import { getActiveSalonToken } from '../../../../utils/salonToken';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const options = {
  responsive: true,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: true,
      text: 'Chart.js Line Chart - Multi Axis',
    },
  },
  scales: {
    y: {
      type: 'linear',
      display: true,
      position: 'left',
    },
    y1: {
      type: 'linear',
      display: true,
      position: 'right',
      grid: {
        drawOnChartArea: false,
      },
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

const data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: [12, 19, 3, 5, 2, 3, 19],
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
      yAxisID: 'y',
    },
    {
      label: 'Dataset 2',
      data: [2, 9, 13, 15, 12, 13, 9],
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
      yAxisID: 'y1',
    },
  ],
};

const ClientsList = () => {
  const dispatch = useDispatch();
  const activeSalonId = useSelector(getActiveSalonId());
  const statistic = useSelector(getStatistic());
  const [link, setLink] = useState(
    'https://zb.retool.com/embedded/public/b660c431-a6a8-4859-8f0b-327578d2abde#token=' +
      getActiveSalonToken(),
  );

  useEffect(() => {
    console.log(activeSalonId, getActiveSalonToken());
    // activeSalonId && dispatch(statisticActions.GET_STATISTIC.REQUEST());
  }, [dispatch, activeSalonId]);

  return (
    <div className={styles.statistic_container}>
      {/*<Line options={options} data={data} />*/}
      <iframe style={{ width: '100%', height: '2600px', border: 'none' }} src={link} />
    </div>
  );
};

export default ClientsList;
