import React from 'react';
import styles from './bannerVideo.module.scss';
import defaultCover from '../../../../assets/images/home/publication.png';
import { Button } from '@mui/material';
import clsx from 'clsx';
import useWindowSize from 'utils/useWindowSize';

const BannerVideo = ({ title, subtitle, description, action, onClick = () => {}, video, cover }) => {
  const { isMobile } = useWindowSize();

  //if(!isMobile) return null;

  return (
    <section className={clsx(styles.container, {[styles.container_desktop]: !isMobile})}>
        {video && isMobile && <video
            loop
            autoPlay
            muted
            playsInline
            poster={cover || defaultCover}
            className={styles.cover}
        >
            <source src= { video } type="video/mp4" />
        </video>}
      <div className={styles.header}>
        {!isMobile && <span className={styles.h1}>{title}</span>}
        <p className={styles.description}>{subtitle}</p>
        <p className={styles.description}>{description}</p>
      </div>
      <div className={styles.action}>
        <Button onClick={onClick} className={styles.confirm_button}>
            {action}
        </Button>
      </div>
    </section>
  );
};
export default BannerVideo;
