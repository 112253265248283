import React, { useState } from 'react';
import styles from './infoPayment.module.scss';
import { useSelector } from 'react-redux';
import { getTotal } from 'Containers/Payments/store/selectors';
import { ReactComponent as ArrowIcon } from 'assets/svg/icons/arrow_l.svg';
import { ReactComponent as CloseIcon } from 'assets/svg/cross.svg';
import clsx from 'clsx';
import Modal from 'Containers/Common/Modal';


const InfoPayment = ({ rows, balance, isMobile }) => {
  const {total: totalRows, saldo: totalEuro} = useSelector(getTotal());
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalProps, setModalProps] = useState({title: '', commissions: 0, balance: 0, nPay: 0, noFee: 0, info: ''});

  const totCommissions = (balance * 0.029) + (rows * 0.15);
  const totEuroCommissions = (totalEuro * 0.029) + (totalRows * 0.15);

  
  const totPay = balance>0 ? (balance - (totCommissions * 1.22) ) : 0;
  const totEuroPay = totalEuro>0 ? (totalEuro - (totEuroCommissions * 1.22) ) : 0;

  const handleModal = (payload) => {
    setModalProps(payload);
    setIsOpenModal(true);
  }


  return (
    <div className={clsx(styles.container, {[styles.container_mbl]: isMobile})}>
      <Modal isModalOpen={isOpenModal} setIsModalOpen={()=>setIsOpenModal(false)} width={'fit-content'} height={'fit-content'}>
        <CloseIcon className={styles.close} onClick={()=>setIsOpenModal(false)}/>
        <div className={clsx(styles.modal, {[styles.modal_mbl]: isMobile})}>
          <div className={styles.head}>
            <span>{modalProps.title}</span>
            <h1>€ {modalProps.balance}</h1>
            <p style={{fontSize: '12px', marginTop: '15px', color: 'gray', lineHeight: '14px', textAlign: 'right'}}>{modalProps.info}</p>
          </div>
          <div className={styles.details}>
            <div className={styles.info}>
              <span>Pagamenti:</span>
              <span>{modalProps.nPay}</span>
            </div>
            <div className={styles.info}>
              <span>Importo lordo:</span>
              <span>€ {modalProps.noFee}</span>
            </div>
            <div className={styles.info}>
              <span>Commissioni:<sup>*</sup></span>
              <span>€ {modalProps.commissions}</span>
            </div>
          </div>
          <div className={styles.footer}><sup>*</sup>{'2.9% + € 0.15 per transazione | iva inclusa'}</div>
        </div>
      </Modal>
      <h1 className={styles.title}>Recap generale pagamenti</h1>
      <div className={styles.item}>
        <span>Saldo Totale</span>
        <div className={styles.saldo} 
          onClick={()=>handleModal({
            title: 'Saldo Totale', 
            balance: totEuroPay.toFixed(2), 
            commissions: (totEuroCommissions * 1.22).toFixed(2),
            nPay: totalRows, 
            noFee: totalEuro.toFixed(2), 
            info: 'Importo totale dei pagamenti ricevuti non ancora incassati tramite bonifico'
          })}>
          <span>€</span>
          <span>{totEuroPay.toFixed(2)}</span>
          <ArrowIcon className={styles.arrow}/>
        </div>
      </div>
      <div className={styles.item}>
        <span>Saldo disponibile</span>
        <div className={styles.saldo} 
          onClick={()=>handleModal({
            title: 'Saldo Disponibile', 
            balance: totPay.toFixed(2), 
            commissions: (totCommissions * 1.22).toFixed(2), 
            nPay: rows, 
            noFee: balance.toFixed(2),
            info: 'Saldo dei pagamenti dei servizi effettuati disponibili per il bonifico'
          })}>
          <span className={styles.highlight}>€</span>
          <span className={styles.highlight}>{totPay.toFixed(2)}</span>
          <ArrowIcon className={styles.arrow}/>
        </div>
      </div>
    </div>
  );
};
export default InfoPayment;
