import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BreakModal from '../../../Scheduler/containers/BreakModal';
import TaskModal from '../../../Scheduler/containers/TaskModal';
import { getModalProps } from '../../store/selectors';

import Modal from 'Containers/Common/Modal';
import RefundModal from 'Containers/Payments/containers/RefundModal';
import ShowInfoPaymentModal from 'Containers/Payments/containers/ShowInfoPaymentModal';
import WorkerRetailModal from 'Containers/Scheduler/containers/SchedulerWorker/WorkerRetailModal';
import WorkerSoldOutModal from 'Containers/Scheduler/containers/SchedulerWorker/WorkerSoldOutModal';
import dayjs from 'dayjs';
import useWindowSize from 'utils/useWindowSize';
import SalonAvatarModal from '../../../Auth/containers/SalonAvatarModal';
import SalonCoverModal from '../../../Auth/containers/SalonCoverModal';
import ClientModal from '../../../Clients/containers/ClientModal';
import ServiceModal from '../../../SalonManagement/containers/Services/containers/ServiceModal';
import ClosuresModal from '../../../SalonManagement/containers/Workers/containers/ClosuresModal';
import ExtraWorkingTimeModal from '../../../SalonManagement/containers/Workers/containers/ExtraWorkingTimeModal';
import WorkerAvatarModal from '../../../SalonManagement/containers/Workers/containers/WorkerAvatarModal';
import WorkerCreationModal from '../../../SalonManagement/containers/Workers/containers/WorkerCreationModal';
import WorkerDescriptionModal from '../../../SalonManagement/containers/Workers/containers/WorkerDescriptionModal';
import WorkerNameModal from '../../../SalonManagement/containers/Workers/containers/WorkerNameModal';
import WorkerWorkTimeOneDayModal from '../../../SalonManagement/containers/Workers/containers/WorkerWorkTimeOneDayModal';
import CalendarHeaderModal from '../../../Scheduler/containers/CalendarHeader/components/CalendarHeaderModal';
import ClientsQueueModal from '../../../Scheduler/containers/ClientsQueueModal';
import TaskInfoModal from '../../../Scheduler/containers/TaskInfoModal';
import SharedWorkersModal from '../../../Settings/containers/SharingContainer/containers/SharedWorkersModal';
import { modalActions } from '../../store';
import EditSuccessModal from '../EditSuccessModal';
import NewClientModal from '../../../Clients/containers/NewClientModal';
import QRcodeImg from 'Containers/Settings/containers/SharingContainer/containers/QRcodeImg';
import EditSalonIbanModal from '../EditSalonIbanModal';
import WorkerPaymentDaysModal from '../../../SalonManagement/containers/Workers/containers/WorkerPaymentDaysModal';
import WorkerEditServiceModal from 'Containers/SalonManagement/containers/Workers/containers/EditServiceModal';
import JoinServicesModal from 'Containers/SalonManagement/containers/Workers/containers/ServicesList/JoinServicesModal';
import AccountModal from 'Containers/Settings/containers/AccountsManager/AccountModal';

const Component = {
  taskInfoModal: TaskInfoModal,
  addTaskModal: TaskModal,
  breakModal: BreakModal,
  editTaskModal: TaskModal,
  clientsQueueModal: ClientsQueueModal,
  calendarHeaderModal: CalendarHeaderModal,
  successModal: EditSuccessModal,
  editServiceModal: ServiceModal,
  editWorkerServiceModal: WorkerEditServiceModal,
  createServiceModal: ServiceModal,
  createWorkerModal: WorkerCreationModal,
  editWorkerWorkTimeModalOneDay: WorkerWorkTimeOneDayModal,
  editWorkerNameModal: WorkerNameModal,
  editWorkerDescriptionModal: WorkerDescriptionModal,
  createWorkerAvatarModal: WorkerAvatarModal,
  editWorkerAvatarModal: WorkerAvatarModal,
  workerClosuresModal: ClosuresModal,
  workerClosuresOneDayModal: ClosuresModal,
  workerExtraWorkingTimeModal: ExtraWorkingTimeModal,
  workerExtraWorkingTimeOneDayModal: ExtraWorkingTimeModal,
  createSalonAvatarModal: SalonAvatarModal,
  createSalonCoverModal: SalonCoverModal,
  clientModal: ClientModal,
  refundModal: RefundModal,
  sharedWorkersModal: SharedWorkersModal,
  workerRetailModal: WorkerRetailModal,
  workerSoldOutModal: WorkerSoldOutModal,
  showInfoPaymentModal: ShowInfoPaymentModal,
  createClientModal: NewClientModal,
  qrCodeModal: QRcodeImg,
  editSalonIbanModal: EditSalonIbanModal,
  workerPaymentDaysModal: WorkerPaymentDaysModal,
  joinServicesModal: JoinServicesModal,
  accountModal: AccountModal,
};

export default () => {
  const dispatch = useDispatch();
  const { type, props, callback } = useSelector(getModalProps());

  const closeAllModals = () => {
    dispatch(modalActions.CLOSE_MODAL.SUCCESS());
  };
  const { isMobile } = useWindowSize();

  const modalProps = {
    taskInfoModal: {
      title: (props?.task?.nome || '') + ' ' + (props?.task?.cognome || ''),
      maxWidth: '400px',
      showCloseBtn: true,
    },
    addTaskModal: {
      title: !!isMobile && props?.task?.orario_end ? 'Prenotazione rapida' : 'Nuova prenotazione',
      maxWidth: '700px',
      showCloseBtn: true,
    },
    breakModal: {
      title: 'Prendi una pausa',
      maxWidth: '400px',
      showCloseBtn: true,
    },
    editTaskModal: {
      title: 'Modifica prenotazione',
      maxWidth: '700px',
      showCloseBtn: true,
    },
    clientsQueueModal: {
      title: `Clienti in coda ${props?.clientsLength}`,
      maxWidth: '750px',
      showCloseBtn: true,
      maxHeight: '60%',
    },
    calendarHeaderModal: {
      title: 'Impostazioni calendario',
      width: 'fit-content',
      showCloseBtn: true,
    },
    successModal: {
      title: props?.header,
      maxWidth: '370px',
      showCloseBtn: true,
    },
    editServiceModal: {
      title: 'Inserisci le informazioni del servizio',
      maxWidth: '500px',
      showCloseBtn: true,
    },
    editWorkerServiceModal: {
      title: 'Inserisci le informazioni del servizio',
      maxWidth: '500px',
      showCloseBtn: true,
    },
    joinServicesModal: {
      title: 'Associa Servizi',
      maxWidth: '500px',
      showCloseBtn: true,
    },
    createServiceModal: {
      title: 'Inserisci le informazioni del servizio',
      maxWidth: '500px',
      showCloseBtn: true,
    },
    createWorkerModal: {
      title: 'Inserisci le informazioni del poltrona',
      maxWidth: '500px',
      showCloseBtn: true,
    },
    editWorkerWorkTimeModalOneDay: {
      title: 'Seleziona orario',
      maxWidth: '370px',
      showCloseBtn: true,
    },
    editWorkerNameModal: {
      title: 'Inserisci il nome della poltrona',
      maxWidth: '500px',
      showCloseBtn: true,
    },
    editWorkerDescriptionModal: {
      title: 'Inserisci la descrizione della poltrona',
      maxWidth: '500px',
      showCloseBtn: true,
    },
    createWorkerAvatarModal: {
      title: "Seleziona l'immagine di profilo della poltrona",
      showCloseBtn: true,
    },
    editWorkerAvatarModal: {
      title: "Cambia l'immagine di profilo della poltrona",
      maxWidth: '500px',
      showCloseBtn: true,
    },
    workerClosuresModal: {
      title: `Modifica orari temporaneamente ${props?.worker ? ` per ${props?.worker}` : ''}`,
      maxWidth: '800px',
      showCloseBtn: true,
    },
    workerClosuresOneDayModal: {
      title: `Modifica orari temporaneamente ${props?.startDay ? `per il ${dayjs(props?.startDay).format('DD/MM/YYYY')}` : ''}`,
      maxWidth: '500px',
      showCloseBtn: true,
    },
    workerExtraWorkingTimeModal: {
      title: `Modifica orari temporaneamente ${props?.worker ?  `per ${props.worker}` : ''}`,
      maxWidth: '800px',
      showCloseBtn: true,
    },
    workerExtraWorkingTimeOneDayModal: {
      title: `Modifica orari temporaneamente ${props?.startDay ? `per il ${dayjs(props?.startDay).format('DD/MM/YYYY')}` : ''}`,
      maxWidth: '500px',
      showCloseBtn: true,
    },
    createSalonAvatarModal: {
      title: "Seleziona l'immagine di profilo del salone",
      showCloseBtn: true,
      maxWidth: '500px',
    },
    createSalonCoverModal: {
      title: "Seleziona l'immagine di copertina del salone",
      showCloseBtn: true,
      maxWidth: '500px',
    },
    clientModal: {
      title: 'Scheda cliente',
      maxWidth: '700px',
      showCloseBtn: true,
    },
    refundModal: {
      title: 'Rimborso',
      maxWidth: '375px',
      showCloseBtn: true,
    },
    sharedWorkersModal: {
      title: 'Condividi poltrona',
      maxWidth: '500px',
      showCloseBtn: true,
    },
    workerRetailModal: {
      title: 'Dettaglio poltrona',
      maxWidth: '300px',
      showCloseBtn: true,
    },
    workerSoldOutModal: {
      title: 'Condividi',
      maxWidth: '378px',
      showCloseBtn: true,
      hasHeader: false,
      background: '#f5f5f5',
      fullScreen: true,
    },
    showInfoPaymentModal: {
      title: 'Dettaglio pagamento',
      maxWidth: '400px',
      showCloseBtn: true,
      background: '#f5f5f5',
      fullScreen: false,
    },
    createClientModal: {
      title: 'Inserisci le informazioni del cliente',
      maxWidth: '500px',
      showCloseBtn: true,
    },
    qrCodeModal: {
      title: 'QR Code',
      maxWidth: 'fit-content',
      maxHeight: 'fit-content',
      showCloseBtn: true,
    },
    editSalonIbanModal: {
      title: props?.title ?? 'Inserisci o modifica le info per il bonifico',
      maxWidth: '700px',
      showCloseBtn: true,
    },
    workerPaymentDaysModal: {
      title: 'Pagamento Online obbligatorio',
      maxWidth: '500px',
      showCloseBtn: true,
    },
    accountModal: {
      title: props?.account ? 'Modifica Account Staff' : 'Nuovo Account Staff',
      maxWidth: '500px',
      showCloseBtn: true,
    },
  };

  const componentProps = {
    taskInfoModal: { taskValue: props },
    addTaskModal: { taskValue: props },
    breakModal: {},
    editTaskModal: { taskValue: props, isEditForm: true },
    clientsQueueModal: { calendarQueue: props, isEditForm: true },
    calendarHeaderModal: { calendarProps: props, isEditForm: true },
    editServiceModal: { service: props, isEditForm: true },
    editWorkerServiceModal: { service: props},
    createServiceModal: {},
    createWorkerModal: {},
    editWorkerWorkTimeModalOneDay: { workDayValue: props },
    editWorkerNameModal: { worker: props, isEditForm: true },
    editWorkerDescriptionModal: { worker: props, isEditForm: true },
    createWorkerAvatarModal: { callback: callback, isEditForm: false },
    editWorkerAvatarModal: { callback: callback, workerValue: props, isEditForm: true },
    workerClosuresModal: {},
    workerClosuresOneDayModal: { isOneDay: true, dayValue: props },
    workerExtraWorkingTimeModal: {},
    workerExtraWorkingTimeOneDayModal: { isOneDay: true, dayValue: props },
    createSalonAvatarModal: { callback: callback, isEditForm: true },
    createSalonCoverModal: { callback: callback, isEditForm: true },
    clientModal: { client_id: props?.client_id, totPren: props?.totPren },
    refundModal: { refundInformation: props },
    sharedWorkersModal: { callback: callback },
    workerRetailModal: {
      worker: props?.worker,
      modalData: props?.modalData,
      tasks: props?.tasks,
      sOModalData: props?.sOModalData,
    },
    workerSoldOutModal: { modalData: props?.modalData },
    showInfoPaymentModal: { pagamento: props?.pagamento },
    createClientModal: { salone: props },
    qrCodeModal: { activeSalon: props?.activeSalon, downloadImage: props?.downloadImage },
    editSalonIbanModal: { activeSalon: props?.activeSalon },
    workerPaymentDaysModal: { callback: callback, isEditForm: false },
    joinServicesModal: { activeServices: props?.activeServices, worker: props?.worker },
    accountModal: { account: props?.account },
  };
  const SelectedComponent = useMemo(() => Component[type], [type]);
  if (!SelectedComponent) return null;
  return (
    <Modal
      subTitle={props?.subtitle}
      setIsModalOpen={closeAllModals}
      isModalOpen={true}
      {...(modalProps[type] || {})}
    >
      <SelectedComponent {...(componentProps[type] || {})} closeModal={closeAllModals} />
    </Modal>
  );
};
