import { useEffect, useState } from 'react';
import styles from './sectionsButtonSelector.module.scss';
import clsx from 'clsx';
import { ReactComponent as CancelIcon } from 'assets/svg/cross.svg';

const SectionAccordion = ({ section, selectedList, setSelectedList}) => {
    const [opened, setOpened] = useState(false);

    const selectedSection = selectedList?.find((selected) => selected.sezione == section.id) || {};
    const selectedFields = selectedSection.fields || [];
    
    const exludedFields = ['sezione', 'disabilitato', 'utenteBusiness', 'idPermesso'];

    useEffect(() => {
      if(selectedSection && (!selectedFields.fields || selectedFields?.fields?.length ==0)) {
        let newList = selectedList;
        let index = selectedList?.findIndex((selected) => selected.sezione == section.id);
        newList[index] = {...newList[index], fields: []};
        Object.keys(selectedSection).filter(key => !exludedFields.includes(key)).map((key) => {
            let getIf = section.options.find((option) => option.field == key && option.value == selectedSection[key]);
            if(getIf) newList[index].fields.push(key);
        });
        setSelectedList(newList);
      }
    }, []);
  
    const handleSelect = (option) => {
        let alreadySelected = selectedList?.findIndex((selected) => selected.sezione == section.id);
        if(alreadySelected > -1) {
            let newArray = selectedList;
            if(newArray[alreadySelected][option.field] && newArray[alreadySelected][option.field] == option.value) {
                delete newArray[alreadySelected][option.field];
                let fieldIndex = newArray[alreadySelected].fields.findIndex((field) => field == option.field);
                newArray[alreadySelected].fields.splice(fieldIndex, 1);

                if(selectedFields.length < 1) {
                    newArray.splice(alreadySelected, 1);
                }
            }
            else {
                newArray[alreadySelected][option.field] = option.value;
                newArray[alreadySelected].fields.push(option.field);
            }
            setSelectedList(newArray);
        }
        else {
            setSelectedList([...selectedList, {sezione: section.id, [option.field]: option.value, fields: [option.field]}]);
        }
    }        

    const deleteSection = () => {
        let index = selectedList?.findIndex((selected) => selected.sezione == section.id);
        let newArray = selectedList;
        newArray.splice(index, 1);
        setSelectedList(newArray);
    }


    return (<>
        <details key={section.id} className={styles.section_accordion} onClick={()=>setOpened(!opened)}>
            <summary>
                <div 
                    className={clsx(styles.title_button, 
                        {[styles.title_button_opened]: opened}, 
                        {[styles.title_button_selected]: selectedFields.length >0 && !opened})
                    }
                >
                    {section.title}
                </div>
                {selectedFields.length > 1 && !opened && (<div className={styles.selected_btn}>
                    + {selectedFields.length}
                    <CancelIcon onClick={()=> deleteSection()}/>
                </div>)} {/* se chiuso e più di uno selezionato, add delete selected*/}
            </summary>
            <div className={styles.options_box}>
                {Array.isArray(section.options) && section.options.map((section) => {
                    let {name, field, value} = section;
                    let checked = selectedSection[field] == value;
                    return (
                        <div 
                            key={section.name} 
                            className={clsx(styles.option_item, styles['option_item_active'])}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleSelect({field, value});
                            }}
                        >
                            <div className={styles.option_value}>{name}</div>
                            <input
                                className={styles.radio_button}
                                multiple={true}
                                type='radio'
                                checked={checked}
                                readOnly={true}
                            />
                        </div>
                    );
                })}
            </div>
        </details>
    </>)
}

const SectionsButtonSelector = ({
    title= 'Scegli e seleziona i settori a cui puo’ accedere l’utente', 
    sections,
    selectedList=[], 
    setSelectedList
}) => {

    return (
        <div className={styles.sections_wrapper}>
            <div className={styles.title}>{title}</div>
            <div className={styles.sections} >
                {sections.map((section, index) => {
                    return (
                        <SectionAccordion 
                            key={index}
                            section={section}
                            selectedList={selectedList}
                            setSelectedList={setSelectedList}
                        />
                    );
                })}
            </div>
        </div>
    );
}

export default SectionsButtonSelector;