import { call, put, takeLatest, select } from 'redux-saga/effects';

import { Api } from '../../../entries/ApiTransport';
import sagaAssessor from '../../../utils/sagaAssessor';
import { authActions, authTypes } from './actions';
import { setActiveSalonInfo } from '../../../utils/salonToken';
import { getActiveSalonId, getSalons, salonWorkTimes } from './selectors';
import { toastActions } from 'Containers/Toasts/store';

const api = Api.getInstance();

const signIn = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { token } = payload;
        let link = '/business/auth/login';
        if (token) {
          link = '/business/auth/tokenLogin';
        }
        const { data } = yield call(() =>
          token
            ? api.post(link, payload, { headers: { Authorization: token } })
            : api.post(link, payload),
        );
        const salons = [];
        if (data?.salone?.token) {
          salons.push({
            ...data.salone,
            isActive: true,
          });
        } else if (data?.salone?.isSuperAdmin) {
          const activeSalons = data.salone.saloni
            .filter((salon) => +salon.eliminato === 0)
            .map((item, index) => ({ ...item, isActive: !index }));
          salons.push(...activeSalons);
        }

        if (salons.length) {
          localStorage.setItem('salons', JSON.stringify(salons));
          const activeSalonId = salons?.find((salon) => salon.isActive)?.idsalone;
          yield put(
            authActions.SIGN_IN.SUCCESS({
              activeSalonId,
              salons,
            }),
          );
        }

        if(data?.role === 1){
          localStorage.setItem('staff', data.idUtenteBusiness);
        }

        callback && typeof callback === 'function' && callback();
      },
    (err) => authActions.SIGN_IN.FAILED(err),
  );

// const getSalon = ({ _, callback }) =>
//   sagaAssessor(
//     () =>
//       function* () {
//         const { data } = yield call(() => api.post('/business/auth/tokenLogin'));
//         yield put(authActions.FETCH_SALON.SUCCESS(data.salone));
//         callback && typeof callback === 'function' && callback();
//       },
//     (err) => authActions.FETCH_SALON.FAILED(err)
//   );

const editSalon = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const {
          data: { salone },
        } = yield call(() => api.post('/business/saloni/modInfoSalone', payload));
        setActiveSalonInfo(salone);
        yield put(authActions.EDIT_SALON_INFO.SUCCESS(salone));
        callback && typeof callback === 'function' && callback();
      },
    (err) => authActions.EDIT_SALON_INFO.FAILED(err),
  );

const activePayment = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const {
          data: { salone },
        } = yield call(() => api.post('/business/saloni/attivaPagamenti', payload));
        setActiveSalonInfo(salone);
        yield put(authActions.ACTIVE_PAYMENT.SUCCESS(salone));
        callback && typeof callback === 'function' && callback();
        yield put(
          toastActions.OPEN_TOAST.SUCCESS({
            type: 'payment',
            props: { title: 'Pagamenti Attivati con successo!' },
          }),
        );
      },
    (err) => authActions.ACTIVE_PAYMENT.FAILED(err),
  );

const editSalonAvatar = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        yield call(() => api.post('/img/setProfilo', payload));
        callback && typeof callback === 'function' && callback();
      },
    (err) => authActions.EDIT_SALON_AVATAR.FAILED(err),
  );

const editSalonCover = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        yield call(() => api.post('/img/setCopertina', payload));
        callback && typeof callback === 'function' && callback();
      },
    (err) => authActions.EDIT_SALON_COVER.FAILED(err),
  );

const editSalonPassword = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const {
          data: { salone },
        } = yield call(() => api.post('/business/auth/cambiaPwd', payload));
        setActiveSalonInfo({ idsalone: salone[0].idsalone, token: salone[0].token });
        callback && typeof callback === 'function' && callback();
      },
    (err) => authActions.EDIT_SALON_PASSWORD.FAILED(err),
  );

const deleteSalon = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        localStorage.clear();
        yield call(() => api.post('/business/auth/deleteSalone', payload));

        const activeSalonId = yield select(getActiveSalonId());
        const salons = yield select(getSalons());
        const activeSalons = salons.filter((salon) => salon.value !== activeSalonId);

        if (!activeSalons.length) {
          localStorage.removeItem('salons');
          yield put(authActions.LOG_OUT.SUCCESS());
        } else {
          const [{ value }] = activeSalons;
          yield put(authActions.SET_ACTIVE_SALON.SUCCESS(value));
          yield put(authActions.DELETE_SALON.SUCCESS(activeSalonId));
        }
        callback && typeof callback === 'function' && callback();
      },
    (err) => {
      authActions.DELETE_SALON.FAILED(err);
      if (err.response.status === 401) {
        localStorage.removeItem('salons');
        window.location.href = '/login';
      }
    },
  );

const logOut = ({ callback }) =>
  sagaAssessor(
    () =>
      function* () {
        window?.flutter_inappwebview?.callHandler('app:logout');
        localStorage.clear();
        yield put(authActions.LOG_OUT.SUCCESS());
        callback && typeof callback === 'function' && callback();
      },
    (err) => {
      alert('error');
      authActions.LOG_OUT.FAILED(err);
    },
  );

const getActiveSignUpStep = ({ _, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        // const { data } = yield call(() => api.get('/business/auth/tokenLogin'));
        yield put(authActions.GET_ACTIVE_SIGN_UP_STEP.SUCCESS(1));
        callback && typeof callback === 'function' && callback();
      },
    (err) => authActions.GET_ACTIVE_SIGN_UP_STEP.FAILED(err),
  );

const setSalonWorkTimes = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        // yield call(() => api.post('/business/saloni/updateOrariSalone', {orari: payload}));
        const workTimes = yield select(salonWorkTimes());
        const req = {
          workTimes,
          ...payload,
        };

        yield put(authActions.SET_SALON_WORK_TIMES.SUCCESS(payload));
        callback && typeof callback === 'function' && callback();
      },
    (err) => authActions.SET_SALON_WORK_TIMES.FAILED(err),
  );

const setSalonServices = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        // yield call(() => api.post('/business/saloni/updateOrariSalone', {orari: payload}));
        yield put(authActions.SET_SALON_SERVICES.SUCCESS(payload));

        callback && typeof callback === 'function' && callback();
      },
    (err) => authActions.SET_SALON_SERVICES.FAILED(err),
  );

const setSalonWorkers = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        // yield call(() => api.post('/business/saloni/updateOrariSalone', {orari: payload}));
        yield put(authActions.SET_SALON_WORKERS.SUCCESS(payload));

        callback && typeof callback === 'function' && callback();
      },
    (err) => authActions.SET_SALON_WORKERS.FAILED(err),
  );

const createCustomer = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(async () => await api.post('/business/createCustomer', payload));
        yield put(authActions.CREATE_CUSTOMER.SUCCESS(data));
        callback?.();
      },
    (err) => authActions.CREATE_CUSTOMER.FAILED(err),
  );

  const sendEmailRecovery = ({payload, callback}) =>
  sagaAssessor(
    () =>
      function* () {
        yield call(() => api.post('/business/auth/recoveryPwd', payload));
        callback?.();
      },
    (err) => authActions.SEND_EMAIL_RECOVERY.FAILED(err),
  );

  const resetPassword = ({payload, callback}) =>
  sagaAssessor(
    () =>
      function* () {
        yield call(() => api.post('/business/auth/resetPwd', payload, {headers: {Authorization: localStorage.getItem('tokenRecovery')}}));
        localStorage.removeItem('tokenRecovery');
        callback?.();
      },
    (err) => authActions.RESET_PASSWORD.FAILED(err),
  );



export default function* () {
  yield takeLatest(authTypes.SIGN_IN.REQUEST, signIn);
  yield takeLatest(authTypes.EDIT_SALON_INFO.REQUEST, editSalon);
  yield takeLatest(authTypes.EDIT_SALON_AVATAR.REQUEST, editSalonAvatar);
  yield takeLatest(authTypes.EDIT_SALON_COVER.REQUEST, editSalonCover);
  yield takeLatest(authTypes.EDIT_SALON_PASSWORD.REQUEST, editSalonPassword);
  yield takeLatest(authTypes.DELETE_SALON.REQUEST, deleteSalon);
  yield takeLatest(authTypes.LOG_OUT.REQUEST, logOut);
  yield takeLatest(authTypes.GET_ACTIVE_SIGN_UP_STEP.REQUEST, getActiveSignUpStep);
  yield takeLatest(authTypes.SET_SALON_WORK_TIMES.REQUEST, setSalonWorkTimes);
  yield takeLatest(authTypes.SET_SALON_SERVICES.REQUEST, setSalonServices);
  yield takeLatest(authTypes.SET_SALON_WORKERS.REQUEST, setSalonWorkers);
  yield takeLatest(authTypes.ACTIVE_PAYMENT.REQUEST, activePayment);
  yield takeLatest(authTypes.CREATE_CUSTOMER.REQUEST, createCustomer);
  yield takeLatest(authTypes.SEND_EMAIL_RECOVERY.REQUEST, sendEmailRecovery);
  yield takeLatest(authTypes.RESET_PASSWORD.REQUEST, resetPassword);
}
