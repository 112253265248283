import dayjs from 'dayjs';
import { ReactComponent as GoogleIcon } from '../../../assets/svg/badgets/google.svg';
import { ReactComponent as WebIcon } from '../../../assets/svg/badgets/web.svg';
import { ReactComponent as NewClientIcon } from '../../../assets/svg/badgets/new_client.svg';
import { ReactComponent as BirthdayIcon } from '../../../assets/svg/badgets/birthday.svg';
import { ReactComponent as SalonIcon } from '../../../assets/svg/badgets/barber_pole.svg';
import { ReactComponent as PaidIcon } from '../../../assets/svg/badgets/paid.svg';
import { ReactComponent as NoteIcon } from '../../../assets/svg/badgets/note.svg';
import { ReactComponent as RecurrenceIcon } from '../../../assets/svg/badgets/recurrence.svg';

export const badgetList = {
  1: {
    icon: <PaidIcon />,
    text: 'Pagato',
  },
  2: {
    icon: <NewClientIcon />,
    text: 'Nuovo cliente',
  },
  3: {
    icon: <BirthdayIcon />,
    text: 'Compleanno',
  },
  4: {
    icon: <GoogleIcon />,
    text: 'Da Google',
  },
  5: {
    icon: <WebIcon />,
    text: 'Da web',
  },
  6: {
    icon: <RecurrenceIcon />,
    text: 'Ricorrente',
  },
  7: {
    icon: <SalonIcon />,
    text: 'Da salone',
  },
  8: {
    icon: <NoteIcon />,
    text: 'Nota',
  },
};

export const selectBadges = (task) => {
  const currentDay = dayjs();
  const taskDay = dayjs(task.data_prenotazione);

  return {
    1: task.statusPayment === 2,
    2: task.data_iscrizione && currentDay.diff(task.data_iscrizione, 'day') <= 30,
    3:
      task.birthday &&
      (taskDay.format('MM-DD') == dayjs(task.birthday).format('MM-DD') ||
        taskDay.format('MM-DD') == dayjs(task.birthday).subtract(1, 'day').format('MM-DD')),
    4: !!task.isFromGoogle,
    5: !!task.web,
    6: !!task?.ricorrente,
    7: !!task.self_prenotatione,
    8: !!task.nota,
  };
};

export const noPriceSalons = [67,79,197,240,493,587,586,611,653,669,691,772,109];
