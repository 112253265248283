import { call, put, takeLatest } from 'redux-saga/effects';
import { Api } from '../../../entries/ApiTransport';
import sagaAssessor from '../../../utils/sagaAssessor';
import { communicationsActions, communicationsTypes } from './actions';

const api = Api.getInstance();

const getToken = () =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.get(`Notifiche/getToken`));
        yield put(communicationsActions.GET_TOKEN_COMMUNICATIONS.SUCCESS(data));
      },
    (err) => communicationsActions.GET_TOKEN_COMMUNICATIONS.FAILED(err)
  );



export default function* () {
  yield takeLatest(communicationsTypes.GET_TOKEN_COMMUNICATIONS.REQUEST, getToken);
}
