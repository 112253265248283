import React from 'react';
import InfoPayment from './InfoPayment';
import InfoBonifici from './InfoBonifici';
import useWindowSize from 'utils/useWindowSize';


const PageInfo = ({ activeFilterId = 1, rows, activeSalon, balanceRows, balance }) => {
  const { isMobile } = useWindowSize();

  return activeFilterId === 2 ? (
    <InfoBonifici rows={rows} activeSalon={activeSalon} balanceRows={balanceRows} balance={balance} isMobile={isMobile} />
  ) : (
    <InfoPayment rows={balanceRows} balance={balance} isMobile={isMobile} />
  );
};

export default PageInfo;
