import useOutsideClick from '../../../../utils/useOutsideClick';
import { useEffect, useRef, useState } from 'react';
import styles from './ColorSelector.module.scss';

import clx from 'classnames';
import SelectorMenu from '../SelectorMenu';
import HoveredInfoIcon from '../../HoveredInfoIcon';
import { ReactComponent as ArrowRIcon } from '../../../../assets/svg/icons/arrow_r.svg';
import clsx from 'clsx';

const ColorSelector = ({ value, setValue, options, title, isFullWidth = false }) => {
  const dropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const formedOptions = options.map((option) => ({
    ...option,
    background: option.value,
  }));

  useOutsideClick(dropdownRef, () => {
    if (isDropdownOpen) setIsDropdownOpen(false);
  });
  return (
    <div
      ref={dropdownRef}
      className={clx(styles.color_selector_wrapper, {
        [styles['color_selector_wrapper_close']]: !isDropdownOpen,
        [styles.color_selector_wrapper_full_width]: isFullWidth
      })}
    >
      {title && (
        <div className={styles.title_container}>
          <div className={styles.input_title}>{title}</div>
          <HoveredInfoIcon description={`
            Seleziona il colore che vuoi associare
          `} />
        </div>
      )}
      <div
        onClick={() => setIsDropdownOpen((isOpen) => !isOpen)}
        className={styles.selector_container}
      >
        <div
          className={styles.value}
          style={{
            background: formedOptions.find((option) => option.value === value)?.background,
          }}
        />
        <ArrowRIcon
          style={{
            transform: isDropdownOpen ? 'rotate(90deg)' : 'rotate(-90deg)',
          }}
        />
      </div>
      <div className={styles.dropdown__menu}>
        <SelectorMenu
          isMultiple={false}
          setIsDropdownOpen={() => setIsDropdownOpen(false)}
          options={formedOptions}
          setValue={setValue}
          value={[value]}
        />
      </div>
    </div>
  );
};

export default ColorSelector;
