import { createSelector } from 'reselect';
import { getIconUrl, imageEntities } from '../../../utils/ImagesCreator';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrBefore);
const selectSalonManagementState = (state) => state.salonManagementReducer;

export const getServices = (activeFilterId, sort) =>
  createSelector(selectSalonManagementState, (state) => {
    const services = state.services
      .filter((service) => {
        switch (activeFilterId) {
          case 3: {
            return service.onlyVisibile && !service.disabilitato;
          }
          case 1: {
            return !service.onlyVisibile && !service.disabilitato;
          }
          case 2: {
            return service.disabilitato;
          }
          default:
            return true;
        }
      })
      .map((service) => ({
        ...service,
        id: service.idservezio,
        name: service.nome,
        cost: service.prezzo,
        duration: service.time,
        icon: getIconUrl(service.idservezio, imageEntities.service),
        disabled: !!service.disabilitato,
        description: service.descrizione,
        color: service.color,
        onlyVisibile: service.onlyVisibile,
        workers: service.workers?.map((worker) => ({
          name: worker.nome_referente,
          id: worker.idpoltrona,
          icon: getIconUrl(worker.iconPoltrona, imageEntities.worker),
        })),
        position: service.ordinamento,
      }));

    if (sort) {
      const { result } = services.reduce(
        (acc, _, index) => {
          const newService = acc.servicesToCheck.find((service) => service.isNew);
          if (newService) {
            const filteredServicesToCheck = acc.servicesToCheck.filter(
              (service) => service.id !== newService.id,
            );
            return {
              servicesToCheck: filteredServicesToCheck,
              result: [...acc.result, newService],
            };
          }
          const serviceInThisPosition = acc.servicesToCheck.find(
            (service) => service.position === index + 1,
          );
          if (serviceInThisPosition) {
            const filteredServicesToCheck = acc.servicesToCheck.filter(
              (service) => service.id !== serviceInThisPosition.id,
            );
            return {
              servicesToCheck: filteredServicesToCheck,
              result: [...acc.result, serviceInThisPosition],
            };
          }

          const firsServiceWithoutPosition = acc.servicesToCheck.find(
            (service) => service.position === 0 || service.position < index + 1,
          );

          if (firsServiceWithoutPosition) {
            const filteredServicesToCheck = acc.servicesToCheck.filter(
              (service) => service.id !== firsServiceWithoutPosition.id,
            );

            return {
              servicesToCheck: filteredServicesToCheck,
              result: [...acc.result, firsServiceWithoutPosition],
            };
          }

          const minPosition = acc.servicesToCheck.reduce(
            (x, y) => Math.min(x, +y.position),
            Infinity,
          );
          const firsServiceWithPosition = acc.servicesToCheck.find(
            (service) => +service.position === +minPosition,
          );

          if (firsServiceWithPosition) {
            const filteredServicesToCheck = acc.servicesToCheck.filter(
              (service) => service.id !== firsServiceWithPosition.id,
            );

            return {
              servicesToCheck: filteredServicesToCheck,
              result: [...acc.result, firsServiceWithPosition],
            };
          }

          return acc;
        },
        {
          servicesToCheck: services,
          result: [],
        },
      );
      return result;
    }

    return services;
  });

export const getService = () =>
  createSelector(selectSalonManagementState, (state) => ({
    ...state.activeService,
    icon: getIconUrl(state.activeService.idservezio, imageEntities.service),
  }));

export const getWorkers = (activeFilterId, sort = false) =>
  createSelector(selectSalonManagementState, (state) => {
    const workers = state.workers
      .filter((worker) => {
        switch (activeFilterId) {
          case 3: {
            return !worker.visible && !worker.disabilitato;
          }
          case 1: {
            return worker.visible && !worker.disabilitato;
          }
          case 2: {
            return worker.disabilitato;
          }
          default:
            return true;
        }
      })
      .map((worker) => ({
        ...worker,
        name: worker.nome_referente,
        id: worker.idpoltrona,
        icon: getIconUrl(worker.idpoltrona, imageEntities.worker),
        servicesCount: worker.servizi?.length,
        closures: worker.closures,
        extraWorkingTime: worker.extraWorkingTime,
        onlyApp: worker.onlyApp,
        disabilitato: worker.disabilitato,
        description: worker.descrizionePoltrona,
        position: worker.ordinamento,
      }));
    if (sort) {
      const { result } = workers.reduce(
        (acc, _, index) => {
          const newsWorkers = acc.workersToCheck.find((worker) => worker.isNew);
          if (newsWorkers) {
            const filteredWorkersToCheck = acc.workersToCheck.filter(
              (worker) => worker.id !== newsWorkers.id,
            );
            return {
              workersToCheck: filteredWorkersToCheck,
              result: [...acc.result, newsWorkers],
            };
          }

          const workerInThisPosition = acc.workersToCheck.find(
            (worker) => worker.position === index + 1,
          );

          if (workerInThisPosition) {
            const filteredWorkersToCheck = acc.workersToCheck.filter(
              (worker) => worker.id !== workerInThisPosition.id,
            );
            return {
              workersToCheck: filteredWorkersToCheck,
              result: [...acc.result, workerInThisPosition],
            };
          }

          const firsWorkerWithoutPosition = acc.workersToCheck.find(
            (worker) => worker.position === 0 || worker.position < index + 1,
          );

          if (firsWorkerWithoutPosition) {
            const filteredWorkersToCheck = acc.workersToCheck.filter(
              (worker) => worker.id !== firsWorkerWithoutPosition.id,
            );

            return {
              workersToCheck: filteredWorkersToCheck,
              result: [...acc.result, firsWorkerWithoutPosition],
            };
          }

          const minPosition = acc.workersToCheck.reduce(
            (x, y) => Math.min(x, +y.position),
            Infinity,
          );
          const firsWorkerWithPosition = acc.workersToCheck.find(
            (worker) => +worker.position === +minPosition,
          );

          if (firsWorkerWithPosition) {
            const filteredWorkersToCheck = acc.workersToCheck.filter(
              (worker) => worker.id !== firsWorkerWithPosition.id,
            );

            return {
              workersToCheck: filteredWorkersToCheck,
              result: [...acc.result, firsWorkerWithPosition],
            };
          }

          return acc;
        },
        {
          workersToCheck: workers,
          result: [],
        },
      );
      return result;
    }
    return workers;
  });

export const getWorker = () =>
  createSelector(selectSalonManagementState, (state) => ({
    ...state.activeWorker,
    icon: getIconUrl(state.activeWorker?.idpoltrona, imageEntities.worker),
  }));

// export const getSalonWorkTimes = () =>
//   createSelector(selectSalonManagementState, (state) => {
//     return state.salonWorkTimes.reduce((acc, item) => {
//       const prev = acc.filter((day) => day.indexDay < item.indexDay && day.indexDay !== 0);
//       const next = acc.filter((day) => day.indexDay > item.indexDay || day.indexDay === 0);
//       return [...prev, item, ...next];
//     }, []);
//   });

export const getWorkerWorkTimes = () =>
  createSelector(selectSalonManagementState, (state) => {
    return state.activeWorker?.workTimes?.reduce((acc, item) => {
      const prev = acc.filter((day) => day.indexDay < item.indexDay && day.indexDay !== 0);
      const next = acc.filter((day) => day.indexDay > item.indexDay || day.indexDay === 0);
      const formedTimePeriod = {
        indexDay: item.indexDay,
        orariApertura: item.orariApertura,
        chiuso:
          item.orariApertura.mattina.alle === '00:00:00' &&
          item.orariApertura.mattina.dalle === '00:00:00' &&
          item.orariApertura.mattina.alle === '00:00:00' &&
          item.orariApertura.mattina.dalle === '00:00:00',
        isFullDay:
          item.orariApertura.mattina.alle === '00:00:00' &&
          item.orariApertura.pomeriggio.dalle === '00:00:00',
      };
      return [...prev, formedTimePeriod, ...next];
    }, []);
  });

export const getWorkerClosures = (tablesFilterDate) =>
  createSelector(selectSalonManagementState, (state) =>
    Object.fromEntries(
      Object.entries(
        state.activeWorker?.closures?.reduce((acc, item) => {
          const createdDate = dayjs(item.createAt).format('YYYY-MM-DD HH:mm s');
          if (acc[createdDate]) acc[createdDate].push(item);
          else acc[createdDate] = [item];
          return acc;
        }, {}) || {},
      )?.filter(
        ([_, values]) =>
          !tablesFilterDate ||
          values.some(({ data_chiusura }) =>
            dayjs(dayjs(data_chiusura).format('YYYY-MM-DD'), 'YYYY-MM-DD').isSame(tablesFilterDate),
          ),
      ),
    ),
  );

export const getWorkerExtraWorkingTime = (tablesFilterDate) =>
  createSelector(selectSalonManagementState, (state) =>
    state.activeWorker?.extraWorkingTime?.filter(
      ({ dataFine }) => !tablesFilterDate || dayjs(dataFine).isSameOrBefore(tablesFilterDate),
    ),
  );

export const getWorkerServices = (activeFilterId) => 
  createSelector(selectSalonManagementState, (state) => {
    const services = state.activeWorker?.workerServices?.filter((service) => {
        switch (activeFilterId) {
          case 3: {
            return service.onlyVisibile && !service.disabilitato;
          }
          case 1: {
            return !service.onlyVisibile && !service.disabilitato;
          }
          case 2: {
            return service.disabilitato;
          }
          default:
            return true;
        }
      })
      .map((service) => ({
        ...service,
        name: service.nome,
        time: service.durata,
        icon: getIconUrl(service.idservezio, imageEntities.service),
        position: state.services.find(s => s.idservezio==service.idservezio)?.ordinamento
      }));
    return services;
  });