import dayjs from 'dayjs';
import { getIconUrl, imageEntities } from '../../../../utils/ImagesCreator';
export const formatCalendarData = ({
  appointments,
  activeWorkers,
  activeDays,
  startWorkTime,
  endWorkTime,
  calendarItemsCount,
  timeStep,
  stepSize,
}) => {
  const stackedTasks = ({ startWork, activeTasks, calendarItemsCount }) => {
    return Array(calendarItemsCount)
      .fill(0)
      .reduce((acc, _, index) => {
        const stringState = startWork + (timeStep.hours * 60 + timeStep.minutes) * 60 * index;
        const selectedTasks = activeTasks.filter(
          (task) => stringState >= task.unixStartTime && stringState < task.unixEndTime,
        );
        if (selectedTasks.length) {
          const alreadyAddedTasks = acc.filter((task) =>
            selectedTasks.some((item) => item.idprenotazione === task.idprenotazione),
          );
          const newTasksToAdd = selectedTasks.filter(
            (task) =>
              !alreadyAddedTasks.some((item) => item.idprenotazione === task.idprenotazione),
          );
          const filledPosition = alreadyAddedTasks.map((item) => item.left);
          const max = filledPosition.length ? Math.max(...filledPosition) : null;
          const emptyPositions =
            max && max + 1 > alreadyAddedTasks.length
              ? Array(max)
                  .fill(0)
                  .map((_, index) => index)
                  .filter((item) => !filledPosition.includes(item))
              : [];
          const newTasks = newTasksToAdd.map((task, taskNumber) => {
            return {
              ...task,
              left: Number.isInteger(emptyPositions[taskNumber])
                ? emptyPositions[taskNumber]
                : alreadyAddedTasks.length + taskNumber,
            };
          });
          return [...acc, ...newTasks];
        }
        return acc;
      }, []);
  };
  return activeDays.map((date) => {
    const dayInDataBase = Object.keys(appointments).find((day) => date === day);

    const startWork = dayjs(`${date}T${startWorkTime.hours}:${startWorkTime.minutes}:00`).unix();
    const endWork = dayjs(`${date}T${endWorkTime.hours}:${endWorkTime.minutes}:00`).unix();
    const workTime = endWork - startWork;

    const workersWithTimeLimit = activeWorkers.map((worker) => {
      const workerTimeLimit = worker.chiusure ? worker.chiusure[dayInDataBase] : [];
      const workerWithTasks = {
        id: worker.idpoltrona,
        workerName: worker.nome_referente,
        workerTasks: [],
        iconPoltrona: worker.iconPoltrona,
        icon: getIconUrl(worker.idpoltrona, imageEntities.worker),
      };
      if (workerTimeLimit?.length) {
        const workersLimits = workerTimeLimit.map((limit) => {
          const unixStartTime = dayjs(`${date}T${limit.dalle}`).unix();
          const unixEndTime = dayjs(
            `${date}T${limit.alle !== '00:00:00' ? limit.alle : '24:00:00'}`,
          ).unix();
          const realStart = unixStartTime > startWork ? unixStartTime : startWork;
          const realEnd = unixEndTime < endWork ? unixEndTime : endWork;
          const startTask =
            ((realStart - startWork) / 60 / (timeStep.hours * 60 + timeStep.minutes)) * stepSize;
          const endTask =
            ((realEnd - realStart) / 60 / (timeStep.hours * 60 + timeStep.minutes)) * stepSize;

          return {
            limitCard: true,
            key: `${date}${worker.idpoltrona}${unixStartTime}${unixEndTime}`,
            orario_start: limit.dalle,
            orario_end: limit.alle,
            unixStartTime,
            unixEndTime,
            unixSize: unixEndTime - unixStartTime,
            top: startTask,
            size: endTask,
          };
        });
        workerWithTasks.workerTasks = workersLimits;
      }

      if (dayInDataBase) {
        const activeTasks = appointments[dayInDataBase]
          .filter((tasks) => tasks.idpoltrona === worker.idpoltrona)
          .map((filteredTask) => {
            const unixStartTime = dayjs(`${date}T${filteredTask.orario_start}`).unix();
            const unixEndTime = dayjs(
              `${date}T${
                filteredTask.orario_end !== '00:00:00' ? filteredTask.orario_end : '24:00:00'
              }`,
            ).unix();

            const startTask =
              ((unixStartTime - startWork) / 60 / (timeStep.hours * 60 + timeStep.minutes)) *
              stepSize;
            const endTask =
              ((unixEndTime - unixStartTime) / 60 / (timeStep.hours * 60 + timeStep.minutes)) *
              stepSize;

            return {
              ...filteredTask,
              key: `${date}${worker.idpoltrona}${filteredTask.idprenotazione}`,
              orario_start: filteredTask.orario_start,
              orario_end: filteredTask.orario_end,
              idprenotazione: filteredTask.idprenotazione,
              nome: filteredTask.nome,
              unixStartTime,
              unixEndTime,
              unixSize: unixEndTime - unixStartTime,
              servizi: filteredTask.servizi.map((service) => ({
                color: service.color,
                nome: service.nome,
                icon: getIconUrl(service.idServizio, imageEntities.service),
                iconaServizio: service.iconaServizio,
              })),
              top: startTask,
              size: endTask,
              left: 0,
              color: filteredTask.servizi?.length ? filteredTask.servizi[0].color : 'gray',
              isActive: unixStartTime > dayjs().unix(),
            };
          });

        workerWithTasks.workerTasks = [...workerWithTasks.workerTasks, ...activeTasks];

        // const formedTasks = stackedTasks({
        //   startWork,
        //   activeTasks,
        //   calendarItemsCount,
        // });
        //
        // workerWithTasks.workerTasks = [...workerWithTasks.workerTasks, ...formedTasks];
      }
      return workerWithTasks;
    });
    return {
      date: date,
      startWork,
      endWork,
      workTime,
      workers: workersWithTimeLimit,
    };
  });
};
