import React, { useEffect, useRef, useState } from 'react';
import SchedulerWorker from '../SchedulerWorker';
import styles from './SchedulerDay.module.scss';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';

const SchedulerDay = ({
  calendarItemsCount,
  day,
  cardSpace,
  setStartData,
  stepSize,
  startData,
  numberOfActiveDays,
  containerRef,
}) => {
  const showHeader = numberOfActiveDays > 1;
  const isPresentDay = day.startWork < dayjs().unix() && day.endWork > dayjs().unix();
  const isPastDay = !isPresentDay && day.startWork < dayjs().unix();
  const countPosition = () =>
    Math.ceil((dayjs().unix() - day.startWork) / (day.workTime / calendarItemsCount)) * stepSize;
  const [presentDayPosition, setPresentDayPosition] = useState(isPresentDay ? countPosition() : 0);
  const [scrolled, setScrolled] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (!isPresentDay) return;
    const intervalId = setInterval(() => {
      const position = countPosition();
      if (position !== presentDayPosition) setPresentDayPosition(position);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [isPresentDay, day.startWork, day.workTime, stepSize, calendarItemsCount]);
  const scrollPage = () => {
    if (isPresentDay && !scrolled) {
      containerRef.current.scrollTo(0, presentDayPosition - 200);
      setScrolled(true);
    }
    if (searchParams.get('reset') == 2) {
      containerRef.current.scrollTo(0, presentDayPosition - 200);
      searchParams.set('reset', 0);
      setSearchParams(searchParams);
    }
  };
  useEffect(scrollPage, [presentDayPosition, searchParams]);
  return (
    <div
      className={styles.scheduler__day__wrapper}
      style={{ minWidth: `${cardSpace * day.workers.length}px` }}
    >
      {showHeader && <div className={styles.day__header}>{dayjs(day.date).format('dddd DD')}</div>}{' '}
      <div className={styles.day_time_line_wrapper}>
        {Array(calendarItemsCount / 6)
          .fill(0)
          .map((_, index) => {
            return (
              <div
                key={index}
                className={styles.day_time_line}
                style={{ top: `${index * 6 * stepSize + (showHeader ? 100 : 50)}px` }}
              />
            );
          })}
      </div>
      {isPresentDay ? (
        <div
          className={styles.day_blocked}
          style={{
            height: `${presentDayPosition}px`,
            borderBottom: '#35A8F6 solid 2px',
            top: `${showHeader ? 100 : 50}px`,
          }}
        />
      ) : isPastDay ? (
        <div
          className={styles.day_blocked}
          style={{
            top: `${showHeader ? 100 : 50}px`,
            height: `calc(100% - ${showHeader ? 100 : 50}px)`,
          }}
        />
      ) : null}
      <div className={styles.workers_container}>
        {day.workers.map((worker) => {
          return (
            <SchedulerWorker
              key={worker.id}
              worker={worker}
              day={{
                date: day.date,
                endWork: day.endWork,
                startWork: day.startWork,
                workTime: day.workTime,
              }}
              setStartData={setStartData}
              stepSize={stepSize}
              startData={startData}
              showHeader={showHeader}
            />
          );
        })}
      </div>
    </div>
  );
};

export default SchedulerDay;
