import styles from './SearchInput.module.scss';
import { ReactComponent as SearchIcon } from '../../../assets/svg/icons/cerca.svg';
import { ReactComponent as CloseIcon } from '../../../assets/svg/cross.svg';
import clx from 'classnames';

const SearchInput = ({ placeholder = '', value = '', setValue, backgroundColor }) => (
  <div className={styles.search_field__wrapper}>
    <SearchIcon className={styles.search_icon} />
    <CloseIcon
      className={clx(styles.close_icon, {
        [styles['hide']]: !value,
      })}
      onClick={() => setValue('')}
    />
    <input
      type={'text'}
      value={value}
      placeholder={placeholder}
      onChange={(event) => setValue(event.target.value)}
      className={styles['field__value']}
      style={{
        ...(backgroundColor && { backgroundColor }),
      }}
    />
  </div>
);

export default SearchInput;
