import useOutsideClick from '../../../../utils/useOutsideClick';
import { useEffect, useRef, useState } from 'react';
import styles from './InputSelector.module.scss';

import clx from 'classnames';
import SelectorMenu from '../SelectorMenu';
import CustomInput from '../../CustomInput';
import useWindowSize from '../../../../utils/useWindowSize';

const InputSelector = ({
  value,
  newValue,
  options,
  placeholder,
  title,
  icon,
  withIcon = false,
  disabled,
  loading,
  error,
  isEmptyMessage,
  readOnly = false,
  acceptNewValue = false,
  setValue: setValueComponent = () => {},
  setNewValue = () => {},
  number,
}) => {
  const dropdownRef = useRef(null);
  options = options.sort((a, b) => a.name.localeCompare(b.name));
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [filteredOption, setFilteredOption] = useState(options);
  const [visibleOptions, setVisibleOptions] = useState([]);
  const { isMobile } = useWindowSize();
  const selectRef = useRef(null);

  const defaultValue = Symbol('default').toString();

  const setValue = (value) => {
    const isDefault = value === defaultValue;
    setValueComponent(isDefault ? '' : value);
    setNewValue(isDefault ? searchString : '');
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    selectRef.current?.scrollTo(0, 0);

    if (value && options.find((option) => option.value === value)?.name === searchString)
      return setFilteredOption(options);
    setFilteredOption(
      options
        .filter(
          (option) =>
            option?.name?.toLowerCase().includes(searchString?.toLowerCase()) ||
            option?.phone?.includes(searchString?.toLowerCase()),
        )
        .concat(
          acceptNewValue && searchString ? [{ name: searchString, value: defaultValue }] : [],
        ),
    );
  }, [options, searchString, value]);

  useEffect(() => {
    value &&
      setSearchString(options.find((option) => option.value === value)?.name || newValue || '');
  }, [value, isDropdownOpen, options]);

  useOutsideClick(dropdownRef, () => {
    if (searchString && !(options.find((option) => option.value === value)?.name || newValue))
      setNewValue(searchString);
    if (isDropdownOpen) setIsDropdownOpen(false);
  });

  const clearData = () => {
    setValue('');
    setSearchString('');
  };

  useEffect(() => {
    if (selectRef?.current?.outerHTML) {
      selectRef.current.onscroll = (e) => {
        if (e.target.scrollTop + e.target.offsetHeight === e.target.scrollHeight) {
          setVisibleOptions((prev) =>
            prev.concat(filteredOption.slice(prev.length, prev.length + 20)),
          );
        }
      };
    }
  }, [selectRef.current]);
  useEffect(() => {
    setVisibleOptions(filteredOption.slice(0, 100));
  }, [filteredOption]);
  return (
    <div
      ref={dropdownRef}
      className={clx(styles.selector_wrapper, {
        [styles['selector_wrapper_mobile']]: isMobile,
        [styles['selector_wrapper_close']]: !isDropdownOpen,
      })}
    >
      <CustomInput
        placeholder={placeholder}
        inputValue={searchString}
        setInputValue={setSearchString}
        loading={loading}
        onAction={() => setIsDropdownOpen(!isDropdownOpen)}
        title={title}
        clearData={clearData}
        withIcon={withIcon}
        icon={icon}
        disabled={disabled}
        error={error}
        height={isMobile ? '50px' : 'fit-content'}
        readOnly={readOnly}
      />
      <div className={styles.dropdown__menu} ref={selectRef}>
        <SelectorMenu
          fullHeight={true}
          isMultiple={false}
          setIsDropdownOpen={() => setIsDropdownOpen(false)}
          options={visibleOptions}
          setValue={setValue}
          value={[value]}
          isEmptyMessage={isEmptyMessage}
          number={number}
        />
      </div>
    </div>
  );
};

export default InputSelector;
