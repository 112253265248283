import Modal from '../Modal';
import styles from './ConfirmSecondaryModal.module.scss';
import { ReactComponent as Bin } from '../../../assets/svg/icons/trash_red.svg';
import { Button } from '@mui/material';
import React from 'react';
import cls from 'classnames';
import { ReactComponent as CloseIcon } from '../../../assets/svg/cross.svg';

const ConfirmSecondaryModal = ({
  isModalOpen,
  setIsModalOpen,
  title,
  subtitle,
  content,
  action,
  Icon = Bin,
  buttons = ['Annulla', 'Disdici'],
  width = '420px',
  buttonsActions = [
    () => {
      setIsModalOpen(false);
    },
  ],
}) => (
  <Modal
    width={width}
    maxWidth={width}
    // height={content ? '300px' : '230px'}
    isModalOpen={isModalOpen}
    setIsModalOpen={setIsModalOpen}
  >
    <div className={styles.modal__content} style={{ width, maxWidth: width }}>
      <div className={styles.header}>
        <Icon />
        <div className={styles.title}>{title}</div>
        <CloseIcon
          className={styles.close__icon}
          onClick={() => {
            setIsModalOpen(false);
          }}
        />
      </div>
      <div className={styles.subtitle}>{subtitle}</div>
      {content}
      <div className={styles.modal__actions}>
        <Button onClick={buttonsActions[0]} className={styles.primary}>
          {buttons[0]}
        </Button>
        {buttons.slice(1).map((button, index) => (
          <Button
            onClick={buttonsActions[index + 1] || action}
            key={index}
            className={cls(styles.primary, styles.secondary)}
          >
            {button}
          </Button>
        ))}
      </div>
    </div>
  </Modal>
);

export default ConfirmSecondaryModal;
