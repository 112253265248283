import React, {useState} from 'react';
import styles from "./SalonWorkerSelectorStep.module.scss";
import {authActions} from "../../../../../store";
import {useDispatch, useSelector} from "react-redux";
import Switcher from "../../../../../../Common/Switcher";
import {SalonWorkTimeStepWrapper} from "../SalonWorkTimeStepWrapper";

export const SalonWorkerSelectorStepContainer = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(true)

  return (
    <SalonWorkTimeStepWrapper
      title={'Domanda...Hai dei collaboratori che ti aiutano nel tuo salone?'}
      action={() => dispatch(authActions.GET_ACTIVE_SIGN_UP_STEP.SUCCESS(value ? 13 : 14))}
    >
      <div className={styles.salon_picker}>
        <Switcher
          value={!value}
          setValue={setValue}
          darkMode={true}
        />
      </div>
    </SalonWorkTimeStepWrapper>
  )
}