import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { Button } from '@mui/material';

import LogoImage from '../../../../assets/images/logo.png';
import PrimaryButton from '../../../Common/Buttons/PrimaryButton';
import TextField from '../../../Common/TextField';
import { authActions } from '../../store';
import styles from './SignIn.module.scss';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../routes/constants';
import useWindowSize from '../../../../utils/useWindowSize';
import clsx from 'clsx';

export default () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fromApp = !!localStorage.getItem('fromApp');
  const { isMobile } = useWindowSize();
  const initialValues = {
    email: '',
    pwd: '',
  };
  const validationSchema = {
    email: yup
      .string('Inserisci la tua email')
      .email('Inserisci una email valida')
      .required('Email è richiesta'),
    pwd: yup
      .string()
      .min(3, 'La password è troppo corta')
      .max(100, 'La password è troppo lunga')
      .required('Password richiesta'),
  };

  const openTypeform = () => {
    const url = 'https://form.typeform.com/to/hrLlq7P0?typeform-source=dashboard.zetabarber.it';
    if (!fromApp) window.open(url, '_blank');
    else window?.flutter_inappwebview?.callHandler('app:url', url);
  };

  const openTerms = () => {
    const termsLink = 'https://application.zetabarber.it/terminiEcondizioniBarbieri.pdf';
    if (!fromApp) window.open(termsLink, '_blank');
    else window?.flutter_inappwebview?.callHandler('app:url', termsLink);
}

  return (
    <div
      className={clsx(styles.container__inner, {
        [styles.container__inner__mobile]: isMobile,
      })}
    >
      <div className={styles['login__container']}>
        <img src={LogoImage} alt='logo' className={styles.logo} />
        <h1>
          Benvenuto {isMobile && <br />}
          <span>Barber</span>
        </h1>
        <p>
          Dai un taglio definitivo allo stress da prenotazione e goditi il tuo lavoro senza pensare
          alla gestione della clientela
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={yup.object().shape(validationSchema)}
          onSubmit={(values) => dispatch(authActions.SIGN_IN.REQUEST(values))}
        >
          {() => (
            <Form className={styles['login__form']}>
              <Field
                name='email'
                id='email'
                //label='Email'
                placeholder='Inserisci email'
                component={TextField}
                type='email'
                inputColor='white' //'#f5f5f5'
                showIcon={true}
              />
              <Field
                name='pwd'
                id='pwd'
                //label='Password'
                placeholder='Inserisci password'
                showPassword={true}
                showIcon={true}
                component={TextField}
                type='password'
                inputColor='white' //'#f5f5f5'
              />

              <Button
                width='100%'
                className={styles.confirm_button}
                variant='contained'
                type={'submit'}
              >
                Accedi
              </Button>
              {/* <PrimaryButton
                type={'button'}
                width='100%'
                text='Welcome page (temp.)'
                onClick={() => navigate(`${ROUTES.SIGN_IN}/welcome`)}
              /> */}
            </Form>
          )}
        </Formik>
        <div className={styles.pwdRecovery} onClick={() => navigate(ROUTES.RECOVERY)}>
          Password  <span> dimenticata? </span>
        </div>
        <div style={{height: '5px', width: '70%', maxWidth: '300px', borderTop: '1px solid #787878', marginTop: '40px'}}/>
        <div className={styles.signUp} onClick={openTypeform}>
            <Button 
              width='100%'
              className={styles.confirm_button}
              variant='contained'
            >
              Richiedi un account
            </Button>
        </div>
        {/* <a
          href='#'
          onClick={() => {
            window?.flutter_inappwebview?.callHandler('app:onboardingContact', 'open');
            //alert('test open onboarding');
          }}
        >
          test open onboarding rubrica
        </a> */}

        
        <div className={styles.footer}>
          Cliccando su accedi accetterai i <span onClick={openTerms}>termini e condizioni </span> e la{' '}
          <span onClick={openTerms}>Privacy policy</span> di ZetaBarber
        </div>
      </div>
    </div>
  );
};
