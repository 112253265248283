import { call, put } from 'redux-saga/effects';

import { setError } from '../shared/Error/store/actions';
import { startLoader, stopLoader } from '../shared/Loader/store/actions';

export default function* (successAction, errorAction, options) {
  try {
    yield put(startLoader(options?.loader));
    yield call(successAction());
  } catch (error) {
    yield put(
      setError(
        error?.response?.data?.error ||
          error?.response?.data?.message ||
          error?.response?.data?.msg ||
          error?.response?.data?.stringCode ||
          null,
      ),
    );
    yield put(
      errorAction(
        error?.response?.data?.error ||
          error?.response?.data?.message ||
          error?.response?.data?.msg ||
          error?.response?.data?.stringCode ||
          null,
      ),
    );
  } finally {
    yield put(stopLoader(options?.loader));
  }
}
