import React, { useEffect, useState } from 'react';
import styles from './Closures.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getWorker, getWorkerClosures } from '../../../../store/selectors';
import { salonManagementActions } from '../../../../store';
import { useNavigate, useParams } from 'react-router-dom';
import { getActiveSalonId } from '../../../../../Auth/store/selectors';
import TitleSectionBack from '../../../../../Common/TitleSectionBack';
import AvatarIcon from '../../../../../Common/AvatarIcon';
import clx from 'classnames';
import useWindowSize from '../../../../../../utils/useWindowSize';
import CustomDatePicker from '../../../../../Common/CustomDatePicker';
import CollapsibleCard from '../../../../../Common/CollapsibleCard';
import ClosuresTable from '../ClosuresTable';
import ConfirmDeleteModal from '../../../../../Common/ConfirmDeleteModal';
import { ReactComponent as PlusIcon } from '../../../../../../assets/svg/icons/plus_button.svg';
import { ReactComponent as Bin } from '../../../../../../assets/svg/icons/trash_red.svg';
import { modalActions } from '../../../../../Modals/store';
import dayjs from 'dayjs';
import { Button } from '@mui/material';

const MOTIVATIONS = ['Festività', 'Ferie', 'Mancanza di servizi', 'Motivi personali'];

const ClosuresCardsList = ({
  closures = [],
  setClosuresDateToDelete,
  isEmptyMessage = 'Non ci sono dati disponibili al momento',
}) => {
  return (
    <div className={styles.closures_cards_list_container}>
      {!closures.length ? (
        <div className={styles.empty_card}>{isEmptyMessage}</div>
      ) : (
        closures.map((item) => (
          <div key={item.items.idchiusura} className={styles.closures_card}>
            <div className={styles.card_row}>
              <div className={styles.row_title}>{item.days.length > 1 ? 'Giorni' : 'Giorno'}</div>
              <div className={styles.row_value}>{item.days.join(' - ')}</div>
            </div>
            <div className={styles.card_row}>
              <div className={styles.row_title}>Periodo</div>
              <div className={styles.row_value}>
                dal {dayjs(item.dates.startDate).format('DD/MM/YY')} al{' '}
                {dayjs(item.dates.endDate).format('DD/MM/YY')}{' '}
              </div>
            </div>
            <div className={styles.card_row}>
              <div className={styles.row_title}>Motivazione</div>
              <div className={styles.row_value}>{MOTIVATIONS[item.items[0].tipo_chiusura--]}</div>
            </div>
            <div className={styles.card_row}>
              <div className={styles.row_title}>Orario</div>
              <div className={styles.row_value}>Tutta la giornata</div>
            </div>
            <Button
              onClick={() =>
                setClosuresDateToDelete(item.items.map(({ data_chiusura }) => data_chiusura))
              }
              className={styles.delete_button}
            >
              <Bin />
              Cancella chiusura
            </Button>
          </div>
        ))
      )}
    </div>
  );
};

const Closures = () => {
  const dispatch = useDispatch();
  const { workerId } = useParams();
  const navigate = useNavigate();
  const worker = useSelector(getWorker());
  const activeSalonId = useSelector(getActiveSalonId());
  const { isMobile } = useWindowSize();

  const [tablesFilterDate, setTablesFilterDate] = useState(null);
  const closures = useSelector(getWorkerClosures(tablesFilterDate));
  const [closuresDateToDelete, setClosuresDateToDelete] = useState(false);
  const closuresMerged = Object.values(closures || {}).map((items) => {
    let startDate, endDate;
    const days = [];
    items.forEach((item) => {
      if (item.data_chiusura < startDate || startDate === undefined) startDate = item.data_chiusura;
      if (item.data_chiusura > endDate || endDate === undefined) endDate = item.data_chiusura;
      const day = dayjs(item.data_chiusura).format('dddd');
      if (!days.includes(day)) days.push(day);
    });
    days.sort((a, b) => {
      const days = ['domenica', 'lunedì', 'martedì', 'mercoledì', 'giovedì', 'venerdì', 'sabato'];
      return days.indexOf(a) - days.indexOf(b);
    });
    return {
      dates: {
        startDate,
        endDate,
      },
      days,
      items,
    };
  });

  useEffect(() => {
    dispatch(salonManagementActions.GET_WORKER.REQUEST(workerId));
    return () => {
      dispatch(salonManagementActions.GET_WORKER.CLEAR());
    };
  }, [workerId]);

  useEffect(() => {
    if (worker?.salone && worker?.salone !== activeSalonId) {
      dispatch(salonManagementActions.GET_WORKER.CLEAR());
      navigate(-1);
    }
  }, [worker, activeSalonId]);

  return (
    <>
      <ConfirmDeleteModal
        isModalOpen={closuresDateToDelete}
        setIsModalOpen={setClosuresDateToDelete}
        title={'Sei sicuro di voler cancellare questa chiusura straordinaria?'}
        buttons={['Annulla', 'Cancella']}
        action={() =>
          closuresDateToDelete.map((date) =>
            dispatch(
              salonManagementActions.DELETE_WORKER_CLOSURES.REQUEST(
                {
                  idPoltrona: workerId,
                  data: date,
                },
                () => setClosuresDateToDelete(false),
              ),
            ),
          )
        }
      />
      <div
        className={clx(styles.closures_workers_container, {
          [styles.closures_workers_container_mobile]: isMobile,
        })}
      >
        <TitleSectionBack
          title={'Chiusura straordinaria'}
          description={'Modifica orari temporaneamente. Aggiungendo una chiusura straordinaria il salone risulterà chiuso solo nelle date selezionate.'}
        />
        <PlusIcon
          className={styles.closures_add_button}
          height='60px'
          width='60px'
          onClick={() =>
            dispatch(
              modalActions.OPEN_MODAL.SUCCESS({
                type: 'workerClosuresModal',
                props: { worker: worker.nome_referente },
              }),
            )
          }
        />
        <div className={styles.tables_actions}>
          <div className={styles.name_cell_container}>
            <AvatarIcon name={worker.nome_referente} icon={worker.icon} size={'50px'} />
            <div className={styles.client_info}>
              <div className={styles.description}>Stai modificando gli orari di</div>
              <div className={styles.name}>{worker.nome_referente}</div>
            </div>
          </div>
          <div className={styles.picker_container}>
            <CustomDatePicker
              type={'button'}
              value={tablesFilterDate}
              setValue={setTablesFilterDate}
              placeholder={'Filtra per data'}
              disablePast={true}
            />
          </div>
        </div>
        <CollapsibleCard title={'Chiusura straordinaria'}>
          {isMobile ? (
            <ClosuresCardsList
              closures={closuresMerged}
              setClosuresDateToDelete={setClosuresDateToDelete}
              isEmptyMessage='Questa poltrona non ha chiusure straordinarie attive al momento'
            />
          ) : (
            <ClosuresTable
              values={closuresMerged}
              setClosuresDateToDelete={setClosuresDateToDelete}
              isEmptyMessage='Questa poltrona non ha chiusure straordinarie attive al momento'
            />
          )}
        </CollapsibleCard>
      </div>
    </>
  );
};

export default Closures;
