import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './JoinServicesModal.module.scss';
import { Button } from '@mui/material';
import clsx from 'clsx';
import useWindowSize from 'utils/useWindowSize';
import { salonManagementActions, salonManagementTypes } from 'Containers/SalonManagement/store';
import SelectorMenu from 'Containers/Common/CustomSelector/SelectorMenu';
import { getServices } from 'Containers/SalonManagement/store/selectors';
import { ReactComponent as AlertIcon } from 'assets/svg/icons/alert.svg';

export default ({ closeModal, activeServices, worker }) => {
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const services = useSelector(getServices());
  const formedServices = services.filter((service) => service.disabilitato == 0).map((service) => ({
    name: service.name,
    value: service.id,
    icon: service.icon,
    cost: service.prezzo,
    duration: service.time,
    onlyVisibile: service.onlyVisibile,
  }));
  const [ selectorValues, setSelectorValues ] = useState([]);
  const hasSelectedValues = formedServices.filter((service) => !activeServices.includes(service.value)).length > 0;

  const saveValue = () => {
    const sendServices = [...activeServices, ...selectorValues];
    const activeServicesIds = sendServices.filter((id) => !worker.activeServicesIds.includes(id));
    const servicesIdsToDelete = worker.activeServicesIds.filter(
      (id) => !sendServices.includes(id),
    );
    if (activeServicesIds.length || servicesIdsToDelete.length) {
      dispatch(
        salonManagementActions.EDIT_WORKER_SERVICES.REQUEST({
          idPoltrona: worker.idpoltrona,
          activeServicesIds,
          servicesIdsToDelete,
        }),
        closeModal(),
      );

    }
  };


  return (
        <div
          className={clsx(styles.service_modal_wrapper, {
            [styles.service_modal_wrapper_mobile]: isMobile,
          })}
        >
          <div className={styles.body}>
            {!hasSelectedValues && <p className={styles.alert}>
                <AlertIcon />
                Non ci sono servizi da aggiungere
            </p>}
            <SelectorMenu
                isMultiple={true}
                value={selectorValues}
                options={formedServices}
                setIsDropdownOpen={() => {}}
                setValue={setSelectorValues}
                blockedValues={activeServices}
                fullHeight={true}
                border={false}
            />
          </div>
          <hr />
          <div className={clsx(styles.actions_container, styles.footer)}>
            <Button onClick={saveValue} className={clsx(styles.confirm_button, {[styles.button_disabled]: !hasSelectedValues})} variant='contained' type={'button'} disabled={!hasSelectedValues}>
              Salva
            </Button>
          </div>
        </div>
  );
};
