import React, { useEffect, useState } from 'react';
import styles from './WorkersList.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getService, getWorkers } from '../../../../store/selectors';

import SelectorMenu from '../../../../../Common/CustomSelector/SelectorMenu';
import { Button } from '@mui/material';
import { salonManagementActions } from '../../../../store';
import { useNavigate, useParams } from 'react-router-dom';
import { getActiveSalonId } from '../../../../../Auth/store/selectors';
import TitleSectionBack from '../../../../../Common/TitleSectionBack';
import AvatarIcon from '../../../../../Common/AvatarIcon';
import useWindowSize from '../../../../../../utils/useWindowSize';
import clx from 'classnames';

const WorkersList = () => {
  const dispatch = useDispatch();
  const { serviceId } = useParams();
  const { isMobile } = useWindowSize();
  const navigate = useNavigate();
  const activeSalonId = useSelector(getActiveSalonId());
  const workers = useSelector(getWorkers());
  const formedWorkers = workers.map((worker) => ({
    name: worker.name,
    value: worker.id,
    icon: worker.icon,
    disabled: worker.disabilitato,
  }));

  const service = useSelector(getService());
  const [activeWorkers, setActiveWorkers] = useState(service.activeWorkersIds || []);

  useEffect(() => {
    service.activeWorkersIds && setActiveWorkers(service.activeWorkersIds);
  }, [service.activeWorkersIds]);

  useEffect(() => {
    dispatch(salonManagementActions.GET_WORKERS.REQUEST());
    dispatch(salonManagementActions.GET_SERVICE.REQUEST(serviceId));
    return () => {
      dispatch(salonManagementActions.GET_WORKERS.CLEAR());
      dispatch(salonManagementActions.GET_SERVICE.CLEAR());
    };
  }, [serviceId]);

  useEffect(() => {
    if (service?.salone && service?.salone !== activeSalonId) {
      dispatch(salonManagementActions.GET_WORKERS.CLEAR());
      dispatch(salonManagementActions.GET_SERVICE.CLEAR());
      navigate(-1);
    }
  }, [service, activeSalonId]);

  const saveValue = () => {
    const activeWorkersIds = activeWorkers.filter((id) => !service.activeWorkersIds.includes(id));
    const workersIdsToDelete = service.activeWorkersIds.filter((id) => !activeWorkers.includes(id));
    if (activeWorkersIds.length || workersIdsToDelete.length) {
      dispatch(
        salonManagementActions.EDIT_SERVICE_WORKERS.REQUEST({
          idServizio: service.idservezio,
          activeWorkersIds,
          workersIdsToDelete,
        }),
      );
    }
  };

  return (
    <div
      className={clx(styles.service_workers_modal_container, {
        [styles.service_workers_modal_container_mobile]: isMobile,
      })}
    >
      <TitleSectionBack title={'Poltrona'} description={'Associa il servizio alle tue poltrone'} />
      <div className={styles.name_cell_container}>
        <AvatarIcon name={service.nome} icon={service.icon} size={'50px'} />
        <div className={styles.client_info}>
          <div className={styles.description}>Stai associando il servizio</div>
          <div className={styles.name}>{service.nome}</div>
        </div>
      </div>
      <div className={styles.list_container}>
        <div className={styles.selector_header}>Poltrone</div>
        <SelectorMenu
          isMultiple={true}
          options={formedWorkers}
          setIsDropdownOpen={() => {}}
          setValue={setActiveWorkers}
          value={activeWorkers}
          fullHeight={true}
          border={false}
        />
        <div className={styles.actions_container}>
          <Button
            onClick={() => saveValue()}
            className={styles.confirm_button}
            variant='contained'
            type={'button'}
          >
            Salva
          </Button>
        </div>
      </div>
    </div>
  );
};

export default WorkersList;
