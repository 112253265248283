import { Button } from '@mui/material';
import styles from './Invoice.module.scss';
import clsx from 'clsx';
import { ReactComponent as SettingIcon } from 'assets/svg/settings.svg';
import { useDispatch } from 'react-redux';

export const Invoice = ({ informations, handlePress, isMobile }) => {
  if (!Object.values(informations).length) return null;
  return (
    <div className={clsx(styles.informations, { [styles.informations_mobile]: isMobile })}>
      <div className={styles.informations_left}>
        {Object.entries({
          address: Object.values(informations?.address || {})
            ?.filter((value) => !!value)
            .join(', '),
          phone: informations?.phone,
          email: informations?.email,
          name: informations?.name,
        })
          .filter(([_, value]) => !!value)
          .map(([_, value]) => {
            return (
              <div className={styles.informations_information}>
                <h4>{value}</h4>
              </div>
            );
          })}
      </div>
      <div className={styles.informations_right}>
        <Button onClick={handlePress} variant='contained' className={styles.informations_button}>
          <SettingIcon className={styles.icon} height='17px' width='17px' />
          Modifica informazioni fatturazione
        </Button>
      </div>
    </div>
  );
};
