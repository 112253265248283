import useOutsideClick from '../../../../utils/useOutsideClick';
import { useEffect, useRef, useState } from 'react';
import styles from './MultiplySelector.module.scss';

import clx from 'classnames';
import SelectorMenu from '../SelectorMenu';
import CustomInput from '../../CustomInput';
import useWindowSize from '../../../../utils/useWindowSize';

const MultiplySelector = ({
  value,
  setValue,
  options,
  placeholder,
  title,
  withIcon = false,
  icon,
  disabled = false,
  error,
  isEmptyMessage,
  closeOnClick = false,
  readOnly,
}) => {
  const dropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchString, setSearchString] = useState('');
  const { isMobile } = useWindowSize();
  useEffect(() => {
    setSearchString(
      options
        .filter((option) => value.includes(option.value))
        .map((i) => i.name)
        .join(' '),
    );
  }, [value]);

  useOutsideClick(dropdownRef, () => {
    if (isDropdownOpen) setIsDropdownOpen(false);
  });

  const clearData = () => {
    setValue('');
    setSearchString('');
  };

  return (
    <div
      ref={dropdownRef}
      className={clx(styles.selector_wrapper, {
        [styles['selector_wrapper_close']]: !isDropdownOpen,
      })}
    >
      <CustomInput
        placeholder={placeholder}
        inputValue={searchString}
        setInputValue={() => {}}
        onAction={() => setIsDropdownOpen(!isDropdownOpen)}
        title={title}
        readOnly={readOnly}
        clearData={clearData}
        withIcon={withIcon}
        icon={icon}
        disabled={disabled}
        error={error}
        height={isMobile ? '50px' : 'fit-content'}
      />
      <div className={styles.dropdown__menu}>
        <SelectorMenu
          isMultiple={true}
          setIsDropdownOpen={() => setIsDropdownOpen(false)}
          options={options}
          setValue={(value) => {
            setValue(value);
            closeOnClick ? setIsDropdownOpen(false) : setIsDropdownOpen(true);
          }}
          value={value}
          isEmptyMessage={isEmptyMessage}
        />
      </div>
    </div>
  );
};

export default MultiplySelector;
