import React from "react";
import {useDispatch} from "react-redux";
import styles from "./WorkerDescriptionModal.module.scss";
import {Field, Form, Formik} from "formik";
import {Button} from "@mui/material";
import {salonManagementActions} from "../../../../store";
import TextField from "../../../../../Common/TextField";

export default ({closeModal, worker}) => {
  const dispatch = useDispatch();
  return (
    <Formik
      initialValues={worker}
      onSubmit={(values) => {
        const req = {
          idPoltrona: values.id,
          descrizionePoltrona: values.description,
        }
        dispatch(salonManagementActions.EDIT_WORKER.REQUEST(req, closeModal))
      }}
    >
      {() => (
        <Form className={styles.closures_modal_wrapper}>
          <Field
            name={'description'}
            id={'description'}
            label="Descrizione"
            component={TextField}
            textarea={true}
            height={'180px'}
            margin={'0px 0px 40px 0px'}
            placeholder={"Campo di testo da compilare"}
          />
          <div className={styles.actions_container}>
            <Button
              className={styles.confirm_button}
              variant="contained"
              type={'submit'}
            >
              Salva
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}