import React, { useEffect, useState } from 'react';
import styles from './WorkerSoldOut.module.scss';
import { ROUTES } from '../../../../../routes/constants';
import AvatarIcon from '../../../../Common/AvatarIcon';
import { ReactComponent as CloseIcon } from '../../../../../assets/svg/cross.svg';
import { ReactComponent as InstagramIcon } from '../../../../../assets/svg/badgets/instagram.svg';
import { ReactComponent as LogoIcon } from '../../../../../assets/svg/logo_noText.svg';
import { ReactComponent as XMasFrame } from '../../../../../assets/svg/xMas_frame.svg';
import LinearProgress from 'Containers/Common/LinearProgress';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import useWindowSize from '../../../../../utils/useWindowSize';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import PrimaryButton from 'Containers/Common/Buttons/PrimaryButton';
import dayjs from 'dayjs';
import { getCalendarSlots, getCalendarWorkerServices } from 'Containers/Scheduler/store/selectors';
import { schedulerActions } from 'Containers/Scheduler/store';
import html2canvas from 'html2canvas';

const CardDisponibili = ({ date, timesFreeTask, worker }) => {
  const slicedTimes =
    timesFreeTask.length > 0
      ? timesFreeTask.slice(0, 6)?.map((el) => {
          return {
            oraInizio: el.oraInizio.split(':')[0] + ':' + el.oraInizio.split(':')[1],
            oraFine: el.oraFine.split(':')[0] + ':' + el.oraFine.split(':')[1],
            isBooked: el.isBooked,
          };
        })
      : [];

  const xMas = new Date(date).getMonth() == 11 && new Date(date).getDate() >= 7;

  return (
    <>
      <header className={styles.head}>
        {worker.icon ? (
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <AvatarIcon
              icon={worker?.icon}
              name={worker.workerName}
              size={'55px'}
              style={{ position: 'absolute' }}
            />
            {xMas && <XMasFrame width={'60px'} height={'65px'} style={{ position: 'absolute' }} />}
          </div>
        ) : null}
        <h1 className={styles.text}>{worker.workerName}</h1>
      </header>
      <div>
        <h1 className={styles.big_text}>DISPONIBILIT&Agrave; APPUNTAMENTI</h1>
        <h1 className={clsx(styles.big_text, { [styles.gold]: !!date })}>
          {dayjs().isSame(dayjs(date), 'day') ? 'Oggi' : dayjs(date).format('DD MMMM')}
        </h1>
      </div>
      <article
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: '5px',
          margin: 'auto 0 0 0',
          alignItems: 'center',
        }}
      >
        {slicedTimes.map((item, index) => {
          return (
            <div key={index} className={styles.time_card}>
              <h3 className={styles.text}>
                {item.oraInizio} {'-'} {item.oraFine}
              </h3>
            </div>
          );
        })}
      </article>
    </>
  );
};

const CardSoldOut = ({ worker, date }) => {
  const xMas = new Date(date).getMonth() == 11 && new Date(date).getDate() >= 7;
  return (
    <>
      <header className={styles.head} style={{ flexDirection: 'column' }}>
        {worker.icon ? (
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <AvatarIcon
              icon={worker?.icon}
              name={worker.workerName}
              size={'110px'}
              style={{ position: 'absolute' }}
            />
            {xMas && (
              <XMasFrame width={'125px'} height={'127px'} style={{ position: 'absolute' }} />
            )}
          </div>
        ) : null}
        <h1 className={styles.text}>{worker.workerName}</h1>
      </header>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          alignItems: 'center',
        }}
      >
        <h1 className={clsx(styles.big_text, { [styles.gold]: true })}>
          {dayjs().isSame(dayjs(date), 'day') ? 'Oggi' : dayjs(date).format('DD MMMM')}
        </h1>
        <div
          style={{
            display: 'flex',
            justifyContent: 'stretch',
          }}
        >
          <h1 className={styles.heavy_text}>Sold</h1>&nbsp;
          <h1 className={clsx(styles.heavy_text, { [styles.gold]: true })}>Out</h1>
        </div>
      </div>
      <div
        style={{
          width: '100%',
          padding: '13px 11px',
        }}
      >
        <h2
          style={{
            fontFamily: 'Roboto',
            textAlign: 'center',
            letterSpacing: '0.014px',
            lineHeight: '17px',
            fontWeight: 300,
          }}
        >
          Vai all’App e scopri il primo appuntamento disponibile!
        </h2>
      </div>
    </>
  );
};

const WorkerSoldOutModal = ({ closeModal, modalData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const { worker, day, soldOut, tasks } = modalData;
  const services = useSelector(getCalendarWorkerServices());
  const [loading, setLoading] = useState(false);
  let find = services.filter(
    (service) =>
      +service.worker_id === +worker.id && service.durata <= 30 && service.disabilitato == 0,
  );
  if (find.length == 0) {
    find = services.filter(
      (service) =>
        +service.worker_id === +worker.id && service.durata > 30 && service.disabilitato == 0,
    );
  }
  const filteredService = find;
  const [defaultService] = filteredService.sort((a, b) => b.durata - a.durata);
  const timesTask = useSelector(getCalendarSlots()) || [];
  const [timesFreeTask, setTimesFreeTasks] = useState([]);
  const xMas = new Date(day.date).getMonth() == 11 && new Date(day.date).getDate() >= 7;

  useEffect(() => {
    if (timesTask.length == 0) {
      dispatch(
        schedulerActions.GET_CALENDAR_SLOTS.REQUEST({
          servizi: [defaultService],
          idPoltrona: worker.id,
          nGiorniPrenotabili: 30,
          lang: 'it',
        }),
      );
    } else {
      const filterForDate = timesTask.filter((time) => time.data === day.date)[0]?.orari;
      const filteredTimes = filterForDate?.filter((time) => time.isBooked == false) ?? [];
      setTimesFreeTasks(filteredTimes);
    }
  }, [timesTask]);

  const [BASE_IMG, setBASE_IMG] = useState('');

  /* const generateShare = async () => {
    try {
      alert('quii');
      // Ottieni il riferimento all'elemento da convertire in immagine
      const elementToCapture = document.querySelector('#soldout-capture');
      if (!elementToCapture) console.log('elemento non trovato');

      const canvas = await html2canvas(elementToCapture, {
        scale: 0.1,
        allowTaint: true,
        useCORS: true,
      }).then((canvas) => {
        canvas.style.display = 'none';
        document.body.appendChild(canvas);
        return canvas;
      });

      const imageUrl = canvas.toDataURL('image/png', 1.0);
      setBASE_IMG(imageUrl);
      console.log(imageUrl);
      alert(BASE_IMG);

      const shareData = {
        title: 'Condividi!',
        text: 'Condividi le disponibiità sulla tua poltrona.',
        files: [imageUrl],
      };
      await navigator.share(shareData);
    } catch (err) {
      console.log("Errore durante la generazione dell'immagine:", err);
      setLoading(false);
    }
  }; */

  const generateShare = async () => {
    const elementToCapture = document.querySelector('#soldout-capture');
    //if (!elementToCapture) alert('Qualcosa è andato storto. Riprova.');

    const canvas = await html2canvas(elementToCapture, {
      allowTaint: true,
      useCORS: true,
    });

    canvas.toBlob(async (blob) => {
      const files = [new File([blob], 'image.png', { type: blob.type })];
      setBASE_IMG(files[0]);
    });
  };

  const handleShare = async () => {
    try {
      /*       const elementToCapture = document.querySelector('#soldout-capture');

      const canvas = await html2canvas(elementToCapture, {
        scale: 0.1,
        allowTaint: true,
        useCORS: true,
      });

      canvas.toBlob(async (blob) => {
        alert('blob. ' + blob);
        const files = [new File([blob], 'image.png', { type: blob.type })];
        setBASE_IMG(files[0]);
        const shareData = {
          title: 'Condividi!',
          text: 'Condividi le disponibiità sulla tua poltrona.',
          files,
        };

        alert(JSON.stringify(shareData));
      });
 */

      const shareData = {
        title: 'Condividi!',
        text: 'Condividi le disponibiità sulla tua poltrona.',
        files: [BASE_IMG],
      };
      await navigator.share(shareData);
      closeModal();
    } catch (err) {
      console.log("Errore durante la generazione dell'immagine:", err);
      setLoading(false);
    }
  };

  const rootElement = !soldOut
    ? document.querySelector('#disponibili')
    : document.getElementById('soldout');

  useEffect(() => {
    if (rootElement && !BASE_IMG) {
      generateShare();
    }
  }, [rootElement]);

  return (
    <>
      <div
        className={clsx(styles.container, {
          [styles.container_mbl]: isMobile,
          [styles.container_xmas]: xMas,
        })}
      >
        <section className={styles.body}>
          <div className={styles.card}>
            {!soldOut ? (
              <CardDisponibili date={day.date} worker={worker} timesFreeTask={timesFreeTask} />
            ) : (
              <CardSoldOut worker={worker} date={day.date} />
            )}
            <footer className={styles.foot}>
              <LogoIcon fill={xMas ? '#1D5E62' : 'white'} />
              <div style={{ display: 'flex' }}>
                <h3>Prenota con</h3>&nbsp;
                <h3 style={{ fontWeight: 600 }}>Zetabarber</h3>
              </div>
            </footer>
          </div>
        </section>
        <section className={styles.footer}>
          {/* <Button
            onClick={() => {
              closeModal();
              dispatch(schedulerActions.GET_CALENDAR_SLOTS.CLEAR());
              navigate(
                `${ROUTES.SALON_MANAGEMENT}${ROUTES.WORKERS}${ROUTES.WORKING_TIME}/${worker.id}`,
              );
            }}
            className={clsx(styles.action_btn, { [styles.disable]: true })}
          >
            Condividi <InstagramIcon className={styles.icon} />
          </Button> */}
        </section>
      </div>
    </>
  );
};

export default WorkerSoldOutModal;
