import React, {useEffect} from 'react';
import styles from "./RegistrationPageContainer.module.scss";
import {authActions} from "../../../../store";
import {useDispatch, useSelector} from "react-redux";
import StepWrapper from "../StepWrapper";
import {WelcomeStepContainer, WelcomeStepFirstPreview} from "../WelcomeStep";
import {
  SalonStartTimeStepContainer,
  SalonStartTimeStepFirstPreview,
  SalonStartTimeStepSecondPreview
} from "../salonWorkTimeSteps/SalonStartTimeStep";
import {getActiveStep} from "../../../../store/selectors";
import {SalonBreakTimeSelectorStepContainer} from "../salonWorkTimeSteps/SalonBreakTimeSelectorStep";
import {SalonBreakStartTimeStepContainer} from "../salonWorkTimeSteps/SalonBreakStartTimeStep";
import {SalonBreakEndTimeStepContainer} from "../salonWorkTimeSteps/SalonBreakEndTimeStep";
import {SalonEndTimeStepContainer} from "../salonWorkTimeSteps/SalonEndTimeStep";
import {SalonClosuresDaysSelectorStepContainer} from "../salonWorkTimeSteps/SalonClosuresDaysSelectorStep";
import {FirstInfoStepContainer, FirstInfoStepFirstPreview, SecondInfoStepPreview} from "../infoSteps/FirstInfoStep";
import {ServicesStepContainer} from "../ServicesStep";
import {ThirdInfoStepContainer} from "../infoSteps/ThirdInfoStep";
import {SalonWorkerSelectorStepContainer} from "../salonWorkTimeSteps/SalonWorkerSelectorStep";
import {FourthInfoStepContainer} from "../infoSteps/FourthInfoStep";
import {FinalStepContainer, FinalStepFirstPreview} from "../FinalStep";
import {WorkersStepContainer} from "../WorkersStep";

const steps = [
  {
    position: 1,
    id: 1,
    container: {
      component: <WelcomeStepContainer/>,
    },
    previews: [{
      component: <WelcomeStepFirstPreview/>,
      animationDuration: 2000
    }]
  },
  {
    position: 2,
    id: 2,
    container: {
      component: <SalonStartTimeStepContainer/>,
    },
    previews: [
      {
        component: <SalonStartTimeStepFirstPreview/>,
        animationDuration: 3000
      },
      {
        component: <SalonStartTimeStepSecondPreview/>,
        animationDuration: 6000
      },
    ]
  },
  {
    position: 3,
    id: 3,
    container: {
      component: <SalonBreakTimeSelectorStepContainer/>,
    },
    previews: []
  },
  {
    position: 4,
    id: 4,
    container: {
      component: <SalonBreakStartTimeStepContainer/>,
    },
    previews: []
  },
  {
    position: 5,
    id: 5,
    container: {
      component: <SalonBreakEndTimeStepContainer/>,
    },
    previews: []
  },
  {
    position: 6,
    id: 6,
    container: {
      component: <SalonEndTimeStepContainer/>,
    },
    previews: []
  },
  {
    position: 7,
    id: 7,
    container: {
      component: <SalonClosuresDaysSelectorStepContainer/>,
    },
    previews: []
  },
  {
    position: 8,
    id: 8,
    container: {
      component: <FirstInfoStepContainer/>,
    },
    previews: [
      {
        component: <FirstInfoStepFirstPreview/>,
        animationDuration: 3000
      },
      {
        component: <SecondInfoStepPreview/>,
        animationDuration: 5000
      },
    ]
  },
  {
    position: 10,
    id: 10,
    container: {
      component: <ServicesStepContainer/>,
    },
    previews: []
  },
  {
    position: 11,
    id: 11,
    container: {
      component: <ThirdInfoStepContainer/>,
    },
    previews: []
  },
  {
    position: 12,
    id: 12,
    container: {
      component: <SalonWorkerSelectorStepContainer/>,
    },
    previews: []
  },
  {
    position: 13,
    id: 13,
    container: {
      component: <WorkersStepContainer/>,
    },
    previews: []
  },
  {
    position: 14,
    id: 14,
    container: {
      component: <FourthInfoStepContainer/>,
    },
    previews: []
  },
  {
    position: 15,
    id: 15,
    container: {
      component: <FinalStepContainer/>,
    },
    previews: [{
      component: <FinalStepFirstPreview/>,
      animationDuration: 5000
    }]
  },
]

export default () => {
  const dispatch = useDispatch()
  const activeStep = useSelector(getActiveStep());

  useEffect(() => {
    dispatch(authActions.GET_ACTIVE_SIGN_UP_STEP.REQUEST())
    return () => {
      dispatch(authActions.GET_ACTIVE_SIGN_UP_STEP.CLEAR(true))
    };
  }, [dispatch]);


  const checkSlidePosition = (activeStep, currentStep) => {
    const activeStepPosition = steps.find(step => step.id === activeStep).position
    const currentStepPosition = steps.find(step => step.id === currentStep).position
    return {
      isActive: activeStepPosition === currentStepPosition,
      isPrevPosition: currentStepPosition < activeStepPosition,
      isNextPosition: activeStepPosition < currentStepPosition,
      isStartPage: currentStep === 1,
      isLastPage: currentStep === 15
    }
  }

  return (
    <div className={styles.container}>
      {
        activeStep && steps.map((step) => {
          return (
            <StepWrapper
              key={step.id}
              container={step.container.component}
              previews={step.previews}
              slidePosition={checkSlidePosition(activeStep, step.id)}
            />
          )
        })
      }
    </div>
  )
}