import { createTheme } from '@mui/material/styles';
import colorsStyle from 'styles/modules/colors.scss';
const colors = {
  black: '#2E3338',
  viola: '#8944AB',
  backViols: '#E4DAFF',
  darkBlue: '#0040DD',
  middleGray: '#DCDFE3',
  softGray: '#CFD4DF',
  textGray: '#AEAEB2',
  gray: 'rgba(46, 51, 56, 0.9)',
  green: '#248A3D',
  red: '#D70015',
  lightBlue: '#D9EBFF',
  blueHover: '#007AFF',
  blue: '#409CFF',
  white: '#FFFFFF',
  clearGray: '#F6F7F8',
  yellow: '#F5E23D',
  clearWhite: '#FAFAFA',
  darkGray: '#22262A',
};

const muiTheme = createTheme({
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          border: 'none',
          '& fieldset': {
            border: 'none',
          },
          '& input': {
            border: `1px solid ${colorsStyle.mediumGray}`,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& input': {
            backgroundColor: colorsStyle.mediumGray,
            lineHeight: '17px',
            fontSize: '14px',
            borderRadius: '10px',
            width: '100%',
            border: `1px solid ${colorsStyle.mediumGray}`,
            height: 'fit-content',
            padding: '12px 15px',
            '&:focus': {
              outline: 'none',
            },
            '&:required': {
              outline: `1px solid ${colorsStyle.red}`,
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontWeight: '700',
          lineHeight: '17px',
          marginBottom: '15px',
          paddingLeft: '10px',
        },
      },
    },

    MuiInputAdornment: {
      styleOverrides: {
        root: {
          background: `${colorsStyle.gradientLightBlue} !important`,
          maxHeight: '100%',
          aspectRatio: '1/1',
          marginLeft: '8px',
          borderRadius: '10px',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minWidth: '41px',
          '& svg': {
            height: '20px',
            width: '20px',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          textTransform: 'none',
          padding: '0px 0px',
          ':hover': {
            backgroundColor: 'none',
            boxShadow: 'none',
            filter: 'brightness(0.8)',
          },
        },
      },
    },
    MuiPickersPopper: {
      styleOverrides: {
        root: {
          filter: 'drop-shadow(7px 3px 2px #AEAEB2)',
          borderRadius: '10px',
          border: '1px solid #CFD4DF',
          overflow: 'hidden',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          backgroundColor: colors.white,
          '&.noFilters': {
            '& .MuiToolbar-root': {
              display: 'none',
            },
            '& .MuiTableHead-root tr': {
              '& th:first-of-type': {
                borderRadius: '8px 0 0 0',
              },
              '& th:last-child': {
                borderRadius: '0 8px 0 0',
              },
            },
          },
          '&.withFilters': {
            '& .MuiTableHead-root': {
              display: 'table-header-group',
            },
          },
        },
        elevation4: {
          boxShadow: 'none',
        },
        rounded: {
          borderRadius: '0',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          position: 'relative',
          wordBreak: 'break-word',
          '&.subTableBody': {
            backgroundColor: '#ECF0FE',
          },
          '&.centeredHeader': {
            '& span': {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
            '& div': {
              display: 'flex',
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
            },
          },
          '&.centeredColumn': {
            textAlign: 'center',
          },
          '&.boldHeader': {
            '& div': {
              fontWeight: '500',
            },
          },
        },
        footer: {
          display: 'flex',
          backgroundColor: colors.clearGray,
          justifyContent: 'flex-end',
          borderRadius: '0 0 8px 8px',
          borderBottom: 'none',
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          '& > div': {
            color: colors.white,
          },
          '&.subTableHeader': {
            backgroundColor: '#ECF0FE',
            paddingTop: '0 !important',
            paddingBottom: '0 !important',
            '& div': {
              color: '#1F4EAB !important',
            },
          },
          '&.subTableHeader:first-of-type': {
            borderRadius: '0 !important',
          },
          '&.subTableHeader:last-child': {
            borderRadius: '0 !important',
          },
        },
        data: {
          fontWeight: '500',
          color: colors.white,
        },
        fixedHeader: {
          backgroundColor: colors.black,
        },
        sortActive: {
          color: colors.white,
        },
        toolButton: {
          '&:hover': {
            backgroundColor: `${colors.clearGray}!important`,

            '& *': {
              color: colors.black,
            },
          },
        },
        sortAction: {
          display: 'flex',
          alignItems: 'center',

          '& svg path': {
            color: colors.white,
          },
        },
      },
    },
    MUIDataTableBodyRow: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#B0C2FF !important',
          },
        },
      },
    },
    MUIDataTableSelectCell: {
      styleOverrides: {
        expanded: {
          '& path': {
            color: `${colors.green}!important`,
          },
        },
        headerCell: {
          backgroundColor: colors.black,
          '& svg path': {
            color: colors.white,
          },
        },
      },
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: colors.black,
          borderRadius: '8px 8px 0 0',
        },
        filterPaper: {
          maxWidth: '20%',
          '& > div': {
            paddingTop: '48px',
          },
        },
        actions: {
          '& svg path': {
            color: colors.white,
          },
        },
      },
    },
    MUIDataTableSearch: {
      styleOverrides: {
        main: {
          display: 'flex',
          alignItems: 'center',
          color: colors.white,
          '& *': {
            color: `${colors.white}!important`,
          },
        },
        searchIcon: {
          marginTop: '0',
        },
        clearIcon: {
          '& *': {
            color: colors.white,
          },
        },
      },
    },
    MUIDataTableToolbarSelect: {
      styleOverrides: {
        root: {
          backgroundColor: colors.black,

          '& *': {
            color: colors.white,
          },
        },
      },
    },
    MUIDataTableFilterList: {
      styleOverrides: {
        root: {
          backgroundColor: colors.black,
          margin: '0',
          padding: '0 16px',
        },
        chip: {
          backgroundColor: colors.white,

          '& span': {
            color: colors.black,
          },
        },
      },
    },
    MUIDataTableFilter: {
      styleOverrides: {
        root: {
          backgroundColor: colors.clearGray,
          '& .MuiGrid-root': {
            maxWidth: 'none',
            padding: '0',
            marginTop: '10px',
          },
        },
      },
    },
  },
});

export default muiTheme;
