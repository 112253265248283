import { Navigate, useLocation } from 'react-router-dom';

import { ROUTES } from '../constants';
import { getMyAccountStaff } from 'Containers/Settings/store/selectors';
import { useSelector } from 'react-redux';
import { SUBSECTIONS } from 'Containers/Settings/containers/AccountsManager/constants';

export const PrivateRoute = ({ component: RouteComponent, isAuthenticated }) => {
  const location = useLocation();
  const myAccount = useSelector(getMyAccountStaff());

  if(localStorage.getItem('staff') && myAccount.permessi?.length){
    let allowed = myAccount.permessi.map((perm) => {
      let sub = SUBSECTIONS.find(sub => sub.id === perm.sezione);
      return sub?.link || '';
    });
    if(!allowed.includes(location.pathname)){
      return <Navigate to={allowed[0]} state={{ from: location }} />;
    }
  }

  if (!isAuthenticated) {
    return <Navigate to={ROUTES.SIGN_IN} state={{ from: location }} />;
  }

  return <RouteComponent/>;
};
