import { paymentTypes } from './actions';

const initialState = {
  payments: [],
  payment: undefined,
  total: 0,
  balance: 0,
  bonifici: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case paymentTypes.GET_PAYMENTS.SUCCESS:
      return {
        ...state,
        payments: action.payload.pagamenti,
        total: action.payload.total_rows,
        saldo: action.payload.total_payment,
        balanceRows: action.payload.total_rows_euro,
        balance: action.payload.total_euro,
      };
    case paymentTypes.GET_PAYMENT.SUCCESS:
      return {
        ...state,
        payment: action.payload.pagamenti,
      };
    case paymentTypes.GET_PAYMENT.CLEAR:
      return {
        ...state,
        payment: undefined,
      };
    case paymentTypes.GET_BONIFICI.SUCCESS:
      return {
        ...state,
        bonifici: action.payload.bonifici,
      };
    default:
      return { ...state };
  }
  
};
