import { combineReducers } from 'redux';

import { authReducer } from '../Containers/Auth/store';
import { errorReducer } from '../shared/Error/store/reducer';
import { LoaderReducer } from '../shared/Loader/store/reducer';
import { schedulerReducer } from '../Containers/Scheduler/store';
import { clientReducer } from '../Containers/Clients/store';
import { paymentReducer } from '../Containers/Payments/store';

import { modalsReducer } from '../Containers/Modals/store';
import { toastsReducer } from '../Containers/Toasts/store';
import { salonManagementReducer } from '../Containers/SalonManagement/store';
import { homeReducer } from '../Containers/Home/store';
import { shopReducer } from '../Containers/Shop/store';
import { communicationsReducer } from '../Containers/Communications/store';
import { notificationReducer } from '../Containers/Notifications/store';
import { statisticReducer } from '../Containers/Statistic/store';
import { settingsReducer } from '../Containers/Settings/store';

export default () =>
  combineReducers({
    loader: LoaderReducer,
    errorReducer,
    paymentReducer,
    modalsReducer,
    toastsReducer,
    authReducer,
    schedulerReducer,
    clientReducer,
    salonManagementReducer,
    homeReducer,
    shopReducer,
    communicationsReducer,
    notificationReducer,
    statisticReducer,
    settingsReducer,
  });
