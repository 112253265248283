import { createSelector } from 'reselect';
import { getIconUrl, imageEntities } from '../../../utils/ImagesCreator';

const selectAuthState = (state) => state.authReducer;

export const getAuth = () => createSelector(selectAuthState, (state) => state.isLogin);

export const getActiveStep = () =>
  createSelector(selectAuthState, (state) => state.signUpValues.activeStepId);

export const salonWorkTimes = () =>
  createSelector(selectAuthState, (state) => state.signUpValues.salonWorkTimes);

export const getSalons = () =>
  createSelector(selectAuthState, (state) =>
    state.salons.map((salon) => ({
      name: salon.nome_salone,
      value: salon.idsalone,
      icon: getIconUrl(salon.idsalone, imageEntities.salon, 'profilo'),
    })),
  );
export const getActiveSalonId = () =>
  createSelector(selectAuthState, (state) => state.activeSalonId);
export const getActiveSalon = () =>
  createSelector(selectAuthState, (state) => {
    const activeSalon = state.salons.find((salon) => +salon.idsalone === +state.activeSalonId);
    return {
      ...activeSalon,
      name: activeSalon?.nome_salone,
      value: activeSalon?.idsalone,
      icon: getIconUrl(activeSalon?.idsalone, imageEntities.salon, 'profilo'),
      coverImg: getIconUrl(activeSalon?.idsalone, imageEntities.salon, 'copertina'),
      email: activeSalon?.email,
      numberOfBookableDays: activeSalon?.n_giorni_prenotabili,
      numberOfAppointments: activeSalon?.nMaxPrenotazioni,
      phoneNumber: activeSalon?.tel,
      showPrice: activeSalon?.showPrice,
      pwdPrenotazione: activeSalon?.pwdPrenotazione,
      isOldStyle: activeSalon?.isOldStyle,
    };
  });

  export const getInvitiOnboarding = () =>
  createSelector(selectAuthState, (state) => {
    const activeSalon = state.salons.find((salon) => +salon.idsalone === +state.activeSalonId);
    return activeSalon?.inviti_onboarding ?? 0;
  });
