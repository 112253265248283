import { schedulerTypes } from './actions';
import dayjs from 'dayjs';

const initialState = {
  calendar: {
    workers: [],
    appointments: {},
    services: [],
    queue: [],
    availableSlots: [],
    detaglioPrenotazioneRicorrente: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case schedulerTypes.GET_CALENDAR_DATA.SUCCESS:
      return {
        ...state,
        calendar: {
          workers: action.payload.mypoltrone?.sort((a, b) => a.ordinamento - b.ordinamento),
          appointments: action.payload.prenotazioni,
          services: action.payload.servizi,
          queue: state.calendar.queue,
        },
      };
    case schedulerTypes.GET_DETAILS_PRENOTAZIONE_RICORRENTE.SUCCESS:
      return {
        ...state,
        detaglioPrenotazioneRicorrente: action.payload || {},
      };
    case schedulerTypes.GET_CALENDAR_QUEUE.SUCCESS:
      return {
        ...state,
        calendar: {
          ...state.calendar,
          queue: action.payload,
        },
      };
    case schedulerTypes.GET_CALENDAR_SLOTS.SUCCESS:
      return {
        ...state,
        availableSlots: action.payload || [],
      };
    case schedulerTypes.GET_CALENDAR_SLOTS.CLEAR:
      return {
        ...state,
        availableSlots: [],
      };
    case schedulerTypes.EDIT_CALENDAR_TASK.SUCCESS: {
      const taskDay = action.payload.data_prenotazione.slice(0, 10);
      if (!state.calendar.appointments[taskDay]) {
        state.calendar.appointments[taskDay] = [];
      }
      return {
        ...state,
        calendar: {
          workers: state.calendar.workers,
          appointments: Object.fromEntries(
            Object.entries(state.calendar.appointments).map(([key, bookings]) => {
              const filteredDayTasks = bookings.filter(
                (item) => item.idprenotazione !== action.payload.idprenotazione,
              );
              if (key === taskDay) {
                action.payload.idpoltrona = action.payload.poltrona;
                return [key, [...filteredDayTasks, action.payload]];
              }
              return [key, filteredDayTasks];
            }),
          ),
          services: state.calendar.services,
          queue: state.calendar.queue,
        },
      };
    }

    case schedulerTypes.ADD_CALENDAR_TASK.SUCCESS: {
      const appointments = state.calendar.appointments;
      action.payload.forEach((task) => {
        const taskDay = task.data_prenotazione.slice(0, 10);
        task.idpoltrona = task.poltrona;
        appointments[taskDay]
          ? (appointments[taskDay] = [...appointments[taskDay], task])
          : (appointments[taskDay] = [task]);
      });
      return {
        ...state,
        calendar: {
          workers: state.calendar.workers,
          appointments,
          services: state.calendar.services,
          queue: state.calendar.queue,
        },
      };
    }

    case schedulerTypes.ADD_CALENDAR_BREAK.SUCCESS: {
      const appointments = state.calendar.appointments;
      let { payload } = action;
      payload = {
        ...payload,
        idprenotazione: Math.floor(Math.random() * 1000000) + 1,
        self_prenotatione: 1,
        data_prenotazione: dayjs(payload.dataPrenotazione).toDate().toISOString(),
        stato: '0',
        orario_start: payload.oraInizio,
        orario_end: payload.oraFine,
        isBreak: 1,
        nota: 'PAUSA',
        ordinamento: 0,
        createAt: new Date().toISOString(),
        servizi: [],
        status: 0,
        tipo: 1,
        visible: 1,
        disabled: 0,
        iconPoltrona: 1,
        isFromGoogle: 0,
        onlyApp: 0,
        disabilitato: 0,
        nome: 'PAUSA',
      };
      payload.poltrone.forEach((worker) => {
        const taskDay = dayjs(action.payload.data_prenotazione).format('YYYY-MM-DD');
        payload['poltrona'] = worker.idpoltrona;
        payload['idpoltrona'] = worker.idpoltrona;

        if (appointments[taskDay]) appointments[taskDay] = [...appointments[taskDay], payload];
        else appointments[taskDay] = [payload];
      });
      return {
        ...state,
        calendar: {
          ...state.calendar,
          appointments,
        },
      };
    }

    case schedulerTypes.SET_CALENDAR_TASK.SUCCESS: {
      action.payload.forEach((task) => {
        const taskDay = dayjs(task.data_prenotazione).format('YYYY-MM-DD');
        if (!state.calendar.appointments[taskDay]) {
          state.calendar.appointments[taskDay] = [];
        }
      });

      return {
        ...state,
        calendar: {
          workers: state.calendar.workers,
          appointments: Object.fromEntries(
            Object.entries(state.calendar.appointments).map((item) => {
              const filteredTasks = item[1].filter(
                (item) =>
                  !action.payload.some((task) => item.idprenotazione === task.idprenotazione),
              );
              const tasksToAdd = action.payload.filter(
                (task) =>
                  item[0] === dayjs(task.data_prenotazione).format('YYYY-MM-DD') && !task.disabled,
              );
              return [item[0], [...filteredTasks, ...tasksToAdd]];
            }),
          ),
          services: state.calendar.services,
          queue: state.calendar.queue,
        },
      };
    }

    case schedulerTypes.DELETE_CALENDAR_TASK.SUCCESS: {
      const { all: deleteAll, id: idPreno, ricorrente: idRecurrence } = action.payload;
      return {
        ...state,
        calendar: {
          workers: state.calendar.workers,
          appointments: Object.fromEntries(
            Object.entries(state.calendar.appointments).map((item) => {
              const filteredDayTasks = item[1].filter(
                (item) =>
                  item.idprenotazione !== idPreno &&
                  (!deleteAll || item.ricorrente != idRecurrence),
              );
              return [item[0], filteredDayTasks];
            }),
          ),
          services: state.calendar.services,
          queue: state.calendar.queue,
        },
      };
    }

    case schedulerTypes.SET_CALENDAR_WORKER_EXTRA_WORKING_TIME.SUCCESS: {
      const {
        activeWorkersIds,
        dataInizio,
        oraInizioMattina,
        oraFineMattina,
        oraInizioPomeriggio,
        oraFinePomeriggio,
      } = action.payload;
      return {
        ...state,
        calendar: {
          workers: state.calendar.workers.map((worker) => {
            if (activeWorkersIds.includes(worker.idpoltrona)) {
              const isClose =
                oraInizioMattina === '00:00:00' &&
                oraFineMattina === '00:00:00' &&
                oraInizioPomeriggio === '00:00:00' &&
                oraFinePomeriggio === '00:00:00';
              if (isClose) {
                worker.chiusure[dataInizio] = [
                  {
                    dalle: '00:00:00',
                    alle: '00:00:00',
                  },
                ];
                return worker;
              }

              const isFullDay = oraFineMattina === '00:00:00' && oraInizioPomeriggio === '00:00:00';
              if (isFullDay) {
                worker.chiusure[dataInizio] = [
                  {
                    dalle: '00:00:00',
                    alle: oraInizioMattina,
                  },
                  {
                    dalle: oraFinePomeriggio,
                    alle: '00:00:00',
                  },
                ];
                return worker;
              }
              worker.chiusure[dataInizio] = [
                {
                  dalle: '00:00:00',
                  alle: oraInizioMattina,
                },
                {
                  dalle: oraFineMattina,
                  alle: oraInizioPomeriggio,
                },
                {
                  dalle: oraFinePomeriggio,
                  alle: '00:00:00',
                },
              ];
              return worker;
            }
            return worker;
          }),
          appointments: state.calendar.appointments,
          services: state.calendar.services,
          queue: state.calendar.queue,
        },
      };
    }

    case schedulerTypes.SET_CALENDAR_WORKER_CLOSURES.SUCCESS: {
      const { activeWorkersIds, dataChiusura } = action.payload;
      return {
        ...state,
        calendar: {
          workers: state.calendar.workers.map((worker) => {
            if (activeWorkersIds.includes(worker.idpoltrona)) {
              worker.chiusure[dataChiusura] = [
                {
                  dalle: '00:00:00',
                  alle: '00:00:00',
                },
              ];
              return worker;
            }
            return worker;
          }),
          appointments: state.calendar.appointments,
          services: state.calendar.services,
          queue: state.calendar.queue,
        },
      };
    }

    default:
      return { ...state };
  }
};
