import { all } from 'redux-saga/effects';

import { authSaga } from '../Containers/Auth/store/';
import { schedulerSaga } from '../Containers/Scheduler/store/';
import { clientSaga } from '../Containers/Clients/store/';
import { salonManagementSaga } from '../Containers/SalonManagement/store/';
import { homeSaga } from '../Containers/Home/store/';
import { shopSaga } from '../Containers/Shop/store';
import { communicationsSaga } from '../Containers/Communications/store';
import { notificationSaga } from '../Containers/Notifications/store';
import { statisticSaga } from '../Containers/Statistic/store';
import { settingsSaga } from '../Containers/Settings/store';
import { paymentSaga } from 'Containers/Payments/store';

export default function* rootSaga() {
  yield all([
    authSaga(),
    schedulerSaga(),
    clientSaga(),
    paymentSaga(),
    salonManagementSaga(),
    homeSaga(),
    shopSaga(),
    communicationsSaga(),
    notificationSaga(),
    statisticSaga(),
    settingsSaga(),
  ]);
}
