import axios from 'axios';
import config from '../config';
import { getActiveSalonToken } from '../../utils/salonToken';

// axios.defaults.baseURL = 'https://zb-backend-v2-test.herokuapp.com/';
// axios.defaults.baseURL = 'https://zetabarber-test-calendar.herokuapp.com/';
// axios.defaults.baseURL = 'http://0.0.0.0:5001';

const axiosInstance = axios.create();

axiosInstance.defaults.baseURL = process.env.REACT_APP_PAYMENT_BE_ENV;

export const setAuthHeaders = () =>
  (axiosInstance.defaults.headers.common = { Authorization: getActiveSalonToken() });

export const removeAuthHeader = () => delete axiosInstance.defaults.headers.common['Authorization'];

export class PaymentsApi {
  static getInstance() {
    return new PaymentsApi();
  }

  get(url, options) {
    setAuthHeaders();
    return axiosInstance.get(`${url}`, options);
  }

  put(url, data) {
    setAuthHeaders();
    return axiosInstance.put(`${url}`, data);
  }

  post(url, data) {
    setAuthHeaders();
    return axiosInstance.post(`${url}`, data);
  }

  patch(url, data) {
    setAuthHeaders();
    return axiosInstance.patch(`${url}`, data);
  }

  delete(url) {
    setAuthHeaders();
    return axiosInstance.delete(`${url}`);
  }
}
