import { createSelector } from 'reselect';
import dayjs from "dayjs";

const selectHomeState = (state) => state.homeReducer;
export const getHomeStatistic = () => createSelector(selectHomeState, (state) => state.home_statistic);
export const getOnboardingData = () => createSelector(selectHomeState, (state) => state.onboarding);
export const getQueue = (date) => createSelector(selectHomeState, (state) =>{
  return state.queue
    .find(item => dayjs(item.date, "DD/MM/YYYY").isSame(date, 'day'))?.list?.map(({nome, cognome, tempo, cellulare, idUtente, idPoltrona}) => ({
      clientName: `${nome} ${cognome}`,
      description: `${tempo} minuti`,
      phoneNumber: cellulare,
      id: idUtente,
      workerId: idPoltrona,
      key: `${idUtente}${idPoltrona}`
    })) || []
});

export const getBestUsers = () => createSelector(
  selectHomeState,
  (state) => state.home_statistic?.bestUser?.map(({nome, cognome, totPrenotazioni, cellulare, email, idutente}) => {
    return {
      clientName: `${nome} ${cognome}`,
      description: `${totPrenotazioni} prenotazioni`,
      phoneNumber: cellulare,
      email: email,
      id: idutente,
      key: idutente
    }
  })
  );


// cellulare
//   :
//   null
// cognome
//   :
//   "Giugliano"
// dataListaAttesa
//   :
//   "2023-04-20T00:00:00.000Z"
// disabilitato
//   :
//   0
// guidNotifica
//   :
//   null
// idListaAttesa
//   :
//   44
// idPoltrona
//   :
//   5
// idSalone
//   :
//   2
// idUtente
//   :
//   29
// nome
//   :
//   "Carmine"
// tempo
//   :
//   35