import { notificationTypes } from './actions';

const initialState = {
  notifications: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case notificationTypes.GET_NOTIFICATIONS.SUCCESS:
      return {
        ...state,
        notifications: action.payload
      };
    default:
      return { ...state };
  }
};
