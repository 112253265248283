import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useWindowSize from '../../../../utils/useWindowSize';
import styles from './RefundModal.module.scss';
import ConfirmSecondaryModal from '../../../Common/ConfirmSecondaryModal';
import { Button } from '@mui/material';
import * as yup from 'yup';
import { Field, Form, Formik } from 'formik';
import clsx from 'clsx';
import TextField from 'Containers/Common/TextField';
import { paymentActions } from 'Containers/Payments/store';
import dayjs from 'dayjs';

const RefundModal = ({ refundInformation = {}, closeModal }) => {
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const [refundData, setRefundData] = useState(undefined);

  const initialValues = {
    description: '',
  };
  const validationSchema = {
    description: yup.string().optional(),
  };
  const refund = () => {
    dispatch(
      paymentActions.REQUEST_REFUND.REQUEST(
        {
          idTransazione: refundInformation.idTransazione,
          importo: refundInformation.importo,
        },
        closeModal,
      ),
    );
  };
  return (
    <>
      <ConfirmSecondaryModal
        isModalOpen={!!refundData}
        setIsModalOpen={() => setRefundData}
        title={'Rimborso'}
        subtitle={`Sei socio di ${refundInformation?.clientName} e hai effettuato un pagamento di ${
          refundInformation?.importo
        }€ il 
        ${dayjs(refundInformation?.created_at).format('DD/MM/YYYY')}.`}
        buttons={['Si', 'No']}
        buttonsActions={[
          refund,
          () => {
            setRefundData(undefined);
            closeModal();
          },
        ]}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={yup.object().shape(validationSchema)}
        onSubmit={(values) => {
          setRefundData(values);
        }}
      >
        {({ values, setFieldValue, errors, touched, setFieldTouched }) => (
          <Form className={styles.form}>
            <div className={styles.body}>
              <h4>Indica {"l'importo"} da rimborsare</h4>
              <p>Su {'"Tipo Carta"'} che termina con **** 0573</p>
              <Field
                name='description'
                id='description'
                placeholder='Inserisci nota opzionale'
                component={TextField}
                type='text'
                height={'50px'}
                width={'100%'}
                margin={'20px 0'}
              />
              <p>{'351,99'} disponibili per il rimborso</p>
            </div>
            <hr />
            <div className={styles.footer}>
              <Button type={'submit'} className={styles.button}>
                Rimborsa
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default RefundModal;
