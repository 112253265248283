import React, {useEffect} from 'react';
import styles from "./StepWrapper.module.scss";
import clx from "classnames";
import {ROUTES} from "../../../../../../routes/constants";
import {useNavigate} from "react-router-dom";

export default (
  {
    container,
    previews = [],
    slidePosition = {}
  }
) => {
  const navigate = useNavigate();
  const timeForOpenAndCloseAnimation = 500
  const {previewsWithDelay, currentDelay} = previews.reduce((acc, preview, index) => {

      const slideAnimation = !index && !slidePosition.isStartPage

      const viewDelay = slideAnimation ?
        preview.animationDuration + (timeForOpenAndCloseAnimation) + acc.currentDelay :
        preview.animationDuration + (timeForOpenAndCloseAnimation * 2) + acc.currentDelay

      return {
        previewsWithDelay: [
          ...acc.previewsWithDelay,
          <div
            key={index}
            className={clx(styles.step_preview, {[styles.step_preview_slide]: slideAnimation})}
            style={{
              animationDuration: slideAnimation ?
                `${preview.animationDuration}ms, ${timeForOpenAndCloseAnimation}ms` :
                `${timeForOpenAndCloseAnimation}ms, ${preview.animationDuration}ms, ${timeForOpenAndCloseAnimation}ms`,
              animationDelay: slideAnimation ?
                `${acc.currentDelay}ms,
                 ${timeForOpenAndCloseAnimation + acc.currentDelay + preview.animationDuration}ms`:
                `${timeForOpenAndCloseAnimation + acc.currentDelay}ms,
                 ${timeForOpenAndCloseAnimation + acc.currentDelay + preview.animationDuration}ms,
                 ${(timeForOpenAndCloseAnimation * 2) + acc.currentDelay + preview.animationDuration}ms`,
            }}
          >
            {preview.component}
          </div>
        ],
        currentDelay: viewDelay
      }
    }, {
      previewsWithDelay: [],
      currentDelay: 0
    }
  )

  useEffect(() => {
    if(slidePosition.isLastPage && slidePosition.isActive) {
      setTimeout(() => navigate(`${ROUTES.SIGN_IN}`) ,9000)
    }
  }, [slidePosition.isLastPage, slidePosition.isActive])

  return (
    <div className={clx(styles.step_wrapper, {
      [styles.step_active]: slidePosition.isActive,
      [styles.step_next]: slidePosition.isNextPosition,
      [styles.step_prev]: slidePosition.isPrevPosition,
    })}>
      {previewsWithDelay}
      <div
        className={clx(styles.step_container, {
          [styles.step_container_slide]: !previews.length && !slidePosition.isStartPage
        })}
        style={{
          animationDelay: `${currentDelay + timeForOpenAndCloseAnimation}ms`,
          animationDuration: `${timeForOpenAndCloseAnimation}ms`
        }}
      >
        {container}
      </div>
    </div>
  )
}
