import styles from './WorkerRetailModal.module.scss';
import { ROUTES } from '../../../../../routes/constants';
import AvatarIcon from '../../../../Common/AvatarIcon';
import { ReactComponent as CloseIcon } from '../../../../../assets/svg/cross.svg';
import { ReactComponent as InstagramIcon } from '../../../../../assets/svg/badgets/instagram.svg';
import LinearProgress from 'Containers/Common/LinearProgress';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import useWindowSize from '../../../../../utils/useWindowSize';
import { modalActions } from '../../../../Modals/store';
import { useDispatch } from 'react-redux';

const WorkerRetailModal = ({
  closeModal,
  worker,
  modalData,
  tasks,
  haveCloseIcon = false,
  sOModalData,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();

  return (
    <>
      {isMobile && (
        <div className={styles.worker_selector}>
          <div className={styles.body}>
            <div
              style={{
                display: 'flex',
                direction: 'row',
                gap: '10px',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  direction: 'row',
                  gap: '15px',
                  alignItems: 'center',
                  marginBottom: '5px',
                }}
              >
                {worker.icon ? (
                  <div>
                    <AvatarIcon icon={worker.icon} name={worker.workerName} size={'50px'} />
                  </div>
                ) : null}
                <div style={{ fontWeight: '700', fontSize: '17px' }}>{worker.workerName}</div>
              </div>
              {haveCloseIcon && (
                <div onClick={() => {}} className={styles.close_btn}>
                  <CloseIcon className={styles.icon} />
                </div>
              )}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <div style={{ display: 'flex', gap: '5px', justifyContent: 'flex-start' }}>
                <span className={styles.text} style={{ fontWeight: '700' }}>
                  {tasks.length ?? 78}
                </span>
                <span className={styles.text}>
                  {tasks.length != 1 ? 'Prenotazioni' : 'Prenotazione'}
                </span>
              </div>
              <div className={styles.linear_progress_box}>
                <LinearProgress percent={modalData.percent} color={'#858AF9'} />
                <div
                  className={styles.linear_progress_box_text}
                  style={{ color: modalData.percent < 100 ? 'black' : '#858AF9' }}
                >
                  {modalData.label}
                </div>
              </div>
            </div>
            {!modalData.closed ? (
              <div
                className={styles.link}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  cursor: 'pointer',
                  gap: '5px',
                  alignItems: 'center',
                }}
                onClick={() => {
                  dispatch(
                    modalActions.OPEN_MODAL.SUCCESS({
                      type: 'workerSoldOutModal',
                      props: { modalData: sOModalData },
                    }),
                  );
                }}
              >
                <InstagramIcon className={styles.icon} />
                <span className={styles.ltext}>{'Condividi su Instagram >'}</span>
              </div>
            ) : null}
          </div>
          {(modalData.salon_management ==1 || !localStorage.getItem('staff')) && <div style={{ width: '100%' }}>
            <hr />
            <div className={styles.footer}>
              <Button
                onClick={() => {
                  closeModal();
                   navigate(
                    `${ROUTES.SALON_MANAGEMENT}${ROUTES.WORKERS}${ROUTES.WORKING_TIME}/${worker.id}`,
                  )
                }}
                className={styles.menu_btn}
              >
                Gestione poltrona
              </Button>
            </div>
          </div>}
        </div>
      )}
    </>
  );
};

export default WorkerRetailModal;
