import TextField from 'Containers/Common/TextField';
import styles from './index.module.scss';
import { Formik, Form, Field } from 'formik';
import { Button } from '@mui/material';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { authActions } from 'Containers/Auth/store';
import clsx from 'clsx';
import useWindowSize from 'utils/useWindowSize';
import { ReactComponent as LockIcon } from '../../../../assets/svg/icons/lock.svg';
import { ReactComponent as MailIcon } from '../../../../assets/svg/icons/mail.svg';
import LogoImage from '../../../../assets/images/logo.png';
import { useEffect, useState } from 'react';
import { ROUTES } from 'routes/constants';
import { useNavigate, useSearchParams } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { toastActions } from 'Containers/Toasts/store';

const RecoveryPwdPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isMobile } = useWindowSize();
    const [email, setEmail] = useState('');
    const [ resend, setResend ] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const initialValues = {
        email: email,
    }
    
    const validationSchema = {
        email: yup
            .string('Inserisci la tua email')
            .email('Inserisci una email valida')
            .required('Email è richiesta'),
    };

    const handleClick = (value) => {
        dispatch(authActions.SEND_EMAIL_RECOVERY.REQUEST({email: value}, ()=>setEmail(value)));
    }

    const handleResend = () => {
        dispatch(authActions.SEND_EMAIL_RECOVERY.REQUEST({email}, ()=>setResend(true)));
    }

    useEffect(() => {
        const token = searchParams.get('token');
        const nomeSalone = searchParams.get('name');
        if (token) {
            localStorage.setItem('tokenRecovery', token);
            localStorage.setItem('name', nomeSalone);
            const expToken = jwtDecode(token);
            if(expToken.exp > Date.now() / 1000) {
                navigate(`${ROUTES.RECOVERY}/password`);
            }
            else {
                if (searchParams.has('token')) searchParams.delete('token');
                if(searchParams.has('name')) searchParams.delete('name');
                setSearchParams(searchParams);
                
                localStorage.removeItem('tokenRecovery');
                localStorage.removeItem('name');
                dispatch(toastActions.OPEN_TOAST.SUCCESS({
                    type: 'api',
                    props: { title: 'Link è scaduto!', message: 'Invia una nuova richiesta.', warning: true },
                }));
            }
        }
    }, []);


    return (
        <div className={clsx(styles.container, {[styles.container_mbl]: isMobile})}>
            {!email 
                ? (<>
                    <div className={styles.container_icon}>
                        <LockIcon className={styles.icon} />
                    </div>
                    <h1>Hai dimenticato la Password?</h1>
                    <span className={styles.description}>{"Inserisci l'email del tuo account Zetabarber per resettare la password."}</span>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={yup.object().shape(validationSchema)}
                        enableReinitialize={true}
                        onSubmit={(values) => {
                            handleClick(values.email);
                        }}
                    >
                        {({ values, setFieldValue }) => {
                            return (
                                <Form className={styles.form}>
                                    <Field
                                        name='email'
                                        id='email'
                                        placeholder='Inserisci email'
                                        component={TextField}
                                        type='email'
                                        inputColor='white' 
                                        showIcon={true}
                                    />
                                    <Button
                                        className={styles.confirm_button}
                                        variant="contained"
                                        type={'submit'}
                                    >
                                        Invia Email
                                    </Button>
                                </Form>
                            )
                        }}
                    </Formik>
                </>)        
                : (<>
                    <div className={styles.container_icon}>
                        <MailIcon className={styles.icon} />
                    </div>
                    <h1>Email inviata</h1>
                    <span className={styles.description}>{"Controlla la casella email. Ti abbiamo inviato la procedura per effettuare il recupero a"}</span>
                    <span className={styles.description} style={{color: 'black'}}>{email}</span>
                    <p style={{fontSize: '12px'}} onClick={!resend ? handleResend : ()=>{}}>{"Se non l'hai ricevuta, controlla la cartella spam"}{!resend && <>{" o"} <span className={styles.highlight}>riprova</span></>}.</p>
                    <p style={{fontSize: '12px'}}>{"Se hai bisogno di assistenza, contattaci a"} <span className={styles.highlight}>+39 353 335 7748</span>.</p>
                </>)
            }
            <p className={resend ? styles.highlight : ''} style={{cursor: 'pointer'}} onClick={()=> navigate(ROUTES.SIGN_IN)}>{"<- Torna al login"}</p>
            <img src={LogoImage} alt='logo' className={styles.logo} />
        </div>
    );
}

export default RecoveryPwdPage;