import style from './Badgets.module.scss';

export const Badgets = ({
  icon,
  text,
  type = 'Small',
  iconSizeSmall = '24px',
  iconSize = '30px',
  hover = false,
}) => {
  const options = {
    Large: (
      <div className={style.badget}>
        <div
          className={style.badget_icon}
          style={{
            maxWidth: iconSize,
            maxHeight: iconSize,
            minWidth: iconSize,
            minHeight: iconSize,
          }}
        >
          {icon}
        </div>
        <div className={style.badget_text}>{text}</div>
      </div>
    ),
    Small: (
      <div className={style.badget_small}>
        <div
          className={style.badget_small_icon}
          style={{
            maxWidth: iconSizeSmall,
            maxHeight: iconSizeSmall,
            minWidth: iconSizeSmall,
            minHeight: iconSizeSmall,
          }}
        >
          {icon}
          {hover && <div className={style.info_card}>{text}</div>}
        </div>
      </div>
    ),
  };
  return options[type];
};
