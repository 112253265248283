import styles from './HoveredInfoIcon.module.scss';
import React from "react";
import { ReactComponent as InfoIcon } from '../../../assets/svg/icons/info_point_pieno.svg';
import clx from "classnames";

const HoveredInfoIcon = (
  {
    title,
    description,
    sidePosition = 'left'
  }
) => (
  <div className={styles.info_container}>
    <div className={styles.icon_value_container}>
      {title && <div className={styles.info_title}>{title}</div>}
      <div className={clx(styles.info_icon_container, {
        [styles.left_position]: sidePosition === 'left',
        [styles.right_position]: sidePosition === 'right',
        [styles.right_up_position]: sidePosition === 'up',
      })
      }>
        <InfoIcon className={styles.info_icon} />
        <div className={styles.info_card}>
          {description}
        </div>
      </div>
    </div>
  </div>
);

export default HoveredInfoIcon;
