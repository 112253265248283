import { ROUTES } from "routes/constants";

export const SECTIONS ={
    '1': 'Calendario',
    '2': 'Gestione Salone',
    '3': 'Clienti',
    '4': 'Pagamenti',
    '5': 'Statistiche',
    '6': 'Notifiche',
    '7': 'Comunicazioni',
    '8': 'Shop',
};

export const SUBSECTIONS = [
    {   id: 1,
        title: 'Calendario',
        link: ROUTES.SCHEDULER,
        options: [
            {name: 'Vedi Tutti', field: 'viewJustMe', value: 0}, 
            {name: 'Vista Singola', field: 'viewJustMe', value: 1}, 
            {name: 'Inserimento', field: 'allowInsert', value: 1}, 
            {name: 'Modifica', field: 'allowEdit', value: 1}
        ]
    },
    {
        id: 2,
        title: 'Gestione Salone',
        link: ROUTES.SALON_MANAGEMENT,
        options: [{name: 'Tutte le operazioni', field: 'allowAll', value: 1}]
    },
    {
        id: 3,
        title: 'Clienti',
        link: ROUTES.CLIENTS,
        options: [{name: 'Tutte le operazioni', field: 'allowAll', value: 1}]
    },
    {
        id: 4,
        title: 'Pagamenti',
        link: ROUTES.PAYMENTS,
        options: [{name: 'Tutte le operazioni', field: 'allowAll', value: 1}]
    },
    {
        id: 5,
        title: 'Statistiche',
        link: ROUTES.STATISTICS,
        options: [{name: 'Tutte le operazioni', field: 'allowAll', value: 1}]
    },
    {
        id: 6,
        title: 'Notifiche',
        link: ROUTES.NOTIFICATION,
        options: [{name: 'Tutte le operazioni', field: 'allowAll', value: 1}]
    },
    {
        id: 7,
        title: 'Comunicazioni',
        link: ROUTES.COMMUNICATIONS,
        options: [{name: 'Tutte le operazioni', field: 'allowAll', value: 1}]
    },
    {
        id: 8,
        title: 'Shop',
        link: ROUTES.SHOP,
        options: [{name: 'Tutte le operazioni', field: 'allowAll', value: 1}]
    },
    
];

export const getSubSections = (section) => {
    let subSections = [];

    if(section.allowAll==1) {
        subSections.push('Tutte le operazioni');
        return subSections;
    }

    if(section.viewJustMe==1) subSections.push('Vista Singola');
    else subSections.push('Vedi Tutti');

    if(section.allowInsert==1) subSections.push('Inserimento');

    if(section.allowEdit==1) subSections.push('Modifica');

    return subSections;
}
