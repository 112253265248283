import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useWindowSize from "utils/useWindowSize";
import firstTab from 'assets/images/payments_info/1.png';
import secondTab from 'assets/images/payments_info/2.png';
import thirdTab from 'assets/images/payments_info/3.png';
import fourthTab from 'assets/images/payments_info/4.png';
import fifthTab from 'assets/images/payments_info/5.png';
import styles from './stepRoles.module.scss';
import clsx from "clsx";
import { Button } from "@mui/material";
import TitleSectionBack from "Containers/Common/TitleSectionBack";
import MobileStepper from '@mui/material/MobileStepper';
import { ROUTES } from "routes/constants";
import { useNavigate } from "react-router-dom";
import Spinner from "Containers/Common/Spinner";
import { getActiveSalonePaymentInfo } from "utils/salonToken";


const StepRoles = () =>{
  const disabled_payments = getActiveSalonePaymentInfo();
  const { isMobile } = useWindowSize();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(1);
  const [goNext, setGoNext] = useState(false);

  const scrollRefs = useRef([]);
  
  const scrollToElement = (key) => {
    if (key > 0) {
      if (scrollRefs.current[key]) {
        scrollRefs.current[key].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
      }
    }
  };

    useEffect(()=>{
        scrollToElement(activeTab-1);
    },[]);

    const goToNextPage = ()=>{
        if(disabled_payments ===1 )
            navigate(`${ROUTES.PAYMENTS}${ROUTES.FORM}`)
        else
        navigate(`${ROUTES.PAYMENTS}`)
    }

    return (<>
    <div className={styles.page}>
        <div className={styles.header}>
        <TitleSectionBack />
        </div>
        <div className={styles.scroll_tab}>
                {/* step 1 */}
            <div ref={element => scrollRefs.current[0]=element} className={clsx(styles.container, { [styles.container_mobile]: isMobile })}>
            <div className={styles.text_section}>
                <h1 className={styles.h1}>Elimina le disdette.</h1>
                <p className={styles.p} >
                    Siamo entusiasti di condividere che i tuoi colleghi stanno già sperimentando questa innovazione.
                    <br/>Fino ad ora, solo {"l'1%"} dei clienti che ha pagato online ha successivamente disdetto la prenotazione.
                </p>
            </div>
            <img className={styles.img_background} src={firstTab} style={{width: '40%'}}/>
            </div>
                {/* step 2 */}
            <div ref={element=> scrollRefs.current[1]=element} className={clsx(styles.container, { [styles.container_mobile]: isMobile })}>
            <div className={styles.text_section}>
                <h1 className={styles.h1}>Nessun appuntamento andrà perso.</h1>
                <p className={styles.p}>
                Con il deposito anticipato ridurrai del 30% i mancati appuntamenti e scoraggerai le cancellazioni tardive.
                </p>
                <p className={styles.p}>
                Risultato?  Avrai sempre una pianificazione finanziaria stabile e precisa.
                </p>
            </div>
            <img className={styles.img_background} src={secondTab} style={{width: '40%'}}/>
            </div>
                {/* step 3 */}
            <div ref={element=>scrollRefs.current[2]=element} className={clsx(styles.container, { [styles.container_mobile]: isMobile })}>
            <div className={styles.text_section}>
                <h1 className={styles.h1}>Tutto nelle tue mani.</h1>
                <p className={styles.p} style={{marginLeft: '30px'}}>
                    <ul type="circle">
                        <li>
                            Gestisci le regole di prenotazione per i clienti già registrati.
                        </li>
                        <br></br>
                        <li>
                            Decidi se i nuovi clienti dovranno pagare obbligatoriamente in anticipo.
                        </li>
                        <br></br>
                        <li>
                            Imposta servizi o poltrone dove è obbligatorio pagare.
                        </li>
                    </ul>
                </p>
            </div>
            <img className={styles.img_background} src={thirdTab} style={{width: '45%'}}/>
            </div>
                {/* step 4 */}
            <div ref={element=>scrollRefs.current[3]=element} className={clsx(styles.container, { [styles.container_mobile]: isMobile })}>
            <div className={styles.text_section}>
                <h1 className={styles.h1}>Riscuoti i pagamenti quando vuoi!</h1>
                <p className={styles.p}>
                    Ricevi gli accrediti settimanali direttamente sul tuo conto corrente, rendendo la gestione finanziaria un gioco da ragazzi.
                    Controlla i pagamenti in entrata direttamente dal tuo gestionale.
                </p>
            </div>
            <img className={styles.img_background} src={fourthTab}/>
            </div>
            {/* step 5 */}
            <div ref={element=>scrollRefs.current[4]=element} className={clsx(styles.container, { [styles.container_mobile]: isMobile })}>
            <div className={styles.text_section}>
                <h1 className={styles.h1}>Nessun vincolo.</h1>
                <p className={styles.p}>
                Categorizzando il cliente non tutti saranno vincolati al pagamento in app, potranno decidere in qualsiasi momento se completare online o in salone.
                {/* , garantendoti una pianificazione finanziaria più sicura, */}
                </p>
            </div>
            <img className={styles.img_background} src={fifthTab} style={{width: '40%'}}/>
            </div>
        </div>    
        
        <div className={styles.buttons}>
            <MobileStepper
                variant="dots"
                steps={5}
                position="static"
                activeStep={activeTab-1}
                sx={{maxWidth:'400px', flexGrow: 1}}
                classes={{
                    root: styles.root, 
                    dot: styles.dot, 
                    dotActive: styles.dotActive,
                  }}
            />

            <Button
                className={styles.confirm_button}
                onClick={() => {
                    setGoNext(true);
                    if(activeTab < 5){
                        setActiveTab(activeTab+1);
                        scrollToElement(activeTab);
                    }
                    else {
                        goToNextPage();
                    }
                }}
                style={{fontSize:'14px'}}
                variant='contained'
            >
                Avanti
            </Button> 
            {goNext && <Button
                    className={styles.secondary_button}
                    onClick={() => {
                        goToNextPage();
                    }}
                    style={{fontSize:'14px'}}
                    variant='contained'
                >
                    Salta &gt;
                </Button> }  
            </div>
     
    </div>
    </>);
}

export default StepRoles;