import React, {useEffect, useState} from 'react';
import styles from "./SalonWorkTimeStepWrapper.module.scss";
import ForwardButton from "../../customComponents/ForwardButton";

export const SalonWorkTimeStepWrapper = (
  {
    children,
    action,
    actionDisabled,
    title
  }
) => {
  return (
    <div className={styles.salon_step_container}>
      <div className={styles.salon_step_content}>
        <div className={styles.step_description}>
          {title}
        </div>
        <div className={styles.step_children}>
          {children}
        </div>
        <ForwardButton
          disabled={actionDisabled}
          onAction={action}
        />
      </div>
    </div>
  )
}
