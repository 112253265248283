import styles from './Payments.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import TitleSectionBack from '../../../Common/TitleSectionBack';
import { ReactComponent as DownloadIcon } from 'assets/svg/icons/download.svg';
import { ReactComponent as ExternalLinkIcon } from 'assets/svg/icons/externalLink.svg';

import useWindowSize from '../../../../utils/useWindowSize';
import clx from 'classnames';
import { settingsActions } from '../../store';
import { getActiveSalon } from '../../../Auth/store/selectors';
import { Cards } from './containers/Cards';
import { Invoice } from './containers/Invoice';
import { PACKS } from './contansts/packs';
import { Pack } from './containers/Pack';
import { EXTRA_PACKS } from './contansts/extraPacks';
import dayjs from 'dayjs';
import {
  getCustomerBill,
  getCustomerInvoices,
  getCustomerPlan,
  getCustomerPortal,
  getCustomerProducts,
} from 'Containers/Settings/store/selectors';
import axios from 'axios';
import store from 'store/store';
import { Button } from '@mui/material';
import Table from 'Containers/Common/CustomTable';
import { paymentActions } from 'Containers/Payments/store';

const Payments = () => {
  const dispatch = useDispatch();
  const products = useSelector(getCustomerProducts());
  const plan = useSelector(getCustomerPlan());
  const invoices = useSelector(getCustomerInvoices());
  const user = useSelector(getActiveSalon());
  const billingPortal = useSelector(getCustomerPortal());
  const billInformation = useSelector(getCustomerBill());
  const fromApp = localStorage.getItem('fromApp') == 'true';
  const isBillingPortal = products.giorniRimanenti <= 0 && !products.isSubscribed;

  const { isMobile } = useWindowSize();
  const [billSelected, setBillSelected] = useState(isBillingPortal ? null : 0);
  const [extraSelected, setExtraSelected] = useState([]);
  const columns = useMemo(
    () =>
      !isMobile
        ? [
          {
            Header: 'Data Fattura',
            accessor: 'date',
            Cell: ({ value }) => dateFormat(value),
          },
          {
            Header: 'Tipologia',
            accessor: 'lines',
            Cell: ({ value }) => value.data[0]?.description.split('00:')[0],
          },
          {
            Header: 'ID',
            accessor: 'id',
          },
          {
            Header: 'Importo',
            accessor: 'total',
            Cell: ({ value }) => (value / 100).toFixed(2) + '€',
          },
          {
            Header: 'Data pagamento',
            accessor: 'paid',
            Cell: ({ row: { original: row } }) =>
              row.paid ? (
                dayjs(row.status_transitions.paid_at * 1000).format('DD/MM/YYYY')
              ) : (
                <div
                  className={clx({
                    [styles.payment_link]: !row.paid,
                    [styles.payment_link_disabled]: row.paid,
                  })}
                  onClick={() => {
                    if (!row.paid)
                      fromApp
                        ? window?.flutter_inappwebview?.callHandler('app:url', row.hosted_invoice_url)
                        : window.open(row.hosted_invoice_url, '_blank')
                  }}
                >
                  <ExternalLinkIcon className={styles.icon} height='15px' width='15px' />
                  <span className={styles.text}> Paga qui </span>
                </div>
              ),
          },
        ]
        : [
          {
            Header: '',
            accessor: 'paid',
            Cell: ({ row: { original: row }, value, ...rest }) => (
              <div className={styles.table}>
                <span className={styles.table_title}>Data Fattura</span>
                <p className={styles.table_row}>{dateFormat(row.date)}</p>
                <span className={styles.table_title}>Tipologia</span>
                <p className={styles.table_row}>
                  {row.lines?.data?.[0]?.description.split('00:')[0]}
                </p>
                <span className={styles.table_title}>ID</span>
                <p className={styles.table_row}>{row.id}</p>
                <span className={styles.table_title}>Importo</span>
                <p className={styles.table_row}>{(row.total / 100).toFixed(2) + '€'}</p>
                <span className={styles.table_title}>Data pagamento</span>
                {row.paid ? (
                  <p className={styles.table_row}>
                    {dayjs(row.status_transitions.paid_at * 1000).format('DD/MM/YYYY')}
                  </p>
                ) : (
                  <div
                    className={clx({
                      [styles.payment_link]: !row.paid,
                      [styles.payment_link_disabled]: row.paid,
                    })}
                    onClick={() => {
                      if (!row.paid)
                        fromApp
                          ? window?.flutter_inappwebview?.callHandler('app:url', row.hosted_invoice_url)
                          : window.open(row.hosted_invoice_url, '_blank')
                    }}
                  >
                    <ExternalLinkIcon className={styles.icon} height='15px' width='15px' />
                    <span className={styles.text}> Paga qui </span>
                  </div>
                )}
              </div>
            ),
          },
        ],
    [invoices, isMobile],
  );
  useEffect(() => {
    if (!user.idCostumerStripe) return;
    if (Object.values(products || {}).length === 0) return;

    dispatch(settingsActions.GET_CUSTOMER_BILL.REQUEST({ clientId: user.idCostumerStripe }));
    dispatch(settingsActions.GET_CUSTOMER_INVOICE.REQUEST({ clientId: user.idCostumerStripe }));
    const productIds = products.prezzi;
    dispatch(settingsActions.GET_CUSTOMER_PLAN.REQUEST({ productIds }));
  }, [products]);

  const handlePressPayment = async () => {
    let newWindow;
    if (!localStorage.getItem('fromApp'))
      newWindow = window.open('', '_blank');
    if (!billingPortal)
      return dispatch(
        settingsActions.CREATE_CUSTOMER_PORTAL.REQUEST(
          { clientId: user.idCostumerStripe },
          ({ data }) => {
            if (!fromApp) newWindow.location.href = data;
            window?.flutter_inappwebview?.callHandler('app:url', data);
          },
        ),
      );
    if (!fromApp) newWindow.location.href = billingPortal;
    window?.flutter_inappwebview?.callHandler('app:url', billingPortal);
  };

  const extraSelect = (id) => {
    if (extraSelected.includes(id)) {
      setExtraSelected(extraSelected.filter((item) => item !== id));
    } else {
      setExtraSelected([...extraSelected, id]);
    }
  };

  const cardsMock = [
    {
      lastFourDigits: billInformation?.sources?.data?.[0]?.last4 || 'xxxx',
      brand: 'visa',
    },
  ];

  const dateFormat = (timestamp) => dayjs(timestamp * 1000).format('DD/MM/YYYY');
  return (
    <div
      className={clx(styles.payment_container, {
        [styles.payment_container_mobile]: isMobile,
        [styles.payment_container_is_bill]: isBillingPortal,
      })}
    >
      {!isBillingPortal && (
        <TitleSectionBack
          title={'Fatturazione'}
          description={`Gestisci i tuoi abbonamenti e le tue carte. Qui puoi vedere i tuoi movimenti e scaricare le fatture.`}
        />
      )}
      <div className={styles.payment_container_body}>
      {(invoices?.invoices?.filter(({ paid }) => !paid)?.[0]?.hosted_invoice_url || !products.isSubscribed ) && <div className={styles.payment_container_body_warning}>
          <h4 className={styles.title}>
            {products.isSubscribed 
              ? (products.giorniRimanenti< 4 ? 'Abbonamento in scadenza' : (products.giorniRimanenti<1 ? 'Abbonamento Scaduto' : 'Rinnova adesso'))
              : 'Non hai ancora un abbonamento?'}
          </h4>
          <div className={styles.content}>
            <h4 className={styles.text}>
              Per visualizzare il tuo calendario ed accedere a tutte le funzionalità del
              gestionale{' '}
              {products.isSubscribed
                ? 'hai bisogno di completare il pagamento in attesa'
                : "scegli l'abbonamento giusto per te"}
              . Se hai già completato il saldo o hai problemi in fase di pagamento contatta{' '}
              {"l'assistenza."}
            </h4>
            <Button
              onClick={() => {
                let newWindow;
                if (!fromApp) newWindow = window.open('', '_blank');

                products.isSubscribed
                  ? (fromApp
                    ?
                    window?.flutter_inappwebview?.callHandler('app:url',
                      invoices?.invoices?.filter(({ paid }) => !paid)?.[0]?.hosted_invoice_url
                    )
                    :
                    newWindow.location.href = invoices?.invoices?.filter(({ paid }) => !paid)?.[0]?.hosted_invoice_url

                  )
                  : dispatch(
                    settingsActions.CREATE_PAYMENT_PORTAL.REQUEST(
                      {
                        stripeUserKey: user.idCostumerStripe,
                      },
                      (data) => {
                        if (!fromApp) newWindow.location.href = data;
                        window?.flutter_inappwebview?.callHandler('app:url', data);
                      },
                    ),
                  );
              }}
              className={styles.button_paid}
            >
              <h4>{products.isSubscribed ? 'Vai al pagamento' : "Scegli l'abbonamento"}</h4>
            </Button>
          </div>
        </div>}
        <div className={styles.payment_container_body_wrapper}>
          <h4>Le tue carte</h4>
          <Cards cards={cardsMock} handlePress={handlePressPayment} isMobile={isMobile} />
          <hr />
          <h4>Informazioni fatturazione</h4>
          <Invoice
            informations={billInformation}
            handlePress={handlePressPayment}
            isMobile={isMobile}
          />
        </div>
        <div className={styles.payment_container_body_wrapper}>
          {/* {!!isBillingPortal && (
            <>
              <h4>Pacchetto abbonamento</h4>
              <div className={styles.packs}>
                {PACKS.map((pack, index) => (
                  <Pack
                    onClick={() => setBillSelected(index)}
                    pack={pack}
                    isActive={index == billSelected}
                  />
                ))}
              </div>
              <hr />
            </>
          )} */}
          {/* {!isBillingPortal && (
            <>
              <h4>Aggiunte al pacchetto abbonamenti</h4>
              <div className={styles.packs}>
                {EXTRA_PACKS.map((pack, index) => (
                  <Pack
                    onClick={() => extraSelect(index)}
                    pack={pack}
                    isActive={extraSelected.includes(index)}
                  />
                ))}
              </div>
            </>
          )} */}

          <h4>Lista movimenti</h4>
          <Table columns={columns} data={invoices?.invoices || []} isMobile={isMobile} />
        </div>
      </div>
    </div >
  );
};

export default Payments;
