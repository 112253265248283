import { useEffect, useState } from 'react';

import UploadIcon from '../../../assets/svg/upload.svg';
import { fileReaderToBase64 } from '../../../helpers';
import { fileValidator } from '../../../utils/dragAndDrop';
import DragAndDropContainer from './DragAndDropContainer';
import styles from './UploadFileModal.module.scss';

const UploadFileModal = ({ setUploadFiles, isMultiple = true }) => {
  const [error, setError] = useState(false);
  const fileTypes = `application/pdf, text/xml, .doc, .docx, application/msword, 
	application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/*`;

  useEffect(() => {
    if (error) {
      setTimeout(() => setError(false), 3000);
    }
  }, [error]);

  return (
    <div className={styles['upload-file__wrapper']}>
      <DragAndDropContainer
        setUploadFiles={setUploadFiles}
        setError={setError}
        isMultiple={isMultiple}
      >
        <div className={styles['upload-file__btn']}>
          <label htmlFor="file" className={styles['btn__label']}>
            <img src={UploadIcon} alt="Carica" />
            <span>Carica</span>
            {error && <p className={styles.error}>{error}</p>}
          </label>
          <input
            className={styles['upload-file__input']}
            onChange={async (e) => {
              e.persist();
              const file = Object.values(e.target.files);

              if (file?.length) {
                const { errVal } = fileValidator(file, { fileSizeMBLimit: 60 });

                if (errVal) {
                  setError(errVal);
                  return;
                }

                const base64FileUrl = await fileReaderToBase64(file);

                isMultiple
                  ? setUploadFiles((prevState) => [
                    ...prevState,
                    {
                      name: file[0].name,
                      url: base64FileUrl
                    }
                  ])
                  : setUploadFiles({
                    name: file[0].name,
                    url: base64FileUrl
                  });
                e.target.value = '';
              }
            }}
            name="file"
            id="file"
            type="file"
            accept={fileTypes}
          />
        </div>
      </DragAndDropContainer>
      {/*<p className={styles.preview__text}>Max 60 MB</p>*/}
    </div>
  );
};

export default UploadFileModal;
