import ButtonSelector from 'Containers/Common/CustomSelector/ButtonSelector';
import clx from 'classnames';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useWindowSize from '../../../../utils/useWindowSize';
import FilterButtons from '../../../Common/FilterButtons';
import TitleSectionBack from '../../../Common/TitleSectionBack';
import { paymentActions } from '../../store';
import { getBonifici, getPayments, getTotal } from '../../store/selectors';
import BonificiTable from '../BonificiTable';
import PaymentTable from '../PaymentTable';
import styles from './PaymentList.module.scss';
import PageInfo from '../PageInfo';
import { getActiveSalon } from 'Containers/Auth/store/selectors';
import dayjs from 'dayjs';

const filtersButtons = [
  {
    name: 'Da ricevere',
    id: 0,
  },
  {
    name: 'Ricevuti',
    id: 2,
  },
];

const dataFilterOptions = [
  {
    name: 'Ultima settimana',
    value: 7,
  },
  {
    name: 'Ultimo mese',
    value: 30,
  },
  {
    name: 'Ultimo anno',
    value: 365,
  },
  {
    name: 'Tutto',
    value: 0,
  },
];

const PaymentList = () => {
  const initialParams = {
    from: '2010-01-01',
    to: '2024-01-01',
    cliente: null,
    limit: 20,
  };
  const dispatch = useDispatch();
  const [activeFilterId, setActiveFilterId] = useState(0);
  const [searchedName, setSearchedName] = useState('');
  const { isMobile } = useWindowSize();
  const payments = useSelector(getPayments());
  const {total, balance, saldo, balanceRows} = useSelector(getTotal());
  const [params, setParams] = useState(initialParams);
  const [period, setPeriod] = useState(0);
  const [page, setPage] = useState(0);
  const [periodBonifici, setPeriodBonifici] = useState(0);
  const [pageBonifici, setPageBonifici] = useState(0);
  const bonifici = useSelector(getBonifici());
  const activeSalon = useSelector(getActiveSalon());

  useEffect(() => {
    if (payments) dispatch(paymentActions.GET_PAYMENTS.REQUEST({ ...params, offset: page.skip }));
  }, [params, page]);
  useEffect(() => {
    dispatch(paymentActions.GET_PAYMENTS.REQUEST({ ...params, offset: page * params.limit }));
  }, []);
  useEffect(() => {
    setPage({
      skip: 0,
      limit: params.limit,
    });
    if (period)
      setParams({
        ...params,

        from: new Date(new Date().setDate(new Date().getDate() - period))
          .toISOString()
          .split('T')[0],
        to: dayjs().format('YYYY-MM-DD'),
      });
    else
      setParams({
        ...params,
        from: '2010-01-01',
        to: dayjs().format('YYYY-MM-DD'),
      });
  }, [period]);

  useEffect(() => {
    if (bonifici) {
      dispatch(paymentActions.GET_BONIFICI.REQUEST({ ...params, offset: pageBonifici.skip }));
    }
  }, []);

  return (
    <div
      className={clx(styles.payments_list_container, {
        [styles.payments_list_container_mobile]: isMobile,
      })}
    >
      <TitleSectionBack
        title={'Pagamenti'}
        description={'Qui puoi vedere e gestire tutti i pagamenti dei tuoi clienti'}
      />
      <div className={styles.filters_container}>
        <div className={styles.button_wrapper}>
          <FilterButtons
            activeFilterId={activeFilterId}
            setActiveFilterId={setActiveFilterId}
            filtersButtons={filtersButtons}
          />
        </div>

        <ButtonSelector
          title={''}
          setValue={setPeriod}
          options={dataFilterOptions}
          value={period}
          backgroundColor={'white'}
          width={isMobile ? '100%' : 'fit-content'}
          marginLeft='0px'
        />
        {/* <SearchInput
          placeholder={'Cerca il pagamento'}
          value={searchedName}
          setValue={setSearchedName}
          backgroundColor={'white'}
        /> */}
      </div>
      <PageInfo
        activeFilterId={activeFilterId}
        rows={
          activeFilterId === 2
            ? bonifici
            : payments.filter(
                (payment) =>
                  (!activeFilterId || payment.versato === activeFilterId) &&
                  (!searchedName ||
                    Object.values(payment)
                      .filter((x) => ['number', 'string'].includes(typeof x))
                      .join(' ')
                      .toLowerCase()
                      .includes(searchedName.toLowerCase())),
              )
        }
        balanceRows={balanceRows}
        activeSalon={activeSalon}
        balance={balance}
      />
      {activeFilterId === 2 ? (
        <>
          <BonificiTable
            setPage={setPageBonifici}
            total={Math.ceil(bonifici.length)}
            values={bonifici}
            isMobile={isMobile}
          />
        </>
      ) : (
        <>
          <PaymentTable
            setPage={setPage}
            total={Math.ceil(total / params.limit)}
            values={payments.filter(
              (payment) =>
                (!activeFilterId || payment.versato === activeFilterId) &&
                (!searchedName ||
                  Object.values(payment)
                    .filter((x) => ['number', 'string'].includes(typeof x))
                    .join(' ')
                    .toLowerCase()
                    .includes(searchedName.toLowerCase())),
            )}
            isMobile={isMobile}
          />
        </>
      )}
    </div>
  );
};

export default PaymentList;
