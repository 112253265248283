import { homeTypes } from './actions';

const initialState = {
  home_statistic: {},
  queue: [],
  onboarding: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case homeTypes.GET_HOME_STAT.SUCCESS:
      return {
        ...state,
        home_statistic: action.payload
      };
    case homeTypes.GET_QUEUE.SUCCESS:
      return {
        ...state,
        queue: action.payload
      };
    case homeTypes.GET_ONBOARDING.SUCCESS:
      return {
        ...state,
        onboarding: action.payload
      };
    case homeTypes.GET_ONBOARDING.CLEAR:
      return {
        ...state,
        onboarding: {}
      };
    default:
      return { ...state };
  }
};
