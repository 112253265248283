/* eslint-disable no-undef */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveSalonToken } from '../../utils/salonToken';
import { communicationsActions, shopActions } from './store';
import { getCommunicationToken, getShopToken } from './store/selectors';

export function Communications() {
  const dispatch = useDispatch();
  const communicationToken = useSelector(getCommunicationToken());
  useEffect(() => {
    dispatch(communicationsActions.GET_TOKEN_COMMUNICATIONS.REQUEST());
  }, []);

  const url = process.env.REACT_APP_COMMUNICATION + '/?token=' + communicationToken;

  if (!communicationToken) return <div></div>;

  return (
    <>
      <iframe
        src={url}
        style={{
          flex: 1,
          height: '100%',
          width: '100%',
          border: 'none',
        }}
      />
    </>
  );
}
