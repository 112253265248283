import React from 'react';
import styles from './BonificiTable.module.scss';
import CustomTable from '../../../Common/CustomTable';
import AvatarIcon from '../../../Common/AvatarIcon';

import { ReactComponent as EyeIcon } from '../../../../assets/svg/eye_gray.svg';
import { ReactComponent as CalendarColor } from '../../../../assets/svg/icons/calendar-color.svg';
import { ReactComponent as ArrowRight } from '../../../../assets/svg/arrow-right.svg';

import { ReactComponent as MailIcon } from '../../../../assets/svg/icons/mail.svg';
import { modalActions } from '../../../Modals/store';
import { useDispatch } from 'react-redux';
import IconButton from '../../../Common/Buttons/IconButton';
import clx from 'classnames';
import { setCategory } from '../constants';
import dayjs from 'dayjs';
import Modal from 'Containers/Common/Modal';
import clsx from 'clsx';
import { useMemo, useState } from 'react';
import PaymentTable from '../PaymentTable';

export default ({ values, isMobile, setPage, total }) => {
  const filteredValues = values?.filter(v => v.sent===1);
  const data = useMemo(() => filteredValues, [filteredValues]);
  const [modalValue, setModalValue] = useState({ isOpen: false, data: [] });


  const columns = useMemo(
    () =>
      isMobile
        ? [
            {
              Header: 'Data Pagamento',
              accessor: 'created_at',
              Cell: ({ row: { original } }) => {
                return (
                  <div className={styles.payment}>
                    <div className={styles.payment_info}>
                      <div className={styles.payment_date}>
                        {dayjs(original.data).format('DD/MM/YYYY')}
                      </div>
                    </div>
                  </div>
                );
              },
            },
            {
              Header: 'Totale Accreditato',
              accessor: 'totaleAccreditato',
              Cell: ({ row: { original } }) => {
                return (
                  <div className={styles.name_cell_container}>
                    <div className={styles.payment_info}>
                      <div className={styles.payment_services} style={{ color: '#65ce8f' }}>
                        +{(original.totale - original.totaleProvider).toFixed(2)} €
                      </div>
                    </div>
                  </div>
                );
              },
            },
            {
              Header: '',
              accessor: '*',
              Cell: ({ row: { original } }) => {
                return (
                  <div className={styles.payment}>
                    <div className={styles.payment_info} style={{ marginRight: '10px' }}>
                      <div
                        className={styles.detail_arrow}
                        onClick={() => {
                          const transactionsMapped = original?.transazioni.map((t) => {
                            return {
                              ...t,
                              clientName: t.cliente?.nome + ' ' + t.cliente?.cognome,
                              totalePagato: t?.importo,
                              voucher: t?.voucher ?? 0,
                              disableRimborsa: false,
                            };
                          });

                          setModalValue({ isOpen: true, data: transactionsMapped });
                        }}
                      >
                        <ArrowRight className={styles.icon} />
                      </div>
                    </div>
                  </div>
                );
              },
            },
          ]
        : [
            {
              Header: 'Data Pagamento',
              accessor: 'created_at',
              Cell: ({ row: { original } }) => {
                return (
                  <div className={styles.payment}>
                    <div className={styles.payment_info}>
                      <div className={styles.payment_date}>
                        {dayjs(original.data).format('DD/MM/YYYY')}
                      </div>
                    </div>
                  </div>
                );
              },
            },
            // {
            //   Header: 'Importo Totale',
            //   accessor: 'totale',
            //   Cell: ({ row: { original } }) => {
            //     return (
            //       <div className={styles.name_cell_container}>
            //         <div className={styles.payment_info}>
            //           <div className={styles.name}>{original.totale.toFixed(2)} €</div>
            //         </div>
            //       </div>
            //     );
            //   },
            // },
            // {
            //   Header: 'Importo Commissioni Bancarie',
            //   accessor: 'totaleProvider',
            //   Cell: ({ row: { original } }) => {
            //     return (
            //       <div className={styles.name_cell_container}>
            //         <div className={styles.payment_info}>
            //           <div className={styles.payment_services}>
            //             {original.totaleProvider.toFixed(2)} €
            //           </div>
            //         </div>
            //       </div>
            //     );
            //   },
            // },
            {
              Header: 'Totale Accreditato',
              accessor: 'totaleAccreditato',
              Cell: ({ row: { original } }) => {
                return (
                  <div className={styles.name_cell_container}>
                    <div className={styles.payment_info}>
                      <div className={styles.payment_services}>
                        {(original.totale - original.totaleProvider).toFixed(2)} €
                      </div>
                    </div>
                  </div>
                );
              },
            },
            {
              Header: 'Pagamenti Dal',
              accessor: 'pagamentiDal',
              Cell: ({ row: { original } }) => {
                return (
                  <div className={styles.name_cell_container}>
                    <div className={styles.payment_info}>
                      <div className={styles.payment_services}>
                        {dayjs(original.from).format('DD/MM/YYYY')}
                      </div>
                    </div>
                  </div>
                );
              },
            },
            {
              Header: 'Pagamenti Al',
              accessor: 'pagamentiAl',
              Cell: ({ row: { original } }) => {
                return (
                  <div className={styles.name_cell_container}>
                    <div className={styles.payment_info}>
                      <div className={styles.payment_services}>
                        {dayjs(original.to).format('DD/MM/YYYY')}
                      </div>
                    </div>
                  </div>
                );
              },
            },
            {
              Header: '',
              accessor: '*',
              Cell: ({ row: { original } }) => {
                return (
                  <div className={styles.payment}>
                    <div className={styles.payment_info} style={{ marginRight: '10px' }}>
                      <IconButton
                        onClick={() => {
                          const transactionsMapped = original?.transazioni.map((t) => {
                            return {
                              ...t,
                              clientName: t.cliente?.nome + ' ' + t.cliente?.cognome,
                              totalePagato: t?.importo,
                              voucher: t.voucher ?? 0,
                            };
                          });

                          setModalValue({ isOpen: true, data: transactionsMapped });
                        }}
                        svg={<EyeIcon />}
                      />
                    </div>
                  </div>
                );
              },
            },
          ],
    [isMobile],
  );

  return (
    <>
      <div
        className={clx(styles.table_card, {
          [styles.table_card_mobile]: isMobile,
        })}
      >
        <CustomTable
          data={data}
          columns={columns}
          pageCount={total}
          setPaginationValue={setPage}
          serverSidePagination={true}
          isMobile={isMobile}
        />
      </div>
      {
        <Modal
          setIsModalOpen={() => setModalValue({ isOpen: false, data: null })}
          isModalOpen={modalValue.isOpen}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '400',
            height: '400',
          }}
          width={'1103px'}
          title={'Dettaglio bonifico'}
          showCloseBtn={true}
        >
          <div
            className={clx(styles.table_card, {
              [styles.table_card_mobile]: isMobile,
            })}
          >
            <PaymentTable
              serverSidePagination={false}
              pageCount={Math.ceil(modalValue?.data?.length / 5)}
              total={Math.ceil(modalValue?.data?.length)}
              values={modalValue?.data}
              isMobile={isMobile}
              pageRows={5}
            />
          </div>
        </Modal>
      }
    </>
  );
};
