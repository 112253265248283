/* eslint-disable no-case-declarations */
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './SchedulerContainer.module.scss';
import { schedulerActions } from '../../store';
import dayjs from 'dayjs';
import { getCalendarData, getCalendarWorker } from '../../store/selectors';
import SchedulerDay from '../SchedulerDay';
import successSound from '../../../../assets/sounds/1.mp3';
import failSound from '../../../../assets/sounds/2.mp3';

import { getActiveSalonId, getActiveSalon } from '../../../Auth/store/selectors';
import { useSearchParams } from 'react-router-dom';
import CalendarHeader from '../CalendarHeader';
import useWindowSize from '../../../../utils/useWindowSize';
import clx from 'classnames';
import SchedulerAddTaskAndQueueButtons from '../SchedulerAddTaskAndQueueButtons';
import { useSSE } from '../../../../utils/useSSE';
import { Slide, ToastContainer, toast } from 'react-toastify';
import { formatCalendarData } from '../utils/formatCalendarData';
import { ToastSuccess } from '../Toast';
import store from 'store/store';
import { Crisp } from 'crisp-sdk-web';
import { modalActions } from 'Containers/Modals/store';
import { getActiveSalonePaymentInfo } from 'utils/salonToken';
import { getMyAccountStaff } from 'Containers/Settings/store/selectors';
import { settingsActions } from 'Containers/Settings/store';

const failAudio = new Audio(failSound);
const successAudio = new Audio(successSound);

const SchedulerContainer = () => {
  const activeSalon = useSelector(getActiveSalon());
  const payment_disable = getActiveSalonePaymentInfo();
  successAudio.volume = 0.2;
  failAudio.volume = 0.2;
  const dispatch = useDispatch();
  const localSearchData = localStorage.getItem('searchParams');
  const localCalendarData = localStorage.getItem('calendarParams');

  const localSearchParams = localSearchData ? JSON.parse(localSearchData) : null;
  const localCalendarParams = localCalendarData ? JSON.parse(localCalendarData) : {};

  const initialSearchParams = localSearchParams || {
    startDay: dayjs().format('YYYY-MM-DD'),
    numberOfActiveDays: '1',
    activeWorkerId: '0',
    reset: 0,
  };
  const initialCalendarParams = {
    stepSize: 14,
    startWorkTime: { hours: 7, minutes: 0 },
    endWorkTime: { hours: 23, minutes: 0 },
    ...localCalendarParams,
  };
  const { isMobile } = useWindowSize();

  const [startWorkTime, setStartWorkTime] = useState(initialCalendarParams.startWorkTime);
  const [endWorkTime, setEndWorkTime] = useState(initialCalendarParams.endWorkTime);
  const [timeStep, setTimeStep] = useState({ hours: 0, minutes: 5 });
  const [cardSpace, setCardSpace] = useState(160);

  const [stepSize, setStepSize] = useState(initialCalendarParams.stepSize);
  const [calendarItemsCount, setCalendarItemsCount] = useState(1);
  const [workersWorkload, setWorkersWorkload] = useState([]);
  const [startData, setStartData] = useState({});
  const [activeDays, setActiveDays] = useState([]);
  const [searchString, setSearchString] = useState('');
  const calendarData = useSelector(getCalendarData());
  const activeSalonId = useSelector(getActiveSalonId());
  const [searchParams, setSearchParams] = useSearchParams(initialSearchParams);
  const startDay = searchParams.get('startDay');
  const numberOfActiveDays = +searchParams.get('numberOfActiveDays');
  const showHeader = numberOfActiveDays > 1;
  const activeWorker = useSelector(getCalendarWorker(+searchParams.get('activeWorkerId')));
  const container_ref = useRef(null);
  const [openAutomaticModal, setAutomaticModal] = useState(false)

  const myAccount = useSelector(getMyAccountStaff());

  useEffect(() => {
    if(localStorage.getItem('staff') && !myAccount) {
      dispatch(settingsActions.GET_MY_ACCOUNT_STAFF.REQUEST());
    }
  }, []);

  useEffect( () => {    
    if( localStorage.getItem('staff')) {
      let calendar = myAccount?.permessi?.length > 0 ? myAccount?.permessi.find(perm => perm.sezione === 1) : null;
      if ( calendar) {
          if( calendar.viewJustMe==1 ) {
            searchParams.set('viewJustMe', 1);
            searchParams.set('activeWorkerId', myAccount.poltrona);
          }
          else searchParams.set('viewJustMe', 0);

          if( calendar.allowInsert==1 ) {
            searchParams.set('allowInsert', 1);
          }
          else searchParams.set('allowInsert', 0);

          if( calendar.allowEdit==1 ) {
            searchParams.set('allowEdit', 1);
          }
          else searchParams.set('allowEdit', 0);
      }

      let client = myAccount?.permessi?.length > 0 ? myAccount?.permessi.find(perm => perm.sezione === 3) : null;
      if( client ) {
        searchParams.set('client', 1);
      }
      else searchParams.set('client', 0);

      let salon_management = myAccount?.permessi?.length > 0 ? myAccount?.permessi.find(perm => perm.sezione === 2) : null;
      if( salon_management ) {
        searchParams.set('salon_management', 1);
      }
      else searchParams.set('salon_management', 0);

      setSearchParams(searchParams);

    }
  },[calendarData, myAccount, searchParams]);


  //get permessi: 
  // calendar: (filter poltrone | insert modal | edit modal | drug task)
  //   - se permessi.length > 0 e localStorage.getItem('staff') e permessi.find(perm => perm.sezione === 1) : calendar
  //   - calendar.viewJustMe==1 | calendar.allowInsert==1 | calendar.allowEdit==1
  // salon_management: (gestione poltrona btn)
  //   - se permessi.length > 0 e localStorage.getItem('staff') e permessi.find(perm => perm.sezione === 2) : salon_management
  // client: (dettaglio cliente update api)
  //   - se permessi.length > 0 e localStorage.getItem('staff') e permessi.find(perm => perm.sezione === 3) : client


  useEffect(() => {
    localStorage.setItem(
      'calendarParams',
      JSON.stringify({ ...localCalendarParams, startWorkTime, endWorkTime, stepSize }),
    );
  }, [startWorkTime, endWorkTime, stepSize]);

  const [lastRefresh, setLastRefresh] = useState(0);
  const [refreshTimeElapsed, setRefreshTimeElapsed] = useState('ora');
  const getCalendar = () => {
    dispatch(
      schedulerActions.GET_CALENDAR_DATA.REQUEST({
        startDate: dayjs(startDay).format('YYYY-MM-DD'),
        endDate: dayjs(startDay).add(numberOfActiveDays, 'day').format('YYYY-MM-DD'),
      }),
    );
    numberOfActiveDays === 1 &&
      dispatch(
        schedulerActions.GET_CALENDAR_QUEUE.REQUEST({
          date: dayjs(startDay).format('YYYY-MM-DD'),
        }),
      );
    setActiveDays(
      Array.from({ length: numberOfActiveDays }, (_, index) =>
        dayjs(startDay).add(index, 'day').format('YYYY-MM-DD'),
      ),
    );
    setLastRefresh(0);
    setRefreshTimeElapsed('ora');
  };

  const getLastRefreshTime = () => {
    const increment = lastRefresh + 1;
    setLastRefresh(increment);
    let minutes = lastRefresh;
    let hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    hours = hours % 24;

    if (minutes > 0 || hours > 0) {
      setRefreshTimeElapsed(
        `${hours > 0 ? `${hours} ${hours > 1 ? 'ore' : 'ora'}` : ''} ${minutes} ${
          minutes > 1 ? 'minuti' : 'minuto'
        } fa`,
      );
    }
  };

  useEffect(() => {
    const refreshTimeInterval = setInterval(() => {
      getLastRefreshTime();
    }, 60000);
    return () => clearInterval(refreshTimeInterval);
  }, [lastRefresh]);

  useEffect(() => {
    //openCalendar to Crisp
    Crisp.session.pushEvent('openCalendar');
    if (localCalendarData) return;
    if (isMobile) {
      setStepSize(6);
      setCardSpace(50);
    } else {
      setStepSize(14);
      setCardSpace(160);
    }
  }, [isMobile]);
  useEffect(() => {
    const activeWorkers = activeWorker ? [activeWorker] : calendarData.workers;
    setWorkersWorkload(
      formatCalendarData({
        appointments: calendarData.appointments,
        activeWorkers,
        activeDays,
        startWorkTime,
        endWorkTime,
        calendarItemsCount,
        timeStep,
        stepSize,
      }),
    );
  }, [calendarData, activeWorker, activeDays, timeStep, stepSize, calendarItemsCount]);

  useEffect(() => {
    setCalendarItemsCount(
      Math.ceil(
        (endWorkTime.hours * 60 +
          endWorkTime.minutes -
          (startWorkTime.hours * 60 + startWorkTime.minutes)) /
          (timeStep.hours * 60 + timeStep.minutes),
      ),
    );
  }, [startWorkTime, endWorkTime, timeStep]);
  const callbackSSE = (data) => {
    let errorStyle = 'toast_warning';
    let successStyle = 'toast_success';
    let css = data.type === 'disdetta' ? errorStyle : successStyle;

    const toastConfig = {
      autoClose: 3000,
      className: styles[css],
      hideProgressBar: false,
      position: toast.POSITION.TOP_RIGHT,
      progressClassName: styles[css + '_progress'],
      closeOnClick: true,
      // pauseOnHover: true,
      progress: undefined,
      theme: 'light',
    };
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const appointments = store.getState().schedulerReducer.calendar.appointments;
    const existingAppointment = Object.values(appointments)
      .flatMap((day) =>
        day.find(({ idprenotazione }) => idprenotazione === data.event.idprenotazione),
      )
      .filter(Boolean)[0];
    const currentTimestamp = new Date().getTime();

    switch (data.type) {
      case 'prenotazione':
        if (existingAppointment?.isNotNew) return;
        if (!isSafari) successAudio.play();
        toast(<ToastSuccess booking={data} />, toastConfig);
        return dispatch(
          schedulerActions.ADD_CALENDAR_TASK.SUCCESS([{ ...data.event, isNotNew: true }]),
        );
      case 'disdetta':
        if (!isSafari) failAudio.play();
        toast(<ToastSuccess booking={data} type='delete' />, toastConfig);
        return dispatch(
          schedulerActions.DELETE_CALENDAR_TASK.SUCCESS({
            id: data.event.idprenotazione,
            all: data.event.deleteAllRicorrenti,
            ricorrente: data.event?.ricorrente,
          }),
        );
      case 'modifica':
        if (!isSafari) successAudio.play();
        toast(<ToastSuccess booking={data} type='update' />, toastConfig);
        return dispatch(schedulerActions.EDIT_CALENDAR_TASK.SUCCESS(data.event));
    }
  };

  useEffect(() => {
    useSSE(callbackSSE, getCalendar, 'scheduler');
    setAutomaticModal(false);
  }, []);

  useEffect(() => {
    if( activeSalon && (!activeSalon.titolare_iban || !activeSalon.iban) && !payment_disable && openAutomaticModal) {
      setAutomaticModal(false);
      dispatch(
        modalActions.OPEN_MODAL.SUCCESS({
          type: 'editSalonIbanModal',
          props: { 
            activeSalon: activeSalon, 
            title: "Ciao Barber! Da oggi è attivo il 'PAGA ORA'!", 
            subtitle: 'Inserisci i tuoi dati bancari per ricevere i pagamenti direttamente sul tuo conto!' },
        })
      );
    }
  }, [openAutomaticModal]);

  useEffect(() => {
    if (searchParams.get('reset') === '1' && container_ref.current) {
      container_ref.current.scrollTo(0, 0);
      searchParams.set('reset', '0');
      setSearchParams(searchParams);
    }
    const searchParamsString = searchParams.toString();
  }, [searchParams]);
  useEffect(() => {
    if (activeDays && numberOfActiveDays && activeSalonId) {
      getCalendar();
    }
  }, [startDay, numberOfActiveDays, activeSalonId]);

  useEffect(() => {
    setWorkersWorkload((data) => {
      return data.map((day) => {
        const workers = day.workers.map((worker) => {
          const workerTasks = worker.workerTasks.map((task) => {
            if (task.limitCard) return task;
            const completeName = (task.nome || '') + ' ' + (task.cognome || '');
            const match =
              !searchString ||
              completeName?.toLowerCase()?.includes(searchString.toLowerCase()) ||
              task.servizi?.find((service) =>
                service.nome?.toLowerCase()?.includes(searchString.toLowerCase()),
              );
            return {
              ...task,
              matched: match,
              opacity: match ? 1 : '0.2',
            };
          });
          return {
            ...worker,
            workerTasks,
          };
        });
        return {
          ...day,
          workers,
        };
      });
    });
  }, [searchString, startData, activeDays]);

  return (
    <>
      <CalendarHeader
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        startDay={startDay}
        numberOfActiveDays={numberOfActiveDays}
        activeDays={activeDays}
        searchString={searchString}
        setSearchString={setSearchString}
        activeWorker={activeWorker}
        setStepSize={setStepSize}
        stepSize={stepSize}
        workTime={{ setEndWorkTime, setStartWorkTime, startWorkTime, endWorkTime }}
      />

      {/* {!isMobile && (
        <div
          style={{
            fontSize: '14px',
            fontWeight: '400',
            padding: '15px 20px',
            marginTop: '5px',
            background: '#FCB734',
            width: 'fit-content',
          }}
        >
          Aggiorna La pagina. Ultimo aggiornamento{' '}
          <span style={{ fontSize: '14px', fontWeight: '700', color: '#1f1f1f' }}>
            {refreshTimeElapsed}
          </span>
        </div>
      )} */}

      <div
        className={clx(styles.scheduler__wrapper, {
          [styles.scheduler__wrapper__mobile]: isMobile,
        })}
      >
        {workersWorkload.length ? (
          <div className={styles.scheduler__container} ref={container_ref}>
            <div
              className={styles.scheduler__inner}
              style={{ height: `${calendarItemsCount * stepSize + (showHeader ? 100 : 50)}px` }}
            >
              <div className={styles.scheduler_time_line_container}>
                <div
                  className={styles.time_line_corner}
                  style={{
                    height: showHeader ? '100px' : '50px',
                  }}
                />
                {Array(calendarItemsCount / 6)
                  .fill(0)
                  .map((_, index) => (
                    <div
                      key={index}
                      className={styles.time_line_value_container}
                      style={{ top: `${index * 6 * stepSize + (showHeader ? 105 : 50)}px` }}
                    >
                      <div className={styles.time_line_value}>
                        {startWorkTime.hours + Math.floor(index / 2)}:{index % 2 ? '30' : '00'}
                      </div>
                    </div>
                  ))}
                {stepSize > 10 &&
                  Array(calendarItemsCount / 6)
                    .fill(0)
                    .map((_, index) => (
                      <div
                        key={index}
                        className={styles.time_line_value_container_half}
                        style={{
                          top: `${
                            index * 6 * stepSize +
                            (showHeader ? 105 : 55) +
                            stepSize * (timeStep.minutes / 2)
                          }px`,
                        }}
                      >
                        <div className={styles.time_line_value}>
                          {startWorkTime.hours + Math.floor(index / 2)}:{index % 2 ? '45' : '15'}
                        </div>
                      </div>
                    ))}
              </div>
              <div className={styles.scheduler_tasks_container}>
                {workersWorkload.map((day) => {
                  const timeStep = day.workTime / calendarItemsCount;
                  return (
                    <SchedulerDay
                      setStartData={setStartData}
                      key={day.date}
                      cardSpace={cardSpace}
                      calendarItemsCount={calendarItemsCount}
                      timeStep={timeStep}
                      stepSize={stepSize}
                      day={day}
                      startData={startData}
                      numberOfActiveDays={numberOfActiveDays}
                      containerRef={container_ref}
                      searchParams={searchParams}
                      setSearchParams={setSearchParams}
                    />
                  );
                })}
              </div>
            </div>
            {(searchParams.get('allowInsert') == 1 || !localStorage.getItem('staff')) && <SchedulerAddTaskAndQueueButtons numberOfActiveDays={numberOfActiveDays} />}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default SchedulerContainer;
