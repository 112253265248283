import React, { memo, useEffect, useMemo, useState } from 'react';
import styles from './Services.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveSalonId } from '../../../Auth/store/selectors';
import { getServices } from '../../store/selectors';
import { salonManagementActions } from '../../store';
import TitleSectionBack from '../../../Common/TitleSectionBack';
import { ReactComponent as PlusIcon } from '../../../../assets/svg/icons/plus_button.svg';
import FilterButtons from '../../../Common/FilterButtons';
import useWindowSize from '../../../../utils/useWindowSize';
import clx from 'classnames';
import { modalActions } from '../../../Modals/store';
import { List, arrayMove, arrayRemove } from 'react-movable';

import ConfirmDeleteModal from '../../../Common/ConfirmDeleteModal';
import { ServiceItem } from './containers/ServiceItem';

const filtersButtons = [
  /* {
    name: 'Tutte',
    id: 0,
  }, */
  {
    name: 'Attivi',
    id: 1,
  },
  {
    name: 'Disabilitate',
    id: 2,
  },
  {
    name: 'Non prenotabile in app',
    id: 3,
  },
];

const colorsList = [
  {
    name: 'Black',
    value: '#1F1F1F',
  },
  {
    name: 'Blue',
    value: '#35A8F6',
  },
  {
    name: 'Green',
    value: '#2DC56A',
  },
  {
    name: 'Yellow',
    value: '#FCB734',
  },
  {
    name: 'Peach',
    value: '#FFB49D',
  },
  {
    name: 'Pink',
    value: '#FF93C7',
  },
  {
    name: 'Light Blue',
    value: '#B5ACEF',
  },
];

const Services = () => {
  const dispatch = useDispatch();
  const [activeFilterId, setActiveFilterId] = useState(1);
  const activeSalonId = useSelector(getActiveSalonId());
  const { isMobile } = useWindowSize();
  const services = useSelector(getServices(activeFilterId, true));

  useEffect(() => {
    activeSalonId && dispatch(salonManagementActions.GET_SERVICES.REQUEST());
  }, [activeSalonId]);

  const openCreateServiceModal = () => {
    dispatch(
      modalActions.OPEN_MODAL.SUCCESS({
        type: 'createServiceModal',
      }),
    );
  };

  return (
    <div
      className={clx(styles.services_container, {
        [styles.services_container_mobile]: isMobile,
      })}
    >
      <div className={styles.header}>
        <TitleSectionBack
          title={'Gestione Servizi'}
          description={'Crea, modifica e assegna i servizi del tuo salone'}
        />
        <PlusIcon
          className={styles.service_add_button}
          height='60px'
          width='60px'
          onClick={openCreateServiceModal}
        />
      </div>
      <div className={styles.button_selector_container}>
        <div className={styles.button_selector_title}>Servizi</div>
        <div className={styles.filters_container}>
          <FilterButtons
            activeFilterId={activeFilterId}
            setActiveFilterId={setActiveFilterId}
            filtersButtons={filtersButtons}
          />
        </div>
      </div>
      {!!services?.length && <ServicesList services={services} />}
    </div>
  );
};

const ServicesList = memo(
  ({ services = [] }) => {
    const dispatch = useDispatch();
    const [serviceToDeleteId, setServiceToDeleteId] = useState(false);
    const [items, setItems] = useState(services);

    useEffect(() => {
      setItems(services);
    }, [services]);

    useEffect(() => {
      if (items.length && services.length) {
        const changedServicesPosition = services.reduce((acc, service, index) => {
          if (+service.id === +items[index].id) return acc;

          const newPosition = items.findIndex(
            (serviceWithNewPosition) => +serviceWithNewPosition.id === +service.id,
          );
          const serviceNewPosition = {
            serviceId: +service.id,
            position: +newPosition + 1,
          };
          return [...acc, serviceNewPosition];
        }, []);

        if (changedServicesPosition.length) {
          dispatch(salonManagementActions.SET_SERVICES_POSITION.REQUEST(changedServicesPosition));
        }
      }
    }, [items]);

    const openEditServiceModal = (service) => {
      dispatch(
        modalActions.OPEN_MODAL.SUCCESS({
          type: 'editServiceModal',
          props: service,
        }),
      );
    };
    return (
      <>
        <ConfirmDeleteModal
          isModalOpen={serviceToDeleteId}
          setIsModalOpen={setServiceToDeleteId}
          title={'Sei sicuro di voler disabilitare il servizio?'}
          buttons={['Annulla', 'Disabilita']}
          action={() =>
            dispatch(
              salonManagementActions.DELETE_SERVICE.REQUEST(
                {
                  idServizio: serviceToDeleteId,
                  disabilitato: 1,
                  setToAll: true,
                },
                () => setServiceToDeleteId(false),
              ),
            )
          }
        />
        <div className={styles.services_list_container}>
          <List
            values={items}
            onChange={({ oldIndex, newIndex }) => {
              const arrayMoved = arrayMove(services, oldIndex, newIndex);
              setItems(arrayMoved);
            }}
            renderList={({ children, props, isDragged }) => (
              <ul
                {...props}
                style={{
                  padding: '0em 0em 1em 0em',
                  cursor: isDragged ? 'grabbing' : 'inherit',
                }}
              >
                {children}
              </ul>
            )}
            renderItem={({ value: service, props, isDragged, isSelected }) => (
              <li
                {...props}
                style={{
                  ...props.style,
                  listStyleType: 'none',
                  cursor: isDragged ? 'grabbing' : 'inherit',
                  display: 'flex',
                  position: isDragged ? 'fixed' : 'relative',
                }}
              >
                <ServiceItem
                  colorsList={colorsList}
                  openEditServiceModal={openEditServiceModal}
                  service={service}
                  setServiceToDeleteId={setServiceToDeleteId}
                  key={service.id}
                />
              </li>
            )}
          />
        </div>
      </>
    );
  },
  (prevProps, nextProps) => {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
  },
);
export default Services;
