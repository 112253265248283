import React, { useState } from 'react';
import styles from './ServiceShortItem.module.scss';
import { salonManagementActions } from 'Containers/SalonManagement/store';
import { ROUTES } from 'routes/constants';
import clx from 'classnames';
import { ReactComponent as EditIconInCircle } from 'assets/svg/icons/editBlueCircle.svg';
import { ReactComponent as Bin } from 'assets/svg/icons/trash_red.svg';
import { ReactComponent as ArrowLIcon } from 'assets/svg/icons/arrow_l.svg';
import { ReactComponent as MenuIcon } from 'assets/svg/icons/menu_gray.svg';
import { ReactComponent as RightIcon } from 'assets/svg/icons/arrow_l.svg';

import HoveredInfoIcon from 'Containers/Common/HoveredInfoIcon';
import Switcher from 'Containers/Common/Switcher';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useWindowSize from 'utils/useWindowSize';

export const ServiceShortItem = ({
  service,
  openEditServiceModal,
  setServiceToDeleteId,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();


  return (
    <details className={clx(styles.item, { [styles.item_mobile]: isMobile })} key={service.id} >
      <summary key={service.id} className={styles.item_summary} style={{cursor: 'pointer'}}>
          <MenuIcon
            style={{
              width: '20px',
              height: '20px',
            }}
          />
        <div className={styles.item_summary_name}>{service.name}</div>
        <div className={styles.item_summary_icon}>
          <RightIcon />
        </div>
      </summary>
      <div className={styles.item_container}>
        <div className={styles.duration_wrapper}>
            <div className={styles.info_cell}>
                <div className={styles.info_title}>
                Durata
                <EditIconInCircle onClick={() => openEditServiceModal(service)} />
                </div>
                <div className={styles.info_value}>{service.duration} Minuti</div>
            </div>
        </div>
        <div className={styles.price_wrapper}>
            <div className={styles.info_cell}>
                <div className={styles.info_title}>
                Prezzo del servizio
                <EditIconInCircle onClick={() => openEditServiceModal(service)} />
                </div>
                <div className={styles.info_value}>€ {parseFloat(service.cost).toFixed(2)}</div>
            </div>
        </div>
        <div className={styles.main_block_action_wrapper}>
          <div className={styles.title_wrapper}>
            <div className={styles.title}>Prenotabile in app</div>
            <HoveredInfoIcon description={'Prenotabile in app'} />
          </div>
          <Switcher
            value={service.onlyVisibile}
            setValue={(value) => {
              dispatch(
                salonManagementActions.MOD_WORKER_SERVICE.REQUEST({
                  idServiziPoltrona: service.id,
                  onlyVisibile: !+value,
                }),
              );
            }}
            isFullWidth={isMobile}
          />
        </div>
        <div
          className={clx(styles.disable_container, {
            [styles.enable_container]: service.disabled,
          })}
        >
          <div className={styles.title_wrapper}>
            <div className={styles.title}>Disabilita servizio</div>
            <HoveredInfoIcon
              description={`
              Disabilitando il servizio non sarà più possibile prenotarlo in app. 
            `}
            />
          </div>
          {!service.disabled && (
            <Button
              onClick={() => setServiceToDeleteId(service.id)}
              className={styles.delete_button}
            >
              <Bin />
              Disabilita
            </Button>
          )}
          {!!service.disabled && (
            <Button
              onClick={() =>
                dispatch(
                  salonManagementActions.EDIT_WORKER_SERVICE.REQUEST({
                    disabilitato: 0,
                    idServiziPoltrona: service.id,
                  
                  }),
                )
              }
              variant='contained'
              className={styles.enable_button}
            >
              Abilita
            </Button>
          )}
        </div>
      </div>
    </details>
  );
};
