import styles from './HomeStaticBlock.module.scss';
import products from '../../../../assets/images/home/products.png';
import product1 from '../../../../assets/images/home/product1.png';
import product2 from '../../../../assets/images/home/product2.png';
import product3 from '../../../../assets/images/home/product3.png';

import community from '../../../../assets/images/home/community.png';
import advertising from '../../../../assets/images/home/publication.png';
import advertising1 from '../../../../assets/images/home/publication1.png';
import interest1 from '../../../../assets/images/home/interest1.png';
import interest2 from '../../../../assets/images/home/interest2.png';
import interest3 from '../../../../assets/images/home/interest3.png';

import { ReactComponent as Shop } from '../../../../assets/svg/icons/shop.svg';
import { ReactComponent as Article } from '../../../../assets/svg/icons/articoli.svg';

import { Button } from '@mui/material';
import useWindowSize from '../../../../utils/useWindowSize';
import clx from 'classnames';
import config from '../../../../entries/config';

const HomeStaticBlock = () => {
  const { isMobile } = useWindowSize();
  return (
    <div className={clx(styles.cards_container, { [styles.cards_container_mobile]: isMobile })}>
      <div className={styles.shop_section}>
        <div className={styles.mobile_img_block}>
          <img src={product1} alt='' />
          <img src={product2} alt='' />
          <img src={product3} alt='' />
        </div>
        <div className={styles.shop_info}>
          <div className={styles.shop_icon}>
            <Shop />
          </div>
          <div className={styles.info_block}>
            <div
              className={styles.info_title}
              onClick={() => {
                window.open(config.shopUrl, '_blank');
              }}
            >
              Nuovi prodotti disponibili nello shop
            </div>
            <div className={styles.info_description}>
              Abbiamo aggiunto 5 nuovi prodotti per la cura della barba che potrebbero interessarti,
              clicca e scoprili
            </div>
          </div>
          <Button
            onClick={() => {
              window.open(config.shopUrl, '_blank');
            }}
            className={styles.shop_button}
          >
            Visualizza
          </Button>
        </div>
        <img className={styles.shop_img} src={products} alt='' />
      </div>
      {
        !(
          <div className={styles.social_section}>
            <div className={styles.social_card}>
              <div className={styles.social_card_header}>
                <div className={styles.info_block}>
                  <div className={styles.info_title}>Entra nella community</div>
                  <div className={styles.info_description}>
                    Abbiamo aggiunto 5 nuovi prodotti per la cura della barba che potrebbero
                    interessarti, clicca e scoprili
                  </div>
                </div>
              </div>
              <img className={styles.social_img} src={community} alt='' />
              <Button className={styles.social_button}>Visualizza</Button>
            </div>
            <div className={styles.social_card}>
              <div className={styles.social_card_header}>
                <div className={styles.info_block}>
                  <div className={styles.info_title}>Altro tipo di pubblicità</div>
                  <div className={styles.info_description}>
                    Abbiamo aggiunto 5 nuovi prodotti per la cura della barba che potrebbero
                    interessarti, clicca e scoprili
                  </div>
                </div>
              </div>
              <img
                className={styles.social_img}
                src={!isMobile ? advertising : advertising1}
                alt=''
              />
              <Button className={styles.social_button}>Visualizza</Button>
            </div>
          </div>
        )
      }
      {
        !(
          <div className={styles.interest_section}>
            <div className={styles.interest_info}>
              <div className={styles.info_block}>
                <div className={styles.info_title}>Ti potrebbe interessare sapere che</div>
                <div className={styles.info_description}>
                  Nuovo articolo sulle tipologie di taglio più in voga quest’estate
                </div>
                <div className={styles.article_icon}>
                  <Article />
                </div>
              </div>
              <Button
                className={styles.interest_button}
                onClick={() => {
                  window.open(config.zb_blog, `_blank`);
                }}
              >
                Visualizza
              </Button>
            </div>
            <div className={styles.interest_img_block}>
              <img className={styles.shop_img} src={interest1} alt='' />
              <img className={styles.shop_img} src={interest2} alt='' />
              <img className={styles.shop_img} src={interest3} alt='' />
            </div>
          </div>
        )
      }
    </div>
  );
};

export default HomeStaticBlock;
