import styles from './Blocker.module.scss';
import { ROUTES } from '../../../../routes/constants';
import useWindowSize from '../../../../utils/useWindowSize';
import clx from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

const Blocker = () => {
    const { isMobile } = useWindowSize();
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`${ROUTES.SALON_MANAGEMENT}${ROUTES.SERVICES}`);
    };

  return (
    <div className={clx(styles.blocker_container, { [styles['blocker_container_mobile']]: isMobile })}>
        <section className={clx(styles.container, { [styles.container_mbl]: isMobile} )}>
            <summary className={styles.header}>
                <span className={styles.h1}>{'Zetabarber si aggiorna 🚀'}</span>
                <p className={styles.description} >{'Abbiamo migliorato e ottimizzato le nostre performance per offrirti un servizio migliore e in linea con le richieste degli store.💯'}</p>
                <p className={styles.description} style={{fontWeight: '600'}}>{'Per continuare a utilizzare correttamente il calendario, abilita qui i prezzi reali dei tuoi servizi.✨'}</p>
            </summary>
            <Button onClick={handleClick} className={styles.confirm_button}>
                Clicca qui
            </Button>
            </section>
    </div>
  );
};

export default Blocker;
