import React, { useEffect, useState } from 'react';
import styles from './infoBonifici.module.scss';
import { ReactComponent as EditCircle } from 'assets/svg/icons/editBlueCircle.svg';
import { ReactComponent as PortafogliIcon } from 'assets/svg/icons/portafogli.svg';
import { Button, isMuiElement } from '@mui/material';
import { useDispatch } from 'react-redux';
import { modalActions } from 'Containers/Modals/store';
import ConfirmModal from 'Containers/Common/ConfirmModal';
import { paymentActions } from 'Containers/Payments/store';
import dayjs from 'dayjs';
import HoveredInfoIcon from 'Containers/Common/HoveredInfoIcon';
import clsx from 'clsx';

const InfoBonifici = ({ activeSalon, rows, balanceRows, balance, isMobile }) => {
  const dispatch = useDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const lastRequest_data = rows.length > 0 ? rows.filter(n=>n.disabled===0&&n.sent===0)[0] : null;
  function onCurrentWeek(date) {
    const WEEK_LENGTH = 604800000;
    var lastMonday = new Date(); // Creating new date object for today
    lastMonday.setDate(lastMonday.getDate() - (lastMonday.getDay() - 1)); // Setting date to last monday
    lastMonday.setHours(0, 0, 0, 0); // Setting Hour to 00:00:00:00
    const res =
      lastMonday.getTime() <= date.getTime() && date.getTime() < lastMonday.getTime() + WEEK_LENGTH;
    return res; // true / false
  }

  
  const saldo = balance >0 ? (balance - (((balance * 0.029) + (balanceRows * 0.15)) * 1.22) ) : 0;
  
  const canRequired = onCurrentWeek(new Date(rows.filter(n=>n.disabled===0)[0]?.data));

  return (
    <>
      <ConfirmModal
        isModalOpen={isOpenModal}
        setIsModalOpen={setIsOpenModal}
        title={'Sei sicuro di voler richiedere il bonifico?'}
        content={
          <div style={{ padding: '10px 20px 5px' }}>
            Potrai effettuare la prossima richiesta tra una settimana!
          </div>
        }
        action={() => {
          const req = {
            idSalone: activeSalon.idsalone,
            nomeSalone: activeSalon.nome_salone,
            data_richiesta: dayjs().format('YYYY-MM-DD'),
            titolare_iban: activeSalon.titolare_iban,
            iban: activeSalon.iban,
            saldo,
          };
            dispatch(paymentActions.SEND_REQUEST.REQUEST(
              req
              ));
          setIsOpenModal(false);
        }}
      />

      <div className={clsx(styles.container, { [styles.container_mobile]: isMobile })}>
        <div className={styles.settings}>
          <h1 className={styles.title} style={{ fontSize: '14px' }}>
            Informazioni per il bonifico
          </h1>
          <div className={styles.section_item}>
            <div className={styles.item}>
              <span className={styles.title}>Intestatario del conto</span>
              <span className={styles.text_gray}>
                {activeSalon.titolare_iban ?? "Inserisci l'intestatario del conto"}
              </span>
            </div>
            <EditCircle
              style={{ cursor: 'pointer' }}
              height={'25px'}
              width={'25px'}
              onClick={() =>
                dispatch(
                  modalActions.OPEN_MODAL.SUCCESS({
                    type: 'editSalonIbanModal',
                    props: { activeSalon: activeSalon },
                  }),
                )
              }
            />
          </div>
          <div className={styles.section_item}>
            <div className={styles.item}>
              <span className={styles.title}>IBAN</span>
              <span className={styles.text_gray}>{activeSalon.iban ?? 'Inserisci iban'}</span>
            </div>
            <EditCircle
              style={{ cursor: 'pointer' }}
              height={'25px'}
              width={'25px'}
              onClick={() =>
                dispatch(
                  modalActions.OPEN_MODAL.SUCCESS({
                    type: 'editSalonIbanModal',
                    props: { activeSalon: activeSalon },
                  }),
                )
              }
            />
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.section_item}>
            <h1 className={styles.title}>Richiedi bonifico</h1>
            <div className={styles.item}>
              <span className={styles.text_gray}>
                Saldo Disponibile
                <HoveredInfoIcon
                  sidePosition={'right'}
                  description={`Totale netto disponibile dei pagamenti effettuati presso il tuo salone.`}
                />
              </span>
              <span className={styles.text_gray} style={{ color: '#65CE8F' }}>
                €{saldo.toFixed(2)}
              </span>
            </div>
          </div>
          {(!lastRequest_data && !canRequired) &&
          (activeSalon.titolare_iban && activeSalon.iban) ? (
            <Button
              className={clsx(styles.confirm_button, {[styles.confirm_button_disable]: saldo<=0})}
              onClick={saldo>4 ? () => setIsOpenModal(true) :null}
              style={{cursor: saldo>4 ? 'pointer' : 'not-allowed'}}
              variant='contained'
            >
              <PortafogliIcon height={'25px'} width={'25px'} className={styles.icon} />
              Invia richiesta
            </Button>
          ) : (
            <div
              style={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center' }}
            >
              {activeSalon.titolare_iban && activeSalon.iban ? (
                <>
                  Potrai richiedere il bonifico a partire da lunedì prossimo!
                  <HoveredInfoIcon
                    description={`Puoi richiedere il bonifico una volta a settimana, dal lunedì alla domenica.`}
                  />
                </>
              ) : (
                <>
                  Per richiedere il bonifico inserisci iban e intestatario del conto.
                  <HoveredInfoIcon
                    description={`Controlla di aver inserito correttamente iban e intestatario del conto.`}
                  />
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default InfoBonifici;
