import { Grid } from '@mui/material';
import styles from './ShowInfoPaymentModal.module.scss';
import clsx from 'clsx';
import AvatarIcon from 'Containers/Common/AvatarIcon';
import paymentTableStyle from '../PaymentTable/PaymentTable.module.scss';
import useWindowSize from 'utils/useWindowSize';
import { ReactComponent as CalendarColor } from 'assets/svg/icons/calendar-color.svg';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const ShowInfoPaymentModal = ({ pagamento = {}, closeModal }) => {
  const { isMobile } = useWindowSize();

  return (
    <div className={clsx(styles.modal, {[styles.modal_mbl]: isMobile})}>
      <Grid container className={styles.body} gap={2}>
        <Grid item xs={12} marginBottom={1}>
          <div className={paymentTableStyle.name_cell_container}>
            <AvatarIcon name={pagamento.clientName} icon={pagamento.icon} size={'33px'} />
            <div className={paymentTableStyle.payment_info}>
              <div className={paymentTableStyle.name}>{pagamento.clientName}</div>
            </div>
          </div>
        </Grid>
        <Grid item flexDirection={'row'} justifyContent={'space-between'} padding={'0px 0px 15px'} xl={12} className={styles.tile}>
          <div className={styles.date_info}>
            <p className={clsx(styles.subtitle, styles.normal)}>Pagato il</p>
            <div className={styles.date}>
              <CalendarColor width={'17px'} height={'18px'} />
              {dayjs(pagamento.created_at)
                .tz('Europe/Rome')
                .format('DD/MM/YYYY HH:mm')
              }
            </div>
          </div> 
          <div className={styles.date_info}>
            <p className={clsx(styles.subtitle, styles.normal)} style={{textAlign: 'right'}} >Prenotazione</p>
            <div className={styles.date}>
              <CalendarColor width={'17px'} height={'18px'} />
              {dayjs(pagamento.data_prenotazione)
                .tz('Europe/Rome')
                .format('DD/MM/YYYY HH:mm')
              }
            </div>
          </div> 
        </Grid>
        <Grid item xs={12} className={styles.tile}>
          <p className={styles.subtitle}>Poltrona:</p>
          <p className={clsx(styles.subtitle, styles.normal)}>{pagamento.poltrona?.nome_referente}</p>
        </Grid>
        <Grid item xs={12} className={styles.tile}>
          <p className={styles.subtitle}>Servizi:</p>
          <div>
            { pagamento.servizi.map((servizio, index) => (
              <p key={index} className={clsx(styles.subtitle, styles.normal)}>{servizio.nomeServizio}</p>
            ))}
          </div>
        </Grid>
        <Grid item xs={12} className={styles.tile}>
          <p className={styles.subtitle}>Prezzo servizio:</p>
          <p className={clsx(styles.subtitle, styles.normal)}>€{pagamento.totalePagato}</p>
        </Grid>
        {
          pagamento.voucher === 0 ? null : (<Grid item xs={12} className={styles.tile}>
            <p className={clsx(styles.subtitle, styles.subtitle_voucher)}>Coupon utilizzato:</p>
            <p className={clsx(styles.subtitle, styles.subtitle_voucher, styles.normal)}>€{pagamento.voucher}</p>
          </Grid>)
        }
        <Grid item xs={12} className={styles.tile}>
          <div className={styles.divider} />
        </Grid>
        <Grid item xs={12} className={styles.tile}>

          <p className={styles.subtitle}>Totale pagato:</p>
          <p className={clsx(styles.subtitle, styles.normal)}>€{pagamento.totalePagato}</p>
        </Grid>
      </Grid>
    </div>
  );
};

export default ShowInfoPaymentModal;
