import React, { useEffect } from 'react';
import styles from './accountItem.module.scss';
import clx from 'classnames';
import { ReactComponent as EditIconInCircle } from 'assets/svg/icons/editBlueCircle.svg';
import { ReactComponent as Bin } from 'assets/svg/icons/trash_red.svg';
import { ReactComponent as MenuIcon } from 'assets/svg/icons/menu_gray.svg';
import { ReactComponent as RightIcon } from 'assets/svg/icons/arrow_l.svg';

import HoveredInfoIcon from 'Containers/Common/HoveredInfoIcon';
import Switcher from 'Containers/Common/Switcher';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useWindowSize from 'utils/useWindowSize';
import { getSubSections, SECTIONS } from '../constants';
import { authActions } from 'Containers/Auth/store';
import { toastActions } from 'Containers/Toasts/store';

export const AccountItem = ({
  key,
  account,
  openEditAccountModal,
  setAccountToDelete,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();

  return (
    <details className={clx(styles.account_container, { [styles.account_container_mbl]: isMobile })} key={key} >
      <summary key={account.idUtenteBusiness} className={styles.item_summary} style={{cursor: 'pointer'}}>
          <MenuIcon
            style={{
              width: '20px',
              height: '20px',
            }}
          />
        <div className={styles.item_summary_name}>{account.nome_referente}</div>
        <div className={styles.item_summary_icon}>
          <RightIcon />
        </div>
      </summary>
      <div className={styles.item_container}>
        <div className={styles.duration_wrapper}>
            <div className={styles.info_cell}>
                <div className={styles.info_title}>
                    Poltrona
                <EditIconInCircle onClick={() => openEditAccountModal(account)} />
                </div>
                <div className={styles.info_value}>{account.nome_referente}</div>
            </div>
        </div>
        <div className={styles.price_wrapper}>
            <div className={styles.info_cell}>
                <div className={styles.info_title}>
                    Email
                    <EditIconInCircle onClick={() => openEditAccountModal(account)} />
                </div>
                <div className={styles.info_value}>{account.email}</div>
            </div>
        </div>
        <div className={styles.main_block_action_wrapper}>
            <div className={styles.title_wrapper}>
                <div className={styles.title}>Recupera Password</div>
                <HoveredInfoIcon
                    description={`
                    Invia l'email per il ripristino della password dell'account.
                `}
                />
            </div>
            <Button
                onClick={() =>{
                    dispatch(authActions.SEND_EMAIL_RECOVERY.REQUEST({email: account.email}, () => {
                        dispatch(toastActions.OPEN_TOAST.SUCCESS({
                            type: 'api',
                            props: { title: 'Email Inviata', message: "Controlla la casella di posta elettronica", warning: false },
                        }))
                    }))
                }}
                className={styles.primary_button}
            >
                Invia Email
            </Button>        
        </div>
        <div className={styles.main_block_action_wrapper}>
            <div className={styles.title_wrapper}>
                <div className={styles.title}>Disattiva Account</div>
                <HoveredInfoIcon
                    description={`Disattivando l'account l'utente non potrà più accedere a zetabarber con esso.`}
                />
            </div>
            <Button
                onClick={() => setAccountToDelete(account)}
                className={styles.delete_button}
            >
                <Bin />
                Disattiva
            </Button>        
        </div>
        <div className={styles.permessi_wrapper}>
            <div className={styles.info_cell}>
                <div className={styles.info_title}>
                    Permessi
                    <EditIconInCircle onClick={() => openEditAccountModal(account)} />
                </div>
            </div>
            <div className={styles.permessi_container}>
                {account.permessi.map((permesso, index) => {
                    let section = SECTIONS[permesso.sezione];
                    let subSections = getSubSections(permesso);
                    if(section)
                        return (<div key={index} className={styles.sections_container}>
                            <div className={styles.section}>
                                {section}
                            </div>
                            <div className={styles.subsections_list}>
                                {subSections.map((subSection, index) => {
                                    return (<>
                                        <div key={index} className={styles.subsection}>
                                            {subSection}
                                        </div>
                                        {index == (subSections.length -1) ? null : (<div className={styles.divider}></div>)}
                                    </>)
                                })}
                            </div>
                        </div>)
                    else return <></>;
                })}
            </div>
        </div>
      </div>
      
    </details>
  );
};
