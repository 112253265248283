export const imageEntities = {
  worker: 'poltrona',
  salon: 'salone',
  service: 'servizio',
  clients: 'clienti',
  icons: 'icone',
};

export const getIconUrl = (id, entity, type) => {
  if (!id || !entity) return '';
  const baseUrl = process.env.REACT_APP_API_URL + 'img/';
  switch (entity) {
    case imageEntities.service:
      return `${baseUrl}${entity}?id=${id}`;
    case imageEntities.icons:
      return `${baseUrl}${entity}?id=${id}`;
    case imageEntities.worker:
      return `${baseUrl}${entity}?id=${id}`;
    // return `${baseUrl}${entity}?id=${id}&refresh=${new Date().getTime()}`
    case imageEntities.clients:
      return `${baseUrl}${entity}?id=${id}`;
    case imageEntities.salon:
      return `${baseUrl}${entity}?id=${id}&tipo=${type}`;
    // return `${baseUrl}${entity}?id=${id}&tipo=${type}&refresh=${new Date().getTime()}`
    default:
      return '';
  }
};
