import { createSelector } from 'reselect';
import dayjs from 'dayjs';

const selectSettingsState = (state) => state.settingsReducer;
export const getCustomerPortal = () =>
  createSelector(selectSettingsState, (state) => state.customerPortal);
export const getCustomerBill = () => createSelector(selectSettingsState, (state) => state.bill);

export const getCustomerInvoices = () =>
  createSelector(selectSettingsState, (state) => state.invoices);
export const getCustomerPlan = () => createSelector(selectSettingsState, (state) => state.plan);
export const getCustomerProducts = () =>
  createSelector(selectSettingsState, (state) => state.products);

export const getAccountsStaff = () => createSelector(selectSettingsState, (state) => state.accounts);
export const getAccountsWorkers = () => {
  return createSelector(selectSettingsState, (state) => {
    return state.accounts.map((account) => account.poltrona.idpoltrona);
  });
}
export const getMyAccountStaff = () =>
  createSelector(selectSettingsState, (state) => state.myAccount);
