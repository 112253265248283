import { create } from '@mui/material/styles/createTransitions';
import { createSelector } from 'reselect';

const selectClientsState = (state) => state.notificationReducer;
export const getNotifications = () =>
  createSelector(selectClientsState, (state) => ({
    notificationsList: state.notifications?.notificationsList?.map((notification) => {
      const user = JSON.parse(notification.nota);
      const fullName = `${user.nome ? user.nome : ''}${user.cognome ? ` ${user.cognome}` : ''}`;
      return {
        message: notification.mesaggio,
        clientId: notification.tipoUtente!= 2 ? notification.idUtente : user.idUtente ?? null,
        clientName: fullName,
        date: notification.sendDate,
        createdAt: notification.createAtNotifica,
      };
    }),
    totalNotifications: state.notifications?.totalNotifications,
  }));
