import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './ServiceEditModal.module.scss';
import { Field, Form, Formik } from 'formik';
import { Button } from '@mui/material';
import { salonManagementActions } from '../../../../store';
import TextField from '../../../../../Common/TextField';
import * as yup from 'yup';
import ButtonSelector from '../../../../../Common/CustomSelector/ButtonSelector';
import clsx from 'clsx';
import useWindowSize from '../../../../../../utils/useWindowSize';
import { getWorkers } from '../../../../store/selectors';

const servicesTimesInMinutes = [
  {
    value: 15,
    name: '15 minuti',
  },
  {
    value: 20,
    name: '20 minuti',
  },
  {
    value: 25,
    name: '25 minuti',
  },
  {
    value: 30,
    name: '30 minuti',
  },
  {
    value: 35,
    name: '35 minuti',
  },
  {
    value: 40,
    name: '40 minuti',
  },
  {
    value: 45,
    name: '45 minuti',
  },
  {
    value: 50,
    name: '50 minuti',
  },
  {
    value: 55,
    name: '55 minuti',
  },
  {
    value: 60,
    name: '60 minuti',
  },
];

export default ({ closeModal, service, }) => {
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();

  const validationSchema = {
    cost: yup
      .number('Inserisci un prezzo')
      .min(4, 'Deve essere uguale o superiore a 4')
      .required('Il Prezzo è obbligatorio')
      .typeError('Deve essere un numero, se hai inserito un numero con la virgola sostituiscila con il punto.') // Messaggio di errore se non è un numero
      .test(
        'max-decimals', 
        'Può avere al massimo 2 decimali', 
        (value) => (value === undefined || value === null || /^[0-9]+(\.[0-9]{1,2})?$/.test(value.toString())),
      ),
  };

  const initialValues = {
    id: service.id,
    duration: service.duration,
    cost: service.cost,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={yup.object().shape(validationSchema)}
      onSubmit={(values) => {
        const req = {
          idServiziPoltrona: values.id,
          time: values.duration,
          prezzo: values.cost,
        };
        dispatch(salonManagementActions.MOD_WORKER_SERVICE.REQUEST(req, closeModal));
      }}
    >
      {({ values, setFieldValue }) => (
        <Form
          className={clsx(styles.service_modal_wrapper, {
            [styles.service_modal_wrapper_mobile]: isMobile,
          })}
        >
          <div className={styles.body}>
            <ButtonSelector
              value={values.duration}
              setValue={(value) => setFieldValue('duration', value)}
              options={servicesTimesInMinutes}
              title={'Durata'}
              marginLeft={0}
              height='50px'
              margin='0px 0px 60px 0px'
              width={'100%'}
            />
            <Field
              name={'cost'}
              id={'cost'}
              label='Prezzo'
              component={TextField}
              height={'50px'}
              margin={'30px 0px 30px 0px'}
              placeholder={'Campo di testo da compilare'}
            />
          </div>
          <hr />
          <div className={clsx(styles.actions_container, styles.footer)}>
            <Button className={styles.confirm_button} variant='contained' type={'submit'}>
              Salva
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
