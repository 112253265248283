import React, { useEffect, useState, useRef } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { clientActions } from '../../../../../Clients/store';
import { getClientsShort } from '../../../../../Clients/store/selectors';
import {
  getCalendarSlots,
  getCalendarWorkers,
  getCalendarWorkerServices,
  getTaskById,
} from '../../../../store/selectors';
import InputSelector from '../../../../../Common/CustomSelector/InputSelector';
import MultiplySelector from '../../../../../Common/CustomSelector/MultiplySelector';
import TextField from '../../../../../Common/TextField';
import { ReactComponent as Calendar } from '../../../../../../assets/svg/icons/calendar_blue.svg';
import { ReactComponent as Scissors } from '../../../../../../assets/svg/icons/scissors_blue.svg';
import { ReactComponent as Person } from '../../../../../../assets/svg/icons/person_blue.svg';
import { ReactComponent as Armchair } from '../../../../../../assets/svg/icons/armchair_blue.svg';
import { ReactComponent as ClockIcon } from '../../../../../../assets/svg/icons/orari_blue.svg';
import { ReactComponent as Bin } from '../../../../../../assets/svg/icons/trash_red.svg';
import { ReactComponent as PlusIcon } from '../../../../../../assets/svg/icons/aggiungi_blue.svg';
import CustomDateTimePicker from '../../../../../Common/CustomDateTimePicker';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import styles from './TaskModal.module.scss';
import Switcher from '../../../../../Common/Switcher';
import HoveredInfoIcon from '../../../../../Common/HoveredInfoIcon';
import ButtonSelector from '../../../../../Common/CustomSelector/ButtonSelector';
import { Button } from '@mui/material';
import CustomTimePicker from '../../../../../Common/CustomTimePicker';
import clx from 'classnames';
import CustomDatePicker from '../../../../../Common/CustomDatePicker';
import { schedulerActions } from '../../../../store';
import ConfirmDeleteModal from '../../../../../Common/ConfirmDeleteModal';
import useWindowSize from '../../../../../../utils/useWindowSize';
import ConfirmSecondaryModal from '../../../../../Common/ConfirmSecondaryModal';
import constants from 'shared/Loader/store/constants';
import { getSpecifLoader } from 'shared/Loader/store/selectors';
import Spinner from 'Containers/Common/Spinner';

const recurrenceOptions = [
  {
    name: 'Ogni settimana',
    value: 1,
  },
  {
    name: 'Ogni due settimana',
    value: 2,
  },
  {
    name: 'Ogni tre settimana',
    value: 3,
  },
  {
    name: 'Ogni quattro settimana',
    value: 4,
  },
];

const TaskModal = ({
  taskValue: { day, workerId, task },
  closeModal,
  isEditForm = false,
  isMobile,
  clients,
}) => {
  const dispatch = useDispatch();
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const slots = useSelector(getCalendarSlots()) || [];
  const loading = useSelector(getSpecifLoader(constants.CLIENT));
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(
      clientActions.GET_CLIENTS.REQUEST({}, () => {}, {
        loader: constants.CLIENT,
      }),
    );
    return () => dispatch(schedulerActions.GET_CALENDAR_SLOTS.CLEAR());
  }, [dispatch]);

  const workers = useSelector(getCalendarWorkers());
  const services = useSelector(getCalendarWorkerServices());
  const taskToEdit = isEditForm ? useSelector(getTaskById(task.idprenotazione)) : {};
  const loadingServices = useSelector(getSpecifLoader(constants.SERVICES));

  const getSlots = ({ servizi, idPoltrona }) => {
    const filteredServices = services.filter(
      ({ idServizio, idPoltrona: idP }) => servizi.includes(idServizio) && idP == idPoltrona,
    );
    if (servizi.length)
      dispatch(
        schedulerActions.GET_CALENDAR_SLOTS.REQUEST(
          {
            servizi: filteredServices,
            idPoltrona,
            nGiorniPrenotabili: 30,
            lang: 'it',
          },
          () => {},
          {
            loader: constants.SERVICES,
          },
        ),
      );
  };
  const initialValues = {
    worker_id: workerId || '',
    client_id: isEditForm ? taskToEdit.utente : '',
    service_ids:
      isEditForm && taskToEdit?.servizi?.length
        ? taskToEdit.servizi.map((task) => task.idServizio)
        : [],
    period: {
      start: task?.unixStartTime ? dayjs(task.unixStartTime * 1000).format('HH:mm:ss') : null,
      end: task?.unixEndTime ? dayjs(task.unixEndTime * 1000).format('HH:mm:ss') : null,
    },
    date: task?.unixStartTime ? dayjs(task.unixStartTime * 1000) : dayjs(),
    recurring_booking: false,
    recurrence: 1,
    recurrenceEndDate: task?.unixStartTime
      ? dayjs(task.unixStartTime * 1000).add(1, 'month')
      : null,
    services: services,
    description: isEditForm ? taskToEdit.nota : '',
  };
  const validationSchema = isEditForm
    ? {
        worker_id: yup.string().required('Seleziona una poltrona'),
        period: yup.object().shape({
          start: yup.string().required('Seleziona un orario'),
          end: yup.string().required('Seleziona un orario'),
        }),
        date: yup.date().required('Data obbligatoria'),
      }
    : {
        worker_id: yup.string().required('Seleziona una poltrona'),
        client_id: yup
          .number()
          .when('description', ([description], schema) =>
            !description
              ? schema.required('Seleziona il cliente o inserisci una nota con il suo nome')
              : schema.nullable(true),
          ),
        service_ids: yup
          .array()
          .min(1, 'Seleziona almeno un servizio')
          .required('Seleziona almeno un servizio'),
        period: yup
          .object()
          .shape({
            start: yup.string().required('Seleziona un orario'),
            end: yup.string().required('Seleziona un orario'),
          })
          .default(undefined)
          .required(),
        date: yup.date().required('Data obbligatoria'),
        description: yup.string(),
        recurring_booking: yup.boolean(),
        recurrence: yup
          .number()
          .when('recurring_booking', ([recurring_booking], schema) =>
            recurring_booking ? schema.required('Campo obbligatorio') : schema.nullable(true),
          ),
        recurrenceEndDate: yup
          .date()
          .when('recurring_booking', ([recurring_booking], schema) =>
            recurring_booking ? schema.required('Campo obbligatorio') : schema.nullable(true),
          ),
      };
  const removeBooking = (recurrence) => {
    dispatch(
      schedulerActions.DELETE_CALENDAR_TASK.REQUEST({
        deleteAllRicorrenti: recurrence,
        idPoltrona: taskToEdit.poltrona,
        idPrenotazione: taskToEdit.idprenotazione,
      }),
    );
    closeModal();
  };

  const scrollToRef = useRef(null);
  const refs = {
    worker_id: scrollToRef,
    service_ids: scrollToRef,
    startDate: scrollToRef,
    client_id: scrollToRef,
  };

  const handleScroll = (ref) => {
    window.scrollTo({
      top: ref.offsetTop,
      left: 0,
      behavior: 'smooth',
    });
  };

  const loadingAfterCall = () => {
    setIsLoading(false);
    closeModal();
  }

  return (
    <>
      {isLoading && <Spinner />}
      <ConfirmSecondaryModal
        isModalOpen={confirmationModalIsOpen}
        setIsModalOpen={setConfirmationModalIsOpen}
        title={'Disdici prenotazione'}
        subtitle={
          task?.ricorrente
            ? `Questa è una prenotazione ricorrente.
          Vuoi disdire solo quest'appuntamento o anche tutti gli altri 
          ricorrenti di questa prenotazione?
        `
            : 'Sei sicuro di voler disdire la prenotazione?'
        }
        buttons={
          task?.ricorrente
            ? ['Disdici solo questa', 'Disdici tutte le ricorrenti']
            : ['Disdici prenotazione']
        }
        buttonsActions={[() => removeBooking(false), () => removeBooking(true)]}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={yup.object().shape(validationSchema)}
        onSubmit={(values) => {
          setIsLoading(true);
          if (isEditForm) {
            const formedTask = {
              idPrenotazione: taskToEdit.idprenotazione,

              orario_start: values.period.start,
              orario_end: values.period.end,
              data_prenotazione: values.date,
              poltrona: values.worker_id,
            };
            dispatch(schedulerActions.EDIT_CALENDAR_TASK.REQUEST( formedTask, ()=> setTimeout(loadingAfterCall, 1000) ));
          } else {
            const services = values.services
              .filter(
                (service) =>
                  +service.worker_id === +values.worker_id &&
                  values.service_ids.includes(service.value),
              )
              .map((service) => ({ idservezio: service.value, nome: service.name }));
            const start = values.period.start
              ?.split(':')
              ?.map((x, i) => (i != 2 ? x : '00'))
              ?.join(':');
            const end = values?.period?.end
              ?.split(':')
              ?.map((x, i) => (i != 2 ? x : '00'))
              ?.join(':');
            const formedValues = {
              idPoltrona: values.worker_id,
              idUtente: values.client_id,
              servizi: services,
              oraInizio: start,
              oraFine: end,
              dataPrenotazione: values.date.format('YYYY-MM-DD'),
              nota: values.description,
              recurrence: {
                enabled: values.recurring_booking,
                to: values.recurring_booking && values.recurrenceEndDate.format('YYYY-MM-DD'),
                recurrenceType: values.recurrence,
              },
            };
            dispatch(schedulerActions.ADD_CALENDAR_TASK.REQUEST( formedValues, ()=> setTimeout(loadingAfterCall, 1000) ));
          }
        }}
      >
        {({ values, setFieldValue, errors, touched, setFieldTouched }) => {
          /* useEffect(() => {

            let firstErrorField = Object.keys(errors)[0];

            // Se esiste un campo con un errore e abbiamo una ref per quel campo
            if (firstErrorField && refs[firstErrorField]) {
              console.log(firstErrorField)
              handleScroll(refs[firstErrorField].current);
              firstErrorField = null;
            }

          }, [errors, touched]); */

          return (
            <Form
              className={clx(styles.create_task_form, {
                [styles.create_task_form_mobile]: isMobile,
              })}
            >
              <div className={styles.body}>
                <div className={styles.inputs_wrapper}>
                  <div className={styles.input_wrapper}>
                    <InputSelector
                      options={workers}
                      value={values.worker_id}
                      setValue={(id) => {
                        setFieldValue('worker_id', id);
                        setFieldTouched('worker_id', false);
                        if (id !== values.worker_id && !isEditForm) {
                          setFieldValue('service_ids', []);
                          setFieldTouched('service_ids', false);
                        }
                      }}
                      placeholder={'Seleziona poltrona'}
                      title={'Poltrona'}
                      icon={<Armchair />}
                      withIcon={true}
                      error={touched.worker_id && errors.worker_id}
                      isEmptyMessage={'Non ci sono poltrone'}
                    />
                  </div>
                  <div className={styles.input_wrapper}>
                    <MultiplySelector
                      readOnly={true}
                      options={values.services.filter(
                        (service) => +service.worker_id === +values.worker_id,
                      )}
                      value={values.service_ids}
                      closeOnClick={true}
                      setValue={(ids) => {
                        // const activeServicesDuration = values.services
                        //   .filter(
                        //     (service) =>
                        //       +service.worker_id === +values.worker_id && ids.includes(service.value),
                        //   )
                        //   .reduce((acc, item) => acc + item.duration, 0);
                        getSlots({
                          servizi: ids,
                          idPoltrona: values.worker_id,
                        });
                        setFieldValue('service_ids', ids);
                        setFieldValue('period', {
                          start: null,
                          end: null,
                        });
                        setFieldTouched('service_ids', false);
                        setFieldTouched('period', false);
                      }}
                      placeholder={'Seleziona il servizio'}
                      title={'Servizio'}
                      icon={values.service_ids.length == 0 ? <Scissors /> : <PlusIcon />}
                      withIcon={true}
                      disabled={isEditForm}
                      error={touched.service_ids && errors.service_ids}
                      isEmptyMessage={'Non ci sono servizi'}
                    />
                  </div>
                </div>

                <div className={styles.inputs_wrapper}>
                  <div className={styles.input_wrapper}>
                    <CustomDatePicker
                      value={values.date}
                      setValue={(date) => {
                        const activeServicesDuration = values.services
                          .filter(
                            (service) =>
                              +service.worker_id === +values.worker_id &&
                              values.service_ids.includes(service.value),
                          )
                          .reduce((acc, item) => acc + item.duration, 0);
                        setFieldValue('date', date);
                        setFieldValue('recurrenceEndDate', date.add(1, 'month'));
                        setFieldValue('period', {
                          start: null,
                          end: null,
                        });
                        setFieldTouched('period', false);
                        setFieldTouched('recurrenceEndDate', false);
                      }}
                      placeholder={'Select date'}
                      title={'Data'}
                      icon={<Calendar />}
                      withIcon={true}
                      error={touched.date && errors.date}
                    />
                  </div>
                  <div className={styles.input_wrapper}>
                    <InputSelector
                      loading={loadingServices}
                      value={JSON.stringify(values.period)}
                      setValue={(value) => {
                        setFieldValue('period', JSON.parse(value));
                        setFieldTouched('period', false);
                      }}
                      options={slots
                        ?.filter(({ data }) => values.date.format('YYYY-MM-DD') === data)
                        ?.flatMap(({ orari }) => orari)
                        .filter(({ isBooked }) => !isBooked)
                        .map((period) => ({
                          name: `Dalle ${period.oraInizio
                            .split(':')
                            .slice(0, 2)
                            .join(':')} alle ${period.oraFine.split(':').slice(0, 2).join(':')} `,
                          value: JSON.stringify({
                            start: period.oraInizio,
                            end: period.oraFine,
                          }),
                        }))}
                      placeholder={'Seleziona la fascia oraria'}
                      title={'Orario'}
                      icon={<ClockIcon />}
                      withIcon={true}
                      error={touched.period?.start && errors.period?.start}
                      isEmptyMessage={'Nessun orario disponibile'}
                      readOnly
                    />
                  </div>
                </div>

                <div className={styles.inputs_wrapper}>
                  <div className={styles.input_wrapper}>
                  <InputSelector
                      loading={loading}
                      options={clients}
                      newValue={values.nota}
                      acceptNewValue={isMobile}
                      value={values.client_id}
                      setNewValue={(value) => {
                        setFieldValue('description', value);
                        setFieldTouched('client_id', false);
                      }}
                      setValue={(id) => {
                        setFieldValue('client_id', id);
                        setFieldTouched('client_id', false);
                      }}
                      placeholder={'Seleziona il cliente'}
                      title={isMobile ? 'Cliente o Nota' : 'Cliente'}
                      icon={<Person />}
                      withIcon={true}
                      disabled={isEditForm}
                      error={touched.client_id && errors.client_id}
                      isEmptyMessage={'Non ci sono clienti'}
                      number={true}
                    />
                  </div>
                </div>
                <div className={styles.inputs_wrapper}>
                  <Field
                    name='description'
                    id='description'
                    label='Nota'
                    placeholder='Inserisci nota opzionale'
                    component={TextField}
                    type='text'
                    textarea={true}
                    height={'80px'}
                    width={'100%'}
                    disabled={isEditForm}
                    margin={'20px 0'}
                  />
                  {!isEditForm && (
                    <>
                      <div className={styles.switcher_wrapper}>
                        <HoveredInfoIcon
                          iconPosition='right'
                          description={`
                        Seleziona questa opzione se vuoi che la prenotazione si ripeta ogni settimana
                      `}
                          title={'Prenotazione ricorrente'}
                        />
                        <div className={styles.switcher_container}>
                          <Switcher
                            value={!values.recurring_booking}
                            setValue={(bool) => setFieldValue('recurring_booking', bool)}
                            isFullWidth={isMobile}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>

                {!isEditForm && (
                  <>
                    <div
                      className={clx(styles.time_inputs_wrapper, {
                        [styles.hide]: !values.recurring_booking,
                      })}
                    >
                      <div className={styles.time_input_wrapper}>
                        <CustomDatePicker
                          value={values.recurrenceEndDate}
                          setValue={(date) => {
                            setFieldValue('recurrenceEndDate', date);
                            setFieldTouched('recurrenceEndDate', false);
                          }}
                          placeholder={'Select date'}
                          title={'Fino al'}
                          icon={<Calendar />}
                          withIcon={true}
                          minDate={values.date}
                          error={touched.recurrenceEndDate && errors.recurrenceEndDate}
                        />
                      </div>
                      <ButtonSelector
                        options={recurrenceOptions}
                        value={values.recurrence}
                        setValue={(value) => {
                          setFieldValue('recurrence', value);
                          setFieldTouched('recurrence', false);
                        }}
                        width='300px'
                        title={'Ricorrenza'}
                        height='50px'
                        marginLeft={isMobile ? 0 : '20px'}
                      />
                    </div>
                  </>
                )}
              </div>
              <hr />
              <div
                className={clx(styles.form_actions, styles.footer, {
                  [styles['edit_actions']]: isEditForm,
                })}
              >
                <Button
                  onClick={() => setConfirmationModalIsOpen(true)}
                  className={styles.delete_button}
                >
                  <Bin />
                  Disdici
                </Button>
                <div className={styles.confirm_button_block}>
                  {!isMobile && (
                    <Button onClick={closeModal} className={styles.return_button}>
                      Annulla &gt;
                    </Button>
                  )}
                  <Button className={styles.confirm_button} variant='contained' type={'submit'}>
                    Conferma
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default TaskModal;
