import styles from '../Toast.module.scss';
import {ReactComponent as Success} from '../../../../../assets/svg/icons/green_success.svg';
import { ReactComponent as Worker } from '../../../../../assets/svg/icons/poltrona.svg';
import { ReactComponent as Scissors } from '../../../../../assets/svg/icons/forbici.svg';
import { ReactComponent as Client } from '../../../../../assets/svg/icons/profilo.svg';
import { ReactComponent as Payment } from '../../../../../assets/svg/icons/portafogli.svg';
import { ReactComponent as Api } from '../../../../../assets/svg/icons/campanella.svg';

export const GeneralToast = ({ booking, type }) => {
  const title = {
    service: 'Servizi!',
    worker: 'Poltrone!',
    client: 'Clienti!',
    payment: 'Pagamenti!',
  };
  
  let Icon;
  switch (type){
    case 'service': 
        Icon = Scissors;
        break;
    case 'worker': 
        Icon = Worker;
        break;
    case 'client':
        Icon = Client;
        break;
    case 'payment':
        Icon = Payment;
        break;
    case 'api':
        Icon = Api;
        break;
    default: 
        Icon = Success;
  }

  return (
    <div className={styles.container_toast}>
      <div style={{display:'flex', flexDirection:'row', gap:'10px', alignItems: 'flex-start', marginBottom: '5px'}}>
        <Icon width='17px' height='17px'/>
        <h3>{type != 'api' ? title[type] : booking.title}</h3>
        </div>
      <h4>{type != 'api' ? booking.title : booking.message}</h4>
    </div>
  );
};