import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store';
import * as Sentry from '@sentry/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Crisp } from 'crisp-sdk-web'; //https://docs.crisp.chat/guides/chatbox-sdks/web-sdk/npm/
import { ufSaloniBannerPayments } from 'utils/salonToken';

Sentry.init({
  dsn: 'https://ac80f02284a64d729dc9497fdd61cc49@o446823.ingest.sentry.io/4505249567408128',
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  enabled: true,
  environment: process.env.NODE_ENV,
  release: process.env.REACT_APP_VERSION,
  enableTracing: true,
  beforeSend: async function (event, hint) {
    const salonInformation = localStorage.getItem('salons');
    let salon = null;
    try {
      salon = JSON.parse(salonInformation);
    } catch {
      salon = salonInformation;
    }
    event.extra.salon = salon;
    return event;
  },
  // Session Replay
  replaysSessionSampleRate: 0.5, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

Crisp.configure('ce09127b-61c6-4f19-b302-2d15a3d87df1', {
  autoload: true,
});
const salon = JSON.parse(localStorage.getItem('salons') || '{}');
const statSalone = JSON.parse(localStorage.getItem('statSalone') || '{}');
console.log('crisp', salon, statSalone);
if (salon[0] !== undefined) {
  Crisp.user.setEmail(salon[0].emailVerificata);
  Crisp.user.setNickname(salon[0].username);
  Crisp.session.setData({
    idsalone: salon[0].idsalone,
    user_id: salon[0].idsalone,
    email_zb: salon[0].email,
  });

  // Data di iscrizione
  const dataIscrizione = new Date(salon[0].data_iscrizione);
  // Data odierna
  const oggi = new Date();
  // Calcolo della differenza in millisecondi
  const differenzaInMillisecondi = oggi - dataIscrizione;
  // Convertire la differenza in millisecondi in giorni
  const millisecondiInUnGiorno = 1000 * 60 * 60 * 24; // Millisecondi in un giorno
  const differenzaInGiorni = Math.floor(differenzaInMillisecondi / millisecondiInUnGiorno);
  //quelli saloni nuovi hanno un evento diverso
  if (differenzaInGiorni < 45) {
    Crisp.session.pushEvent('onboardingUser');
    localStorage.setItem('uf_onboardingUser', 'yes');
  } else {
    localStorage.setItem('uf_onboardingUser', 'no');
  }

  if (statSalone?.allPren > 10) {
    localStorage.setItem('uf_allpren', 'plus_10');
  } else {
    localStorage.setItem('uf_allpren', 'under_10');
  }

  (function (w, d, s) {
    var a = d.getElementsByTagName('head')[0];
    var r = d.createElement('script');
    r.async = 1;
    r.src = s;
    r.setAttribute('id', 'usetifulScript');
    r.dataset.token = '2671525f4402acd4b51353d545f209cc';
    a.appendChild(r);
  })(window, document, 'https://www.usetiful.com/dist/usetiful.js');
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ToastContainer
      position='top-right'
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      draggable
      pauseOnHover
      style={{ fontSize: '3rem' }}
      theme='light'
    />
    <BrowserRouter basename='/'>
      <App />
    </BrowserRouter>
  </Provider>,
);

reportWebVitals();
