import actionCreator from '../../../utils/actionCreator';

const AuthActionTypes = [
  'SIGN_IN',
  'FETCH_SALON',
  'LOG_OUT',
  'SET_ACTIVE_SALON',
  'EDIT_SALON_INFO',
  'EDIT_SALON_AVATAR',
  'EDIT_SALON_COVER',
  'EDIT_SALON_PASSWORD',
  'DELETE_SALON',
  'GET_ACTIVE_SIGN_UP_STEP',
  'SET_SALON_WORK_TIMES',
  'SET_SALON_SERVICES',
  'SET_SALON_WORKERS',
  'ACTIVE_PAYMENT',
  'SET_ID_COSTUMER_STRIPE',
  'CREATE_CUSTOMER',
  'SEND_EMAIL_RECOVERY',
  'RESET_PASSWORD',
];

export const { types: authTypes, actions: authActions } = actionCreator(AuthActionTypes);
