import React, { useEffect } from 'react';

import styles from './Table.module.scss';
import { useTable, usePagination } from 'react-table';
import { Button } from '@mui/material';
import { ReactComponent as ArrowRIcon } from '../../../assets/svg/icons/arrow_l.svg';
import { ReactComponent as ArrowLIcon } from '../../../assets/svg/icons/arrow_r.svg';
import clx from 'classnames';

const Table = ({
  columns = [],
  data = [],
  serverSidePagination = false,
  setPaginationValue,
  pageCount,
  showPageValue = true,
  emptyTableMessage = 'Non ci sono dati da mostrare',
  entityId,
  isMobile,
  pageRows=20
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    pageOptions,
    prepareRow,
    state: { pageIndex, pageSize },
    canPreviousPage,
    canNextPage,
    page,
    nextPage,
    previousPage,
    gotoPage,
    footerGroups,
  } = useTable(
    {
      columns,
      data,
      manualPagination: serverSidePagination,
      ...(serverSidePagination && { pageCount }),
      ...(pageRows && {initialState: {
        pageIndex: 0,
        pageSize: pageRows,
      }}),
    },
    usePagination,
  );

  useEffect(() => {
    if (serverSidePagination) {
      setPaginationValue({
        skip: pageRows * pageIndex,
        limit: pageRows,
      });
    }
  }, [serverSidePagination, pageIndex, pageSize]);

  useEffect(() => {
    gotoPage(0);
  }, [entityId]);

  return (
    <>
      <table
        {...getTableProps()}
        className={clx(styles.table_container, {
          [styles.table_container_mobile]: isMobile,
        })}
      >
        <thead align='left'>
          {headerGroups
            .filter(({ headers }) => headers?.some(({ Header }) => !!Header))
            .map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) =>
                  headerGroups.some((headerGroup) =>
                    headerGroup.headers.some((column) => !!column.render('Header')),
                  ) ? (
                    <th {...column.getHeaderProps()} className={styles.table_header}>
                      {column.render('Header')}
                    </th>
                  ) : (
                    <th
                      {...column.getHeaderProps()}
                      className={styles.table_header}
                      style={{
                        padding: '0px',
                      }}
                    />
                  ),
                )}
              </tr>
            ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.length === 0 && (
            <tr>
              <td className={styles.empty_table}>{emptyTableMessage}</td>
            </tr>
          )}
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td className={styles.table_cell} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          {footerGroups.map((group) => (
            <tr {...group.getFooterGroupProps()}>
              {group.headers.map((column) => (
                <td {...column.getFooterProps()}>{column.render('Footer')}</td>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
      <div
        className={clx(styles.pagination_wrapper, {
          [styles.pagination_wrapper_mobile]: isMobile,
        })}
      >
        <div className={styles.pagination_container}>
          {showPageValue && (
            <div className={styles.page_count}>
              Pagina {pageIndex + 1} di {pageOptions.length}
            </div>
          )}
          <div className={styles.arrow_block}>
            <Button
              disableElevation
              className={styles.arrow_button}
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              variant='contained'
              style={{ padding: '2px 3px 0px 0px' }}
            >
              <ArrowLIcon />
            </Button>
            <Button
              disableElevation
              className={styles.arrow_button}
              onClick={() => nextPage()}
              disabled={!canNextPage}
              variant='contained'
            >
              <ArrowRIcon />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
