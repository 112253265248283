import React from 'react';
import styles from './ServiceItem.module.scss';
import { salonManagementActions } from '../../../../store';
import { ROUTES } from '../../../../../../routes/constants';
import clx from 'classnames';
import { ReactComponent as EditIconInCircle } from '../../../../../../assets/svg/icons/editBlueCircle.svg';
import { ReactComponent as Bin } from '../../../../../../assets/svg/icons/trash_red.svg';
import { ReactComponent as ArrowLIcon } from '../../../../../../assets/svg/icons/arrow_l.svg';
import { ReactComponent as MenuIcon } from '../../../../../../assets/svg/icons/menu_gray.svg';
import { ReactComponent as RightIcon } from '../../../../../../assets/svg/icons/arrow_l.svg';

import HoveredInfoIcon from '../../../../../Common/HoveredInfoIcon';
import Switcher from '../../../../../Common/Switcher';
import { Button } from '@mui/material';
import ColorSelector from '../../../../../Common/CustomSelector/ColorSelector';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useWindowSize from '../../../../../../utils/useWindowSize';

export const ServiceItem = ({
  service,
  openEditServiceModal,
  colorsList,
  setServiceToDeleteId,
  isDragged,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();

  return (
    <details className={clx(styles.item, { [styles.item_mobile]: isMobile })} key={service.id}>
      <summary key={service.id} className={styles.item_summary}>
        <button
          data-movable-handle
          style={{
            background: 'transparent',
            border: 'none',
            cursor: isDragged ? 'grabbing' : 'grab',
          }}
          tabIndex={-1}
        >
          <MenuIcon
            style={{
              width: '20px',
              height: '20px',
            }}
          />
        </button>

        <div className={styles.item_summary_name}>{service.name}</div>
        <div className={styles.item_summary_icon}>
          <RightIcon />
        </div>
      </summary>
      <div className={styles.item_container}>
        <div className={styles.name_wrapper}>
          <div className={styles.info_cell}>
            <div className={styles.info_title}>
              Nome del servizio
              <EditIconInCircle onClick={() => openEditServiceModal(service)} />
            </div>
            <div className={styles.info_value}>{service.name}</div>
          </div>
        </div>
        <div className={styles.description_wrapper}>
          <div className={styles.info_cell}>
            <div className={styles.info_title}>
              Descrizione del servizio
              <EditIconInCircle onClick={() => openEditServiceModal(service)} />
            </div>
            <div className={styles.info_value}>{service.description}</div>
          </div>
        </div>
        <div className={styles.main_block_action_wrapper}>
          <div className={styles.title_wrapper}>
            <div className={styles.title}>Prenotabile in app</div>
            <HoveredInfoIcon description={'Prenotabile in app'} />
          </div>
          <Switcher
            value={service.onlyVisibile}
            setValue={(value) => {
              dispatch(
                salonManagementActions.EDIT_SERVICE.REQUEST({
                  idServizio: service.id,
                  onlyVisibile: !+value,
                  setToAll: true,
                }),
              );
            }}
            isFullWidth={isMobile}
          />
        </div>
        <div className={styles.color_wrapper}>
          <ColorSelector
            options={colorsList}
            value={service.color}
            setValue={(value) => {
              dispatch(
                salonManagementActions.EDIT_SERVICE.REQUEST({
                  idServizio: service.id,
                  color: value,
                }),
              );
            }}
            title={'Colore servizio'}
            isFullWidth={isMobile}
          />
        </div>
        <div
          className={styles.main_block_action_card}
          onClick={() =>
            navigate(`${ROUTES.SALON_MANAGEMENT}${ROUTES.SERVICES}${ROUTES.WORKERS}/${service.id}`)
          }
        >
          <div className={styles.main_block_action_card_info}>
            <div className={styles.action_card_info_title}>Poltrone</div>
            <div className={styles.action_card_info_value}>
              Visualizza le poltrone che svolgono questo servizio
            </div>
          </div>
          <div className={styles.main_block_action_card_action}>
            <div className={styles.arrow_icon}>
              <ArrowLIcon />
            </div>
          </div>
        </div>
        <div
          className={styles.main_block_action_card}
          onClick={() =>
            navigate(`${ROUTES.SALON_MANAGEMENT}${ROUTES.SERVICES}${ROUTES.ICONS}/${service.id}`)
          }
        >
          <div className={styles.main_block_action_card_info}>
            <div className={styles.action_card_info_title}>Icone</div>
            <div className={styles.action_card_info_value}>
              Scegli l’icona da visualizzare in App per il tuo servizio
            </div>
          </div>
          <div className={styles.main_block_action_card_action}>
            <div className={styles.arrow_icon}>
              <ArrowLIcon />
            </div>
          </div>
        </div>
        <div className={styles.duration_wrapper}>
          <div className={styles.info_cell}>
            <div className={styles.info_title}>
              Durata
              <EditIconInCircle onClick={() => openEditServiceModal(service)} />
            </div>
            <div className={styles.info_value}>{service.duration} Minuti</div>
          </div>
        </div>
        <div className={styles.price_wrapper}>
          <div className={styles.info_cell}>
            <div className={styles.info_title}>
              Prezzo del servizio
              <EditIconInCircle onClick={() => openEditServiceModal(service)} />
            </div>
            <div className={styles.info_value}>€ {parseFloat(service.cost).toFixed(2)}</div>
          </div>
        </div>
        <div
          className={clx(styles.disable_container, {
            [styles.enable_container]: service.disabled,
          })}
        >
          <div className={styles.title_wrapper}>
            <div className={styles.title}>Disabilita servizio</div>
            <HoveredInfoIcon
              description={`
              Disabilitando il servizio non sarà più possibile prenotarlo in app. 
            `}
            />
          </div>
          {!service.disabled && (
            <Button
              onClick={() => setServiceToDeleteId(service.id)}
              className={styles.delete_button}
            >
              <Bin />
              Disabilita
            </Button>
          )}
          {!!service.disabled && (
            <Button
              onClick={() =>
                dispatch(
                  salonManagementActions.EDIT_SERVICE.REQUEST({
                    disabilitato: 0,
                    idServizio: service.id,
                    setToAll: true,
                  }),
                )
              }
              variant='contained'
              className={styles.enable_button}
            >
              Abilita
            </Button>
          )}
        </div>
      </div>
    </details>
  );
};
