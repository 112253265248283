import { createSelector } from 'reselect';
import { getIconUrl, imageEntities } from '../../../utils/ImagesCreator';


const selectPaymentState = (state) => state.paymentReducer;

export const getPayments = () =>
  createSelector(selectPaymentState, (state) =>
    state.payments.map((payment) => ({
      ...payment,
      type: payment.rimborsabile ? 2 : 1,
      clientName: `${payment.cliente.nome} ${payment.cliente.cognome}`,
    })),
  );
export const getTotal = () => createSelector(selectPaymentState, ({total, balance, saldo, balanceRows}) => {return {total, balance, saldo, balanceRows}});

export const getBonifici = () => createSelector(selectPaymentState, (state) => state.bonifici);

