import actionCreator from '../../../utils/actionCreator';

const settingsActionTypes = [
  'CREATE_CUSTOMER_PORTAL',
  'GET_CUSTOMER_BILL',
  'GET_CUSTOMER_PLAN',
  'GET_CUSTOMER_INVOICE',
  'GET_CUSTOMER_PRODUCTS',
  'CREATE_PAYMENT_PORTAL',
  'ACCEPT_TERMS',
  'GET_ACCOUNTS_STAFF',
  'ADD_ACCOUNT_STAFF',
  'EDIT_ACCOUNT_STAFF',
  'DELETE_ACCOUNT_STAFF',
  'GET_MY_ACCOUNT_STAFF',
];

export const { types: settingsTypes, actions: settingsActions } =
  actionCreator(settingsActionTypes);
