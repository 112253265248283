import React from 'react';

import styles from "./ClientsQueueModal.module.scss";
import AvatarIcon from "../../../Common/AvatarIcon";
import {ReactComponent as WhatsappIcon} from '../../../../assets/svg/icons/whatsapp.svg';
import {ReactComponent as PhoneIcon} from '../../../../assets/svg/icons/chiamate.svg';
import {ReactComponent as Person} from '../../../../assets/svg/icons/profilo.svg';
import clx from "classnames";
import IconButton from "../../../Common/Buttons/IconButton";
import useWindowSize from "../../../../utils/useWindowSize";
import {modalActions} from "../../../Modals/store";
import {useDispatch} from "react-redux";

export default ({calendarQueue}) => {
  const dispatch = useDispatch();
  const {isMobile} = useWindowSize();

  const openClientModal = (id) => {
    dispatch(
      modalActions.OPEN_MODAL.SUCCESS({
        type: 'clientModal',
        props: {client_id: id},
      }),
    )
  }
  return (
    <div className={clx(styles.queue_container, {
      [styles.queue_container_mobile]: isMobile
    })}>
      {
        calendarQueue?.workers?.map(worker => {
          return (
            <div
              key={worker.id}
              className={clx(styles.worker_wrapper, {[styles['hide']]: !worker.clients.length})}
            >
              <div
                className={styles.worker_container}
              >
                <div className={styles.worker_info_container}>
                  <div className={styles.value_icon}>
                    <AvatarIcon
                      icon={worker.icon}
                      name={worker.name}
                      size={"33px"}
                    />
                  </div>
                  <div className={styles.option_value}>
                    {worker.name}
                  </div>
                  <span className={styles.menu_icon}>
                &#8942;
              </span>
                </div>
              </div>
              <div className={styles.clients_wrapper}>
                {
                  worker.clients.map(client => {
                    return (
                      <div
                        key={client.id}
                        className={styles.client_container}
                      >
                        <div className={styles.client_name_container}>
                          <div className={styles.value_icon}>
                            <AvatarIcon
                              icon={client.icon}
                              name={client.name}
                              size={"33px"}
                            />
                          </div>
                          <div className={styles.option_value}>
                            {client.name}
                          </div>
                        </div>
                        <div className={styles.client_social_container}>
                          <IconButton
                            svg={<WhatsappIcon/>}
                            type={'whatsapp'}
                            size={'38px'}
                            isActive={!!client.phone}
                            value={client.phone}
                            margin={'0 10px 0 0'}
                          />
                          <IconButton
                            svg={<PhoneIcon/>}
                            type={'phone'}
                            size={'38px'}
                            isActive={!!client.phone}
                            value={client.phone}
                            margin={'0 10px 0 0'}
                          />
                          <IconButton
                            svg={<Person/>}
                            type={'default'}
                            size={'38px'}
                            isActive={!!client.id}
                            onClick={() => openClientModal(client.id)}
                          />
                        </div>
                        <div
                          onClick={() => openClientModal(client.id)}
                          className={styles.client_actions_container}>
                          &#8942;
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>

          )
        })
      }
    </div>
  )
}
