import { salonManagementTypes } from './actions';

const initialState = {
  services: [],
  activeService: {
    activeWorkersIds: [],
  },
  workers: [],
  activeWorker: {
    activeServicesIds: [],
    workTimes: [],
    workerServices: [],
  },
  // salonWorkTimes: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case salonManagementTypes.GET_SERVICES.SUCCESS:
      return {
        ...state,
        services: action.payload,
      };
    case salonManagementTypes.GET_SERVICE.SUCCESS:
      return {
        ...state,
        activeService: action.payload,
      };
    case salonManagementTypes.CREATE_SERVICE.SUCCESS:
      return {
        ...state,
        services: [{ ...action.payload, isNew: true }, ...state.services],
      };
    case salonManagementTypes.EDIT_SERVICE.SUCCESS:
    case salonManagementTypes.DELETE_SERVICE.SUCCESS:
    case salonManagementTypes.ENABLE_SERVICE.SUCCESS:
      return {
        ...state,
        activeService: {
          ...state.activeService,
          ...action.payload,
        },
        services: state.services.map((service) =>
          +action.payload.idservezio === +service.idservezio
            ? {
                ...service,
                ...action.payload,
              }
            : service,
        ),
      };
    case salonManagementTypes.EDIT_SERVICE_WORKERS.SUCCESS:
      return {
        ...state,
        activeService: {
          ...state.activeService,
          activeWorkersIds: [
            ...state.activeService.activeWorkersIds.filter(
              (workerId) => !action.payload.workersIdsToDelete.includes(workerId),
            ),
            ...action.payload.activeWorkersIds,
          ],
        },
      };
    case salonManagementTypes.SET_SERVICE_WORKERS.SUCCESS:
      return {
        ...state,
        activeService: {
          ...state.activeService,
          activeWorkersIds: action.payload.activeWorkersIds,
        },
      };

    case salonManagementTypes.GET_SERVICE.CLEAR:
      return {
        ...state,
        activeService: {},
      };
    case salonManagementTypes.GET_WORKERS.SUCCESS:
      return {
        ...state,
        workers: action.payload,
      };
    case salonManagementTypes.CREATE_WORKER.SUCCESS:
      return {
        ...state,
        workers: [{ ...action.payload, isNew: true }, ...state.workers],
      };
    case salonManagementTypes.GET_WORKER.SUCCESS:
    case salonManagementTypes.GET_WORKER_WORK_TIMES.SUCCESS:
    case salonManagementTypes.SET_WORKER_WORK_TIMES.SUCCESS:
      return {
        ...state,
        activeWorker: {
          ...state.activeWorker,
          ...action.payload,
        },
      };
    case salonManagementTypes.GET_WORKER.CLEAR:
      return {
        ...state,
        activeWorker: {
          activeServicesIds: [],
          workTimes: [],
          workerIdForCopy: null,
        },
      };
    case salonManagementTypes.EDIT_WORKER.SUCCESS:
    case salonManagementTypes.DELETE_WORKER.SUCCESS:
    case salonManagementTypes.ENABLE_WORKER.SUCCESS:
      return {
        ...state,
        workers: state.workers.map((worker) =>
          +action.payload.idPoltrona === +worker.idpoltrona
            ? {
                ...worker,
                ...action.payload,
              }
            : worker,
        ),
      };
    case salonManagementTypes.EDIT_WORKER_AVATAR.SUCCESS:
      return {
        ...state,
        workers: state.workers.map((worker) =>
          +action.payload.idPoltrona === +worker.idpoltrona
            ? {
                ...worker,
                iconPoltrona: action.payload.avatarImg
                  ? action.payload.idPoltrona
                  : worker.iconPoltrona,
              }
            : worker,
        ),
      };
    case salonManagementTypes.SET_WORKER_SERVICES.SUCCESS:
      return {
        ...state,
        activeWorker: {
          ...state.activeWorker,
          activeServicesIds: action.payload.activeServicesIds,
        },
      };
    case salonManagementTypes.EDIT_WORKER_SERVICES.SUCCESS: {
        return {
          ...state,
          activeWorker: {
            ...state.activeWorker,
            activeServicesIds: [
              ...state.activeWorker.activeServicesIds.filter(
                (serviceId) => !action.payload.servicesIdsToDelete.includes(serviceId),
              ),
              ...action.payload.activeServicesIds,
            ],
            workerServices: [...state.activeWorker.workerServices, ...state.services.filter(service => action.payload.activeServicesIds.includes(service.idservezio)).map(service => ({ ...service, durata: service.time}))],
          },
          
        };
      }
      case salonManagementTypes.GET_WORKER_SERVICES.SUCCESS: 
        return {
          ...state,
          activeWorker: {
            ...state.activeWorker,
            workerServices: action.payload
          }
      };
      case salonManagementTypes.MOD_WORKER_SERVICE.SUCCESS: {
        let services = [...state.activeWorker.workerServices];
        let index = services.findIndex(service => service.idServiziPoltrona === action.payload.idServiziPoltrona);

        services[index]= {
          ...services[index], 
          prezzo: action.payload.prezzo ? action.payload.prezzo : services[index].prezzo,
          durata: action.payload.time ? action.payload.time : services[index].durata,
          disabilitato: action.payload.disabilitato || action.payload.disabilitato==0 ? action.payload.disabilitato : services[index].disabilitato,
          onlyVisibile: action.payload.onlyVisibile || action.payload.onlyVisibile==0 ? action.payload.onlyVisibile : services[index].onlyVisibile
        };

        return {
          ...state,
          activeWorker: {
            ...state.activeWorker,
            workerServices: services
          },
        };
      };
      case salonManagementTypes.DELETE_WORKER_SERVICE.SUCCESS: {
        let servizi = state.activeWorker.workerServices;
        let idservezio = servizi.find((service) => service.idServiziPoltrona === action.payload.idServiziPoltrona).idservezio;
        return {
          ...state,
          activeWorker: {
            ...state.activeWorker,
            workerServices: servizi.filter((service) => service.idServiziPoltrona !== action.payload.idServiziPoltrona),
            activeServicesIds: state.activeWorker.activeServicesIds.filter((service) => service !== idservezio)
          },
        };
      };
    case salonManagementTypes.SET_WORKER_CLOSURES.SUCCESS:
      return {
        ...state,
        activeWorker: {
          ...state.activeWorker,
          closures: action.payload,
        },
      };
    case salonManagementTypes.SET_WORKER_EXTRA_WORKING_TIME.SUCCESS:
      return {
        ...state,
        activeWorker: {
          ...state.activeWorker,
          extraWorkingTime: action.payload,
        },
      };
    case salonManagementTypes.DELETE_WORKER_CLOSURES.SUCCESS:
      return {
        ...state,
        activeWorker: {
          ...state.activeWorker,
          closures: state.activeWorker.closures.filter(
            (item) => item.data_chiusura !== action.payload,
          ),
        },
      };
    case salonManagementTypes.DELETE_WORKER_EXTRA_TIME.SUCCESS:
      return {
        ...state,
        activeWorker: {
          ...state.activeWorker,
          extraWorkingTime: state.activeWorker.extraWorkingTime.filter(
            (item) => item.idPoltronaOrari !== action.payload,
          ),
        },
      };
    case salonManagementTypes.SET_WORKERS_POSITION.SUCCESS:
      return {
        ...state,
        workers: state.workers.map((worker) => {
          const workerToChange = action.payload.find(
            ({ workerId }) => +workerId === +worker.idpoltrona,
          );
          if (workerToChange) {
            return {
              ...worker,
              ordinamento: workerToChange.position,
            };
          }
          return worker;
        }),
      };
    case salonManagementTypes.SET_SERVICES_POSITION.SUCCESS:
      return {
        ...state,
        services: state.services.map((service) => {
          const serviceToChange = action.payload.find(
            ({ serviceId }) => +serviceId === +service.idservezio,
          );
          if (serviceToChange) {
            return {
              ...service,
              ordinamento: serviceToChange.position,
            };
          }
          return service;
        }),
      };
    case salonManagementTypes.GET_SALON.SUCCESS:
      return {
        ...state,
        salonWorkTimes: action.payload,
      };
    // case salonManagementTypes.SET_SALON_WORK_TIMES.SUCCESS:
    //   return {
    //     ...state,
    //     salonWorkTimes: action.payload
    //   };
    default:
      return { ...state };
  }
};
