import { useCallback } from 'react';
import { getActiveSalonId, getActiveSalonToken } from './salonToken';
import { call } from 'redux-saga/effects';
import config from '../entries/config';

const whereExecuting = [];

export const useSSE = async (callback, refresh, updateWhere) => {
  var myHeaders = new Headers();
  const token = getActiveSalonToken();
  const id = getActiveSalonId();
  if (updateWhere && !location.href.toString().includes(updateWhere)) return;
  if (whereExecuting.includes(updateWhere)) return;
  whereExecuting.push(updateWhere);
  myHeaders.append('Authorization', token);
  // myHeaders.append('Content-Type', 'text/event-stream');
  // myHeaders.append('Cache-Control', 'no-cache');
  myHeaders.append('Connection', 'keep-alive');

  const url = config.sse_url + id;
  const eventsNewPrenotazione = new EventSource(url);
  eventsNewPrenotazione.onmessage = (event) => {
    if (!event || !event.data) return;
    const parsedData = JSON.parse(event.data);
    if (parsedData?.event && parsedData?.newEvent) callback(parsedData);
  };

  eventsNewPrenotazione.onerror = (event) => {
    console.log('Error on statusPrenotazioni', event);
  };
};
