import React from 'react';
import styles from "./FourthInfoStep.module.scss";
import ForwardButton from "../../customComponents/ForwardButton";
import {authActions} from "../../../../../store";
import {useDispatch} from "react-redux";

export const FourthInfoStepContainer = () => {
  const dispatch = useDispatch();

  return (
    <div className={styles.second_info_step_container}>
      <h2>
        Bene, hai creato con successo delle
        <br/>
        poltrone per il tuo salone,
        <br/>
        successivamente potrai <span className={styles.blue_inject}>gestire</span> gli
        <br/>
        <span className={styles.gold_inject}>e i servizi</span> delle singole
      </h2>
      <ForwardButton
        onAction={() => dispatch(authActions.GET_ACTIVE_SIGN_UP_STEP.SUCCESS(15))}
      />
    </div>
  )
}
