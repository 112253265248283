import styles from './CustomStaticPeriodDatePicker.module.scss';

import { LocalizationProvider } from '@mui/x-date-pickers1/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers1/AdapterDayjs';
import React from 'react';
import { StaticDatePicker } from '@mui/x-date-pickers1/StaticDatePicker';

import { PickersDay } from '@mui/x-date-pickers1';
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import dayjs from 'dayjs';

dayjs.extend(isBetweenPlugin);

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    background: 'linear-gradient(180deg, #E1E7FF 0%, #F2EBFF 100%) !important',
    color: '#6984FF',
    '&.Mui-disabled': {
      color: 'red',
    },
    '&:hover, &:focus': {
      background: '#858AF9',
      color: 'white',
    },
  }),
  ...(isFirstDay && {
    background: '#858AF9',
    color: 'white',
    '&:focus.Mui-selected': {
      color: 'white',
    },
    '&.Mui-selected': {
      color: 'white',
    },
  }),
  ...(isLastDay && {
    background: '#858AF9',
    color: 'white',
    '&:focus.Mui-selected': {
      color: 'white',
    },
    '&.Mui-selected': {
      color: 'white',
    },
  }),
}));

const muiTheme = createTheme({
  components: {
    MuiDayPicker: {
      styleOverrides: {
        header: {
          justifyContent: 'space-around',
        },
      },
    },

    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: {
          position: 'relative',
          margin: 'auto ',
          padding: 0,
        },
        labelContainer: {
          width: '200px',
          display: 'flex',
          justifyContent: 'center',
        },
        label: {
          fontSize: '17px',
          fontWeight: 400,
          '&:first-letter': {
            textTransform: 'capitalize',
          },
        },
      },
    },
    MuiPickersArrowSwitcher: {
      styleOverrides: {
        root: {
          left: '-50px',
          position: 'absolute',
        },
        spacer: {
          width: '250px',
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: '17px',
          lineHeight: '20px',
          color: '#787878',
          background: '#F5F5F5',
          minWidth: '36px',
          minHeight: '36px',
          margin: '4px',
          '&.Mui-disabled': {
            background: 'transparent',
          },
          '&:focus.Mui-selected': {
            background: 'transparent',
            color: '#787878',
          },
          '&.Mui-selected': {
            background: 'transparent',
            color: '#787878',
          },
          '&:hover': {
            background: '#e2e2e2',
          },
        },
      },
    },
  },
});

const CustomStaticPeriodDatePicker = ({
  period,
  setPeriod,
  error,
  touched,
  disabledDates = [],
}) => {
  const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
    const { start, end } = period;

    const isFirstDay = date.isSame(start, 'day');
    const isLastDay = date.isSame(end, 'day');
    const dayIsBetween = start && end && date.isBetween(start, end, null, '[]');

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };

  const periodCounter = (date) => {
    const { start, end } = period;
    const isSameDay = start && end && start.isSame(end, 'day');
    const startDayDiff = start && start.diff(date, 'day');
    const endDayDiff = end && end.diff(date, 'day');
    return {
      start: !start
        ? date
        : endDayDiff === 0 && startDayDiff === 0
        ? null
        : endDayDiff === 0
        ? end
        : (startDayDiff > 0 && isSameDay) ||
          (Math.abs(startDayDiff) <= Math.abs(endDayDiff) && !isSameDay)
        ? date
        : start,
      end: !end
        ? date
        : endDayDiff === 0 && startDayDiff === 0
        ? null
        : startDayDiff === 0
        ? start
        : (endDayDiff < 0 && isSameDay) ||
          (Math.abs(endDayDiff) < Math.abs(startDayDiff) && !isSameDay)
        ? date
        : end,
    };
  };
  return (
    <div className={styles.picker_wrapper}>
      {touched && error && <div className={styles.error_message}>{error}</div>}
      <ThemeProvider theme={muiTheme}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          // dateFormats={{ monthAndYear: 'yyyy MM' }}
        >
          <StaticDatePicker
            openTo='day'
            displayStaticWrapperAs='desktop'
            onDateChange={(date) => setPeriod(periodCounter(date))}
            disablePast={true}
            views={['day']}
            renderDay={renderWeekPickerDay}
            renderInput={() => null}
            onChange={() => {}}
            value={null}
            dayOfWeekFormatter={(day) => {
              return <div className={styles.day_name_value}>{day}</div>;
            }}
            shouldDisableDate={(date) =>
              disabledDates.find((disableDate) => date.isSame(disableDate, 'day'))
            }
            showDaysOutsideCurrentMonth={true}
          />
        </LocalizationProvider>
      </ThemeProvider>
    </div>
  );
};

export default CustomStaticPeriodDatePicker;
