import React, { useEffect, useState } from 'react';
import styles from './ServiceIconsList.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getService } from '../../../../store/selectors';

import SelectorMenu from '../../../../../Common/CustomSelector/SelectorMenu';
import { Button } from '@mui/material';
import { salonManagementActions } from '../../../../store';
import { useNavigate, useParams } from 'react-router-dom';
import { getActiveSalonId } from '../../../../../Auth/store/selectors';
import TitleSectionBack from '../../../../../Common/TitleSectionBack';
import AvatarIcon from '../../../../../Common/AvatarIcon';
import { getIconUrl, imageEntities } from '../../../../../../utils/ImagesCreator';
import useWindowSize from '../../../../../../utils/useWindowSize';
import clx from 'classnames';

const servicesIcons = [2, 3, 4, 5, 6, 7];

const ServiceIconsList = () => {
  const dispatch = useDispatch();
  const { serviceId } = useParams();
  const { isMobile } = useWindowSize();
  const navigate = useNavigate();
  const activeSalonId = useSelector(getActiveSalonId());
  const service = useSelector(getService());
  const [iconId, setIconId] = useState(service.iconaServizio);

  const formedServiceIcons = servicesIcons.map((iconId) => {
    return {
      name: '',
      value: iconId,
      icon: getIconUrl(iconId, imageEntities.icons),
    };
  });

  useEffect(() => {
    service.iconaServizio && setIconId(service.iconaServizio);
  }, [service.iconaServizio]);

  useEffect(() => {
    dispatch(salonManagementActions.GET_SERVICE.REQUEST(serviceId));
    return () => {
      dispatch(salonManagementActions.GET_SERVICE.CLEAR());
    };
  }, [serviceId]);

  useEffect(() => {
    if (service?.salone && service?.salone !== activeSalonId) {
      dispatch(salonManagementActions.GET_SERVICE.CLEAR());
      navigate(-1);
    }
  }, [service, activeSalonId]);

  const saveValue = () => {
    if (iconId && iconId !== service.iconaServizio) {
      dispatch(
        salonManagementActions.EDIT_SERVICE.REQUEST({
          idServizio: service.idservezio,
          iconaServizio: iconId,
        }),
      );
    }
  };

  return (
    <div
      className={clx(styles.service_icons_container, {
        [styles.service_icons_container_mobile]: isMobile,
      })}
    >
      <TitleSectionBack title={'Icone'} description={'Associa una icona alle tue poltrone'} />
      <div className={styles.name_cell_container}>
        <AvatarIcon name={service.nome} icon={service.icon} size={'60px'} />
        <div className={styles.client_info}>
          <div className={styles.description}>Stai associando il servizio</div>
          <div className={styles.name}>{service.nome}</div>
        </div>
      </div>
      <div className={styles.list_container}>
        <div className={styles.selector_header}>Icone</div>
        <SelectorMenu
          isMultiple={false}
          isMultipleOne={true}
          options={formedServiceIcons}
          setIsDropdownOpen={() => {}}
          setValue={setIconId}
          value={[iconId]}
          fullHeight={true}
          border={false}
        />
        <div className={styles.actions_container}>
          <Button
            onClick={saveValue}
            className={styles.confirm_button}
            variant='contained'
            type={'button'}
          >
            Salva
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ServiceIconsList;
