import {useDispatch} from "react-redux";
import Modal from "../../../../Common/Modal";
import styles from './AddNote.module.scss';
import { Button } from '@mui/material';
import React, {useState, useEffect} from 'react';
import {clientActions} from "../../../store";

const AddNote = ({client_id, isModalOpen, setIsModalOpen}) => {
    const dispatch = useDispatch();
    const [editedNota, setEditedNota] = useState("");

    const handleNoteChange = (event) => {
        setEditedNota(event.target.value);
    };

    useEffect(() => {
        setEditedNota("");
    }, []);
    return (
        <Modal
            // height={content ? '300px' : '230px'}
            isModalOpen={isModalOpen}
            setIsModalOpen={() => {
                setEditedNota("");
                setIsModalOpen(false)
            }}
            title={'Aggiungi nota'}
            maxWidth={'380px'}
            showCloseBtn={true}

        >
            <div className={styles.main}>
                <div className={styles.body}>
                    <div className={styles.description}>
                        <label>Descrizione</label>
                        <textarea cols="30" rows="10" placeholder="Scrivi qui la tua nota" value={editedNota} onChange={handleNoteChange}></textarea>
                    </div>
                </div>
                <div className={styles.footer}>
                    <Button
                        variant='contained'
                        className={styles.confirm_button}
                        onClick={() => {
                            dispatch(
                                clientActions.ADD_NOTE.REQUEST(
                                    {
                                        idcliente: client_id,
                                        nota: editedNota
                                    },() => {
                                        dispatch(clientActions.GET_CLIENT.REQUEST({ idcliente: client_id }, () => setIsModalOpen(false)));
                                        setEditedNota("");
                                    }
                                ),
                            )
                        }}
                    >
                        Salva
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export default AddNote;
