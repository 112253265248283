import {call, put, takeLatest} from 'redux-saga/effects';

import {Api} from '../../../entries/ApiTransport';
import sagaAssessor from '../../../utils/sagaAssessor';
import {statisticTypes, statisticActions} from './actions';

const api = Api.getInstance();

const getStatistic = () =>
  sagaAssessor(
    () =>
      function* () {
        const {data} = yield call(() => api.get('/business/statistic'));
        yield put(statisticActions.GET_STATISTIC.SUCCESS(data));
      },
    (err) => statisticActions.GET_STATISTIC.FAILED(err)
  );

export default function* () {
  yield takeLatest(statisticTypes.GET_STATISTIC.REQUEST, getStatistic);
}
