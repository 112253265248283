import React, { useEffect } from "react";
import dayjs from "dayjs";
import isBetweenPlugin from 'dayjs/plugin/isBetween';

dayjs.extend(isBetweenPlugin);
import CustomStaticPeriodDatePicker from "../../../../../Common/CustomStaticPeriodDatePicker";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import styles from "./ClosuresModal.module.scss";
import { Field, Form, Formik } from "formik";
import { getWorker, getWorkers } from "../../../../store/selectors";
import { Button } from "@mui/material";
import { salonManagementActions } from "../../../../store";
import SelectorMenu from "../../../../../Common/CustomSelector/SelectorMenu";
import clx from "classnames";
import { schedulerActions } from "../../../../../Scheduler/store";
import useWindowSize from "../../../../../../utils/useWindowSize";
import TextField from "../../../../../Common/TextField";

const findBlockedDates = (workers, activeWorkers = []) => {
  const filteredWorkers = workers.filter(worker => activeWorkers.includes(worker.id))
  const closuresDates = filteredWorkers
    .reduce((acc, worker) => {
      const workerClosuresDates = worker.closures?.map(({ data_chiusura }) => dayjs(data_chiusura).format("YYYY-MM-DD")) || []
      return [...acc, ...workerClosuresDates]
    }, [])

  const extraWorkingDates = filteredWorkers.reduce((acc, worker) => {
    const workerExtraWorkingDates = worker.extraWorkingTime?.reduce((acc, { dataInizio, dataFine, giorno }) => {
      const dif = dataInizio && dataFine && dayjs(dataFine).diff(dataInizio, 'day')
      if (dif >= 0) {
        const days = Array.from({ length: dif + 1 }, (_, i) => (
          dayjs(dataInizio).add(i, 'days').format("YYYY-MM-DD"))
        )
          .filter(day => dayjs(day).day() === +giorno) || []
        return [...acc, ...days]
      }
      return acc
    }, []) || []
    return [...acc, ...workerExtraWorkingDates]
  }, [])

  return [...new Set([...closuresDates, ...extraWorkingDates])]
}

const findBlockedWorkers = (workers, startDay) => {
  const closuresWorkers = workers
    .reduce((acc, worker) => {
      const workerClosure = worker.closures?.find(({ data_chiusura }) => dayjs(data_chiusura).isSame(startDay, 'day'))?.poltrona
      return workerClosure ? [...acc, workerClosure] : acc
    }, [])

  const extraWorkingTimeWorkers = workers.reduce((acc, worker) => {
    const workerExtraWorkingTime = worker.extraWorkingTime?.find(({ dataInizio, dataFine, giorno }) =>
      dataInizio &&
      dataFine &&
      +giorno === dayjs(startDay).day() &&
      dayjs(startDay).isBetween(dataInizio, dataFine, 'day', '[]')
    )?.idPoltrona
    return workerExtraWorkingTime ? [...acc, workerExtraWorkingTime] : acc
  }, [])

  return [...new Set([...closuresWorkers, ...extraWorkingTimeWorkers])]
}
const MOTIVATIONS = ['Festività', 'Ferie', 'Mancanza di servizi', 'Motivi personali']
export default (
  {
    closeModal,
    isOneDay,
    dayValue
  }
) => {
  const dispatch = useDispatch();
  const worker = useSelector(getWorker());
  const workers = useSelector(getWorkers());
  const { isMobile } = useWindowSize();

  const formedWorkers = workers
    .map(worker => ({
      name: worker.name,
      value: worker.id,
      icon: worker.icon
    }))

  useEffect(() => {
    dispatch(salonManagementActions.GET_WORKERS.REQUEST({ query: { workTime: true } }))
  }, [])

  const initialValues = {
    datePeriod: {
      start: isOneDay ? dayjs(dayValue.startDay) : null,
      end: isOneDay ? dayjs(dayValue.startDay) : null,
      blockedDates: isOneDay ? [] : findBlockedDates(workers, [worker?.idpoltrona]),
    },
    motivation: '',
    activeWorkersIds: worker?.idpoltrona ? [worker?.idpoltrona] : [],
    blockedWorkers: isOneDay ? findBlockedWorkers(workers, dayValue.startDay) : []
  };
  const validationSchema = {
    datePeriod: yup.object().shape({
      start: yup.date().typeError("Select date").required(),
      end: yup.date().typeError("Select date").required(),
    })
      .test("checkDate", "This date already taken", (val) => {
        const { start, end, blockedDates } = val
        const matchedDate = blockedDates.find(closureDate => start && end && dayjs(closureDate).isBetween(start, end, null, '[]'))
        return !matchedDate
      }),

  };

  return (
    <div className={clx(styles.worker_closures_container, {
      [styles.worker_closures_container_one_day]: isOneDay,
      [styles.worker_closures_container_mobile]: isMobile
    })}>

      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={yup.object().shape(validationSchema)}
        onSubmit={(values) => {
          const req = {
            idPoltrona: worker.idpoltrona,
            activeWorkersIds: values.activeWorkersIds,
            dataChiusura: values.datePeriod?.start.format("YYYY-MM-DD"),
            dataChiusuraFine: values.datePeriod?.end.format("YYYY-MM-DD"),
            tipo_chiusura: MOTIVATIONS.findIndex(i => i === values?.motivation) + 1,
          }

          if (isOneDay) {
            return dispatch(schedulerActions.SET_CALENDAR_WORKER_CLOSURES.REQUEST(req, closeModal))
          }
          return dispatch(salonManagementActions.SET_WORKER_CLOSURES.REQUEST(req, closeModal))

        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form className={styles.closures_modal_wrapper}>
            <div className={styles.body} >

              {/* prenotazione */}
              <div className={styles.reverse_column}>
                <div className={styles.block}>
                  <div className={styles.range_picker_container}>
                    <div className={styles.date_selector_title}>Seleziona il periodo</div>
                    <div style={{ width: 'fit-content' }}>
                      <CustomStaticPeriodDatePicker
                        period={values.datePeriod}
                        setPeriod={(period) => setFieldValue('datePeriod', { ...values.datePeriod, ...period })}
                        error={errors.datePeriod?.start || errors.datePeriod?.end || errors.datePeriod}
                        touched={touched.datePeriod}
                        disabledDates={values.datePeriod.blockedDates}
                      />
                    </div>
                  </div>
                  <div className={styles.motivation__menu}>
                    <div className={styles.motivation__menu_title}>
                      Motivazione <span> (Facoltativa)</span>
                    </div>
                    <SelectorMenu
                      isMultipleOne={true}
                      fullHeight={true}
                      setIsDropdownOpen={() => {
                      }}
                      options={MOTIVATIONS.map((i) => ({ name: i, value: i }))}
                      setValue={(value) => {
                        setFieldValue('motivation', value)
                      }}
                      value={values.motivation}
                      error={touched.motivation && errors.motivation}
                      blockedValues={values.blockedWorkers}
                    />
                  </div>

                </div>

                {/* poltrona */}
                <div className={styles.workers__menu}>
                  <div className={styles.workers__menu_title}>
                    Poltrone
                  </div>
                  <SelectorMenu
                    isMultiple={true}
                    fullHeight={true}
                    setIsDropdownOpen={() => {
                    }}
                    options={formedWorkers}
                    setValue={(value) => {
                      setFieldValue('activeWorkersIds', value)
                      setFieldValue('datePeriod.blockedDates', findBlockedDates(workers, value))
                    }}
                    value={values.activeWorkersIds}
                    error={touched.activeWorkersIds && errors.activeWorkersIds}
                    blockedValues={values.blockedWorkers}
                  />
                </div>
              </div>

              {/* Commento */}
              <div className={styles.comment}>
                <div className={styles.comment__title}>Commento<span> (Facoltativo)</span></div>
                <Field
                  name={"note"}
                  id={'note'}
                  textarea={true}
                  placeHolder="Campo di testo da compilare"
                  height="100px"
                  className={styles.input}
                  component={TextField}
                />
              </div>
            </div>
            <hr className={styles.line} />
            <div className={styles.footer}>
              <div className={styles.actions_container}>
                <Button
                  className={styles.confirm_button}
                  variant="contained"
                  type={'submit'}
                >
                  Salva
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>

  );
}