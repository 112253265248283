import React, { useEffect } from 'react';
import { getActiveSalonePaymentInfo } from 'utils/salonToken';
import PaymentList from '../PaymentList';
import { ROUTES } from 'routes/constants';
import { useNavigate } from 'react-router-dom';

const BlankPage = () => {
  const disabled_payments = getActiveSalonePaymentInfo();
  const navigate = useNavigate();
  const pdfLinkDrive =
    'https://drive.google.com/file/d/1EhoMwHAQeXRijTRabxesHIn6p5wHcm-Q/view?usp=sharing';

  useEffect(()=>{
    if (disabled_payments === 1)
      navigate(`${ROUTES.PAYMENTS}${ROUTES.INFORMATIONS}`);
  }, [disabled_payments])

  return (
    <PaymentList />
  );
};

export default BlankPage;
