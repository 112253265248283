import clx from 'classnames';
import { Button } from '@mui/material';
import React from 'react';
import styles from './FilterButtons.module.scss';
import useWindowSize from '../../../utils/useWindowSize';

export default ({ filtersButtons, activeFilterId, setActiveFilterId }) => {
  const {isMobile}= useWindowSize()
  return (
    <div className={clx(styles.buttons_container, {
      [styles.buttons_container_mobile]:isMobile
    })}>
      {filtersButtons.map((button) => {
        return (
          <Button
            className={clx(styles.filter_button, {
              [styles.active_filter_button]: button.id === activeFilterId,
            })}
            key={button.id}
            onClick={() => setActiveFilterId(button.id)}
            variant='contained'
          >
            <div className={styles.button_value}>{button.name}</div>
          </Button>
        );
      })}
    </div>
  );
};
