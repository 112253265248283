import actionCreator from '../../../utils/actionCreator';

const schedulerActionTypes = [
  'GET_CALENDAR_DATA',
  'EDIT_CALENDAR_TASK',
  'ADD_CALENDAR_TASK',
  'ADD_CALENDAR_BREAK',
  'DELETE_CALENDAR_TASK',
  'SET_CALENDAR_TASK',
  'GET_CALENDAR_QUEUE',
  'GET_CALENDAR_SLOTS',
  'SET_CALENDAR_WORKER_EXTRA_WORKING_TIME',
  'SET_CALENDAR_WORKER_CLOSURES',
  'GET_DETAILS_PRENOTAZIONE_RICORRENTE',
];

export const { types: schedulerTypes, actions: schedulerActions } =
  actionCreator(schedulerActionTypes);
