import clx from 'classnames';

import styles from './IconButton.module.scss';
import { useRef } from 'react';

const IconButton = ({
  size = '25px',
  padding = '5px',
  svg,
  onClick,
  type = 'default',
  value,
  isActive = true,
  background = '',
  margin = '0 0 0 0',
}) => {
  const fromApp = localStorage.getItem('fromApp') == 'true';

  const handleIconClick = (e) => {
    switch (type) {
      case 'default': {
        onClick();
        break;
      }
      case 'phone': {
        e.preventDefault();
        if (isActive) {
          window?.flutter_inappwebview?.callHandler("app:phone", value, "")
          if (!fromApp)
            window.location.href = `tel:${value.replace(/\D/g, "")}`;
        }
        break;
      }
      case 'email': {
        e.preventDefault();
        if (isActive) {
          window?.flutter_inappwebview?.callHandler("app:mail", value)
          if (!fromApp)
            window.location.href = `mailto:${value}`;
        }
        break;
      }
      case 'whatsapp': {
        e.preventDefault();
        if (isActive) {
          const baseUrl = 'https://wa.me/'
          window?.flutter_inappwebview?.callHandler("app:whatsapp", value.replace(/\D/g, ""), "")
          if (!fromApp)
            window?.open(`${baseUrl}${value.replace(/\D/g, "")}`, '_blank');
        }
        break;
      }
      case 'instagram': {
        e.preventDefault();
        if (isActive) {
          //window?.flutter_inappwebview?.callHandler("app:mail", value)
          if (!fromApp)
            window.open(`https://www.instagram.com/zetabarber.it/`, '_blank');
        }
        break;
      }
    }
  };

  return (
    <button
      role='button'
      style={{
        height: size,
        width: size,
        padding,
        background,
        margin,
      }}
      className={styles.icon__btn}
      onClick={(e) => handleIconClick(e)}
      disabled={!isActive}
    >
      {svg}
    </button>
  );
};

export default IconButton;
