import { shopActions, shopTypes } from './actions';

const initialState = {
  shopToken: '',
  error: '',
};

export default (state = initialState, action) => {

  switch (action.type) {
    case shopTypes.GET_TOKEN_SHOP.SUCCESS:
      return {
        ...state,
        shopToken: action.payload?.key
      };
    case shopTypes.GET_TOKEN_SHOP.FAILED:
      return {
        ...state,
        error: action.payload
      };
    default:
      return { ...state };
  }
};
