import useOutsideClick from '../../utils/useOutsideClick';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSalons, getActiveSalon } from '../Auth/store/selectors';
import styles from './Header.module.scss';
import { authActions } from '../Auth/store';
import { ReactComponent as Bell } from '../../assets/svg/icons/campanella.svg';
import { ReactComponent as Menu } from '../../assets/svg/icons/menu.svg';
import Logout from '../../assets/svg/icons/esci.svg';

import clx from 'classnames';
import SelectorMenu from '../Common/CustomSelector/SelectorMenu';
import AvatarIcon from '../Common/AvatarIcon';
import useWindowSize from '../../utils/useWindowSize';
import ConfirmModal from '../Common/ConfirmModal';
import { useSearchParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

const Header = ({ setSideBarIsOpen, isCalendar = false }) => {
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const dropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams({
    logout: 0,
  });
  const salons = useSelector(getSalons());
  const activeSalon = useSelector(getActiveSalon());
  const setValue = (salonId) => {
    if (salonId === -1) {
      setIsModalOpen(true);
      setSearchParams({ logout: 1 });
      window?.flutter_inappwebview?.callHandler('app:logout');
      return;
    }
    dispatch(authActions.SET_ACTIVE_SALON.SUCCESS(salonId));
  };
  useOutsideClick(dropdownRef, () => {
    if (isDropdownOpen) setIsDropdownOpen(false);
  });
  return (
    <>
      <ConfirmModal
        isModalOpen={!!+searchParams.get('logout')}
        setIsModalOpen={setSearchParams}
        title={"Vuoi uscire dall'account zetabarber?"}
        action={() => {
          localStorage.clear();
          window.location.href = ROUTES.HOME;
        }}
      />
      <div
        className={clx(styles.header_wrapper, {
          [styles['header_wrapper_mobile']]: isMobile,
          [styles['header_wrapper_calendar']]: isCalendar,
          [styles['header_wrapper_calendar_mobile']]: isCalendar && isMobile,
        })}
      >
        <div className={styles.menu_container}>
          <Menu onClick={() => setSideBarIsOpen((isOpen) => !isOpen)} />
        </div>
        <div className={styles.info_container}>
          {/* <div className={styles.notification_icon_container}>
          <Bell/>
        </div>
        <div className={styles.notification_icon_container_mobile}>
          <Bell/>
        </div> */}
          <div
            ref={dropdownRef}
            className={clx(styles.salon_wrapper, {
              [styles['salon_wrapper_closed']]: !isDropdownOpen,
            })}
          >
            <div
              onClick={() => setIsDropdownOpen((isOpen) => !isOpen)}
              className={styles.salon_container}
            >
              <AvatarIcon icon={activeSalon.icon} name={activeSalon.name} size={'30px'} />
              <div className={styles.salon_name}>{activeSalon.name}</div>
              <div className={styles.arrow} />
            </div>
            <div className={styles.dropdown__menu}>
              <SelectorMenu
                isMultiple={false}
                setIsDropdownOpen={() => setIsDropdownOpen(false)}
                options={[...salons, { value: -1, name: 'Esci', icon: Logout, size: 30 }]}
                setValue={setValue}
                value={[activeSalon.value]}
                width='230px'
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
