import { call, put, takeLatest, select } from 'redux-saga/effects';

import { Api } from '../../../entries/ApiTransport';
import sagaAssessor from '../../../utils/sagaAssessor';
import { salonManagementTypes, salonManagementActions } from './actions';
import { modalActions } from '../../Modals/store';
import { objectToQuery } from '../../../helpers';
import { toastActions } from 'Containers/Toasts/store';

const api = Api.getInstance();

const getServices = () =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.get('/business/servizi/get'));
        yield put(salonManagementActions.GET_SERVICES.SUCCESS(data.servizi));
      },
    (err) => salonManagementActions.GET_SERVICES.FAILED(err),
  );

const getService = ({ payload }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.get(`/business/servizi/get/${payload}`));
        yield put(salonManagementActions.GET_SERVICE.SUCCESS(data.service));
      },
    (err) => salonManagementActions.GET_SERVICE.FAILED(err),
  );

const createService = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.post('/business/servizi/add', payload));
        const res = {
          ...payload,
          idservezio: data.id,
          ordinamento: 0,
          iconPoltrona: 1,
        };
        yield put(salonManagementActions.CREATE_SERVICE.SUCCESS(res));

        callback?.(res);
        yield put(
          toastActions.OPEN_TOAST.SUCCESS({
            type: 'service',
            props: { title: 'Hai aggiunto un nuovo servizio!' },
          }),
        );
      },
    (err) => salonManagementActions.CREATE_SERVICE.FAILED(err),
  );

const editService = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        yield call(() => api.post('/business/servizi/modServizio', payload));

        const service = {};

        if ('idServizio' in payload) service.idservezio = payload.idServizio;
        if ('nome' in payload) service.nome = payload.nome;
        if ('onlyVisibile' in payload) service.onlyVisibile = payload.onlyVisibile;
        if ('descrizione' in payload) service.descrizione = payload.descrizione;
        if ('color' in payload) service.color = payload.color;
        if ('prezzo' in payload) service.prezzo = payload.prezzo;
        if ('time' in payload) service.time = payload.time;
        if ('disabilitato' in payload) service.disabilitato = payload.disabilitato;
        if ('iconaServizio' in payload) service.iconaServizio = payload.iconaServizio;
        if ('setToAll' in payload) service.setToAll = payload.setToAll;

        yield put(salonManagementActions.EDIT_SERVICE.SUCCESS(service));
        callback && typeof callback === 'function' && callback();
        /* yield put(modalActions.OPEN_MODAL.SUCCESS({
          type: 'successModal',
          props: { title: 'Impostazioni salvate con successo' }
        })); */
        yield put(
          toastActions.OPEN_TOAST.SUCCESS({
            type: 'service',
            props: { title: 'Impostazioni salvate con successo!' },
          }),
        );
      },
    (err) => salonManagementActions.EDIT_SERVICE.FAILED(err),
  );

const editServiceWorkers = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        yield call(() => api.post('/business/servizi/modServizio', payload));
        yield put(salonManagementActions.EDIT_SERVICE_WORKERS.SUCCESS(payload));
        callback && typeof callback === 'function' && callback();
        /* yield put(modalActions.OPEN_MODAL.SUCCESS({
          type: 'successModal',
          props: { title: 'Impostazioni salvate con successo' } //snackbar
        })); */
        yield put(
          toastActions.OPEN_TOAST.SUCCESS({
            type: 'service',
            props: { title: 'Impostazioni salvate con successo!' },
          }),
        );
      },
    (err) => salonManagementActions.EDIT_SERVICE_WORKERS.FAILED(err),
  );

const deleteService = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        yield call(() => api.post('/business/servizi/modServizio', payload));
        const { idServizio, ...rest } = payload;
        const service = {
          ...rest,
          idservezio: idServizio,
        };
        yield put(salonManagementActions.DELETE_SERVICE.SUCCESS(service));
        callback && typeof callback === 'function' && callback();
        yield put(
          toastActions.OPEN_TOAST.SUCCESS({
            type: 'service',
            props: { title: 'Servizio disabilitato!', warning: true },
          }),
        );
      },
    (err) => salonManagementActions.DELETE_SERVICE.FAILED(err),
  );

const enableService = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        yield call(() => api.post('/business/servizi/modServizio', payload));
        const { idServizio, ...rest } = payload;
        const service = {
          ...rest,
          idservezio: idServizio,
        };
        yield put(salonManagementActions.DELETE_SERVICE.SUCCESS(service));
        callback && typeof callback === 'function' && callback();
        yield put(
          toastActions.OPEN_TOAST.SUCCESS({
            type: 'service',
            props: { title: 'Servizio abilitato con successo!' },
          }),
        );
      },
    (err) => salonManagementActions.DELETE_SERVICE.FAILED(err),
  );

const getWorkers = ({ payload }) =>
  sagaAssessor(
    () =>
      function* () {
        const query = objectToQuery(payload?.query || '');
        const { data } = yield call(() => api.get(`/business/poltrone/getPoltrone${query}`));
        yield put(salonManagementActions.GET_WORKERS.SUCCESS(data.poltrone));
      },
    (err) => salonManagementActions.GET_WORKERS.FAILED(err),
  );

const getWorker = ({ payload }) =>
  sagaAssessor(
    () =>
      function* () {
        const {
          data: { worker },
        } = yield call(() => api.get(`/business/poltrone/getPoltrone/${payload}`));
        yield put(salonManagementActions.GET_WORKER.SUCCESS(worker));
      },
    (err) => salonManagementActions.GET_WORKER.FAILED(err),
  );

const getWorkerWorkTimes = ({ payload }) =>
  sagaAssessor(
    () =>
      function* () {
        const {
          data: { orari },
        } = yield call(() => api.post(`/business/poltrone/getOrari`, { idPoltrona: payload }));
        yield put(
          salonManagementActions.GET_WORKER_WORK_TIMES.SUCCESS({
            workTimes: orari,
            workerIdForCopy: payload,
          }),
        );
      },
    (err) => salonManagementActions.GET_WORKER_WORK_TIMES.FAILED(err),
  );

const createWorker = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.post('/business/poltrone/add', payload));

        const res = {
          ...payload,
          idpoltrona: data.id,
          ordinamento: 0,
          nome_referente: payload.nomeReferente,
        };
        yield put(salonManagementActions.CREATE_WORKER.SUCCESS(res));

        callback && typeof callback === 'function' && callback();
        yield put(
          toastActions.OPEN_TOAST.SUCCESS({
            type: 'worker',
            props: { title: 'Hai aggiunto una nuova poltrona!' },
          }),
        );
      },
    (err) => salonManagementActions.CREATE_WORKER.FAILED(err),
  );

const editWorker = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        yield call(() => api.post('/business/poltrone/modPoltrona', payload));

        const worker = {};
        if ('idPoltrona' in payload) worker.idPoltrona = payload.idPoltrona;
        if ('nomeReferente' in payload) worker.nome_referente = payload.nomeReferente;
        if ('onlyApp' in payload) worker.onlyApp = payload.onlyApp;
        if ('visible' in payload) worker.visible = payload.visible;
        if ('descrizionePoltrona' in payload)
          worker.descrizionePoltrona = payload.descrizionePoltrona;
        if ('disabilitato' in payload) worker.disabilitato = payload.disabilitato;

        yield put(salonManagementActions.EDIT_WORKER.SUCCESS(worker));
        callback && typeof callback === 'function' && callback();
        /* yield put(modalActions.OPEN_MODAL.SUCCESS({
          type: 'successModal',
          props: { title: 'Impostazioni salvate con successo' } //snackbar
        })); */
        yield put(
          toastActions.OPEN_TOAST.SUCCESS({
            type: 'worker',
            props: { title: 'Impostazioni salvate con successo!' },
          }),
        );
      },
    (err) => salonManagementActions.EDIT_WORKER.FAILED(err),
  );

const editWorkerAvatar = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        yield call(() => api.post('/business/poltrone/modPoltrona', payload));
        yield put(salonManagementActions.EDIT_WORKER_AVATAR.SUCCESS(payload));
        callback && typeof callback === 'function' && callback();
        /* yield put(modalActions.OPEN_MODAL.SUCCESS({
          type: 'successModal',
          props: { title: 'Impostazioni salvate con successo' } //snackbar
        })); */
        yield put(
          toastActions.OPEN_TOAST.SUCCESS({
            type: 'worker',
            props: { title: 'Impostazioni salvate con successo!' },
          }),
        );
      },
    (err) => salonManagementActions.EDIT_WORKER_AVATAR.FAILED(err),
  );

const editWorkerServices = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        yield call(() => api.post('/business/poltrone/modPoltrona', payload));
        yield put(salonManagementActions.EDIT_WORKER_SERVICES.SUCCESS(payload));
        callback && typeof callback === 'function' && callback();
        /* yield put(modalActions.OPEN_MODAL.SUCCESS({
          type: 'successModal',
          props: { title: 'Impostazioni salvate con successo' } 
        })); */
        yield put(
          toastActions.OPEN_TOAST.SUCCESS({
            type: 'worker',
            props: { title: 'Impostazioni salvate con successo!' },
          }),
        );
      },
    (err) => salonManagementActions.EDIT_WORKER_SERVICES.FAILED(err),
  );

const deleteWorker = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        yield call(() => api.post('/business/poltrone/modPoltrona', payload));
        const worker = {
          idPoltrona: payload.idPoltrona,
          disabilitato: payload.disabilitato,
        };
        yield put(salonManagementActions.DELETE_WORKER.SUCCESS(worker));
        callback && typeof callback === 'function' && callback();
        yield put(
          toastActions.OPEN_TOAST.SUCCESS({
            type: 'worker',
            props: { title: 'Poltrona disabilitata!', warning: true },
          }),
        );
      },
    (err) => salonManagementActions.DELETE_WORKER.FAILED(err),
  );

const enableWorker = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        yield call(() => api.post('/business/poltrone/modPoltrona', payload));
        const worker = {
          idPoltrona: payload.idPoltrona,
          disabilitato: payload.disabilitato,
        };
        yield put(salonManagementActions.ENABLE_WORKER.SUCCESS(worker));
        callback && typeof callback === 'function' && callback();
        yield put(
          toastActions.OPEN_TOAST.SUCCESS({
            type: 'worker',
            props: { title: 'Poltrona abilitata con successo!' },
          }),
        );
      },
    (err) => salonManagementActions.ENABLE_WORKER.FAILED(err),
  );

const setWorkerWorkTimes = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const req = {
          orari: payload.workTimes,
          idPoltrona: payload.workerIdForCopy,
        };
        yield call(() => api.post('/business/poltrone/updateOrariPoltrona', req));
        yield put(
          salonManagementActions.SET_WORKER_WORK_TIMES.SUCCESS({
            workTimes: payload.workTimes,
            workerIdForCopy: payload.workerIdForCopy,
          }),
        );
        callback && typeof callback === 'function' && callback();
        yield put(
          toastActions.OPEN_TOAST.SUCCESS({
            type: 'worker',
            props: { title: 'Orari modificati con successo!' },
          }),
        );
      },
    (err) => salonManagementActions.SET_WORKER_WORK_TIMES.FAILED(err),
  );

const setWorkerClosures = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const {
          data: { closures },
        } = yield call(() => api.post('/business/poltrone/addChiusuraMultiply', payload));
        yield put(salonManagementActions.SET_WORKER_CLOSURES.SUCCESS(closures));
        callback && typeof callback === 'function' && callback();
        yield put(
          toastActions.OPEN_TOAST.SUCCESS({
            type: 'worker',
            props: { title: 'Chiusura straordinaria aggiunta con successo!' },
          }),
        );
      },
    (err) => salonManagementActions.SET_WORKER_CLOSURES.FAILED(err),
  );

const setWorkerExtraWorkingTime = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const {
          data: { extraWorkingTime },
        } = yield call(() => api.post('/business/poltrone/addOrarioMultiply', payload));
        yield put(salonManagementActions.SET_WORKER_EXTRA_WORKING_TIME.SUCCESS(extraWorkingTime));
        callback && typeof callback === 'function' && callback();
        yield put(
          toastActions.OPEN_TOAST.SUCCESS({
            type: 'worker',
            props: { title: 'Apertura straordinaria aggiunta con successo!' },
          }),
        );
      },
    (err) => salonManagementActions.SET_WORKER_EXTRA_WORKING_TIME.FAILED(err),
  );

const deleteWorkerClosures = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        yield call(() => api.post('/business/poltrone/deleteChiusura', payload));
        yield put(salonManagementActions.DELETE_WORKER_CLOSURES.SUCCESS(payload.data));
        callback && typeof callback === 'function' && callback();
        yield put(
          toastActions.OPEN_TOAST.SUCCESS({
            type: 'worker',
            props: { title: 'Chiusura straordinaria rimossa!', warning: true },
          }),
        );
      },
    (err) => salonManagementActions.DELETE_WORKER_CLOSURES.FAILED(err),
  );

const deleteWorkerExtraTime = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        yield call(() => api.post('/business/poltrone/deleteOrario', payload));
        yield put(salonManagementActions.DELETE_WORKER_EXTRA_TIME.SUCCESS(payload.idPoltronaOrari));
        callback && typeof callback === 'function' && callback();
        yield put(
          toastActions.OPEN_TOAST.SUCCESS({
            type: 'worker',
            props: { title: 'Apertura straordinaria rimossa!', warning: true },
          }),
        );
      },
    (err) => salonManagementActions.DELETE_WORKER_EXTRA_TIME.FAILED(err),
  );

const changeWorkersPosition = ({ payload }) =>
  sagaAssessor(
    () =>
      function* () {
        yield call(() => api.post('/business/poltrone/sort', { workersToSort: payload }));
        yield put(salonManagementActions.SET_WORKERS_POSITION.SUCCESS(payload));
        yield put(
          toastActions.OPEN_TOAST.SUCCESS({
            type: 'worker',
            props: { title: 'Impostazioni modificate con successo!' },
          }),
        );
      },
    (err) => salonManagementActions.SET_WORKERS_POSITION.FAILED(err),
  );

const changeServicesPosition = ({ payload }) =>
  sagaAssessor(
    () =>
      function* () {
        yield call(() => api.post('/business/servizi/sort', { servicesToSort: payload }));
        yield put(salonManagementActions.SET_SERVICES_POSITION.SUCCESS(payload));
        yield put(
          toastActions.OPEN_TOAST.SUCCESS({
            type: 'service',
            props: { title: 'Impostazioni modificate con successo!' },
          }),
        );
      },
    (err) => salonManagementActions.SET_SERVICES_POSITION.FAILED(err),
  );

const getWorkerServices = ({ payload }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.get(`/business/poltrone/getServiziPoltrona/${payload}`));
        yield put(salonManagementActions.GET_WORKER_SERVICES.SUCCESS(data.serviziPoltrona));
      },
    (err) => salonManagementActions.GET_WORKER_SERVICES.FAILED(err),
  );
const modWorkerService = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        yield call(() => api.post('/business/poltrone/modServizioPoltrona', payload));
        yield put(salonManagementActions.MOD_WORKER_SERVICE.SUCCESS(payload));
        yield put(
          toastActions.OPEN_TOAST.SUCCESS({
            type: 'worker',
            props: { title: 'Servizio modificato!' },
          }),
        );
        callback?.();
      },
    (err) => salonManagementActions.MOD_WORKER_SERVICE.FAILED(err),
  );
const delWorkerService = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        yield call(() => api.post('/business/poltrone/deleteServizioPoltrona', payload));
        yield put(salonManagementActions.DELETE_WORKER_SERVICE.SUCCESS(payload));
        callback?.();
        yield put(
          toastActions.OPEN_TOAST.SUCCESS({
            type: 'worker',
            props: { title: 'Servizio disabilitato!' },
          }),
        );
      },
    (err) => salonManagementActions.DELETE_WORKER_SERVICE.FAILED(err),
  );
  

const getSalon = () =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.get('/business/saloni/getOrariSalone'));
        yield put(salonManagementActions.GET_SALON.SUCCESS(data.orari));
      },
    (err) => salonManagementActions.GET_SALON.FAILED(err),
  );

// const setSalonWorkTimes = ({payload, callback}) =>
//   sagaAssessor(
//     () =>
//       function* () {
//         yield call(() => api.post('/business/saloni/updateOrariSalone', {orari: payload}));
//         yield put(salonManagementActions.SET_SALON_WORK_TIMES.SUCCESS(payload));
//         callback && typeof callback === 'function' && callback();
//       },
//     (err) => salonManagementActions.SET_SALON_WORK_TIMES.FAILED(err)
//   );

export default function* () {
  yield takeLatest(salonManagementTypes.GET_SERVICES.REQUEST, getServices);
  yield takeLatest(salonManagementTypes.GET_SERVICE.REQUEST, getService);
  yield takeLatest(salonManagementTypes.CREATE_SERVICE.REQUEST, createService);
  yield takeLatest(salonManagementTypes.EDIT_SERVICE.REQUEST, editService);
  yield takeLatest(salonManagementTypes.EDIT_SERVICE_WORKERS.REQUEST, editServiceWorkers);
  yield takeLatest(salonManagementTypes.DELETE_SERVICE.REQUEST, deleteService);
  yield takeLatest(salonManagementTypes.ENABLE_SERVICE.REQUEST, enableService);
  yield takeLatest(salonManagementTypes.GET_WORKERS.REQUEST, getWorkers);
  yield takeLatest(salonManagementTypes.GET_WORKER.REQUEST, getWorker);
  yield takeLatest(salonManagementTypes.CREATE_WORKER.REQUEST, createWorker);
  yield takeLatest(salonManagementTypes.EDIT_WORKER.REQUEST, editWorker);
  yield takeLatest(salonManagementTypes.EDIT_WORKER_AVATAR.REQUEST, editWorkerAvatar);
  yield takeLatest(salonManagementTypes.EDIT_WORKER_SERVICES.REQUEST, editWorkerServices);
  yield takeLatest(salonManagementTypes.DELETE_WORKER.REQUEST, deleteWorker);
  yield takeLatest(salonManagementTypes.ENABLE_WORKER.REQUEST, enableWorker);
  yield takeLatest(salonManagementTypes.GET_WORKER_WORK_TIMES.REQUEST, getWorkerWorkTimes);
  yield takeLatest(salonManagementTypes.SET_WORKER_WORK_TIMES.REQUEST, setWorkerWorkTimes);
  yield takeLatest(salonManagementTypes.SET_WORKER_CLOSURES.REQUEST, setWorkerClosures);
  yield takeLatest(
    salonManagementTypes.SET_WORKER_EXTRA_WORKING_TIME.REQUEST,
    setWorkerExtraWorkingTime,
  );
  yield takeLatest(salonManagementTypes.GET_WORKER_SERVICES.REQUEST, getWorkerServices);
  yield takeLatest(salonManagementTypes.MOD_WORKER_SERVICE.REQUEST, modWorkerService);
  yield takeLatest(salonManagementTypes.DELETE_WORKER_SERVICE.REQUEST, delWorkerService);
  yield takeLatest(salonManagementTypes.DELETE_WORKER_CLOSURES.REQUEST, deleteWorkerClosures);
  yield takeLatest(salonManagementTypes.DELETE_WORKER_EXTRA_TIME.REQUEST, deleteWorkerExtraTime);
  yield takeLatest(salonManagementTypes.SET_WORKERS_POSITION.REQUEST, changeWorkersPosition);
  yield takeLatest(salonManagementTypes.SET_SERVICES_POSITION.REQUEST, changeServicesPosition);
  yield takeLatest(salonManagementTypes.GET_SALON.REQUEST, getSalon);
  // yield takeLatest(salonManagementTypes.SET_SALON_WORK_TIMES.REQUEST, setSalonWorkTimes);
}
