import clsx from 'clsx';
import styles from './accountsManager.module.scss';
import TitleSectionBack from 'Containers/Common/TitleSectionBack';
import { modalActions } from 'Containers/Modals/store';
import { ReactComponent as PlusIcon } from 'assets/svg/icons/plus_button.svg';
import { useDispatch, useSelector } from 'react-redux';
import useWindowSize from '../../../../utils/useWindowSize';
import ConfirmDeleteModal from 'Containers/Common/ConfirmDeleteModal';
import { memo, useEffect, useState } from 'react';
import { AccountItem } from './AccountItem';
import { getAccountsStaff } from 'Containers/Settings/store/selectors';
import { settingsActions } from 'Containers/Settings/store';


const AccountsManager = () => {
    const dispatch = useDispatch();
    const { isMobile } = useWindowSize();
    
    const accounts = useSelector(getAccountsStaff());

    useEffect(() => {
        dispatch(settingsActions.GET_ACCOUNTS_STAFF.REQUEST());
    }, []);


    return (
        <div className={clsx(styles.manager_container, {[styles.manager_container_mbl]: isMobile})}>
            <div className={styles.header}>
                <TitleSectionBack
                title={'Gestione Account Staff'}
                description={`Gestisci gli account dei tuoi collaboratori. Crea, modifica e personalizza i permessi per garantire il giusto accesso a ciascun utente.`}
                />
                <PlusIcon className={styles.add_button}
                    height="60px" width="60px"
                    onClick={() => dispatch(modalActions.OPEN_MODAL.SUCCESS({type: 'accountModal'}))} 
                />
            </div>
            {accounts.length >0
                ? <AccountsList accounts={accounts} />
                : (<div className={styles.body}>
                    <p>
                        Non ci sono account attivi
                    </p>
                    <p>
                        Inizia assegnando un account ai tuoi collaboratori
                    </p>
                </div>)
            }
        </div>
    );
};

const AccountsList = memo(({ accounts = [] }) => {
    const dispatch = useDispatch();
    const [items, setItems] = useState(accounts);
    const [accountToDelete, setAccountToDelete] = useState(false);
  
    useEffect(() => {
      setItems(accounts)
    }, [accounts])

    const openEditAccountModal = (account) => {
        dispatch(
          modalActions.OPEN_MODAL.SUCCESS({
            type: 'accountModal',
            props: {account},
          }),
        );
      };
  
    return (
        <>
      <ConfirmDeleteModal
        isModalOpen={accountToDelete}
        setIsModalOpen={setAccountToDelete}
        title={`Stai disattivando l'account di ${accountToDelete.nome_referente}?`}
        buttons={['Annulla', 'Procedi']}
        action={() => {
            dispatch(
              settingsActions.DELETE_ACCOUNT_STAFF.REQUEST(
              {
                idUtenteBusiness: accountToDelete.idUtenteBusiness
              },
              () => setAccountToDelete(false),
            ),
          )
         }
        }
      />
      <div className={styles.accounts_list_container}>
        {items.map((account, index) => (
            <AccountItem
                key={index}
                account={account}
                openEditAccountModal={openEditAccountModal}
                setAccountToDelete={setAccountToDelete}
            />
        ))}
      </div>
    </>
    );
}, (prevProps, nextProps) => {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps)
  });

export default AccountsManager;