import React, { useEffect, useState } from 'react';
import { getActiveSalon } from 'Containers/Auth/store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import styles from './onboarding.module.scss';
import LinearProgress from 'Containers/Common/LinearProgress';
import { ReactComponent as CheckFill } from 'assets/svg/icons/check_fill.svg';
import { ReactComponent as CheckCurrent } from 'assets/svg/icons/check_current.svg';
import { ReactComponent as CheckBlank } from 'assets/svg/icons/check_blank.svg';
import { ReactComponent as CardIcon } from 'assets/svg/icons/credit_card.svg';
import { getOnboardingData } from 'Containers/Home/store/selectors';
import { homeActions } from 'Containers/Home/store';
import { modalActions } from 'Containers/Modals/store';
import { Button } from '@mui/material';
import { InstagramForm, StripeForm } from './Components';
import clsx from 'clsx';
import { settingsActions } from 'Containers/Settings/store';
import useWindowSize from 'utils/useWindowSize';

const Onboarding = () => {
  const activeSalon = useSelector(getActiveSalon());
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();
  const { onboardingData, onboardingSteps } = useSelector(getOnboardingData());
  const [currentStep, setCurrentStep] = useState(0);
  const totSteps = onboardingSteps?.length;

  useEffect(() => {
    if (onboardingSteps?.length > 0) setCurrentStep(onboardingSteps?.find(step => !step.completed)?.id ?? 0);
    else dispatch(homeActions.GET_ONBOARDING.REQUEST());
  }, [onboardingSteps]);

  const openCreateServiceModal = () => {
    dispatch(
      modalActions.OPEN_MODAL.SUCCESS({
        type: 'createServiceModal',
      }),
    );
  };

  const openCreateWorkerModal = () => {
    dispatch(
      modalActions.OPEN_MODAL.SUCCESS({
        type: 'createWorkerModal',
      }),
    );
  };

  const openPaymentPortal = () => {
    let newWindow;
    if (!localStorage.getItem('fromApp'))
      newWindow = window.open('', '_blank');

    dispatch(
      settingsActions.CREATE_CUSTOMER_PORTAL.REQUEST(
        { clientId: onboardingData.cusStripe },
        ({ data }) => {
          if (!localStorage.getItem('fromApp')) newWindow.location.href = data;
          window?.flutter_inappwebview?.callHandler('app:url', data);
        },
      ),
    );
  }


  function switcherSteps(step) {
    switch (step.id) {
      case 1:
        if (step.completed) {
          return (
            <>
              <div className={styles.step_row}>
                <CheckFill width={'16px'} height={'16px'} />
                {step.title}
              </div>
            </>
          );
        }
        else {
          if (step.id == currentStep) {
            return (
              <>
                <div className={styles.step_row}>
                  <CheckCurrent width={'16px'} height={'16px'} />
                  {step.title}
                </div>
                <div className={clsx(styles.step_row, { [styles.form]: true })}>
                  <p className={styles.text}>
                    Crea la tua prima poltrona velocemente e in totale autonomia.
                  </p>
                  <Button className={styles.confirm_button} onClick={() => openCreateWorkerModal()}>Inserisci Ora</Button>
                </div>
              </>
            );
          }
          else {
            return (
              <>
                <div className={styles.step_row}>
                  <CheckBlank width={'16px'} height={'16px'} />
                  {step.title}
                </div>
              </>
            );
          }
        }
      case 2:
        if (step.completed) {
          return (
            <>
              <div className={styles.step_row}>
                <CheckFill width={'16px'} height={'16px'} />
                {step.title}
              </div>
            </>
          );
        }
        else {
          if (step.id == currentStep) {
            return (
              <>
                <div className={styles.step_row}>
                  <CheckCurrent width={'16px'} height={'16px'} />
                  {step.title}
                </div>
                <div className={clsx(styles.step_row, { [styles.form]: true })}>
                  <p className={styles.text}>
                    Crea il tuo primo servizio velocemente e in totale autonomia.
                  </p>
                  <Button className={styles.confirm_button} onClick={() => openCreateServiceModal()}>Inserisci Ora</Button>
                </div>
              </>
            );
          }
          else {
            return (
              <>
                <div className={styles.step_row}>
                  <CheckBlank width={'16px'} height={'16px'} />
                  {step.title}
                </div>
              </>
            );
          }
        }
      case 3:
        if (step.completed) {
          return (
            <>
              <div className={styles.step_row}>
                <CheckFill width={'16px'} height={'16px'} />
                {step.title}
              </div>
            </>
          );
        }
        else {
          if (step.id == currentStep) {
            return (
              <>
                <div className={styles.step_row}>
                  <CheckCurrent width={'16px'} height={'16px'} />
                  {step.title}
                </div>
                <div className={styles.step_row}>
                  <StripeForm />
                </div>
              </>
            );
          }
          else {
            return (
              <>
                <div className={styles.step_row}>
                  <CheckBlank width={'16px'} height={'16px'} />
                  {step.title}
                </div>
              </>
            );
          }
        }
      case 4:
        if (step.completed) {
          return (
            <>
              <div className={styles.step_row}>
                <CheckFill width={'16px'} height={'16px'} />
                {step.title}
              </div>
            </>
          );
        }
        else {
          if (step.id == currentStep) {
            return (
              <>
                <div className={styles.step_row}>
                  <CheckCurrent width={'16px'} height={'16px'} />
                  {step.title}
                </div>
                <div className={clsx(styles.step_row, { [styles.form]: true })}>
                  <p className={styles.text}>
                    Aggiungi un metodo di pagamento direttamente sul nostro portale Stripe.
                  </p>
                  <Button className={styles.confirm_button} onClick={() => openPaymentPortal()}>
                    <CardIcon className={styles.icon} height='17px' width='17px' />
                    Aggiungi Metodo di Pagamento
                  </Button>
                </div>
              </>
            );
          }
          else {
            return (
              <>
                <div className={styles.step_row}>
                  <CheckBlank width={'16px'} height={'16px'} />
                  {step.title}
                </div>
              </>
            );
          }
        }
      case 5:
        if (step.completed) {
          return (
            <>
              <div className={styles.step_row}>
                <CheckFill width={'16px'} height={'16px'} />
                {step.title}
              </div>
            </>
          );
        }
        else {
          if (step.id == currentStep) {
            return (
              <>
                <div className={styles.step_row}>
                  <CheckCurrent width={'16px'} height={'16px'} />
                  {step.title}
                </div>
                <div className={styles.step_row} >
                  <InstagramForm />
                </div>
              </>
            );
          }
          else {
            return (
              <>
                <div className={styles.step_row}>
                  <CheckBlank width={'16px'} height={'16px'} />
                  {step.title}
                </div>
              </>
            );
          }
        }

      default:
        break;
    }
  }

  return (
    <section className={clsx(styles.container, { [styles.container_mbl]: isMobile })}>
      <summary className={styles.header}>
        <span className={styles.h1}>Ciao {activeSalon.nome_salone}, ottieni 15 giorni gratis</span>
        <p className={styles.text}>
          Ogni step che completi ti avvicinerà all’ obiettivo finale! 🏆
        </p>
        <p className={styles.text}>
          Completali tutti per ottenere ulteriori 15 giorni di utilizzo gratuiti.✨
        </p>
        <div className={styles.progress_section}>
          <span className={styles.text}>
            {onboardingSteps?.filter(s => s.completed).length} di {totSteps} completati
          </span>
          <div className={styles.progress}>
            <LinearProgress color={'#858AF9'} percent={(onboardingSteps?.filter(s => s.completed)?.length / onboardingSteps?.length) * 100} />
          </div>
        </div>
      </summary>
      <summary className={styles.body}>
        {onboardingSteps?.map((step) => {
          return (<>{switcherSteps(step)}</>);
        })}
      </summary>
    </section>
  );
};
export default Onboarding;
