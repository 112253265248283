import styles from './Desktop.module.scss';

import { LocalizationProvider } from '@mui/x-date-pickers2/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers2/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers2/TimePicker';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import CustomInput from '../../CustomInput';
import useWindowSize from '../../../../utils/useWindowSize';
import { CalendarIcon } from '@mui/x-date-pickers2';
const InputComponent = React.forwardRef(
  (
    {
      InputProps,
      setIsOpen,
      placeholder,
      title,
      clearData,
      withIcon,
      icon,
      error,
      disabled,
      isMobile,
      value,
    },
    ref,
  ) => (
    <div ref={InputProps.ref} className={styles.picker_input_wrapper}>
      <div ref={ref}>
        <CustomInput
          {...InputProps}
          ref={null}
          onFocus={() => setIsOpen(true)}
          onBlur={() => setIsOpen(false)}
          placeholder={placeholder}
          inputValue={value || '--:--'}
          onAction={() => setIsOpen(true)}
          title={title}
          clearData={clearData}
          withIcon={withIcon}
          icon={icon}
          clearButton={false}
          error={error}
          disabled={disabled}
          height={isMobile ? '50px' : 'fit-content'}
        />
      </div>
    </div>
  ),
);
const CustomTimePickerDesktop = ({
  value,
  setValue,
  placeholder,
  title,
  icon,
  withIcon,
  minTime,
  maxTime,
  error,
  disabled,
  timeStep,
  open = false,
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const { isMobile } = useWindowSize();
  const clearData = () => setValue(null);
  const inputRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const setPosition = () => {
    if (inputRef.current) {
      const { current } = inputRef;
      const position = current.getBoundingClientRect();
      setAnchorEl({
        getBoundingClientRect: () => position,
      });
    }
  };
  useEffect(() => {
    window.addEventListener('click', setPosition);
    setPosition();
    return () => window.removeEventListener('click', setPosition);
  }, [inputRef]);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        open={isOpen}
        minTime={minTime}
        maxTime={maxTime}
        slotProps={{
          popper: {
            anchorEl: anchorEl,
          },
        }}
        slots={{
          textField: (props) => (
            <InputComponent
              {...props}
              setIsOpen={setIsOpen}
              placeholder={placeholder}
              title={title}
              clearData={clearData}
              withIcon={withIcon}
              icon={icon}
              error={error}
              disabled={disabled}
              isMobile={isMobile}
              ref={inputRef}
            />
          ),
        }}

        {...(timeStep && { minutesStep: timeStep })}
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        className={styles.picker_input_wrapper}
        value={value}
        ampm={false}
        onChange={(newValue) => {
          newValue = newValue.set('second', 0);
          setValue(newValue);
        }}
      />
    </LocalizationProvider>
  );
};

export default CustomTimePickerDesktop;
