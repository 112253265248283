import styles from './NotificationsList.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import TitleSectionBack from '../../../Common/TitleSectionBack';
import { getNotifications } from '../../store/selectors';
import { notificationActions } from '../../store';
import NotificationsTable from '../NotificationsTable';
import SearchInput from '../../../Common/SearchInput';
import { getActiveSalonId } from '../../../Auth/store/selectors';
import FilterButtons from '../../../Common/FilterButtons';
import useWindowSize from "../../../../utils/useWindowSize";
import clx from "classnames";

const filtersButtons = [
  {
    name: 'Disdette',
    id: 2,
  },
  {
    name: 'Prenotazioni',
    id: 1,
  },
  // {
  //   name: 'Comunicazioni',
  //   id: 4,
  // },
];

const NotificationsList = () => {
  const dispatch = useDispatch();
  const {isMobile} = useWindowSize();
  const [activeFilterId, setActiveFilterId] = useState(2);
  const [searchedName, setSearchedName] = useState('');
  const activeSalonId = useSelector(getActiveSalonId());
  const notifications = useSelector(getNotifications());
  const [paginationValue, setPaginationValue] = useState({
    skip: 0,
    limit: 10,
  });
  const fromApp = localStorage.getItem('fromApp');
  const url = 'https://blues2.notion.site/Non-perderti-pi-nessuna-novit-0f57434259334a02b67eacd908ae7207?pvs=4';

  useEffect(() => {
    activeSalonId &&
      activeFilterId &&
      dispatch(
        notificationActions.GET_NOTIFICATIONS.REQUEST({
          query: {
            notification_type: activeFilterId,
            skip: paginationValue.skip,
            limit: paginationValue.limit,
          },
        }),
      );
  }, [dispatch, activeSalonId, activeFilterId, paginationValue.skip, paginationValue.limit]);
  return (
    <div className={clx(styles.notification_list_container, {
      [styles.notification_list_container_mobile]: isMobile
    })}>
      <TitleSectionBack
        title={'Notifiche'}
        description={'Resta informato sui tuoi appuntamenti e sulle novità ZetaBarber'}
      />
      <div className={styles.filters_container}>
        <div className={styles.button_wrapper}>
          <FilterButtons
            activeFilterId={activeFilterId}
            setActiveFilterId={(value) => {
              setActiveFilterId(value);
              setPaginationValue({
                skip: 0,
                limit: 10,
              });
            }}
            filtersButtons={filtersButtons}
          />
          <button className={styles.confirm_button} onClick={()=>{
            if (!fromApp) window.open(url, '_blank');
            else window?.flutter_inappwebview?.callHandler('app:url', url);
          }}>
            Novità
            <div className={styles.novita}/>
          </button>
        </div>
        <SearchInput
          placeholder={'Cerca cliente'}
          value={searchedName}
          setValue={setSearchedName}
          backgroundColor={'white'}
        />
      </div>
      <NotificationsTable
        tableName={filtersButtons.find((filter) => filter.id === activeFilterId).name}
        activeFilterId={activeFilterId}
        setPaginationValue={setPaginationValue}
        pageCount={Math.ceil(notifications?.totalNotifications / paginationValue.limit)}
        isMobile={isMobile}
        values={notifications?.notificationsList?.filter(
          (notification) =>
            !searchedName ||
            notification.clientName.toLowerCase().includes(searchedName.toLowerCase()),
        )}
      />
    </div>
  );
};

export default NotificationsList;
