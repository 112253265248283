import { useEffect, useRef, useState } from 'react';

function useBlockSize({ observedBlock, delay = 300, sizes = [] }) {
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();

  // const [height, setHeight] = useState();

  const handleElementResized = () => {
    if (observedBlock.current?.offsetWidth && observedBlock.current.offsetWidth !== width) {
      setWidth(observedBlock.current.offsetWidth);
    }
    if (observedBlock.current?.offsetHeight && observedBlock.current.offsetHeight !== height) {
      setHeight(observedBlock.current.offsetHeight);
    }
  };

  const resizeObserverWidth = new ResizeObserver(handleElementResized);
  const resizeObserverHeight = new ResizeObserver(handleElementResized);

  useEffect(() => {
    observedBlock?.current && resizeObserverWidth.observe(observedBlock.current);
    return function cleanup() {
      resizeObserverWidth.disconnect();
    };
  }, [observedBlock]);
  useEffect(() => {
    observedBlock?.current && resizeObserverHeight.observe(observedBlock.current);
    return function cleanup() {
      resizeObserverHeight.disconnect();
    };
  }, [observedBlock]);

  return {
    width: sizes.find((size) => width >= size.from && width < size.to)?.value,
    height: sizes.find((size) => height >= size.from && height < size.to)?.value,
  };
}

export default useBlockSize;
