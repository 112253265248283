import React from 'react';
import { useSelector } from 'react-redux';

import Spinner from '../../Containers/Common/Spinner';
import { getLoader } from './store/selectors';
import { ROUTES } from '../../routes/constants';
import LinearLoader from 'Containers/Common/LinearLoader';

export default () => {
  const loading = useSelector(getLoader());
  const page = window.location.pathname;

  return (
  <>
    {loading 
    ? page == ROUTES.SCHEDULER 
        ? <LinearLoader color={'secondary'}/>
        : <Spinner /> 
    : null}
  
  </>);
};
