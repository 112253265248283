import styles from './SelectorMenu.module.scss';

import clx from 'classnames';
import AvatarIcon from '../../AvatarIcon';
import { ReactComponent as Verified } from '../../../../assets/svg/verified.svg';
import { ReactComponent as AddressBook } from '../../../../assets/svg/address-book.svg';

import clsx from 'clsx';

const SelectorUserCard = ({ option }) => (
  <div className={styles.option_value_wrapper}>
    {
      !(
        <div className={styles.value_icon}>
          <AvatarIcon icon={option.icon} name={option.name} size={option.size || '35px'} />
        </div>
      )
    }
    {option?.background ? (
      <div style={{ background: option.background }} className={styles.value_color} />
    ) : null}
    <div className={styles.section_value}>
      <div className={styles.option_value}>{option.name}</div>
      <div className={styles.second_option}>
        {option.phone}
        <div style={{ alignItems: 'center' }}>
          {option.verified ? <Verified width={'13px'} height={'13px'} /> : null}
          {/* Utente inserito da rubrica {option.createFromSalone ? <AddressBook width={'18px'} height={'18px'} /> : null} */}
        </div>
      </div>
    </div>
  </div>
);

const SelectorMenu = ({
  value = [],
  setValue,
  options,
  isMultiple,
  setIsDropdownOpen,
  fullHeight = false,
  border = true,
  error = false,
  blockedValues = [],
  width = '100%',
  isMultipleOne = false,
  isEmptyMessage = 'Nessuna opzione disponibili',
  darkMode = false,
  number = false,
}) => {
  const selectValueHandler = (selectedValue) => {
    if (isMultiple) {
      const isIncludeValue = value.includes(selectedValue);
      if (isIncludeValue) {
        setValue(value.filter((value) => value !== selectedValue));
      } else {
        setValue([...value, selectedValue]);
      }
    } else {
      setValue(selectedValue);
      setIsDropdownOpen();
    }
  };
  return (
    <div
      className={clx(styles.menu_wrapper, {
        [styles.menu_wrapper_fullHeight]: fullHeight,
        [styles.menu_wrapper_border]: !border,
        [styles.menu_wrapper_dark_mode]: darkMode,
      })}
      style={{ width }}
    >
      {!options.length && (
        <div className={clx(styles.option_item)}>
          <div className={styles.option_value_wrapper}>
            <div className={styles.option_value}>{isEmptyMessage}</div>
          </div>
        </div>
      )}
      {options.map((option) => (
        <div
          className={clx(styles.option_item, {
            [styles['option_item_active']]: value.includes(option.value),
            [styles['option_item_blocked']]: blockedValues.includes(option.value),
            [styles['option_item_disabled']]: option.disabled,
          })}
          key={option.value}
          onClick={() =>
            !option.disabled &&
            !blockedValues.includes(option.value) &&
            selectValueHandler(option.value)
          }
        >
          {!number ? (
            <div className={styles.option_value_wrapper}>
              {option.icon ? (
                <div className={styles.value_icon}>
                  <AvatarIcon icon={option.icon} name={option.name} size={option.size || '50px'} />
                </div>
              ) : null}
              {option.background ? (
                <div style={{ background: option.background }} className={styles.value_color} />
              ) : null}
              <div className={styles.section_value}>
                <div className={styles.option_value}>{option.name}</div>
              </div>
            </div>
          ) : (
            <SelectorUserCard option={option} />
          )}

          {isMultiple ? (
            <input
              className={styles.radio_button}
              type={'radio'}
              checked={value.includes(option.value)}
              onChange={() => {}}
            />
          ) : null}
          {isMultipleOne ? (
            <input
              className={clsx(styles.radio_button, styles.select_button)}
              type={'radio'}
              checked={value.includes(option.value)}
              onChange={() => {}}
            />
          ) : null}
        </div>
      ))}
      {!!error && <div className={styles.error_message}>{error}</div>}
    </div>
  );
};

export default SelectorMenu;
