import { call, put, takeLatest } from 'redux-saga/effects';

import { Api } from '../../../entries/ApiTransport';
import sagaAssessor from '../../../utils/sagaAssessor';
import { clientTypes, clientActions } from './actions';
import { toastActions } from 'Containers/Toasts/store';

const api = Api.getInstance();

const getClients = ({ payload, callback, options }) =>
  sagaAssessor(
    () =>
      function* () {
        const {
          data: { clienti },
        } = yield call(() => api.get('/business/clienti/getAll'));
        yield put(clientActions.GET_CLIENTS.SUCCESS({ clienti }));
      },
    (err) => clientActions.GET_CLIENTS.FAILED(err),
    options,
  );

const getClientsPlus = ({ payload, callback, options }) =>
  sagaAssessor(
    () =>
      function* () {
        const {
          data: { clienti, inviti_onboarding },
        } = yield call(() => api.get('/business/clienti/getAllClientiPlus'));
        yield put(clientActions.GET_CLIENTS.SUCCESS({ clienti, inviti_onboarding }));
      },
    (err) => clientActions.GET_CLIENTS.FAILED(err),
    options,
  );

const getClient = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const {
          data: { profilo },
        } = yield call(() => api.post('/business/clienti/getCliente', payload));
        yield put(clientActions.GET_CLIENT.SUCCESS(profilo));
        callback && typeof callback === 'function' && callback();
      },
    (err) => clientActions.GET_CLIENT.FAILED(err),
  );
const addClient = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.post('/business/clienti/crea', payload));
        yield put(clientActions.ADD_CLIENT.SUCCESS(data));
        callback && typeof callback === 'function' && callback();

        yield put(
          toastActions.OPEN_TOAST.SUCCESS({
            type: 'clients',
            props: { title: 'Hai aggiunto un nuovo cliente!' },
          }),
        );
      },
    (err) => clientActions.ADD_CLIENT.FAILED(err),
  );

const editClient = ({ payload }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() =>
          api.post('/business/clienti/aggiorna', { user: payload }),
        );
        yield put(clientActions.EDIT_CLIENT.SUCCESS(payload));
      },
    (err) => clientActions.EDIT_CLIENT.FAILED(err),
  );

const editClientphone = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.post('/business/clienti/aggiorna_numero', payload));
        yield put(clientActions.EDIT_CLIENT_PHONE.SUCCESS(payload));
        callback && typeof callback === 'function' && callback();
      },
    (err) => clientActions.EDIT_CLIENT_PHONE.FAILED(err),
  );

const addNote = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.post('/business/clienti/nota', payload));
        yield put(clientActions.ADD_NOTE.SUCCESS(payload));
        callback && typeof callback === 'function' && callback();
      },
    (err) => clientActions.ADD_NOTE.FAILED(err),
  );

const editNote = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.put('/business/clienti/nota', payload));
        yield put(clientActions.EDIT_NOTE.SUCCESS(payload));
        callback && typeof callback === 'function' && callback();
      },
    (err) => clientActions.EDIT_NOTE.FAILED(err),
  );

const deleteNote = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.post('/business/clienti/delete/nota', payload));
        yield put(clientActions.DELETE_NOTE.SUCCESS(payload));
        callback && typeof callback === 'function' && callback();
      },
    (err) => clientActions.DELETE_NOTE.FAILED(err),
  );

const deleteClient = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        yield call(() => api.post('business/clienti/disabilita', payload));
        yield put(clientActions.DELETE_CLIENT.SUCCESS(payload.idUtente));
        callback && typeof callback === 'function' && callback();
      },
    (err) => clientActions.DELETE_CLIENT.FAILED(err),
  );

const enableClient = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        yield call(() => api.post('business/clienti/abilita', payload));
        yield put(clientActions.ENABLE_CLIENT.SUCCESS(payload.idUtente));
        callback && typeof callback === 'function' && callback();
      },
    (err) => clientActions.ENABLE_CLIENT.FAILED(err),
  );

const deleteClientAppointment = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        yield call(() => api.post('business/prenotazioni/disable', payload));
        yield put(clientActions.DELETE_CLIENT_APPOINTMENT.SUCCESS(payload.idPrenotazione));
        callback && typeof callback === 'function' && callback();
      },
    (err) => clientActions.DELETE_CLIENT_APPOINTMENT.FAILED(err),
  );

const getPrenotazioneByID = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { idPrenotazione } = payload;
        const { data } = yield call(() =>
          api.get(`prenotazioni/getPrenotazioneById?idPrenotazione=${idPrenotazione}`),
        );
        yield put(clientActions.GET_PRENOTAZIONE_BY_ID.SUCCESS(data));
        callback && typeof callback === 'function' && callback();
      },
    (err) => clientActions.GET_PRENOTAZIONE_BY_ID.FAILED(err),
  );

  //get clienti con più di 3 prenotazioni disdette all'ultimo momento
  const getBadClients = ({ payload, callback }) => 
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.get('/business/clienti/badUsers'));
        yield put(clientActions.GET_BAD_CLIENTS.SUCCESS(data));
        callback?.();
      },
    (err) => clientActions.GET_BAD_CLIENTS.FAILED(err),
  );

export default function* () {
  yield takeLatest(clientTypes.GET_CLIENTS.REQUEST, getClients);
  yield takeLatest(clientTypes.GET_CLIENTS_PLUS.REQUEST, getClientsPlus);
  yield takeLatest(clientTypes.GET_CLIENT.REQUEST, getClient);
  yield takeLatest(clientTypes.ADD_CLIENT.REQUEST, addClient);
  yield takeLatest(clientTypes.EDIT_CLIENT.REQUEST, editClient);
  yield takeLatest(clientTypes.EDIT_CLIENT_PHONE.REQUEST, editClientphone);
  yield takeLatest(clientTypes.GET_BAD_CLIENTS.REQUEST, getBadClients);

  yield takeLatest(clientTypes.DELETE_CLIENT.REQUEST, deleteClient);
  yield takeLatest(clientTypes.ENABLE_CLIENT.REQUEST, enableClient);

  yield takeLatest(clientTypes.DELETE_CLIENT_APPOINTMENT.REQUEST, deleteClientAppointment);
  yield takeLatest(clientTypes.ADD_NOTE.REQUEST, addNote);
  yield takeLatest(clientTypes.EDIT_NOTE.REQUEST, editNote);
  yield takeLatest(clientTypes.DELETE_NOTE.REQUEST, deleteNote);
  yield takeLatest(clientTypes.GET_PRENOTAZIONE_BY_ID.REQUEST, getPrenotazioneByID);
}
