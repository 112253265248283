import TextField from 'Containers/Common/TextField';
import styles from './index.module.scss';
import { Formik, Form, Field } from 'formik';
import { Button } from '@mui/material';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { authActions } from 'Containers/Auth/store';
import clsx from 'clsx';
import useWindowSize from 'utils/useWindowSize';
import { ReactComponent as LockIcon } from '../../../../assets/svg/icons/lock.svg';
import { ReactComponent as SuccessIcon } from '../../../../assets/svg/icons/green_success.svg';
import LogoImage from '../../../../assets/images/logo.png';
import { useEffect, useState } from 'react';
import { ROUTES } from 'routes/constants';
import { useNavigate } from 'react-router-dom';

const ResetPwdPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isMobile } = useWindowSize();
    const [ reset, setReset ] = useState(false);
    const initialValues = {
        newPwd: '',
        newPwdConfirm: '',
    }
    const salone = useState(localStorage.getItem('name') || '');
    
    const validationSchema = {
        newPwd: yup.string().min(3).required('Obbligatorio'),
        newPwdConfirm: yup.string()
        .oneOf([yup.ref('newPwd'), null], 'Le password devono coincidere')
    };

    const handleClick = (value) => {
        dispatch(authActions.RESET_PASSWORD.REQUEST({newPwd: value}, ()=>setReset(true)));
    }

    useEffect(() => {
        if(!localStorage.getItem('tokenRecovery') && !reset) navigate(ROUTES.SIGN_IN);
    }, []);

    return (
        <div className={clsx(styles.container, {[styles.container_mbl]: isMobile})}>
            {!reset 
                ? (<>
                    <div className={styles.container_icon}>
                        <LockIcon className={styles.icon} />
                    </div>
                    <h1>Reimposta la password</h1>
                    {salone  
                        ? <span className={styles.description}>Stai resettando la password di {salone}. Inserisci una nuova password.</span>
                        : <span className={styles.description}>Inserisci e conferma una nuova password.</span>
                    }
                    <Formik
                        initialValues={initialValues}
                        validationSchema={yup.object().shape(validationSchema)}
                        enableReinitialize={true}
                        onSubmit={(values) => {
                            handleClick(values.newPwd);
                        }}
                    >
                        {({ values, setFieldValue }) => {
                            return (
                                <Form className={styles.form}>
                                    <Field
                                        name={'newPwd'}
                                        id={'newPwd'}
                                        placeholder="Nuova password"
                                        component={TextField}
                                        height={'50px'}
                                        type="password"
                                        showPassword={true}
                                        inputColor={'white'}
                                        showIcon={true}
                                    />
                                    <Field
                                        name={'newPwdConfirm'}
                                        id={'newPwdConfirm'}
                                        placeholder="Conferma password"
                                        component={TextField}
                                        height={'50px'}
                                        type="password"
                                        showPassword={true}
                                        inputColor={'white'}
                                        showIcon={true}
                                    />  
                                    <Button
                                        className={styles.confirm_button}
                                        variant="contained"
                                        type={'submit'}
                                    >
                                        Salva
                                    </Button>
                                </Form>
                            )
                        }}
                    </Formik>
                    <p style={{cursor: 'pointer'}} onClick={()=> navigate(ROUTES.SIGN_IN)}>{"<- Torna al login"}</p>

                </>)        
                : (<>
                    <div className={clsx(styles.container_icon, styles.success)}>
                        <SuccessIcon className={styles.icon} />
                    </div>
                    <h1>Completato</h1>
                    <span className={styles.description}>Password modificata correttamente!</span>
                    <p style={{fontSize: '12px'}}>Accedi con la nuova password.</p>
                    <Button
                        className={styles.confirm_button}
                        style={{maxWidth:'335px'}}
                        variant="contained"
                        onClick={()=> navigate(ROUTES.SIGN_IN)}
                    >
                        Vai al login
                    </Button>
                </>)
            }
            <img src={LogoImage} alt='logo' className={styles.logo} />
        </div>
    );
}

export default ResetPwdPage;