import React, { useEffect, useState } from 'react';
import styles from './HomeTables.module.scss';
import { ReactComponent as ClockIcon } from '../../../../assets/svg/icons/orari.svg';
import { ReactComponent as StarIcon } from '../../../../assets/svg/icons/stella.svg';
import { ReactComponent as WhatsappIcon } from '../../../../assets/svg/icons/whatsapp.svg';
import { ReactComponent as PhoneIcon } from '../../../../assets/svg/icons/chiamate.svg';
import { ReactComponent as TrashIcon } from '../../../../assets/svg/icons/elimina.svg';
import { ReactComponent as ArrowRIcon } from '../../../../assets/svg/icons/arrow_r.svg';
import { ReactComponent as ArrowLIcon } from '../../../../assets/svg/icons/arrow_l.svg';
import { ReactComponent as Person } from '../../../../assets/svg/icons/profilo.svg';

import dayjs from 'dayjs';
import { Button } from '@mui/material';
import AvatarIcon from '../../../Common/AvatarIcon';
import useWindowSize from '../../../../utils/useWindowSize';
import clx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { getBestUsers, getQueue } from '../../store/selectors';
import { modalActions } from '../../../Modals/store';
import IconButton from '../../../Common/Buttons/IconButton';

const HomeTables = () => {
  const [activeDay, setActiveDay] = useState(dayjs());
  const { isMobile } = useWindowSize();
  const bestUser = useSelector(getBestUsers());
  const queue = useSelector(getQueue(activeDay));
  return (
    <div
      className={clx(styles.tables_container, { [styles['tables_container_mobile']]: isMobile })}
    >
      <HomeTable
        users={queue}
        activeDay={activeDay}
        setActiveDay={setActiveDay}
        headerIcon={<ClockIcon />}
        headerText={`${queue.length} clienti in coda`}
        linkButton={true}
        emptyMessage='Non ci sono clienti in coda al momento'
        deleteButton={false}
      />
      <HomeTable
        users={bestUser}
        headerIcon={<StarIcon />}
        headerText={'Top 10 migliori clienti'}
        linkButton={true}
        emptyMessage={
          <p>
            Non hai ancora clienti registrati su ZetaBarber,
            <a
              href={
                'https://blog.zetabarber.it/fai-iscrivere-i-tuoi-primi-100-clienti-su-zetabarber-in-meno-di-72-ore/'
              }
            >
              leggi {"l'articolo"} e arriva velocemente a 100 clienti registrati
            </a>
          </p>
        }
        deleteButton={false}
      />
    </div>
  );
};

const HomeTable = ({
  users,
  activeDay,
  setActiveDay,
  headerIcon,
  headerText,
  pageSize = 5,
  deleteButton = true,
  linkButton = false,
  emptyMessage = 'Non ci sono dati al momento',
}) => {
  const dispatch = useDispatch();
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [activePage, setActivePage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const { isMobile } = useWindowSize();

  useEffect(() => {
    if (!users?.length) {
      setFilteredUsers([]);
      setActivePage(0);
      setLastPage(0);
      return;
    }
    const last = Math.floor((users.length - 1) / pageSize);
    if (last >= activePage && activePage >= 0) {
      setFilteredUsers(users.slice(activePage * pageSize, (activePage + 1) * pageSize));
      setLastPage(last);
    }
  }, [users, pageSize, activePage]);

  const openClientModal = (id) => {
    dispatch(
      modalActions.OPEN_MODAL.SUCCESS({
        type: 'clientModal',
        props: { client_id: id },
      }),
    )
  }

  return (
    <div className={styles.table_container}>
      <div className={styles.table_header}>
        <div className={styles.header_label}>
          {headerIcon}
          <div className={styles.label_text}>{headerText}</div>
        </div>
        {activeDay ? (
          <div className={styles.day_selector}>
            <div
              onClick={() => setActiveDay((activeDay) => dayjs(activeDay).subtract(1, 'day'))}
              className={styles.arrow_left}
            />
            <div className={styles.day_value}>{dayjs(activeDay).format('dddd DD')}</div>
            <div
              onClick={() => setActiveDay((activeDay) => dayjs(activeDay).add(1, 'day'))}
              className={styles.arrow_right}
            />
          </div>
        ) : null}
      </div>
      <div className={styles.table_clients}>
        {filteredUsers.map((item) => (
          <div key={item.key} className={styles.client_item}>
            {isMobile ? (
              <>
                <div className={styles.avatar_with_description}>
                  <AvatarIcon icon={''} name={item.clientName} size={'33px'} />
                  <div className={styles.description}>
                    <div className={styles.name}>{item.clientName}</div>
                    <div className={styles.value}>{item.description}</div>
                  </div>
                </div>
                <div
                  className={styles.menu_icon}
                  onClick={() => openClientModal(item.id)}
                >
                  &#8942;
                </div>
              </>
            ) : (
              <>
                <AvatarIcon icon={''} name={item.clientName} size={'30px'} />
                <div className={styles.description}>
                  <div className={styles.name}>{item.clientName}</div>
                  <div className={styles.value}>{item.description}</div>
                </div>

                <div className={styles.client_actions}>
                  <IconButton
                    svg={<WhatsappIcon />}
                    type={'whatsapp'}
                    size={'38px'}
                    isActive={!!item.phoneNumber}
                    value={item.phoneNumber}
                    margin={'0 5px 0 0'}
                  />
                  <IconButton
                    svg={<PhoneIcon />}
                    type={'phone'}
                    size={'38px'}
                    isActive={!!item.phoneNumber}
                    value={item.phoneNumber}
                    margin={'0 5px 0 0'}
                  />
                  {deleteButton && (
                    <IconButton
                      svg={<TrashIcon />}
                      type={'default'}
                      size={'38px'}
                      isActive={true}
                      onClick={() => {
                      }}
                    />
                  )}
                  {linkButton && (
                    <IconButton
                      svg={<Person />}
                      type={'default'}
                      size={'38px'}
                      isActive={!!item.id}
                      onClick={() => openClientModal(item.id)}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        ))}
        {!filteredUsers?.length && <div className={styles.empty_message}>{emptyMessage}</div>}
      </div>
      <div className={styles.table_footer}>
        {activeDay ? (
          <div className={styles.day_selector}>
            <div
              onClick={() => setActiveDay((activeDay) => dayjs(activeDay).subtract(1, 'day'))}
              className={styles.arrow_left}
            />
            <div className={styles.day_value}>{dayjs(activeDay).format('dddd DD')}</div>
            <div
              onClick={() => setActiveDay((activeDay) => dayjs(activeDay).add(1, 'day'))}
              className={styles.arrow_right}
            />
          </div>
        ) : (
          <div className={styles.pagination_value}>
            Pagina {activePage + 1} di {lastPage + 1}
          </div>
        )}
        <div className={styles.arrow_block}>
          <Button
            disableElevation
            className={styles.arrow_button}
            onClick={() => setActivePage((activePage) => activePage - 1)}
            variant='contained'
            disabled={activePage <= 0}
          >
            <ArrowRIcon />
          </Button>
          <Button
            disableElevation
            className={styles.arrow_button}
            onClick={() => setActivePage((activePage) => activePage + 1)}
            variant='contained'
            disabled={activePage >= lastPage}
          >
            <ArrowLIcon />
          </Button>
        </div>
      </div>
    </div>
  );
};
export default HomeTables;
