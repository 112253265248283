import React, { useEffect } from 'react';
import styles from './WorkerCreationModal.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { salonManagementActions } from '../../../../store';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import TextField from '../../../../../Common/TextField';
import { Button } from '@mui/material';
import clx from 'classnames';
import ButtonSelector from "../../../../../Common/CustomSelector/ButtonSelector";
import { getWorkers } from "../../../../store/selectors";
import useWindowSize from '../../../../../../utils/useWindowSize';
import clsx from 'clsx';
import InputSelector from '../../../../../Common/CustomSelector/InputSelector';

const WorkerCreationModal = ({ closeModal }) => {
  const dispatch = useDispatch();
  const workers = useSelector(getWorkers());
  const { isMobile } = useWindowSize()
  const formedWorkers = workers.map(worker => ({
    name: worker.name,
    value: worker.id,
    icon: worker.icon
  }))

  const initialValues = {
    nomeReferente: '',
    descrizionePoltrona: '',
    workerIdForCopy: '',
  };

  const validationSchema = {
    nomeReferente: yup.string('Inserisci la tua nome').required('Nome è richiesta'),
  };

  useEffect(() => {
    dispatch(salonManagementActions.GET_WORKERS.REQUEST());
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={yup.object().shape(validationSchema)}
      enableReinitialize={false}
      onSubmit={(values) => {

        const req = {
          nomeReferente: values.nomeReferente,
          descrizionePoltrona: values.descrizionePoltrona,
          idCopiaOrari: values.workerIdForCopy,
          visible: 1,
          onlyApp: 0,
          disabilitato: 0,
          defaultWorkTimes: !values.workerIdForCopy,
          allServices: true,
        }

        return dispatch(
          salonManagementActions.CREATE_WORKER.REQUEST(
            req,
            closeModal,
          ),
        );
      }}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form className={clsx(styles.worker_modal_wrapper, { [styles.worker_modal_wrapper_mobile]: isMobile })}>
            <div className={styles.body} >

              <Field
                name={'nomeReferente'}
                id={'nomeReferente'}
                label='Nome della poltrona'
                component={TextField}
                height={'50px'}
                placeholder={'Campo di testo da compilare'}
              />
              <br />
              <Field
                name={'descrizionePoltrona'}
                id={'descrizionePoltrona'}
                label='Descrizione poltrona'
                component={TextField}
                textarea={true}
                height={'80px'}
                placeholder={'Campo di testo da compilare'}
              />
              <InputSelector
                value={values.workerIdForCopy}
                setValue={(value) => setFieldValue('workerIdForCopy', value)}
                options={formedWorkers}
                title={'Copia orari da'}
                marginLeft={0}
                height='50px'
                margin='0px 0px 200px 0px'
                width={'100%'}
              />
            </div>
            <hr />

            <div className={clx(styles.actions_container, styles.footer)}>
              <Button className={styles.confirm_button} variant='contained' type={'submit'}>
                Salva
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default WorkerCreationModal;
