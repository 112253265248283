import { useEffect } from 'react';
import HomeInfoCards from '../HomeInfoCards';
import styles from './HomePage.module.scss';
import HomeTables from '../HomeTables';
import HomeStaticBlock from '../HomeStaticBlock';
import { useDispatch, useSelector } from 'react-redux';
import { homeActions } from '../../store';
import { getActiveSalonId, getActiveSalon } from '../../../Auth/store/selectors';
import useWindowSize from '../../../../utils/useWindowSize';
import clx from 'classnames';
import BannerBlock from '../BannerBlock';
import { ROUTES } from '../../../../routes/constants';
import { getActiveSalonToken } from 'utils/salonToken';
import blankPayBg from 'assets/images/payments_info/1.png';
import background from 'assets/images/home/background1.jpg';
import backgroundMbl from 'assets/images/home/background1_mbl.jpg';
import { getInvitiOnboarding } from '../../../Auth/store/selectors';
import { useNavigate } from 'react-router-dom';
import { getHomeStatistic } from 'Containers/Home/store/selectors';
import { getCustomerProducts } from 'Containers/Settings/store/selectors';
import BannerVideo from '../BannerVideo';
import Onboarding from '../Onboarding';
import coverTiktokVideo from '../../../../assets/images/home/coverTiktokVideo.png';



const Home = () => {
  const dispatch = useDispatch();
  const activeSalon = useSelector(getActiveSalon());
  const activeSalonId = useSelector(getActiveSalonId());
  const { isMobile } = useWindowSize();
  const homeStat = useSelector(getHomeStatistic());
  const userInvited = useSelector(getInvitiOnboarding());
  const products = useSelector(getCustomerProducts());
  const token = getActiveSalonToken();
  const navigate = useNavigate();
  const fromApp = !!localStorage.getItem('fromApp');


  useEffect(() => {
    if (activeSalonId) {
      dispatch(homeActions.GET_HOME_STAT.REQUEST());
      dispatch(homeActions.GET_QUEUE.REQUEST());
      dispatch(homeActions.GET_ONBOARDING.CLEAR());
      window?.flutter_inappwebview?.callHandler('app:notifications', {
        salonId: activeSalonId,
      });
    }
  }, [dispatch, activeSalonId]);

  function openFlutterContact() {
    window?.flutter_inappwebview?.callHandler('app:onboardingContact', {
      action: 'open',
      token,
      invitiRimanenti: userInvited,
    });
  }
  useEffect(()=>{
    if(userInvited>0 && homeStat.allClienti < 30) openFlutterContact();
  }, [userInvited, homeStat]);

  const handlePressTikTok = () => {
    const tiktok = 'https://vm.tiktok.com/ZGegAWqFX/';

    if (!fromApp) window.open(tiktok, '_blank');
    else window?.flutter_inappwebview?.callHandler('app:url', tiktok);
  };

  return (
    <div className={clx(styles.home_container, { [styles['home_container_mobile']]: isMobile })}>
      <HomeInfoCards />
      {!<BannerVideo
        cover={coverTiktokVideo}
        video={'https://zb-img.s3.eu-west-3.amazonaws.com/video/video+tik+tok+(1).mp4'}
        title={'Prova il nuovo filtro Tik Tok di Zetabarber!🔥'}
        description={"Questo effetto ha già raggiunto centinaia di utenti, riusciresti a fare meglio? 🤩 Coinvolgi i tuoi clienti, invitali ad utilizzare “Perfect cut” prima del taglio e cerca di stupirci ❤️🫂"}
        action={'Mettiti alla prova'}
        onClick={() => handlePressTikTok()}
      />}
      {!<BannerBlock
        action={'Registrati qui'}
        background={isMobile ? backgroundMbl : background}
        description={`Vuoi provare il servizio? Clicca qui per registrarti e ricevere un mese di prova gratuito!`}
        onClick={() => {
          window.open(`https://form.typeform.com/to/eXqvW04X`, '_blank');
        }}
        />}
      {!products.isSubscribed && !activeSalon.idCostumerStripe && homeStat.allClienti < 100 && <Onboarding />}
      <HomeTables />
      {!<BannerBlock
        title={"L'innovazione che il tuo salone merita🚀"}
        subtitle={`Non perderai più soldi💰 a causa degli appuntamenti fantasma e avrai tantissime altre novità per ottimizzare il tuo tempo 🕔`}
        description={`Il servizio è già online per tutti i saloni di Zetabarber dal 14 marzo😉✨`}
        action={'Avanti'}
        img={blankPayBg}
        onClick={() => {
          navigate(`${ROUTES.PAYMENTS}${ROUTES.INFORMATIONS}`);
        }}
      />}
      <HomeStaticBlock />
    </div>
  );
};

export default Home;
