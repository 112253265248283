import styles from './CustomTimePicker.module.scss';

import {LocalizationProvider} from "@mui/x-date-pickers1/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers1/AdapterDayjs";
import {TimePicker} from "@mui/x-date-pickers1/TimePicker";
import React, {useState} from "react";

const CustomTimePicker = (
  {
    value,
    setValue,
    title,
    minTime = null,
    maxTime = null,
    error,
  }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        open={isOpen}
        minTime={minTime}
        maxTime={maxTime}
        onClose={() => setIsOpen(false)}
        renderInput={({inputRef, inputProps, InputProps}) => {
          return <div
            ref={inputRef}
            className={styles.picker_input_wrapper}
          >
            <label className={styles.input_label_container}>
              <div className={styles.input_title}>
                {title}
              </div>
              <div className={styles.input_wrapper}>
                <div className={styles.field__container}>
                  <input
                    type={'text'}
                    value={inputProps.value || '--:--'}
                    className={styles['field__value']}
                    readOnly={true}
                    onClick={() => setIsOpen(true)}
                    required={!!error}
                  />
                  <div className={styles.error_message}>{error}</div>
                </div>
              </div>
            </label>
          </div>
        }}
        value={value}
        ampm={false}
        onChange={(newValue) => {
          setValue(newValue);
        }}
      />
    </LocalizationProvider>
  );
};

export default CustomTimePicker;
