import React, { memo, useEffect, useState } from 'react';
import styles from './Workers.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveSalonId } from '../../../Auth/store/selectors';
import { getWorkers } from '../../store/selectors';
import { salonManagementActions } from '../../store';
import TitleSectionBack from '../../../Common/TitleSectionBack';
import { ReactComponent as PlusIcon } from '../../../../assets/svg/icons/plus_button.svg';
import FilterButtons from '../../../Common/FilterButtons';
import useWindowSize from '../../../../utils/useWindowSize';
import clx from 'classnames';
import { List, arrayMove } from 'react-movable';

import ConfirmDeleteModal from '../../../Common/ConfirmDeleteModal';
import { WorkerItem } from './containers/WorkerItem';
import { modalActions } from "../../../Modals/store";

const filtersButtons = [
  /* {
    name: 'Tutte',
    id: 0,
  }, */
  {
    name: 'Attivi',
    id: 1,
  },
  {
    name: 'Disabilitate',
    id: 2,
  },
  {
    name: 'Visibili solo su gestionale',
    id: 3,
  },
];

const Workers = () => {
  const dispatch = useDispatch();
  const [activeFilterId, setActiveFilterId] = useState(1);
  const activeSalonId = useSelector(getActiveSalonId());
  const workers = useSelector(getWorkers(activeFilterId, true));
  const { isMobile } = useWindowSize();

  useEffect(() => {
    activeSalonId && dispatch(salonManagementActions.GET_WORKERS.REQUEST());
  }, [activeSalonId]);

  const openCreateWorkerModal = () => {
    dispatch(
      modalActions.OPEN_MODAL.SUCCESS({
        type: 'createWorkerModal',
      }),
    );
  };

  return (
    <div
      className={clx(styles.workers_container, {
        [styles.workers_container_mobile]: isMobile,
      })}
    >
      <div className={styles.header}>
        <TitleSectionBack
          title={'Gestione Poltrone'}
          description={'Crea, modifica e gestisci i tuoi collaboratori all’interno del tuo salone'}
        />
        <PlusIcon className={styles.worker_add_button}
          height="60px" width="60px"
          onClick={openCreateWorkerModal} />
      </div>
      <div className={styles.button_selector_container}>
        <div className={styles.button_selector_title}>Poltrone</div>
        <div className={styles.filters_container}>
          <FilterButtons
            activeFilterId={activeFilterId}
            setActiveFilterId={setActiveFilterId}
            filtersButtons={filtersButtons}
          />
        </div>
      </div>
      {!!workers?.length && <WorkersList workers={workers} />}
    </div>
  );
};

const WorkersList = memo(({ workers = [] }) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState(workers);
  const [workerToDeleteId, setWorkerToDeleteId] = useState(false);

  useEffect(() => {
    setItems(workers)
  }, [workers])

  useEffect(() => {
    if (items.length && workers.length) {
      const changedWorkersPosition = workers.reduce((acc, worker, index) => {

        if (+worker.id === +items[index].id) return acc

        const newPosition = items.findIndex(workerWithNewPosition => +workerWithNewPosition.id === +worker.id)
        const workerNewPosition = {
          workerId: +worker.id,
          position: +newPosition + 1
        }
        return [...acc, workerNewPosition]
      }, [])

      if (changedWorkersPosition.length) {
        dispatch(salonManagementActions.SET_WORKERS_POSITION.REQUEST(changedWorkersPosition));
      }
    }
  }, [items])

  return (
    <>
      <ConfirmDeleteModal
        isModalOpen={workerToDeleteId}
        setIsModalOpen={setWorkerToDeleteId}
        title={'Sei sicuro di voler disabilitare la poltrona?'}
        buttons={['Annulla', 'Disabilita']}
        action={() =>
          dispatch(
            salonManagementActions.DELETE_WORKER.REQUEST(
              {
                idPoltrona: workerToDeleteId,
                disabilitato: 1,
              },
              () => setWorkerToDeleteId(false),
            ),
          )
        }
      />
      <div className={styles.workers_list_container}>
        {!!workers.length && (
          <List
            ref={(ref) => ref}
            values={items}
            onChange={({ oldIndex, newIndex }) => {
              const arrayMoved = arrayMove(workers, oldIndex, newIndex);
              setItems(arrayMoved);
            }}
            renderList={({ children, props, isDragged }) => (
              <ul
                {...props}
                style={{
                  padding: '0em 0em 1em 0em',
                  cursor: isDragged ? 'grabbing' : 'inherit',
                }}
              >
                {children}
              </ul>
            )}
            renderItem={({ value: worker, props, isDragged, isSelected }) => (
              <li
                {...props}
                key={worker.id}
                style={{
                  ...props.style,
                  listStyleType: 'none',
                  cursor: isDragged ? 'grabbing' : 'inherit',
                  display: 'flex',
                  position: isDragged ? 'fixed' : 'relative',
                }}
              >
                <WorkerItem
                  isDragged={isDragged}
                  worker={worker}
                  setWorkerToDeleteId={setWorkerToDeleteId}
                />
              </li>
            )}
          />
        )}
      </div>
    </>
  );
}, (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps)
});

export default Workers;
