import React, { useEffect, useState } from 'react';
import styles from './WorkerWorkTime.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getWorker, getWorkerWorkTimes } from '../../../../store/selectors';
import { salonManagementActions } from '../../../../store';
import { useNavigate, useParams } from 'react-router-dom';
import { getActiveSalonId } from '../../../../../Auth/store/selectors';
import TitleSectionBack from '../../../../../Common/TitleSectionBack';
import AvatarIcon from '../../../../../Common/AvatarIcon';
import { modalActions } from '../../../../../Modals/store';
import clx from 'classnames';
import dayjs from 'dayjs';
import useWindowSize from '../../../../../../utils/useWindowSize';
import { ROUTES } from '../../../../../../routes/constants';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { ReactComponent as ArrowLIcon } from '../../../../../../assets/svg/icons/arrow_l.svg';

const WorkerWorkTime = () => {
  const dispatch = useDispatch();
  const { workerId } = useParams();
  const navigate = useNavigate();
  const worker = useSelector(getWorker());
  const activeSalonId = useSelector(getActiveSalonId());
  const workTimes = useSelector(getWorkerWorkTimes());
  const { isMobile } = useWindowSize();
  useEffect(() => {
    if (workerId !== 'new') {
      dispatch(salonManagementActions.GET_WORKER.REQUEST(workerId));
      dispatch(salonManagementActions.GET_WORKER_WORK_TIMES.REQUEST(workerId));
    }
    return () => {
      dispatch(salonManagementActions.GET_WORKER.CLEAR());
    };
  }, [workerId]);

  useEffect(() => {
    if (worker?.salone && worker?.salone !== activeSalonId) {
      dispatch(salonManagementActions.GET_WORKER.CLEAR());
      navigate(-1);
    }
  }, [worker, activeSalonId]);

  return (
    <div
      className={clx(styles.service_workers_modal_container, {
        [styles.service_workers_modal_container_mobile]: isMobile,
      })}
    >
      <TitleSectionBack title={'Orari'} description={'Gestisci gli orari della tua poltrona'} />
      <div className={styles.name_cell_container}>
        <AvatarIcon name={worker.nome_referente} icon={worker.icon} size={'50px'} />
        <div className={styles.client_info}>
          <div className={styles.description}>Stai modificando gli orari di</div>
          <div className={styles.name}>{worker.nome_referente}</div>
        </div>
      </div>
      <Swiper
        className={styles.time_cards_container}
        style={{ overflow: 'visible' }}
        grabCursor={true}
        slidesPerView={7}
        breakpoints={{
          0: {
            slidesPerView: 1.2,
          },
          400: {
            slidesPerView: 2.2,
          },
          600: {
            slidesPerView: 3.2,
          },
          750: {
            slidesPerView: 4.2,
          },
          900: {
            slidesPerView: 5.2,
          },
          1000: {
            slidesPerView: 6.2,
          },
          1100: {
            slidesPerView: 7,
          },
        }}
        // className={clx(styles.cards_container, {[styles.cards_container_mobile]: isMobile})}
      >
        {workTimes?.map((timeValue) => {
          const today = new Date().getDay();
          const isToday = new Date().getDay() == timeValue.indexDay;

          return (
            <SwiperSlide
              onClick={() =>
                dispatch(
                  modalActions.OPEN_MODAL.SUCCESS({
                    type: 'editWorkerWorkTimeModalOneDay',
                    props: { indexDay: timeValue.indexDay },
                  }),
                )
              }
              className={clx(styles.time_card_container, {
                [styles.active]: isToday,
                [styles.close]: timeValue.chiuso,
              })}
              key={timeValue.indexDay}
            >
              <div className={styles.day_header}>
                <div className={styles.day_name}>
                  {dayjs().day(timeValue.indexDay).format('dddd')}
                </div>
                {timeValue.isFullDay && (
                  <div className={styles.time_value}>
                    {dayjs(timeValue.orariApertura.mattina.dalle, 'HH:mm:ss').format('HH:mm')} -{' '}
                    {dayjs(timeValue.orariApertura.pomeriggio.alle, 'HH:mm:ss').format('HH:mm')}
                  </div>
                )}
                {!timeValue.isFullDay && (
                  <>
                    <div className={styles.time_value}>
                      {dayjs(timeValue.orariApertura.mattina.dalle, 'HH:mm:ss').format('HH:mm')} -{' '}
                      {dayjs(timeValue.orariApertura.mattina.alle, 'HH:mm:ss').format('HH:mm')}
                    </div>
                    <div className={styles.time_value}>
                      {dayjs(timeValue.orariApertura.pomeriggio.dalle, 'HH:mm:ss').format('HH:mm')}{' '}
                      - {dayjs(timeValue.orariApertura.pomeriggio.alle, 'HH:mm:ss').format('HH:mm')}
                    </div>
                  </>
                )}
                <div className={styles.close_value}>Chiuso</div>
              </div>
              <div className={styles.time_action}>Modifica orari &gt;</div>
            </SwiperSlide>
          );
        })}
      </Swiper>

      {/*</div>*/}
      <div className={styles.list_container}>
        <div
          onClick={() =>
            navigate(
              `${ROUTES.SALON_MANAGEMENT}${ROUTES.WORKERS}${ROUTES.CLOSURES}/${worker.idpoltrona}`,
            )
          }
          className={styles.list_item_container}
        >
          <div className={styles.info_block}>
            <div className={styles.description_block}>
              <div className={styles.item_title}>Chiusura straordinaria</div>
              <div className={styles.item_description}>Aggiungi e modifica gli orari della tua poltrona temporaneamente</div>
            </div>
          </div>
          <ArrowLIcon className={styles.arrow} />
        </div>
        <div
          onClick={() =>
            navigate(
              `${ROUTES.SALON_MANAGEMENT}${ROUTES.WORKERS}${ROUTES.EXTRA_WORKING_TIME}/${worker.idpoltrona}`,
            )
          }
          className={styles.list_item_container}
        >
          <div className={styles.info_block}>
            <div className={styles.description_block}>
              <div className={styles.item_title}>Apertura straordinaria</div>
              <div className={styles.item_description}>Aggiungi e modifica gli orari della tua poltrona temporaneamente</div>
            </div>
          </div>
          <ArrowLIcon className={styles.arrow} />
        </div>
      </div>
    </div>
  );
};

export default WorkerWorkTime;
