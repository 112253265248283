import {useDispatch} from "react-redux";
import Modal from "../../../../Common/Modal";
import styles from './DeleteNote.module.scss';
import { Button } from '@mui/material';
import React, {useState, useEffect} from 'react';
import {clientActions} from "../../../store";
import ConfirmSecondaryModal from "../../../../Common/ConfirmSecondaryModal";
import {schedulerActions} from "../../../../Scheduler/store";

const DeleteNote = ({client_id, nota, isModalOpen, setIsModalOpen}) => {
    const dispatch = useDispatch();
    const [editedNota, setEditedNota] = useState(nota?.nota);

    useEffect(() => {
        setEditedNota(nota?.nota);
    }, [nota]);

    const removeNote = (status) => {
        if(status){
            dispatch(
                clientActions.DELETE_NOTE.REQUEST(
                    {
                        idnota: nota?.idutentenota,
                    },() => {
                        dispatch(clientActions.GET_CLIENT.REQUEST({ idcliente: client_id }, () => setIsModalOpen(false)));
                        setEditedNota("");
                    }
                ),
            )
        }else{
            setIsModalOpen(false);
        }
    };

    return (
        <ConfirmSecondaryModal
            // height={content ? '300px' : '230px'}
            isModalOpen={isModalOpen}
            setIsModalOpen={() => {
                setEditedNota("");
                setIsModalOpen(false)
            }}

            title={'Elimina nota'}
            maxWidth={'380px'}
            showCloseBtn={true}
            buttons={['Elimina', "Annulla"]}
            content={
                <div className={styles.main}>
                    <p className={styles.question}>Sei sicuro di voler cancellare questa nota?</p>
                    <p className={styles.nota}>{nota?.nota}</p>
                </div>
            }
            buttonsActions={[() => removeNote(true), () => removeNote(false)]}
        >

        </ConfirmSecondaryModal>
    );
}

export default DeleteNote;
