import { useEffect, useState } from 'react';

var isMobileCache = false;
function useWindowSize() {
  const [isMobile, setIsMobile] = useState(isMobileCache);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 840) {
        if (!isMobile) {
          setIsMobile(true);
          isMobileCache = true;
          setWindowHeight(window.innerHeight);
        }
      } else {
        if (isMobile) {
          setIsMobile(false);
          isMobileCache = false;
          setWindowHeight(window.innerHeight);
        }
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  });

  return { isMobile, windowHeight };
}

export default useWindowSize;
