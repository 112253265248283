import Modal from '../Modal';
import styles from './ConfirmDeleteModal.module.scss';
import { ReactComponent as Bin } from '../../../assets/svg/icons/trash_red.svg';
import { Button } from '@mui/material';
import React from 'react';

const ConfirmDeleteModal = ({
  isModalOpen,
  setIsModalOpen,
  title,
  content,
  action,
  buttons = ['Annulla', 'Disdici'],
  width = '450px',
  buttonsActions = [() => setIsModalOpen(false)],
}) => (
  <Modal
    width={width}
    // height={content ? '300px' : '230px'}
    isModalOpen={isModalOpen}
    setIsModalOpen={setIsModalOpen}
  >
    <div className={styles.modal__content}>
      <Bin />
      <div className={styles.title}>{title}</div>
      {content}
      <div className={styles.modal__actions}>
        {buttons.map((button, index) => (
          <Button
            key={index}
            onClick={buttonsActions[index] || action}
            className={styles.return_button}
          >
            {button} &gt;
          </Button>
        ))}
      </div>
    </div>
  </Modal>
);

export default ConfirmDeleteModal;
