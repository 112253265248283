const { REACT_APP_ENV } = process.env;

const common = {
  env: REACT_APP_ENV,
};

const config = {
  ...common,
  api_url: process.env.REACT_APP_API_URL,
  payments_url: process.env.REACT_APP_PAYMENTS_URL,
  zb_blog: process.env.REACT_APP_ZB_BLOG,
  shopUrl: process.env.REACT_APP_ZB_SHOP,
  sse_url: process.env.REACT_APP_SSE_URL,
};

export default config;
