import React from 'react';
import styles from './WorkerWorkTimeOneDayModal.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getWorker, getWorkerWorkTimes } from '../../../../store/selectors';
import { Field, Form, Formik } from 'formik';
import TextField from '../../../../../Common/TextField';
import CustomTimePicker from '../../../../../Common/CustomTimePicker';
import dayjs from 'dayjs';
import { salonManagementActions } from '../../../../store';
import { Button } from '@mui/material';
import clx from 'classnames';
import { ReactComponent as Bin } from '../../../../../../assets/svg/icons/trash_red.svg';

const arrayToFormikObject = (array = [], keyName) => {
  return array.reduce((acc, item) => {
    acc[item[keyName]] = item;
    return acc;
  }, {});
};

const WorkerWorkTimeOneDayModal = ({ workDayValue, closeModal }) => {
  const dispatch = useDispatch();
  const workTimeData = useSelector(getWorkerWorkTimes());
  const worker = useSelector(getWorker());

  const initialValues = JSON.parse(
    JSON.stringify({
      ...arrayToFormikObject(workTimeData, 'indexDay'),
      activeDayIndex: workDayValue.indexDay,
    }),
  );
  if (initialValues[workDayValue.indexDay].chiuso) {
    initialValues[workDayValue.indexDay].orariApertura.mattina.dalle = '07:00:00';
    initialValues[workDayValue.indexDay].orariApertura.mattina.alle = '13:00:00';
    initialValues[workDayValue.indexDay].orariApertura.pomeriggio.dalle = '15:00:00';
    initialValues[workDayValue.indexDay].orariApertura.pomeriggio.alle = '18:00:00';
  }
  return (
    <div className={clx(styles.worker_time_modal_container)}>
      <div className={styles.worker_name}>{worker.nome_referente || ''}</div>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values) => {
          const { activeDayIndex, ...rest } = values;
          const blockedStartTime = { dalle: '00:00:00' };
          const blockedEndTime = { alle: '00:00:00' };
          const blockedTime = {
            ...blockedStartTime,
            ...blockedEndTime,
          };

          const formedValues = Object.entries(rest).map(([_, item]) => ({
            ...item,
            orariApertura: {
              mattina: item.chiuso
                ? blockedTime
                : item.isFullDay
                ? { dalle: item.orariApertura.mattina.dalle, ...blockedEndTime }
                : item.orariApertura.mattina,
              pomeriggio: item.chiuso
                ? blockedTime
                : item.isFullDay
                ? { ...blockedStartTime, alle: item.orariApertura.pomeriggio.alle }
                : item.orariApertura.pomeriggio,
            },
          }));
          dispatch(
            salonManagementActions.SET_WORKER_WORK_TIMES.REQUEST(
              {
                workTimes: formedValues,
                workerIdForCopy: worker.idpoltrona,
              },
              closeModal,
            ),
          );
        }}
      >
        {({ values, setFieldValue }) => (
          <Form className={styles.form_container}>
            <Field
              name={`${values.activeDayIndex}.chiuso`}
              id={`${values.activeDayIndex}.chiuso`}
              label='Chiuso'
              checkBoxAsRadio={true}
              component={TextField}
              margin={'25px 0 0 0'}
            />
            <div
              className={clx(styles.time_selector_wrapper, {
                [styles.time_selector_wrapper_hide]: values[values.activeDayIndex]?.chiuso,
              })}
            >
              <div className={styles.time_selector_title}>Imposta un orario</div>
              <div
                className={clx(styles.time_selector_container, {
                  [styles.time_selector_container_full_day]:
                    values[values.activeDayIndex]?.isFullDay,
                })}
              >
                <div
                  className={clx(
                    styles.time_selector_item_container,
                    styles.time_selector_item_container_full_day,
                  )}
                >
                  <div className={styles.time_selector_item}>
                    <CustomTimePicker
                      title={'Dalle'}
                      value={dayjs(
                        values[values.activeDayIndex]?.orariApertura?.mattina?.dalle,
                        'HH:mm:ss',
                      )}
                      setValue={(time) =>
                        setFieldValue(
                          `${values.activeDayIndex}.orariApertura.mattina.dalle`,
                          time.format('HH:mm:ss'),
                        )
                      }
                      disabled={values[values.activeDayIndex]?.chiuso}
                      maxTime={dayjs(
                        values[values.activeDayIndex]?.orariApertura?.pomeriggio?.alle,
                        'HH:mm:ss',
                      )}
                    />
                  </div>
                  <div className={clx(styles.time_selector_item, styles.time_selector_item_delete)}>
                    <CustomTimePicker
                      title={'Alle'}
                      value={dayjs(
                        values[values.activeDayIndex]?.orariApertura?.pomeriggio?.alle,
                        'HH:mm:ss',
                      )}
                      setValue={(time) =>
                        setFieldValue(
                          `${values.activeDayIndex}.orariApertura.pomeriggio.alle`,
                          time.format('HH:mm:ss'),
                        )
                      }
                      disabled={values[values.activeDayIndex]?.chiuso}
                      minTime={dayjs(
                        values[values.activeDayIndex]?.orariApertura?.mattina?.dalle,
                        'HH:mm:ss',
                      )}
                    />
                    <Bin
                      onClick={() => {
                        !values[values.activeDayIndex]?.isFullDay &&
                          setFieldValue(`${values.activeDayIndex}.isFullDay`, true);
                      }}
                    />
                  </div>
                </div>

                <div
                  className={clx(
                    styles.time_selector_item_container,
                    styles.time_selector_item_container_all_fields,
                  )}
                >
                  <div className={styles.time_selector_item}>
                    <CustomTimePicker
                      title={'Dalle'}
                      value={dayjs(
                        values[values.activeDayIndex]?.orariApertura?.mattina?.dalle,
                        'HH:mm:ss',
                      )}
                      setValue={(time) =>
                        setFieldValue(
                          `${values.activeDayIndex}.orariApertura.mattina.dalle`,
                          time.format('HH:mm:ss'),
                        )
                      }
                      disabled={values[values.activeDayIndex]?.chiuso}
                      maxTime={dayjs(
                        values[values.activeDayIndex]?.orariApertura?.mattina?.alle,
                        'HH:mm:ss',
                      )}
                    />
                  </div>
                  <div className={clx(styles.time_selector_item, styles.time_selector_item_delete)}>
                    <CustomTimePicker
                      title={'Alle'}
                      value={dayjs(
                        values[values.activeDayIndex]?.orariApertura?.mattina?.alle,
                        'HH:mm:ss',
                      )}
                      setValue={(time) =>
                        setFieldValue(
                          `${values.activeDayIndex}.orariApertura.mattina.alle`,
                          time.format('HH:mm:ss'),
                        )
                      }
                      disabled={values[values.activeDayIndex]?.chiuso}
                      minTime={dayjs(
                        values[values.activeDayIndex]?.orariApertura?.mattina?.dalle,
                        'HH:mm:ss',
                      )}
                    />
                    <Bin
                      onClick={() => {
                        !values[values.activeDayIndex]?.isFullDay &&
                          setFieldValue(`${values.activeDayIndex}.isFullDay`, true);
                      }}
                    />
                  </div>
                </div>

                <div
                  className={clx(
                    styles.time_selector_item_container,
                    styles.time_selector_item_container_all_fields,
                  )}
                >
                  <div className={styles.time_selector_item}>
                    <CustomTimePicker
                      title={'Dalle'}
                      value={dayjs(
                        values[values.activeDayIndex]?.orariApertura?.pomeriggio?.dalle,
                        'HH:mm:ss',
                      )}
                      setValue={(time) =>
                        setFieldValue(
                          `${values.activeDayIndex}.orariApertura.pomeriggio.dalle`,
                          time.format('HH:mm:ss'),
                        )
                      }
                      disabled={values[values.activeDayIndex]?.chiuso}
                      maxTime={dayjs(
                        values[values.activeDayIndex]?.orariApertura?.pomeriggio?.alle,
                        'HH:mm:ss',
                      )}
                    />
                  </div>
                  <div className={clx(styles.time_selector_item, styles.time_selector_item_delete)}>
                    <CustomTimePicker
                      title={'Alle'}
                      value={dayjs(
                        values[values.activeDayIndex]?.orariApertura?.pomeriggio?.alle,
                        'HH:mm:ss',
                      )}
                      setValue={(time) =>
                        setFieldValue(
                          `${values.activeDayIndex}.orariApertura.pomeriggio.alle`,
                          time.format('HH:mm:ss'),
                        )
                      }
                      disabled={values[values.activeDayIndex]?.chiuso}
                      minTime={dayjs(
                        values[values.activeDayIndex]?.orariApertura?.pomeriggio?.dalle,
                        'HH:mm:ss',
                      )}
                    />
                    <Bin
                      onClick={() => {
                        !values[values.activeDayIndex]?.isFullDay &&
                          setFieldValue(`${values.activeDayIndex}.isFullDay`, true);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                onClick={() => {
                  values[values.activeDayIndex]?.isFullDay &&
                    setFieldValue(`${values.activeDayIndex}.isFullDay`, false);
                }}
                className={clx(styles.time_selector_add_new_field, {
                  [styles.time_selector_add_new_field_hide]:
                    !values[values.activeDayIndex]?.isFullDay,
                })}
              >
                Aggiungi un orario
              </div>
            </div>
            <div className={styles.form_actions}>
              <Button className={styles.confirm_button} variant='contained' type={'submit'}>
                Salva
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default WorkerWorkTimeOneDayModal;
