import React, { useState } from 'react';
import { getActiveSalon } from 'Containers/Auth/store/selectors';
import { useSelector } from 'react-redux';
import styles from './bannerBlock.module.scss';
import { Button } from '@mui/material';
import clsx from 'clsx';
import useWindowSize from 'utils/useWindowSize';

const BannerBlock = ({ title, subtitle, description, img, action, onClick = () => {}, background }) => {
  const { isMobile } = useWindowSize();

  return (
    <section className={clsx(styles.container, { [styles.container_mbl]: isMobile, [styles.container_bg]: background } )}>
        {background && <img className={styles.img_bg} src={background} alt=''/>}
      <summary className={styles.header}>
        <span className={styles.h1}>{title}</span>
        <p className={styles.description}>{subtitle}</p>
        <p className={styles.description}>{description}</p>
      </summary>
      {img && <img className={styles.img} src={img} alt='' />}
      <Button onClick={onClick} className={styles.confirm_button}>
          {action}
      </Button>
    </section>
  );
};
export default BannerBlock;
