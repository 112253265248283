import React from 'react';
import CustomTable from '../../../../../Common/CustomTable';
import { ReactComponent as Bin } from '../../../../../../assets/svg/icons/trash_red.svg';
import { ReactComponent as PlusInCircle } from '../../../../../../assets/svg/plus_circle_blue.svg';
import styles from './ExtraWorkingTimeTable.module.scss';
import dayjs from 'dayjs';
import { modalActions } from '../../../../../Modals/store';
import { useDispatch } from 'react-redux';

export default ({ values, worker, setExtraTimeIdToDelete, isEmptyMessage = "Non ci sono dati al momento" }) => {
  const dispatch = useDispatch();

  const data = React.useMemo(() => values, [values]);

  const columns = React.useMemo(
    () => [
      {
        Header: '',
        id: 'Header',
        columns: [
          {
            Header: 'Giorno',
            accessor: 'giorno',
            id: 'day',
            Cell: ({ value }) => (
              <div className={styles.day_container}>
                <div className={styles.day_value}>{dayjs().day(value).format('dddd')}</div>
              </div>
            ),
          },
          {
            Header: 'Periodo',
            accessor: 'dataInizio',
            Cell: ({ row: { values } }) => (
              <>
                dal {dayjs(values.dataInizio).format('DD/MM/YY')} al{' '}
                {dayjs(values.dataFine).format('DD/MM/YY')}
              </>
            ),
          },
          {
            Header: 'Orario',
            accessor: 'dataFine',
            Cell: ({ row: { original } }) => {
              const morningStartTime = dayjs(original?.oraInizioMattina, 'HH:mm:ss').format(
                'HH:mm',
              );
              const morningEndTime = dayjs(original?.oraFineMattina, 'HH:mm:ss').format('HH:mm');
              const eveningStartTime = dayjs(original?.oraInizioPomeriggio, 'HH:mm:ss').format(
                'HH:mm',
              );
              const eveningEndTime = dayjs(original?.oraFinePomeriggio, 'HH:mm:ss').format('HH:mm');

              const isFullDay =
                original?.oraFineMattina === '00:00:00' &&
                original?.oraInizioPomeriggio === '00:00:00';
              return (
                <div className={styles.time_cell}>
                  {isFullDay && (
                    <div className={styles.time_cell_value}>
                      dalle {morningStartTime}&nbsp; alle {eveningEndTime}&nbsp;
                    </div>
                  )}
                  {!isFullDay && (
                    <>
                      <div className={styles.time_cell_value}>
                        dalle {morningStartTime}&nbsp; alle {morningEndTime}&nbsp;
                      </div>
                      <div className={styles.time_cell_value}>
                        dalle {eveningStartTime}&nbsp; alle {eveningEndTime}&nbsp;
                      </div>
                    </>
                  )}
                </div>
              );
            },
          },
          {
            Header: 'Pagamento Online Obbligatorio',
            accessor: 'mustPay',
            Cell: ({ value }) => (
                value 
                ? <div className={styles.badge_payment_active}>Attivo</div>
                : <div className={styles.badge_payment}>Non Attivo</div>
            ),
          },
          {
            Header: '',
            accessor: 'idPoltronaOrari',
            Cell: ({ value }) => (
              <div
                className={styles.delete_cell_container}
                onClick={() => setExtraTimeIdToDelete(value)}
              >
                <div className={styles.text}>Cancella apertura</div>
                <Bin />
              </div>
            ),
          },
        ],
        Footer: () => {
          return (
            <div className={styles.action_footer}>
              <div
                onClick={() =>
                  dispatch(
                    modalActions.OPEN_MODAL.SUCCESS({
                      type: 'workerExtraWorkingTimeModal',
                      props: { worker: worker?.nome_referente ?? null }
                    }),
                  )
                }
                className={styles.action_button}
              >
                Nuova apertura straordinaria
                <PlusInCircle />
              </div>
            </div>
          );
        },
      },
    ],
    [],
  );

  return data?.length ? (
    <CustomTable data={data} columns={columns} />
  ) : (
    <div>
      <div className={styles.empty_card}>
        {isEmptyMessage}
      </div>
      <div className={styles.action_footer}>
        <div
          onClick={() =>
            dispatch(
              modalActions.OPEN_MODAL.SUCCESS({
                type: 'workerExtraWorkingTimeModal',
                props: { worker: worker?.nome_referente ?? null }
              }),
            )
          }
          className={styles.action_button}
        >
          Nuova apertura straordinaria
          <PlusInCircle />
        </div>
      </div>
    </div>
  );
};
