import React, { useEffect, useState } from 'react';
import styles from './ExtraWorkingTime.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  getWorker,
  getWorkerClosures,
  getWorkerExtraWorkingTime,
  getWorkerWorkTimes,
} from '../../../../store/selectors';
import { salonManagementActions } from '../../../../store';
import { useNavigate, useParams } from 'react-router-dom';
import { getActiveSalonId } from '../../../../../Auth/store/selectors';
import TitleSectionBack from '../../../../../Common/TitleSectionBack';
import AvatarIcon from '../../../../../Common/AvatarIcon';
import { modalActions } from '../../../../../Modals/store';
import clx from 'classnames';
import useWindowSize from '../../../../../../utils/useWindowSize';
import ConfirmDeleteModal from '../../../../../Common/ConfirmDeleteModal';
import { Button } from '@mui/material';
import CustomDatePicker from '../../../../../Common/CustomDatePicker';
import CollapsibleCard from '../../../../../Common/CollapsibleCard';
import ExtraWorkingTimeTable from '../ExtraWorkingTimeTable';
import { ReactComponent as PlusIcon } from '../../../../../../assets/svg/icons/plus_button.svg';
import { ReactComponent as Bin } from '../../../../../../assets/svg/icons/trash_red.svg';
import dayjs from 'dayjs';

const ExtraTimeCardsList = ({
  extraTime = [],
  setExtraTimeIdToDelete,
  isEmptyMessage = 'non ci sono dati disponibili',
}) => {
  return (
    <div className={styles.extra_time_cards_list_container}>
      {!extraTime.length ? (
        <div className={styles.empty_card}>{isEmptyMessage}</div>
      ) : (
        extraTime.map((item) => {
          const morningStartTime = dayjs(item?.oraInizioMattina, 'HH:mm:ss').format('HH:mm');
          const morningEndTime = dayjs(item?.oraFineMattina, 'HH:mm:ss').format('HH:mm');
          const eveningStartTime = dayjs(item?.oraInizioPomeriggio, 'HH:mm:ss').format('HH:mm');
          const eveningEndTime = dayjs(item?.oraFinePomeriggio, 'HH:mm:ss').format('HH:mm');

          const isFullDay =
            item?.oraFineMattina === '00:00:00' && item?.oraInizioPomeriggio === '00:00:00';

          return (
            <div key={item.idPoltronaOrari} className={styles.extra_time_card}>
              <div className={styles.card_row}>
                <div className={styles.row_title}>Giorno</div>
                <div className={styles.row_value}>{dayjs().day(item.giorno).format('dddd')}</div>
              </div>
              <div className={styles.card_row}>
                <div className={styles.row_title}>Periodo</div>
                <div className={styles.row_value}>
                  dal {dayjs(item.dataInizio).format('DD/MM/YY')} al{' '}
                  {dayjs(item.dataFine).format('DD/MM/YY')}
                </div>
              </div>
              <div className={styles.card_row}>
                <div className={styles.row_title}>Orario</div>
                {isFullDay && (
                  <div className={styles.row_value}>
                    dalle {morningStartTime}&nbsp; alle {eveningEndTime}&nbsp;
                  </div>
                )}
                {!isFullDay && (
                  <div className={styles.row_value}>
                    dalle {morningStartTime}&nbsp; alle {morningEndTime}&nbsp;
                    <br />
                    dalle {eveningStartTime}&nbsp; alle {eveningEndTime}&nbsp;
                  </div>
                )}
              </div>
              <div className={styles.card_row_payment}>
                <div className={styles.row_title}>Pagamento Online obbligatorio</div>
                  {item.mustPay 
                    ? <div className={styles.badge_payment_active}>Attivo</div>
                    : <div className={styles.badge_payment}>Non Attivo</div>
                  }
              </div>
              <Button
                onClick={() => setExtraTimeIdToDelete(item.idPoltronaOrari)}
                className={styles.delete_button}
              >
                <Bin />
                Cancella apertura
              </Button>
            </div>
          );
        })
      )}
    </div>
  );
};

const ExtraWorkingTime = () => {
  const dispatch = useDispatch();
  const { workerId } = useParams();
  const navigate = useNavigate();
  const worker = useSelector(getWorker());
  const activeSalonId = useSelector(getActiveSalonId());
  const { isMobile } = useWindowSize();

  const [tablesFilterDate, setTablesFilterDate] = useState(null);
  const extraWorkingTime = useSelector(getWorkerExtraWorkingTime(tablesFilterDate));
  const [extraTimeIdToDelete, setExtraTimeIdToDelete] = useState(false);

  useEffect(() => {
    dispatch(salonManagementActions.GET_WORKER.REQUEST(workerId));
    return () => {
      dispatch(salonManagementActions.GET_WORKER.CLEAR());
    };
  }, [workerId]);

  useEffect(() => {
    if (worker?.salone && worker?.salone !== activeSalonId) {
      dispatch(salonManagementActions.GET_WORKER.CLEAR());
      navigate(-1);
    }
  }, [worker, activeSalonId]);

  return (
    <>
      <ConfirmDeleteModal
        isModalOpen={extraTimeIdToDelete}
        setIsModalOpen={setExtraTimeIdToDelete}
        title={'Sei sicuro di voler disabilitare la poltrona extra time?'}
        buttons={['Annulla', 'Disabilita']}
        action={() =>
          dispatch(
            salonManagementActions.DELETE_WORKER_EXTRA_TIME.REQUEST(
              {
                idPoltronaOrari: extraTimeIdToDelete,
              },
              () => setExtraTimeIdToDelete(false),
            ),
          )
        }
      />
      <div
        className={clx(styles.extra_time_workers_container, {
          [styles.extra_time_workers_container_mobile]: isMobile,
        })}
      >
        <TitleSectionBack
          title={'Apertura straordinaria'}
          description={`Modifica orari temporaneamente. Con l'apertura straordinaria crei nuovi orari per i giorni selezionati che vanno a sostituire completamente quelli esistenti!`}
        />
        <PlusIcon
          className={styles.extra_time_add_button}
          height='60px'
          width='60px'
          onClick={() =>
            dispatch(
              modalActions.OPEN_MODAL.SUCCESS({
                type: 'workerExtraWorkingTimeModal',
                props: { worker: worker.nome_referente },
              }),
            )
          }
        />
        <div className={styles.tables_actions}>
          <div className={styles.name_cell_container}>
            <AvatarIcon name={worker.nome_referente} icon={worker.icon} size={'50px'} />
            <div className={styles.client_info}>
              <div className={styles.description}>Stai modificando gli orari di</div>
              <div className={styles.name}>{worker.nome_referente}</div>
            </div>
          </div>
          <div className={styles.picker_container}>
            <CustomDatePicker
              type={'button'}
              value={tablesFilterDate}
              setValue={setTablesFilterDate}
              placeholder={'Filtra per data'}
              disablePast={true}
            />
          </div>
        </div>
        <CollapsibleCard title={'Aperture straordinarie'}>
          {isMobile ? (
            <ExtraTimeCardsList
              extraTime={extraWorkingTime}
              setExtraTimeIdToDelete={setExtraTimeIdToDelete}
              isEmptyMessage='Questa poltrona non ha aperture straordinarie attive al momento'
            />
          ) : (
            <ExtraWorkingTimeTable
              values={extraWorkingTime}
              worker={worker}
              setExtraTimeIdToDelete={setExtraTimeIdToDelete}
              isEmptyMessage='Questa poltrona non ha aperture straordinarie attive al momento'
            />
          )}
        </CollapsibleCard>
      </div>
    </>
  );
};

export default ExtraWorkingTime;
