import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { clientActions } from '../../../Clients/store';
import { getClientsShort } from '../../../Clients/store/selectors';
import { getCalendarWorkers, getCalendarWorkerServices, getTaskById } from '../../store/selectors';
import InputSelector from '../../../Common/CustomSelector/InputSelector';
import MultiplySelector from '../../../Common/CustomSelector/MultiplySelector';
import TextField from '../../../Common/TextField';
import { ReactComponent as Calendar } from '../../../../assets/svg/icons/calendar_blue.svg';
import { ReactComponent as Scissors } from '../../../../assets/svg/icons/scissors_blue.svg';
import { ReactComponent as Person } from '../../../../assets/svg/icons/person_blue.svg';
import { ReactComponent as Armchair } from '../../../../assets/svg/icons/armchair_blue.svg';
import { ReactComponent as Bin } from '../../../../assets/svg/icons/trash_red.svg';
import CustomDateTimePicker from '../../../Common/CustomDateTimePicker';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import styles from './BreakModal.module.scss';
import Switcher from '../../../Common/Switcher';
import HoveredInfoIcon from '../../../Common/HoveredInfoIcon';
import ButtonSelector from '../../../Common/CustomSelector/ButtonSelector';
import { Button } from '@mui/material';
import CustomTimePicker from '../../../Common/CustomTimePicker';
import clx from 'classnames';
import CustomDatePicker from '../../../Common/CustomDatePicker';
import { schedulerActions } from '../../store';
import ConfirmDeleteModal from '../../../Common/ConfirmDeleteModal';
import useWindowSize from '../../../../utils/useWindowSize';
import { useSearchParams } from 'react-router-dom';

const recurrenceOptions = [
  {
    name: 'Ogni settimana',
    value: 1,
  },
  {
    name: 'Ogni due settimana',
    value: 2,
  },
  {
    name: 'Ogni tre settimana',
    value: 3,
  },
  {
    name: 'Ogni quattro settimana',
    value: 4,
  },
];

const BreakModal = ({ closeModal }) => {
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const [searchParams, _] = useSearchParams();
  const date = searchParams?.get('startDay') || dayjs().format('YYYY-MM-DD');
  const isCurrent = date === dayjs().format('YYYY-MM-DD');

  useEffect(() => {}, [dispatch]);

  const workers = useSelector(getCalendarWorkers());
  const currentMinute = dayjs().minute();
  const minuteRounded = currentMinute - (currentMinute % 5) + 5;
  const initialValues = {
    worker_id: undefined,
    startDate: (isCurrent ? dayjs() : dayjs(date)).set('minutes', minuteRounded),
    endDate: (isCurrent ? dayjs() : dayjs(date)).set('minutes', minuteRounded).add(15, 'minutes'),
    dateBreak: dayjs(date),
  };

  const validationSchema = {
    worker_id: yup.string().required('Seleziona una poltrona'),
    startDate: yup.date().required('Orario inizio obbligatorio'),
    endDate: yup.date().required('Orario fine obbligatorio'),
    dateBreak: yup.date().required('Data obbligatoria'),
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={yup.object().shape(validationSchema)}
        onSubmit={(values) => {
          const formedTask = {
            oraInizio: values.startDate.format('HH:mm:ss'),
            oraFine: values.endDate.format('HH:mm:ss'),
            dataPrenotazione: values.dateBreak.format('YYYY-MM-DD'),
            poltrone: [
              {
                idpoltrona: values.worker_id,
              },
            ],
          };
          dispatch(
            schedulerActions.ADD_CALENDAR_BREAK.REQUEST(formedTask, () => {
              dispatch(
                schedulerActions.GET_CALENDAR_DATA.REQUEST({
                  startDate: dayjs(date).format('YYYY-MM-DD'),
                  endDate: dayjs(date).add(1, 'day').format('YYYY-MM-DD'),
                }),
              );
            }),
          );
          closeModal();
        }}
      >
        {({ values, setFieldValue, errors, touched, setFieldTouched }) => (
          <Form
            className={clx(styles.create_task_form, {
              [styles.create_task_form_mobile]: isMobile,
            })}
          >
            <div className={styles.body}>
              <div className={styles.inputs_wrapper}>
                <InputSelector
                  options={workers}
                  value={values.worker_id}
                  setValue={(id) => {
                    setFieldValue('worker_id', id);
                    setFieldTouched('worker_id', false);
                  }}
                  placeholder={'Seleziona poltrona'}
                  title={'Poltrona'}
                  icon={<Armchair />}
                  withIcon={true}
                  error={!!touched.worker_id || !!errors.worker_id}
                  isEmptyMessage={'Non ci sono poltrone'}
                />
              </div>

              <div className={styles.inputs_wrapper}>
                <div className={styles.input_wrapper}>
                  <CustomTimePicker
                    value={values.startDate}
                    setValue={(date) => {
                      setFieldValue('startDate', date);
                      setFieldTouched('startDate', false);
                      if (
                        dayjs(date).get('hour') * 60 + dayjs(date).get('minute') >
                        dayjs(values.endDate).get('hour') * 60 + dayjs(values.endDate).get('minute')
                      ) {
                        setFieldValue('endDate', date.add(15, 'minute'));
                      }
                    }}
                    placeholder={'Select date'}
                    title={'Orario inizio'}
                    icon={<Calendar />}
                    withIcon={true}
                    // maxTime={values.endDate.subtract(15, 'minutes')}
                    error={touched.startDate && errors.startDate}
                  />
                </div>
                <div className={styles.input_wrapper}>
                  <CustomTimePicker
                    value={values.endDate}
                    setValue={(date) => {
                      setFieldValue('endDate', date);
                      setFieldTouched('endDate', false);
                    }}
                    placeholder={'Select date'}
                    title={'Orario fine'}
                    icon={<Calendar />}
                    withIcon={true}
                    minTime={values.startDate.add(15, 'minute')}
                    error={touched.endDate && errors.endDate}
                  />
                </div>
              </div>

              <CustomDatePicker
                value={values.dateBreak}
                setValue={(date) => {
                  setFieldValue('dateBreak', date);
                  setFieldTouched('dateBreak', false);
                }}
                placeholder={'Select date'}
                title={'Data'}
                icon={<Calendar />}
                withIcon={true}
                minDate={values.startDate}
                error={touched.dateBreak && errors.dateBreak}
              />
            </div>
            <hr />
            <div className={clx(styles.form_actions, styles.footer)}>
              <div className={styles.confirm_button_block}>
                {!isMobile && (
                  <Button onClick={closeModal} className={styles.return_button}>
                    Annulla &gt;
                  </Button>
                )}
                <Button className={styles.confirm_button} variant='contained' type={'submit'}>
                  Conferma
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default BreakModal;
