import actionCreator from '../../../utils/actionCreator';

const clientActionTypes = [
  'GET_CLIENTS',
  'GET_CLIENTS_PLUS',
  'GET_CLIENT',
  'ADD_CLIENT',
  'DELETE_CLIENT',
  'ENABLE_CLIENT',
  'GET_BAD_CLIENTS',

  'DELETE_CLIENT_APPOINTMENT',
  'EDIT_CLIENT',
  'EDIT_CLIENT_PHONE',
  'ADD_NOTE',
  'EDIT_NOTE',
  'DELETE_NOTE',
  'GET_PRENOTAZIONE_BY_ID',
];

export const { types: clientTypes, actions: clientActions } = actionCreator(clientActionTypes);
