import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getToastProps } from '../../store/selectors';
import styles from './Toast.module.scss';

import { toastActions } from '../../store';
import { GeneralToast } from 'Containers/Scheduler/containers/Toast/general';
import { toast } from 'react-toastify';


export default () => {
  const dispatch = useDispatch();
  const { type, props, callback } = useSelector(getToastProps());
  const closeAllToasts = () => {
    dispatch(toastActions.CLOSE_TOAST.SUCCESS());
  };
  const errorStyle = 'toast_warning';
  const successStyle = 'toast_success';
  let css = props.warning ? errorStyle : successStyle;

  const setToast = (type) => {
    const toastConfig = {
      autoClose: 3000,
      className: styles[css],
      hideProgressBar: false,
      position: toast.POSITION.TOP_RIGHT,
      progressClassName: styles[css+'_progress'],
      closeOnClick: true,
      // pauseOnHover: true,
      progress: undefined,
      theme: 'light',
    };

    // setTimeout(() => {
    //   closeAllToasts();
    //   console.log("aaa")
    // }, 1000);

    switch (type) {
      case 'service':
        toast(<GeneralToast booking={props} type={type} />, toastConfig);
        closeAllToasts();
        break;

      case 'worker':
        toast(<GeneralToast booking={props} type={type} />, toastConfig);
        closeAllToasts();
        break;
      
      case 'client':
        toast(<GeneralToast booking={props} type={type} />, toastConfig);
        closeAllToasts();
        break;

      case 'payment':
        toast(<GeneralToast booking={props} type={type} />, toastConfig);
        closeAllToasts();
        break;

      default:
        toast(<GeneralToast booking={props} type={type} />, toastConfig);
        closeAllToasts();
    }

    
  };

  useEffect(() => {
    if(type) setToast(type);

  }, [type]);
  
  return (
    <>
    </>
  )
}
