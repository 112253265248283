import React, { memo, useEffect, useState } from 'react';
import styles from './ServicesList.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getServices, getWorker, getWorkerServices } from '../../../../store/selectors';
import { ReactComponent as PlusIcon } from 'assets/svg/icons/plus_button.svg';
import { salonManagementActions } from '../../../../store';
import { useNavigate, useParams } from 'react-router-dom';
import { getActiveSalonId } from '../../../../../Auth/store/selectors';
import TitleSectionBack from '../../../../../Common/TitleSectionBack';
import AvatarIcon from '../../../../../Common/AvatarIcon';
import useWindowSize from '../../../../../../utils/useWindowSize';
import clx from 'classnames';
import FilterButtons from 'Containers/Common/FilterButtons';
import { modalActions } from 'Containers/Modals/store';
import { ServiceShortItem } from './ServiceShortItem';
import ConfirmDeleteModal from 'Containers/Common/ConfirmDeleteModal';

const filtersButtons = [
  {
    name: 'Attivi',
    id: 1,
  },
  {
    name: 'Visibili solo su gestionale',
    id: 3,
  },
];


const WorkerServices = () => {
  const dispatch = useDispatch();
  const { workerId } = useParams();
  const { isMobile } = useWindowSize();
  const navigate = useNavigate();
  const [activeFilterId, setActiveFilterId] = useState(1);
  const worker = useSelector(getWorker());
  const services = useSelector(getServices(activeFilterId));
  const workerServices = useSelector(getWorkerServices(activeFilterId));
  const activeSalonId = useSelector(getActiveSalonId());
  const [activeServices, setActiveServices] = useState(worker.activeServicesIds);
  const formedServices = workerServices?.filter(service => activeServices.includes(service.idservezio)).sort((a,b) => a.position - b.position).map((service) => ({
    name: service.name,
    value: service.idservezio,
    icon: service.icon,
    cost: service.prezzo,
    duration: service.time,
    onlyVisibile: service.onlyVisibile,
    id: service.idServiziPoltrona,
    position: service.position,
  }));


  useEffect(() => {
    worker.activeServicesIds && setActiveServices(worker.activeServicesIds);
  }, [worker.activeServicesIds]);
  useEffect(() => {
    if (workerId !== 'new') {
      dispatch(salonManagementActions.GET_WORKER.REQUEST(workerId));
      dispatch(salonManagementActions.GET_SERVICES.REQUEST());
      dispatch(salonManagementActions.GET_WORKER_SERVICES.REQUEST(workerId));
    }
    return () => {
      dispatch(salonManagementActions.GET_WORKER.CLEAR());
      dispatch(salonManagementActions.GET_SERVICES.CLEAR());
      dispatch(salonManagementActions.GET_WORKER_SERVICES.CLEAR());
    };
  }, [workerId]);

  useEffect(() => {
    if (worker?.salone && worker?.salone !== activeSalonId) {
      dispatch(salonManagementActions.GET_WORKER.CLEAR());
      dispatch(salonManagementActions.GET_SERVICES.CLEAR());
      dispatch(salonManagementActions.GET_WORKER_SERVICES.CLEAR());
      navigate(-1);
    }
  }, [worker, activeSalonId]);


  return (
    <div
      className={clx(styles.service_workers_modal_container, {
        [styles.service_workers_modal_container_mobile]: isMobile,
      })}
    >
      <div className={styles.header}>
        <TitleSectionBack
          title={'Associa servizi'}
          description={'Gestisci i servizi della tua poltrona'}
        />
        <PlusIcon className={styles.worker_add_button}
            height="60px" width="60px"
            onClick={()=>
              dispatch(modalActions.OPEN_MODAL.SUCCESS({
                type: 'joinServicesModal',
                props: { worker, activeServices },
              }))
            } 
        />
      </div>
      <div className={styles.name_cell_container}>
        <AvatarIcon name={worker.nome_referente} icon={worker.icon} size={'50px'} />
        <div className={styles.client_info}>
          <div className={styles.description}>Stai modificando i servizi di</div>
          <div className={styles.name}>{worker.nome_referente}</div>
        </div>
      </div>
      <div className={styles.button_selector_container}>
        <div className={styles.button_selector_title}>Servizi</div>
        <div className={styles.filters_container}>
          <FilterButtons
            activeFilterId={activeFilterId}
            setActiveFilterId={setActiveFilterId}
            filtersButtons={filtersButtons}
          />
        </div>
      </div>
      {!!formedServices?.length && <ServicesList services={formedServices} />}
    </div>
  );
};

const ServicesList = memo(
  ({ services = [] }) => {
    const dispatch = useDispatch();
    const [serviceToDeleteId, setServiceToDeleteId] = useState(false);
    const [items, setItems] = useState(services);

    useEffect(() => {
      setItems(services);
    }, [services]);

    const openEditServiceModal = (service) => {
      dispatch(
        modalActions.OPEN_MODAL.SUCCESS({
          type: 'editWorkerServiceModal',
          props: service,
        }),
      );
    };

    return (
      <>
        <ConfirmDeleteModal
          isModalOpen={serviceToDeleteId}
          setIsModalOpen={setServiceToDeleteId}
          title={'Sei sicuro di voler disabilitare il servizio?'}
          buttons={['Annulla', 'Disabilita']}
          action={() =>
            dispatch(
              salonManagementActions.DELETE_WORKER_SERVICE.REQUEST(
                {
                  idServiziPoltrona: serviceToDeleteId,
                },
                () => setServiceToDeleteId(false),
              ),
            )
          }
        />
        <div className={styles.services_list_container}>
          {items.map((service) => (
                <ServiceShortItem
                  key={service.value}
                  service={service}
                  openEditServiceModal={openEditServiceModal}
                  setServiceToDeleteId={setServiceToDeleteId}
                />
          ))}
        </div>
      </>
    );
  },
  (prevProps, nextProps) => {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
  },
);


export default WorkerServices;
