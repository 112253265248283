import ReactCrop, {centerCrop, makeAspectCrop} from "react-image-crop";
import React, {forwardRef, useImperativeHandle, useRef, useState} from "react";
import 'react-image-crop/src/ReactCrop.scss'


export default forwardRef(({setImageValue, aspect = 1, url = '', circularCrop = true}, ref) => {
  const [crop, setCrop] = useState();
  const imageRef = useRef(null)

  useImperativeHandle(ref, () => ({
    setCroppedImage() {
      cropImageNow()
    }
  }));

  const cropImageNow = () => {
    const canvas = document.createElement('canvas');
    const image = imageRef.current
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    const base64Image = canvas.toDataURL('image/jpeg');
    setImageValue(base64Image);
  };
  const onImageLoad = () => {
    if (imageRef.current) {
      const {width, height} = imageRef.current
      setCrop(centerCrop(
        makeAspectCrop(
          {
            unit: 'px',
            height: height / 2
          },
          aspect,
          width,
          height,
        ),
        width,
        height,
      ))
    }
  }

  return (
    <>
      {
        !!url &&
        <ReactCrop
          crop={crop}
          onChange={setCrop}
          circularCrop={circularCrop}
          keepSelection={true}
          aspect={aspect}
          style={{
            width: 'fit-content'
          }}
        >
          <img
            style={{
              maxHeight: '60vh',
              width: 'fit-content'
            }}
            ref={imageRef}
            onLoad={onImageLoad}
            src={url}
            alt='crop'
          />
        </ReactCrop>
      }
    </>
  )
})