import { badgeClasses } from '@mui/material';
import { clientTypes } from './actions';

const initialState = {
  clients: [],
  activeClient: {},
  prenotazione: {},
  inviti_onboarding: 0,
  badClients: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case clientTypes.GET_CLIENTS.SUCCESS:
      return {
        ...state,
        clients: action.payload.clienti,
      };
    case clientTypes.GET_CLIENTS_PLUS.SUCCESS:
      return {
        ...state,
        clients: action.payload.clienti,
        inviti_onboarding: action.payload.inviti_onboarding ?? 20,
      };
    case clientTypes.GET_CLIENT.SUCCESS:
      return {
        ...state,
        activeClient: action.payload,
      };
    case clientTypes.ADD_CLIENT.SUCCESS: {
      const clients = state.clients;
      return {
        ...state,
        clients: [...clients, action.payload.user],
      };
    }
    case clientTypes.EDIT_CLIENT.SUCCESS:
      return {
        ...state,
        clients: state.clients.map((client) =>
          +client.idutente === +action.payload.idutente
            ? {
                ...client,
                ...action.payload,
              }
            : client,
        ),
        activeClient: {
          ...state.activeClient,
          cliente: {
            ...state.activeClient.cliente,
            ...action.payload,
          },
        },
      };
    case clientTypes.DELETE_CLIENT.SUCCESS:
      return {
        ...state,
        clients: state.clients.map((client) =>
          +client.idutente !== +action.payload
            ? client
            : {
                ...client,
                disabilitato: true,
              },
        ),
      };
    case clientTypes.ENABLE_CLIENT.SUCCESS:
      return {
        ...state,
        clients: state.clients.filter((client) =>
          (+client.idutente !== +action.payload + client.idutente) !== +action.payload
            ? client
            : {
                ...client,
                disabilitato: false,
              },
        ),
      };
    case clientTypes.DELETE_CLIENT_APPOINTMENT.SUCCESS:
      return {
        ...state,
        activeClient: {
          ...state.activeClient,
          prenotazioni: state.activeClient.prenotazioni.filter(
            (appointment) => +appointment.idprenotazione !== +action.payload,
          ),
        },
      };

    case clientTypes.GET_PRENOTAZIONE_BY_ID.SUCCESS:
      return {
        ...state,
        prenotazione: action.payload,
      };
    case clientTypes.GET_BAD_CLIENTS.SUCCESS:
      return {
        ...state,
        badClients: action.payload.clienti,
      };
    default:
      return { ...state };
  }
};
