import useOutsideClick from '../../../../utils/useOutsideClick';
import { useEffect, useRef, useState } from 'react';
import styles from './ButtonSelector.module.scss';

import clx from 'classnames';
import SelectorMenu from '../SelectorMenu';
import useWheel from 'utils/useWheel';
import SpinnerSmall from 'Containers/Common/SpinnerSmall';

const ButtonSelector = ({
  value,
  setValue,
  options,
  title,
  backgroundColor,
  margin = '0',
  marginLeft = '20px',
  width = 'fit-content',
  height = 'fit-content',
  loading,
}) => {
  const dropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0, width: 0, height: 0 });
  useEffect(() => {
    if (dropdownRef.current) {
      const position = dropdownRef.current.getBoundingClientRect();
      setPosition(position);
    }
  }, [dropdownRef.current]);
  useOutsideClick(dropdownRef, () => {
    if (isDropdownOpen) setIsDropdownOpen(false);
  });
  useWheel(dropdownRef, () => {
    if (isDropdownOpen) setIsDropdownOpen(false);
  });
  return (
    <div
      ref={dropdownRef}
      className={clx(styles.selector_wrapper, {
        [styles['selector_wrapper_close']]: !isDropdownOpen,
      })}
      style={{
        margin,
        marginLeft,
        width,
        ...(!!backgroundColor && { backgroundColor }),
        maxWidth: width,
        minWidth: width,
        borderRadius: '10px',
      }}
    >
      {!!loading && <SpinnerSmall />}
      {title && <div className={styles.input_title}>{title}</div>}
      <div
        onClick={() => setIsDropdownOpen((isOpen) => !isOpen)}
        className={styles.selector_container}
        style={{
          margin,
          width,
          ...(!!backgroundColor && { backgroundColor }),
          height: height,
          maxWidth: width,
          minWidth: width,
        }}
      >
        <div className={styles.value}>
          {
            options.find((option) =>
              typeof value === 'object'
                ? JSON.stringify(option.value) === JSON.stringify(value)
                : option.value === value,
            )?.name
          }
        </div>
        <div className={styles.arrow} />
      </div>
      <div
        className={styles.dropdown__menu}
        style={{
          // position: 'absolute',
          // top: position.y,
          // right: position.x,
          // width: position.width,
          // height: position.height * 5,
          zIndex: 10000,
        }}
      >
        <SelectorMenu
          isMultiple={false}
          setIsDropdownOpen={() => setIsDropdownOpen(false)}
          options={options}
          setValue={setValue}
          value={[value]}
        />
      </div>
    </div>
  );
};
export default ButtonSelector;
