import styles from './index.module.scss';
import { useDispatch } from 'react-redux';
import { authActions } from 'Containers/Auth/store';
import { Button } from '@mui/material';
import clsx from 'clsx';
import { settingsActions } from 'Containers/Settings/store';
import { ReactComponent as CloseIcon } from 'assets/svg/cross.svg';
import useWindowSize from 'utils/useWindowSize';

const TermsModal = () => {
    const { isMobile } = useWindowSize();
    const dispatch = useDispatch();
    const fromApp = !!localStorage.getItem('fromApp');

    const openTerms = () => {
        const termsLink = 'https://application.zetabarber.it/terminiEcondizioniBarbieri.pdf';
        if (!fromApp) window.open(termsLink, '_blank');
        else window?.flutter_inappwebview?.callHandler('app:url', termsLink);
    }

    return (<>
        <div className={clsx(styles.container, {[styles.container_mbl]: isMobile})}>
            {/* <CloseIcon className={clsx(styles.close_icon)} onClick={() => dispatch(authActions.LOG_OUT.REQUEST())} /> */}
            <h2>Aggiornamento Termini e Condizioni</h2>
            <div className={styles.description}>
                Abbiamo aggiornato i nostri <span onClick={openTerms}>Termini e Condizioni</span> per offrirti un servizio migliore e più trasparente. 
                Ti invitiamo a leggere attentamente le modifiche apportate <span onClick={openTerms}>qui</span>.
                <p>{"Cliccando su 'Accetta', confermi di aver letto e compreso i nuovi termini."}</p>
                {/* <p>Per continuare ad usare il nostro servizio clicca su Accetta</p> */}
            </div>
            <div className={styles.footer}>
                <Button
                    width='100%'
                    className={styles.confirm_button}
                    variant='contained'
                    onClick={() => dispatch(settingsActions.ACCEPT_TERMS.REQUEST())}
                >
                    Accetta
                </Button>
                <Button 
                    width='100%'
                    className={styles.secondary}
                    variant='text'
                    onClick={() => dispatch(authActions.LOG_OUT.REQUEST())}
                >Esci</Button>
            </div>
        </div>
    </>);
}

export default TermsModal;