import React, {useState} from 'react';
import styles from "./ServicesStep.module.scss";
import ForwardButton from "../customComponents/ForwardButton";
import {authActions} from "../../../../store";
import {useDispatch} from "react-redux";
import SelectorMenu from "../../../../../Common/CustomSelector/SelectorMenu";

const servicesToSelect = [
  {
    name: 'Taglio',
    value: 1,
  },
  {
    name: 'Barba',
    value: 2,
  },
  {
    name: 'Taglio + barba',
    value: 3,
  },
  {
    name: 'Shampoo',
    value: 4,
  },
  {
    name: 'Sopracciglia',
    value: 5,
  }
]

export const ServicesStepContainer = () => {
  const dispatch = useDispatch();
  const [activeServices, setActiveServices] = useState([])
  return (
    <div className={styles.second_info_step_container}>
      <h2>
        Scegli i tuoi servizi di base
      </h2>
      <div className={styles.selector_menu_container}>
        <SelectorMenu
          isMultiple={true}
          options={servicesToSelect}
          setIsDropdownOpen={() => {
          }}
          setValue={setActiveServices}
          value={activeServices}
          fullHeight={true}
          border={false}
          darkMode={true}
        />
      </div>
      <ForwardButton
        onAction={() => dispatch(authActions.SET_SALON_SERVICES.REQUEST(activeServices, () => dispatch(authActions.GET_ACTIVE_SIGN_UP_STEP.SUCCESS(11))))}
      />
    </div>
  )
}
