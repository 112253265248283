import React from 'react';
import styles from "./SalonBreakStartTimeStep.module.scss";
import CustomTimePicker from "../../customComponents/CustomTimePicker";
import {authActions} from "../../../../../store";
import {useDispatch, useSelector} from "react-redux";
import {salonWorkTimes} from "../../../../../store/selectors";
import {SalonWorkTimeStepWrapper} from "../SalonWorkTimeStepWrapper";

export const SalonBreakStartTimeStepContainer = () => {
  const dispatch = useDispatch();
  const {startMorning, endMorning} = useSelector(salonWorkTimes());

  const setValue = (endMorning) => {
    dispatch(authActions.SET_SALON_WORK_TIMES.SUCCESS({endMorning}))
  }

  return (
    <SalonWorkTimeStepWrapper
      title={'A che ora Inizia la tua pausa?'}
      actionDisabled={!endMorning}
      action={() => dispatch(authActions.GET_ACTIVE_SIGN_UP_STEP.SUCCESS(5))}
    >
      <div className={styles.salon_picker}>
        <CustomTimePicker
          title={'Imposta l’orario di inizio pausa'}
          value={endMorning}
          setValue={setValue}
          minTime={startMorning}
        />
      </div>
    </SalonWorkTimeStepWrapper>
  )
}
