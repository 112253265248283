export const getActiveSalonToken = () => {
  const salons = JSON.parse(localStorage.getItem('salons'));
  return salons?.find((salon) => salon.isActive)?.token;
};
export const getActiveSalonId = () => {
  const salons = JSON.parse(localStorage.getItem('salons'));
  return salons?.find((salon) => salon.isActive)?.idsalone;
};
export const setActiveSalonById = (activeSalonId) => {
  const salons = JSON.parse(localStorage.getItem('salons'));
  const editedSalons = salons.map((salon) => ({
    ...salon,
    isActive: +salon.idsalone === +activeSalonId,
  }));
  localStorage.setItem('salons', JSON.stringify(editedSalons));
};

export const disabledSalonById = (activeSalonId) => {
  const salons = JSON.parse(localStorage.getItem('salons'));
  const editedSalons = salons.map((salon) =>
    +salon.idsalone === +activeSalonId ? { ...salon, eliminato: 1 } : salon,
  );
  localStorage.setItem('salons', JSON.stringify(editedSalons));
};

export const setActiveSalonInfo = (salonInfo) => {
  const salons = JSON.parse(localStorage.getItem('salons'));
  const editedSalons = salons.map((salon) =>
    +salonInfo.idsalone === +salon.idsalone ? { ...salon, ...salonInfo } : salon,
  );
  localStorage.setItem('salons', JSON.stringify(editedSalons));
};

export const getActiveSalonePaymentInfo = () => {
  const salons = JSON.parse(localStorage.getItem('salons'));
  return salons?.find((salon) => salon.isActive)?.disable_payment;
};

export const ufSaloniBannerPayments = [
2,6,7,8,10,13,14,21,22,24,45,67,68,71,73,78,79,100,102,106,109,115,119,122,127,129,135,136,137,147,148,166,176,177,
187,190,197,200,202,203,205,221,232,239,240,251,257,259,262,264,299,318,320,323,325,327,335,352,378,383,391,418,441,
442,447,450,453,454,458,461,473,476,493,511,514,521,524,547,548,551,554,556,559,571,572,577,586,587,592,594,599,600,
611,617,623,630,633,635,649,652,653,661,667,669,670,671,673,675,679,680,681,685,687,690,691,692,697,700,701,705,708,
710,719,720,731,745,752,754,755,757,758,760,764,765,770,771,772,774,775,777,779,787,792,
];
