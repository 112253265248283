import actionCreator from '../../../utils/actionCreator';

const salonManagementActionTypes = [
  'GET_WORKERS',
  'GET_WORKER',
  'CREATE_WORKER',
  'EDIT_WORKER',
  'EDIT_WORKER_AVATAR',
  'SET_WORKER_SERVICES',
  'EDIT_WORKER_SERVICES',
  'DELETE_WORKER',
  'ENABLE_WORKER',
  'GET_WORKER_WORK_TIMES',
  'SET_WORKER_WORK_TIMES',
  'SET_WORKER_CLOSURES',
  'SET_WORKER_EXTRA_WORKING_TIME',
  'DELETE_WORKER_CLOSURES',
  'DELETE_WORKER_EXTRA_TIME',
  'SET_WORKERS_POSITION',
  'SET_SERVICES_POSITION',
  'GET_SERVICES',
  'GET_SERVICE',
  'CREATE_SERVICE',
  'EDIT_SERVICE',
  'EDIT_SERVICE_WORKERS',
  'SET_SERVICE_WORKERS',
  'DELETE_SERVICE',
  'ENABLE_SERVICE',
  'GET_SALON',
  // 'SET_SALON_WORK_TIMES',
  'GET_WORKER_SERVICES',
  'DELETE_WORKER_SERVICE',
  'MOD_WORKER_SERVICE',
];

export const { types: salonManagementTypes, actions: salonManagementActions } = actionCreator(salonManagementActionTypes);
