import styles from './ClientsList.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import TitleSectionBack from '../../../Common/TitleSectionBack';
import { getClientsShort, getClientsStats, getInvitiOnboarding } from '../../store/selectors';
import { clientActions } from '../../store';
import ClientsTable from '../ClientsTable';
import clx from 'classnames';
import SearchInput from '../../../Common/SearchInput';
import { getActiveSalonId } from '../../../Auth/store/selectors';
import FilterButtons from '../../../Common/FilterButtons';
import useWindowSize from '../../../../utils/useWindowSize';
import { Button } from '@mui/material';
import { ReactComponent as PlusIcon } from '../../../../assets/svg/icons/plus_button.svg';
import { ReactComponent as InviteIcon } from '../../../../assets/svg/user_telegram.svg';
import { modalActions } from 'Containers/Modals/store';
import { getActiveSalonToken } from 'utils/salonToken';

const filtersButtons = [
  {
    name: 'Attivi',
    id: 1,
  },
  {
    name: 'Non Attivi',
    id: 2,
  },
  {
    name: 'Disabilitati',
    id: 3,
  },
  {
    name: 'Tutti',
    id: 0,
  },
  {
    name: 'Inseriti da te',
    id: 4,
  },
  {
    name: 'Nuovi',
    id: 5,
  },
];

const ClientsList = () => {
  const dispatch = useDispatch();
  const [activeFilterId, setActiveFilterId] = useState(1);
  const [searchedName, setSearchedName] = useState('');
  const { isMobile } = useWindowSize();
  const activeSalonId = useSelector(getActiveSalonId());
  const clients = useSelector(getClientsShort());
  const userInvited = useSelector(getInvitiOnboarding()) ?? 0;
  const clientsStats = useSelector(getClientsStats(searchedName));
  useEffect(() => {
    activeSalonId && dispatch(clientActions.GET_CLIENTS_PLUS.REQUEST());
  }, [dispatch, activeSalonId]);

  const buttonSize = isMobile ? '55px' : '60px';

  const token = getActiveSalonToken();

  function openFlutterContact() {
    let minimum = 0;
    if(userInvited == 50) minimum = 30;
    window?.flutter_inappwebview?.callHandler('app:onboardingContact', {
      action: 'open',
      token,
      invitiRimanenti: userInvited,
      minimum,
    });
  }

  return (
    <>
      <div
        className={clx(styles.clients_list_container, {
          [styles.clients_list_container_mobile]: isMobile,
        })}
      >
        <div className={styles.header}>
          <TitleSectionBack
            title={'Clienti'}
            description={'Visualizza la lista di tutti i clienti del tuo salone'}
          />
          {!isMobile && (
            <div className={styles.plus_client}>
              <PlusIcon
                height={buttonSize}
                width={buttonSize}
                onClick={() =>
                  dispatch(
                    modalActions.OPEN_MODAL.SUCCESS({
                      type: 'createClientModal',
                      props: { idsalone: activeSalonId },
                    }),
                  )
                }
              />
            </div>
          )}
        </div>
        <div className={styles.filters_container}>
          <div className={styles.button_wrapper}>
            <FilterButtons
              activeFilterId={activeFilterId}
              setActiveFilterId={setActiveFilterId}
              filtersButtons={filtersButtons}
            />
          </div>
          <SearchInput
            placeholder={'Cerca cliente'}
            value={searchedName}
            setValue={setSearchedName}
            backgroundColor={'white'}
          />
        </div>
        <ClientsTable
          values={clients.filter(
            (client) =>
              (!activeFilterId ||
                client.type === activeFilterId ||
                (activeFilterId === 4 && client.createFromSalone === 1) ||
                (activeFilterId === 5 && client.totAppointments < 3) ||
                (activeFilterId === 3 && client.disabilitato === 1)) &&
              (!searchedName || client.name.toLowerCase().includes(searchedName.toLowerCase())),
          )}
          clientsStats={clientsStats}
          isMobile={isMobile}
        />
      </div>
      {isMobile && (
        <div className={styles.plus_client_mbl}>
          {userInvited > 0 && (
            <div
              className={styles.inviteButton}
              style={{ height: { buttonSize }, width: { buttonSize } }}
            >
              <InviteIcon height={'30px'} width={'30px'} onClick={() => openFlutterContact()} />
            </div>
          )}
          <PlusIcon
            height={buttonSize}
            width={buttonSize}
            onClick={() =>
              dispatch(
                modalActions.OPEN_MODAL.SUCCESS({
                  type: 'createClientModal',
                  props: { idsalone: activeSalonId },
                }),
              )
            }
          />
        </div>
      )}
    </>
  );
};

export default ClientsList;
