import React, {useState} from 'react';
import styles from "./SalonBreakTimeSelectorStep.module.scss";
import {authActions} from "../../../../../store";
import {useDispatch, useSelector} from "react-redux";
import Switcher from "../../../../../../Common/Switcher";
import {SalonWorkTimeStepWrapper} from "../SalonWorkTimeStepWrapper";

export const SalonBreakTimeSelectorStepContainer = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(true)

  return (
    <SalonWorkTimeStepWrapper
      title={'Hai una pausa durante la giornata?'}
      action={() => dispatch(authActions.GET_ACTIVE_SIGN_UP_STEP.SUCCESS(value ? 4 : 6))}
    >
      <div className={styles.salon_picker}>
        <Switcher
          value={!value}
          setValue={setValue}
          darkMode={true}
        />
      </div>
    </SalonWorkTimeStepWrapper>
  )
}