import styles from './AvatarIcon.module.scss';
import { useState } from 'react';
import clx from 'classnames';

const AvatarIcon = ({ icon, name, size }) => {
  const substringName = (name) => {
    if (!name) return '';
    // console.log(name);
    const matches = name.match(/\b(\w)/g);
    // console.log(name, matches);
    return matches?.join('').toUpperCase();
  };
  const colors = [
    '#05DCA9',
    '#FF93C7',
    '#FFA16C',
    '#A5C',
    '#4AA0D1',
    '#C4C4C4',
    '#FF6868',
    '#8DCDF0',
    '#1f1f1f',
  ];
  name = substringName(name);
  const [first, second] = [name?.charCodeAt(0) || 0, name?.charCodeAt(1) || 0];
  const color = colors[(first + second) % colors.length];

  const [isError, setError] = useState(false);
  return (
    <div
      className={clx(styles.icon_container, { [styles.is_error]: isError || !icon })}
      style={{
        width: size,
        height: size,
        minWidth: size,
      }}
    >
      {!!icon && (
        <img
          className={styles.icon_image}
          src={icon}
          alt=''
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            setError(true);
          }}
          onLoad={({ currentTarget }) => {
            currentTarget.onerror = null;
            setError(false);
          }}
        />
      )}
      <div
        className={styles.icon_on_error}
        style={{
          backgroundColor: color,
        }}
      >
        <div className={styles.text}>{name}</div>
      </div>
    </div>
  );
};

export default AvatarIcon;
