import {call, put, takeLatest} from 'redux-saga/effects';

import {Api} from '../../../entries/ApiTransport';
import sagaAssessor from '../../../utils/sagaAssessor';
import {notificationTypes, notificationActions} from './actions';
import {objectToQuery} from "../../../helpers";

const api = Api.getInstance();

const getNotifications = ({payload}) =>
  sagaAssessor(
    () =>
      function* () {
        const query = objectToQuery(payload?.query || '')

        const {data: {notifications}} = yield call(() => api.get(`/Notifiche/salone/clients/getAll${query}`));
        yield put(notificationActions.GET_NOTIFICATIONS.SUCCESS(notifications));
      },
    (err) => notificationActions.GET_NOTIFICATIONS.FAILED(err)
  );

export default function* () {
  yield takeLatest(notificationTypes.GET_NOTIFICATIONS.REQUEST, getNotifications);

}
