export const clientStatuses = [
  {
    value: 0,
    name: 'Standard',
  },
  /*
  {
    value: 1,
    name: 'VIP',
  },
  {
    value: 2,
    name: 'Fidelity',
  },
  */
  {
    value: 3,
    name: 'Nuovo cliente ',
  },
  /*
  {
    value: 4,
    name: 'Ammonito',
  },
  {
    value: 5,
    name: 'A rischio',
  },
  */
];

export const setCategory = (category) => {
  let type;
  return (type = clientStatuses.find((s) => s.value === category)?.name ?? '');
};
