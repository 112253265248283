import { authTypes } from './actions';
import { disabledSalonById, setActiveSalonById } from '../../../utils/salonToken';

const initialState = {
  salons: [],
  isLogin: false,
  activeSalonId: false,
  signUpValues: {
    salonWorkTimes: {
      startMorning: null,
      endMorning: null,
      startEvening: null,
      endEvening: null,
    },
    activeStepId: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case authTypes.SIGN_IN.REQUEST:
      return {
        ...state,
      };
    case authTypes.SIGN_IN.FAILED:
      return {
        ...state,
      };
    case authTypes.FETCH_SALON.SUCCESS:
      return {
        ...state,
        salons: action.payload,
      };
    case authTypes.SIGN_IN.SUCCESS:
      return {
        ...state,
        isLogin: true,
        activeSalonId: action.payload.activeSalonId,
        salons: action.payload.salons,
      };
    case authTypes.SET_ACTIVE_SALON.SUCCESS:
      setActiveSalonById(action.payload);
      return {
        ...state,
        activeSalonId: action.payload,
      };
      case authTypes.SET_ID_COSTUMER_STRIPE.SUCCESS:
        return {
          ...state,
          salons: state.salons.map((salon) =>
            state.activeSalonId === salon.idsalone ? { ...salon, idCostumerStripe: action.payload.idCustomerStripe } : salon,
          ),
        };
    case authTypes.EDIT_SALON_INFO.SUCCESS:
      return {
        ...state,
        salons: state.salons.map((salon) =>
          action.payload.idsalone === salon.idsalone ? { ...salon, ...action.payload } : salon,
        ),
      };
    case authTypes.ACTIVE_PAYMENT.SUCCESS:
      return {
        ...state,
        salons: state.salons.map((salon) =>
          action.payload.idsalone === salon.idsalone ? { ...salon, ...action.payload } : salon,
        ),
      };
    case authTypes.DELETE_SALON.SUCCESS:
      disabledSalonById(action.payload);
      return {
        ...state,
        salons: state.salons.filter((salon) => action.payload !== salon.idsalone),
      };
    case authTypes.LOG_OUT.SUCCESS:
      return {
        ...state,
        isLogin: false,
        activeSalonId: false,
        salons: [],
      };
    case authTypes.GET_ACTIVE_SIGN_UP_STEP.SUCCESS:
      return {
        ...state,
        signUpValues: {
          ...state.signUpValues,
          activeStepId: action.payload,
        },
      };
    case authTypes.SET_SALON_WORK_TIMES.SUCCESS:
      return {
        ...state,
        signUpValues: {
          ...state.signUpValues,
          salonWorkTimes: {
            ...state.signUpValues.salonWorkTimes,
            ...action.payload,
          },
        },
      };
    case authTypes.GET_ACTIVE_SIGN_UP_STEP.CLEAR:
      return {
        ...state,
        signUpValues: {
          salonWorkTimes: {
            startMorning: null,
            endMorning: null,
            startEvening: null,
            endEvening: null,
          },
          activeStepId: false,
        },
      };
      case authTypes.CREATE_CUSTOMER.SUCCESS:
      return {
        ...state,
        salons: state.salons.map((salon) =>
          action.payload.idsalone === salon.idsalone ? { ...salon, idCustomerStripe: action.payload.customer.idCustomerStripe, tel: action.payload.customer.phone } : salon,
        ),
      };
    default:
      return { ...state };
  }
};
