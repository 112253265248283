import React from 'react';
import styles from "./SalonBreakEndTimeStep.module.scss";
import CustomTimePicker from "../../customComponents/CustomTimePicker";
import {authActions} from "../../../../../store";
import {useDispatch, useSelector} from "react-redux";
import {salonWorkTimes} from "../../../../../store/selectors";
import {SalonWorkTimeStepWrapper} from "../SalonWorkTimeStepWrapper";

export const SalonBreakEndTimeStepContainer = () => {
  const dispatch = useDispatch();
  const {endMorning, startEvening} = useSelector(salonWorkTimes());

  const setValue = (startEvening) => {
    dispatch(authActions.SET_SALON_WORK_TIMES.SUCCESS({startEvening}))
  }

  return (
    <SalonWorkTimeStepWrapper
      title={'A che ora Finisce la tua pausa?'}
      actionDisabled={!startEvening}
      action={() => dispatch(authActions.GET_ACTIVE_SIGN_UP_STEP.SUCCESS(6))}
    >
      <div className={styles.salon_picker}>
        <CustomTimePicker
          title={'Imposta l’orario di fine pausa'}
          value={startEvening}
          setValue={setValue}
          minTime={endMorning}
        />
      </div>
    </SalonWorkTimeStepWrapper>
  )
}
