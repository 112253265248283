import React from 'react';
import styles from "./WorkersStep.module.scss";
import ForwardButton from "../customComponents/ForwardButton";
import {authActions} from "../../../../store";
import {useDispatch} from "react-redux";
import {ReactComponent as PlusInCircle} from '../../../../../../assets/svg/plus_circle_blue.svg';
import {ReactComponent as Bin} from '../../../../../../assets/svg/bin_blue.svg';
import {Field, FieldArray, Form, Formik} from "formik";
import * as yup from "yup";
import TextField from "../../../../../Common/TextField";
import {Button} from "@mui/material";

export const WorkersStepContainer = () => {
  const dispatch = useDispatch();

  const validationSchema = {
    workers: yup.array()
      .of(
        yup.object().shape({
          name: yup.string().min(2, 'too short').required('Required'),
        })
      )
      .max(5, 'Maximum of 5 workers')
  }

  return (
    <div className={styles.second_info_step_container}>
      <h2>
        Quanti collaboratori hai nel tuo salone?
      </h2>
      <Formik
        initialValues={{workers: [{name: ''}]}}
        validationSchema={yup.object().shape(validationSchema)}
        enableReinitialize={true}
        onSubmit={({workers}) => {
          dispatch(authActions.SET_SALON_WORKERS.REQUEST(workers, () => dispatch(authActions.GET_ACTIVE_SIGN_UP_STEP.SUCCESS(14))))
        }}
      >
        {({values, setFieldValue}) => {
          return (
            <Form className={styles.selector_menu_container}>
              <div className={styles.field_title}>
                Inserisci il nome della poltrona
              </div>
              <div className={styles.workers_container}>
                <FieldArray
                  name="workers"
                  render={arrayHelpers => (
                    values.workers.map((worker, index, {length}) => (
                      <div
                        key={index}
                        className={styles.field_container}
                      >
                        <Field
                          name={`workers.${index}.name`}
                          id={`workers.${index}.name`}
                          label=''
                          component={TextField}
                          height={'47px'}
                          margin={'0 0 0 0'}
                          placeholder={'Inserisci il nome della poltrona'}
                          inputColor={'#787878'}
                          textColor={'white'}
                        />
                        <Button
                          onClick={() => length < 5 && arrayHelpers.insert(index, {name: ''})}
                          className={styles.field_button}
                        >
                          <PlusInCircle/>
                        </Button>
                        <Button
                          onClick={() => length > 1 && arrayHelpers.remove(index)}
                          className={styles.field_button}
                        >
                          <Bin/>
                        </Button>
                      </div>
                    ))
                  )}
                />
              </div>
              <ForwardButton
                type={'submit'}
                onAction={() => {}}
              />
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
