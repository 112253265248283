import { Button } from '@mui/material';
import styles from './Cards.module.scss';
import clx from 'classnames';
import { ReactComponent as Mastercards } from 'assets/svg/icons/mastercards.svg';
import { ReactComponent as SettingIcon } from 'assets/svg/settings.svg';

export const Cards = ({ cards, handlePress, isMobile }) => {
  return (
    <div className={clx(styles.cards, { [styles.cards_mobile]: isMobile })}>
      <div className={styles.cards_left}>
        {cards.map((card) => {
          return (
            <div className={styles.cards_card}>
              <div className={styles.cards_card_brand}>
                <Mastercards />
              </div>
              <div className={styles.cards_card_number}>
                <h2>**** **** **** {card.lastFourDigits}</h2>
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.cards_right}>
        <Button onClick={handlePress} variant='contained' className={styles.cards_button}>
          <SettingIcon className={styles.icon} height='17px' width='17px' />
          Modifica metodo di pagamento
        </Button>
      </div>
    </div>
  );
};
