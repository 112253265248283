import React from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import styles from './WorkerNameModal.module.scss';
import { Field, Form, Formik } from 'formik';
import { Button } from '@mui/material';
import { salonManagementActions } from '../../../../store';
import TextField from '../../../../../Common/TextField';

export default ({ closeModal, worker }) => {
  const dispatch = useDispatch();

  const validationSchema = {
    name: yup.string('Inserisci la tua nome').required('Nome è richiesta'),
  };

  return (
    <Formik
      initialValues={worker}
      validationSchema={yup.object().shape(validationSchema)}
      onSubmit={(values) => {
        const req = {
          idPoltrona: values.id,
          nomeReferente: values.name,
        };
        dispatch(salonManagementActions.EDIT_WORKER.REQUEST(req, closeModal));
      }}
    >
      {() => (
        <Form className={styles.closures_modal_wrapper}>
          <Field
            name={'name'}
            id={'name'}
            label='Nome'
            component={TextField}
            height={'50px'}
            margin={'0px 0px 40px 0px'}
            placeholder={'Campo di testo da compilare'}
          />
          <div className={styles.actions_container}>
            <Button className={styles.confirm_button} variant='contained' type={'submit'}>
              Salva
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
