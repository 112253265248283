import React from 'react';

import styles from './TitleSectionBack.module.scss';
import clx from 'classnames';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowRIcon } from '../../../assets/svg/icons/arrow_r.svg';

export default ({ title, description, isActiveButton = true }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.section_back_wrapper}>
      {isActiveButton && (
        <Button onClick={() => navigate(-1)} className={styles.arrow_button}>
          <ArrowRIcon />
        </Button>
      )}
      <div className={styles.title_section}>
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{description}</div>
      </div>
    </div>
  );
};
