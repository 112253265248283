import { communicationsTypes } from './actions';

const initialState = {
  communicationToken: '',
};

export default (state = initialState, action) => {

  switch (action.type) {
    case communicationsTypes.GET_TOKEN_COMMUNICATIONS.SUCCESS:
      return {
        ...state,
        communicationToken: action.payload.token
      };
    default:
      return { ...state };
  }
};
