import { call, put, takeLatest } from 'redux-saga/effects';

import { Api } from '../../../entries/ApiTransport';
import sagaAssessor from '../../../utils/sagaAssessor';
import { schedulerActions, schedulerTypes } from './actions';
import { toastActions } from 'Containers/Toasts/store';
import { setError } from 'shared/Error/store/actions';

const api = Api.getInstance();

const getCalendarData = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() =>
          api.post('/business/prenotazioni/getCalendario', payload),
        );
        yield put(schedulerActions.GET_CALENDAR_DATA.SUCCESS(data));
        callback && typeof callback === 'function' && callback();
      },
    (err) => schedulerActions.GET_CALENDAR_DATA.FAILED(err),
  );

const getCalendarQueue = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const {
          data: { poltrone },
        } = yield call(() => api.post('/business/getQueueByDay', payload));
        yield put(schedulerActions.GET_CALENDAR_QUEUE.SUCCESS(poltrone));
        callback && typeof callback === 'function' && callback();
      },
    (err) => schedulerActions.GET_CALENDAR_QUEUE.FAILED(err),
  );
const getCalendarSlots = ({ payload, callback, options }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.post('/business/prenotazioni/getOrari', payload));
        yield put(schedulerActions.GET_CALENDAR_SLOTS.SUCCESS(data?.orari));
        callback && typeof callback === 'function' && callback();
      },
    (err) => schedulerActions.GET_CALENDAR_SLOTS.FAILED(err),
    options,
  );
const editCalendarTask = ({ payload, callback, options }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.post('/business/prenotazioni/updatePren', payload));
        yield put(schedulerActions.EDIT_CALENDAR_TASK.SUCCESS(data.prenotazione));
        callback && typeof callback === 'function' && callback();
      },
    (err) => schedulerActions.EDIT_CALENDAR_TASK.FAILED(err),
    options,
  );

const addCalendarTask = ({ payload, callback, options }) =>
  sagaAssessor(
    () =>
      function* () {
        try{
       
          const { data } = yield call(() =>
            api.post('/business/prenotazioni/setPrenotazioneSelf', payload),
          );
          const tasks = data.prenotazioni ? data.prenotazioni : [data.prenotazione];
          yield put(schedulerActions.ADD_CALENDAR_TASK.SUCCESS(tasks));
          callback && typeof callback === 'function' && callback(); 
        } catch(error) {
            if(error.response?.data?.stringCode==='orarioNonDisponibile') {
              const values={
                ...options.values,
                saveAnyway: true
              }
              options.errCallback?.(values);
            }
            else {
              yield put(
                setError(error?.response?.data?.stringCode),
              );
            }
        }
      },
    // (err) => {
    //   schedulerActions.ADD_CALENDAR_TASK.FAILED(err);
    // },
  );
const addCalendarBreak = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => api.post('/business/prenotazioni/takeABreak', payload));
        // const tasks = data.prenotazioni ? data.prenotazioni : [data.prenotazione]
        yield put(schedulerActions.ADD_CALENDAR_BREAK.SUCCESS(payload));
        callback && typeof callback === 'function' && callback();
      },
    (err) => schedulerActions.ADD_CALENDAR_BREAK.FAILED(err),
  );
const deleteCalendarTask = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        yield call(() => api.post('/business/prenotazioni/disable', payload));
        yield put(
          schedulerActions.DELETE_CALENDAR_TASK.SUCCESS({
            id: payload.idPrenotazione,
            all: payload.deleteAllRicorrenti,
            ricorrente: payload.ricorrente,
          }),
        );
        callback && typeof callback === 'function' && callback();
      },
    (err) => schedulerActions.DELETE_CALENDAR_TASK.FAILED(err),
  );

const setWorkerExtraWorkingTime = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        yield call(() => api.post('/business/poltrone/addOrarioMultiply', payload));
        yield put(schedulerActions.SET_CALENDAR_WORKER_EXTRA_WORKING_TIME.SUCCESS(payload));
        callback && typeof callback === 'function' && callback();
        yield put(
          toastActions.OPEN_TOAST.SUCCESS({
            type: 'worker',
            props: { title: 'Apertura straordinaria aggiunta con successo!' },
          }),
        );
      },
    (err) => schedulerActions.SET_CALENDAR_WORKER_EXTRA_WORKING_TIME.FAILED(err),
  );

const setWorkerClosures = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        yield call(() => api.post('/business/poltrone/addChiusuraMultiply', payload));
        yield put(schedulerActions.SET_CALENDAR_WORKER_CLOSURES.SUCCESS(payload));
        callback && typeof callback === 'function' && callback();
        yield put(
          toastActions.OPEN_TOAST.SUCCESS({
            type: 'worker',
            props: { title: 'Chiusura straordinaria aggiunta con successo!' },
          }),
        );
      },
    (err) => schedulerActions.SET_CALENDAR_WORKER_CLOSURES.FAILED(err),
  );

const getDetailsPrenotazioneRicorrente = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const responce = yield call(() =>
          api.get('/business/prenotazioni/dettaglioRicorrente/' + payload.guidRicorrenza),
        );
        yield put(schedulerActions.GET_DETAILS_PRENOTAZIONE_RICORRENTE.SUCCESS(responce?.data));
        callback && typeof callback === 'function' && callback();
      },
    (err) => schedulerActions.GET_DETAILS_PRENOTAZIONE_RICORRENTE.FAILED(err),
  );

export default function* () {
  yield takeLatest(schedulerTypes.GET_CALENDAR_DATA.REQUEST, getCalendarData);
  yield takeLatest(schedulerTypes.EDIT_CALENDAR_TASK.REQUEST, editCalendarTask);
  yield takeLatest(schedulerTypes.ADD_CALENDAR_TASK.REQUEST, addCalendarTask);
  yield takeLatest(schedulerTypes.ADD_CALENDAR_BREAK.REQUEST, addCalendarBreak);
  yield takeLatest(schedulerTypes.DELETE_CALENDAR_TASK.REQUEST, deleteCalendarTask);
  yield takeLatest(schedulerTypes.GET_CALENDAR_QUEUE.REQUEST, getCalendarQueue);
  yield takeLatest(
    schedulerTypes.SET_CALENDAR_WORKER_EXTRA_WORKING_TIME.REQUEST,
    setWorkerExtraWorkingTime,
  );
  yield takeLatest(schedulerTypes.GET_CALENDAR_SLOTS.REQUEST, getCalendarSlots);
  yield takeLatest(schedulerTypes.SET_CALENDAR_WORKER_CLOSURES.REQUEST, setWorkerClosures);
  yield takeLatest(
    schedulerTypes.GET_DETAILS_PRENOTAZIONE_RICORRENTE.REQUEST,
    getDetailsPrenotazioneRicorrente,
  );
}
