import React from 'react';
import styles from './ClientsTable.module.scss';
import CustomTable from '../../../Common/CustomTable';
import AvatarIcon from '../../../Common/AvatarIcon';
import { ReactComponent as WhatsappIcon } from '../../../../assets/svg/icons/whatsapp.svg';
import { ReactComponent as PhoneIcon } from '../../../../assets/svg/icons/chiamate.svg';
import { ReactComponent as MailIcon } from '../../../../assets/svg/icons/mail.svg';
import { ReactComponent as NewIcon } from 'assets/svg/badgets/new_client.svg';
import { ReactComponent as BadIcon } from 'assets/svg/icons/warning_user.svg';
import { modalActions } from '../../../Modals/store';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '../../../Common/Buttons/IconButton';
import clx from 'classnames';
import { setCategory } from '../constants';
import dayjs from 'dayjs';
import { allBadClients } from 'Containers/Clients/store/selectors';

export default ({ values, clientsStats, isMobile }) => {
  const badClients = useSelector(allBadClients());  
  const dispatch = useDispatch();
  const data = React.useMemo(() => values, [values]);

  const daysAppointment = (date) => {
    if (date) {
      let days = dayjs().diff(date, 'day');
      if (days > 0) {
        return ` - Ultima prenotazione ${days} giorni fa`;
      } else if (days == 0) {
        return ' - Ultima prenotazione Oggi';
      } else {
        return ` - Prossima prenotazione tra ${`${days}`.replace('-', '')} giorni`;
      }
    }
    return ' - Ancora nessuna prenotazione';
  };

  const columns = React.useMemo(
    () =>
      isMobile
        ? [
            {
              Header: '',
              accessor: 'actions',
              Cell: ({ row: { original } }) => {
                return (
                  <div className={styles.client_mobile_card}>
                    <div className={styles.avatar_container}>
                      <AvatarIcon name={original.name} icon={original.icon} size={'50px'} />
                      {original.totAppointments < 3 && <NewIcon style={{bottom: '0px'}}/>}
                      {badClients.length>0 && badClients?.find(a=> a?.utente === original.idutente) && <BadIcon style={{top: '0px', width: '21px', height: '21px'}}/>}
                    </div>
                    <div className={styles.card_info_container}>
                      <div className={styles.card_avatar_social}>
                        <div className={styles.card_avatar_name}>{original.name}</div>
                        <div className={styles.card_social}>
                          <IconButton
                            svg={<WhatsappIcon />}
                            type={'whatsapp'}
                            size={'49px'}
                            isActive={!!original.phone}
                            value={original.phone}
                            margin={'0 10px 0 0'}
                          />
                          <IconButton
                            svg={<MailIcon />}
                            type={'email'}
                            size={'49px'}
                            isActive={!!original.email}
                            value={original.email}
                            margin={'0 10px 0 0'}
                          />
                          <IconButton
                            svg={<PhoneIcon />}
                            type={'phone'}
                            size={'49px'}
                            isActive={!!original.phone}
                            value={original.phone}
                            margin={'0 10px 0 0'}
                          />
                        </div>
                      </div>
                      <div
                        className={styles.link}
                        onClick={() =>
                          dispatch(
                            modalActions.OPEN_MODAL.SUCCESS({
                              type: 'clientModal',
                              props: { client_id: original.value, totPren: original.totAppointments },
                            }),
                          )
                        }
                      >
                        &gt;
                      </div>
                    </div>
                  </div>
                );
              },
            },
          ]
        : [
            {
              Header: '',
              accessor: 'name',
              Cell: ({ row: { original } }) => {
                return (
                  <div className={styles.name_cell_container}>
                    <div className={styles.avatar_container}>
                      <AvatarIcon name={original.name} icon={original.icon} size={'78px'} />
                      {original.totAppointments < 3 && <NewIcon style={{bottom: '0px'}}/>}
                      {badClients.length>0 && badClients?.find(a=> a?.utente === original.idutente) && <BadIcon style={{top: '0px', width: '28px', height: '28px'}}/>}
                    </div>
                    <div className={styles.client_info}>
                      <div className={styles.name}>{original.name}</div>
                      <div className={styles.description}>
                        {original.totAppointments ?? 0} prenotazioni effettuate
                        {original.category >= 0 ? ` - ${setCategory(original.category)}` : ''}
                        {daysAppointment(original.lastAppointment)}
                      </div>
                    </div>
                  </div>
                );
              },
            },
            {
              Header: '',
              accessor: 'actions',
              Cell: ({ row: { original } }) => {
                return (
                  <div className={styles.client_actions}>
                    <IconButton
                      svg={<WhatsappIcon />}
                      type={'whatsapp'}
                      size={'49px'}
                      isActive={!!original.phone}
                      value={original.phone}
                      margin={'0 10px 0 0'}
                    />
                    <IconButton
                      svg={<PhoneIcon />}
                      type={'phone'}
                      size={'49px'}
                      isActive={!!original.phone}
                      value={original.phone}
                      margin={'0 10px 0 0'}
                    />
                    <IconButton
                      svg={<MailIcon />}
                      type={'email'}
                      size={'49px'}
                      isActive={!!original.email}
                      value={original.email}
                      margin={'0 10px 0 0'}
                    />
                  </div>
                );
              },
            },
            {
              Header: '',
              accessor: 'link',
              Cell: ({ row: { original } }) => {
                return (
                  <div
                    className={styles.link}
                    onClick={() =>
                      dispatch(
                        modalActions.OPEN_MODAL.SUCCESS({
                          type: 'clientModal',
                          props: { client_id: original.value, totPren: original.totAppointments },
                        }),
                      )
                    }
                  >
                    Dettagli &gt;
                  </div>
                );
              },
            },
          ],
    [isMobile, badClients],
  );

  return (
    <div
      className={clx(styles.table_card, {
        [styles.table_card_mobile]: isMobile,
      })}
    >
      <div className={styles.header_container}>
        <div className={styles.header_cell}>
          <div className={styles.name}>Clienti Registrati</div>
          <div className={styles.value}>{clientsStats.registeredClients}</div>
        </div>
        <div className={styles.header_cell}>
          <div className={styles.name}>Clienti Attivi</div>
          <div className={styles.value}>{clientsStats.activeClients}</div>
        </div>
        <div className={styles.header_cell}>
          <div className={styles.name}>Clienti Non Attivi</div>
          <div className={styles.value}>{clientsStats.nonActiveClients}</div>
        </div>
        <div className={styles.header_cell}>
          <div className={styles.name}>Clienti Disabilitati</div>
          <div className={styles.value}>{clientsStats.disabledClients}</div>
        </div>
      </div>
      <CustomTable data={data} columns={columns} isMobile={isMobile} />
    </div>
  );
};
