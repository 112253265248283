import styles from './CustomInput.module.scss';

import clx from 'classnames';
import { ReactComponent as CloseIcon } from '../../../assets/svg/cross.svg';
import { Button } from '@mui/material';
import Spinner from '../Spinner';
import SpinnerSmall from '../SpinnerSmall';

const CustomInput = ({
  inputValue,
  setInputValue,
  onAction,
  clearData,
  loading,
  placeholder,
  title,
  withIcon = false,
  icon,
  readOnly = false,
  disabled = false,
  error = '',
  clearButton = true,
  height = 'fit-content',
  inputRef,
  ...props
}) => {
  return (
    <label className={styles.input_label_container}>
      <div className={styles.input_title}>{title}</div>
      <div className={styles.input_wrapper} style={{ height }}>
        <div className={styles.field__container}>
          {loading ? <SpinnerSmall /> : ''}
          <input
            style={{ height }}
            type={'text'}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder={placeholder}
            className={styles['field__value']}
            readOnly={readOnly}
            onFocus={!readOnly ? onAction : () => {}}
            onClick={readOnly ? onAction : () => {}}
            disabled={disabled}
            required={!!error}
            ref={inputRef}
            {...props}
          />

          <CloseIcon
            className={clx(styles.close_icon, {
              [styles['hide']]: !inputValue || disabled || !clearButton,
            })}
            onClick={clearData}
          />
          <div className={styles.error_message}>{error}</div>
        </div>
        {withIcon ? (
          <Button
            disabled={disabled}
            onClick={onAction}
            className={styles.input_icon}
            style={{ ...(height !== 'fit-content' && { height, minWidth: height }) }}
          >
            {icon}
          </Button>
        ) : (
          ''
        )}
      </div>
    </label>
  );
};

export default CustomInput;
