import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';

import { Api } from '../../../entries/ApiTransport';
import sagaAssessor from '../../../utils/sagaAssessor';
import { getActiveSalonToken } from '../../../utils/salonToken';
import { shopActions, shopTypes } from './actions';

const api = Api.getInstance();

const getToken = () =>
  sagaAssessor(
    () =>
      function* () {

        const { data } = yield call(() => axios.post('https://zb-shop-prod.herokuapp.com/auth/getClient', { token: getActiveSalonToken() }));
        yield put(shopActions.GET_TOKEN_SHOP.SUCCESS(data));
      },
    (err) => shopActions.GET_TOKEN_SHOP.FAILED(err)
  );



export default function* () {
  yield takeLatest(shopTypes.GET_TOKEN_SHOP.REQUEST, getToken);
}
