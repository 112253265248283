import axios from 'axios';
import config from './config';
import { getActiveSalonToken } from '../utils/salonToken';

const axiosInstance = axios.create();
// axios.defaults.baseURL = 'https://zb-backend-v2-test.herokuapp.com/';
axiosInstance.defaults.baseURL = process.env.REACT_APP_API_URL;
//axiosInstance.defaults.baseURL = 'http://127.0.0.1:5001';

// axiosInstance.defaults.baseURL = 'http://0.0.0.0:5001';

//axiosInstance.defaults.baseURL = config.api_url

export const setAuthHeaders = () =>
  (axiosInstance.defaults.headers.common = { Authorization: getActiveSalonToken() });

export const removeAuthHeader = () => delete axiosInstance.defaults.headers.common['Authorization'];

export class Api {
  static getInstance() {
    return new Api();
  }

  get(url, options) {
    setAuthHeaders();
    return axiosInstance.get(`${url}`, options);
  }

  put(url, data) {
    setAuthHeaders();
    return axiosInstance.put(`${url}`, data);
  }

  post(url, data, config) {
    setAuthHeaders();
    return axiosInstance.post(`${url}`, data, config);
  }

  patch(url, data) {
    setAuthHeaders();
    return axiosInstance.patch(`${url}`, data);
  }

  delete(url) {
    setAuthHeaders();
    return axiosInstance.delete(`${url}`);
  }
}
