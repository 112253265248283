import { useState } from 'react';
import React from 'react';

import SideBar from '../../SideBar';
import styles from './PageWrapper.module.scss';
import Header from "../../Header";
import { useLocation } from "react-router-dom";
import { ROUTES } from "../../../routes/constants";
import useWindowSize from "../../../utils/useWindowSize";
import clx from "classnames";

const PageWrapper = ({ children }) => {
  const [sideBarIsOpen, setSideBarIsOpen] = useState(false);
  const location = useLocation();
  const { isMobile } = useWindowSize()
  const isCalendar = location.pathname === ROUTES.SCHEDULER

  return (
    <div className={clx(styles['page-wrapper'], {
      [styles['page-wrapper_mobile_calendar']]: isMobile && isCalendar,
    })}>

      <Header
        setSideBarIsOpen={setSideBarIsOpen}
        isCalendar={isCalendar}
      />

      <div className={styles['content-wrapper']}>
        <SideBar
          sideBarIsOpen={sideBarIsOpen}
          setSideBarIsOpen={setSideBarIsOpen}
          isCalendar={isCalendar}
        />
        <div className={styles.section__wrapper}>
          <section>{children}</section>
        </div>
      </div>

    </div>
  );
};

export default PageWrapper;
