import { createSelector } from 'reselect';
import { getIconUrl, imageEntities } from '../../../utils/ImagesCreator';

const selectSchedulerState = (state) => state.schedulerReducer;

export const getCalendarWorkers = () =>
  createSelector(selectSchedulerState, (state) =>
    state.calendar.workers.map((worker) => ({
      name: worker.nome_referente,
      value: worker.idpoltrona,
      icon: getIconUrl(worker.idpoltrona, imageEntities.worker),
    })),
  );
export const getCalendarSlots = () =>
  createSelector(selectSchedulerState, (state) => state.availableSlots);
export const getCalendarData = () =>
  createSelector(selectSchedulerState, (state) => state.calendar);
export const getCalendarWorker = (id) =>
  createSelector(selectSchedulerState, (state) =>
    state.calendar.workers.find((worker) => +worker.idpoltrona === +id),
  );
export const getCalendarWorkerServices = () =>
  createSelector(selectSchedulerState, (state) =>
    state.calendar.services.map((service) => ({
      // value: service.idServiziPoltrona,
      idServiziPoltrona: service.idServiziPoltrona,
      idServizio: service.idServizio,
      idPoltrona: service.idPoltrona,
      durata: service.durata,
      prezzo: service.prezzo,
      disabilitato: service.disabilitato,
      onlyVisible: service.onlyVisible,
      idservezio: service.idservezio,
      time: service.time,
      value: service.idServizio,
      name: service.nome,
      icon: getIconUrl(service.idServizio, imageEntities.service),
      worker_id: +service.idpoltrona,
      duration: service.durata,
    })),
  );

export const getTaskById = (id) =>
  createSelector(selectSchedulerState, (state) =>
    Object.entries(state.calendar.appointments).reduce((acc, [data, tasks]) => {
      const foundTask = tasks.find((task) => +task.idprenotazione === +id);
      if (foundTask) {
        return foundTask;
      }
      return acc;
    }, {}),
  );

export const getCalendarQueue = () =>
  createSelector(selectSchedulerState, (state) => {
    const workers = state.calendar.queue
      // .filter(worker => worker.listaAttesa?.length > 0)
      .map((worker) => ({
        id: worker.idpoltrona,
        name: worker.nome_referente,
        icon: getIconUrl(worker.idpoltrona, imageEntities.worker),
        clients: worker.listaAttesa.map((client) => ({
          id: client.idUtente,
          queueId: client.idListaAttesa,
          name: `${client.nome} ${client.cognome}`,
          phone: client.cellulare,
          icon: '',
          duration: client.tempo,
        })),
      }));
    const clientsLength = workers.reduce((acc, item) => acc + item.clients.length, 0);
    return {
      workers,
      clientsLength,
    };
  });
export const infoRiccorrenzaSelect = () =>
  createSelector(selectSchedulerState, (state) => state.detaglioPrenotazioneRicorrente);
