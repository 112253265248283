import React from 'react';
import styles from "./FirstInfoStep.module.scss";
import ForwardButton from "../../customComponents/ForwardButton";
import {authActions} from "../../../../../store";
import {useDispatch} from "react-redux";

export const FirstInfoStepContainer = () => {
  const dispatch = useDispatch();

  return (
    <div className={styles.second_info_step_preview}>
      <h2>
        Ora <span className={styles.blue_inject}>scegli i servizi</span> base che vorrai
        <br/>
        offrire ai tuoi clienti.
        <br/>
        Potrai <span className={styles.gold_inject}>sempre</span> aggiungerne di nuovi o
        <br/>
        modificare quelli esistenti.
      </h2>
      <ForwardButton
        onAction={() => dispatch(authActions.GET_ACTIVE_SIGN_UP_STEP.SUCCESS(10))}
      />
    </div>
  )
}

export const FirstInfoStepFirstPreview = () => {
  return (
    <div className={styles.first_info_step_first_preview}>
      <h1>Perfetto <span>hai inserito gli orari</span> del tuo salone</h1>
    </div>
  )
}

export const SecondInfoStepPreview = () => {
  const dispatch = useDispatch();
  return (
    <div className={styles.first_info_step_container}>
      <h2>
        Ricorda che <span className={styles.gold_inject}>potrai sempre
        <br/>
        modificare</span>in seguito <span className={styles.blue_inject}>tutti gli orari,</span>
        <br/>
        sia generali che dei singoli
        <br/>
        collaboratori
      </h2>
    </div>
  )
}
