import React from 'react';
import styles from './NotificationsTable.module.scss';
import CustomTable from '../../../Common/CustomTable';
import AvatarIcon from '../../../Common/AvatarIcon';
import { modalActions } from '../../../Modals/store';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';

export default ({ values, setPaginationValue, pageCount, activeFilterId, tableName, isMobile }) => {
  const dispatch = useDispatch();
  const data = React.useMemo(() => values, [values]);
  const columns = React.useMemo(
    () =>
      isMobile
        ? [
            {
              Header: '',
              accessor: 'clientName',
              Cell: ({ row: { original, ...info } }) => {
                return (
                  <div className={styles.mobile_card_container}>
                    <div className={styles.client_info_and_message_container}>
                      <div className={styles.client_info_container}>
                        <AvatarIcon name={original.clientName} icon={''} size={'50px'} />
                        <div className={styles.client_info}>
                          <div className={styles.name}>{original.clientName}</div>
                          <div className={styles.description}>
                            {dayjs(original.date).format('DD MMMM YYYY HH:mm')}
                          </div>
                        </div>
                      </div>
                      <div className={styles.notification_message}>{original.message}</div>
                    </div>
                    {original.clientId && <div
                      className={styles.link}
                      onClick={() =>
                        original.clientId &&
                        dispatch(
                          modalActions.OPEN_MODAL.SUCCESS({
                            type: 'clientModal',
                            props: { client_id: original.clientId },
                          }),
                        )
                      }
                    >
                      &gt;
                    </div>}
                  </div>
                );
              },
            },
          ]
        : [
            {
              Header: '',
              accessor: 'createdAt',
              Cell: ({ row: {original} }) => {
                return <>
                  <div className={styles.name_cell_container}>
                    {dayjs(original.createdAt).format('DD MMMM YYYY')}
                  </div>
                  <div style={{height: '10px'}}/>
                  <div className={styles.name_cell_container}>
                    {dayjs(original.createdAt).format('HH:mm')}
                  </div>
                </>
              },
            },
            {
              Header: '',
              accessor: 'clientName',
              Cell: ({ row: { original } }) => {
                return (
                  <div className={styles.name_cell_container}>
                    <AvatarIcon name={original.clientName} icon={''} size={'40px'} />
                    <div className={styles.client_info}>
                      <div className={styles.name}>{original.clientName}</div>
                    </div>
                  </div>
                );
              },
            },
            {
              Header: '',
              accessor: 'message',
              Cell: ({ value }) => {
                return <div className={styles.name_cell_container}>{value}</div>;
              },
            },
            {
              Header: '',
              accessor: 'clientId',
              Cell: ({ value }) => {
                return (<>
                  {value && <div
                    className={styles.link}
                    onClick={() =>
                      value &&
                      dispatch(
                        modalActions.OPEN_MODAL.SUCCESS({
                          type: 'clientModal',
                          props: { client_id: value },
                        }),
                      )
                    }
                  >
                    Dettagli &gt;
                  </div>}
                </>);
              },
            },
          ],
    [isMobile],
  );

  return (
    <div className={styles.table_card}>
      {/* {tableName && (
        <>
          <div className={styles.table_name}>{tableName}</div>
          <hr
            style={{
              borderColor: '#E5E5E544',
            }}
          />
        </>
      )} */}
      <CustomTable
        data={data}
        columns={columns}
        serverSidePagination={true}
        setPaginationValue={setPaginationValue}
        pageCount={pageCount}
        entityId={activeFilterId}
        isMobile={isMobile}
      />
    </div>
  );
};
