import React from "react";
import CustomTable from "../../../../../Common/CustomTable";
import { ReactComponent as Bin } from '../../../../../../assets/svg/icons/trash_red.svg';
import { ReactComponent as PlusInCircle } from '../../../../../../assets/svg/plus_circle_blue.svg';
import styles from "./ClosuresTable.module.scss";
import dayjs from "dayjs";
import { modalActions } from "../../../../../Modals/store";
import { useDispatch } from "react-redux";

const MOTIVATIONS = ['Festività', 'Ferie', 'Mancanza di servizi', 'Motivi personali']

export default ({ values, setClosuresDateToDelete, isEmptyMessage = "Non ci sono dati disponibili al momento" }) => {

  const dispatch = useDispatch();
  const data = React.useMemo(
    () => values,
    [values]
  )

  const columns = React.useMemo(
    () => [
      {
        Header: '',
        id: 'Header',
        columns: [
          {
            Header: 'Giorno',
            accessor: 'days',
            id: 'day',
            Cell: ({ value }) => <div className={styles.day_value}>{value.join(' - ')
            }</div>
          },
          {
            Header: 'Periodo',
            accessor: 'dates',
            id: 'date',
            Cell: ({ value }) => <>{
              dayjs(value.startDate).format("DD/MM/YY")
            } fino a {
                dayjs(value.endDate).format("DD/MM/YY")
              }</>
          },
          {
            Header: 'Motivazione',
            accessor: 'items',
            id: 'motivation',
            Cell: ({ value }) => <div className={styles.day_value}>{
              MOTIVATIONS[value[0].tipo_chiusura--]
            }</div>
          },
          {
            Header: '',
            accessor: 'items',
            id: 'chiusura',
            Cell: ({ value }) => (
              <div
                className={styles.delete_cell_container}
                onClick={() => setClosuresDateToDelete(value.map(({ data_chiusura }) => data_chiusura))}
              >
                <div className={styles.text}>
                  Cancella chiusura
                </div>
                <Bin />
              </div>
            ),
          }
        ],
        Footer: () => {
          return (
            <div className={styles.action_footer}>
              <div
                onClick={() => dispatch(modalActions.OPEN_MODAL.SUCCESS({
                  type: 'workerClosuresModal',
                  props: { worker: data[0]?.items[0]?.nome_referente ?? null }
                }))}
                className={styles.action_button}
              >
                Nuova chiusura straordinaria
                <PlusInCircle />
              </div>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    data?.length ?
      <CustomTable data={data} columns={columns} /> :
      <div className={styles.empty_card}>
        {isEmptyMessage}
        <div className={styles.action_footer}>
          <div
            onClick={() => dispatch(modalActions.OPEN_MODAL.SUCCESS({
              type: 'workerClosuresModal',
              props: { worker: data[0]?.items[0]?.nome_referente ?? null },
            }))}
            className={styles.action_button}
          >
            Nuova chiusura straordinaria
            <PlusInCircle />
          </div>
        </div>
      </div>
  )
}