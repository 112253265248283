import styles from "./HomeInfoCards.module.scss";
import clx from "classnames";
import HoveredInfoIcon from "../../../Common/HoveredInfoIcon";
import {useSelector} from "react-redux";
import {getHomeStatistic} from "../../store/selectors";
import useWindowSize from "../../../../utils/useWindowSize";
import {Swiper, SwiperSlide} from 'swiper/react';

import 'swiper/css';

const HomeInfoCards = () => {
  const homeStat = useSelector(getHomeStatistic())
  const {isMobile} = useWindowSize()
  const data = Object.keys(homeStat).length ?
    [
      {
        cardName: 'Clienti',
        disabled: false,
        values: [
          {
            title: 'Totale Clienti',
            count: +homeStat.allClienti,
            value: false,
            isPercent: false,
            positive: false,
            infoDescription: 'Info Totale Clienti'
          },
          {
            title: 'Utenti attivi',
            count: +homeStat.activeUser,
            value: `${(+homeStat.diffActiveUser)?.toFixed(0)}%`,
            isPercent: true,
            positive: +homeStat.diffActiveUser >= 0,
            infoDescription: 'Info Totale Clienti'
          },
          {
            title: 'Nuovi utenti',
            count: +homeStat.allNewUserToday,
            value: `${(+homeStat.diffNewUserToday)?.toFixed(0)}%`,
            isPercent: true,
            positive: +homeStat.diffNewUserToday >= 0,
            infoDescription: 'Info Nuovi utenti'
          },
        ]
      },
      {
        cardName: 'Prenotazioni',
        disabled: false,
        values: [
          {
            title: 'Totale prenotazioni',
            count: +homeStat.allPren,
            value: false,
            isPercent: false,
            positive: false,
            infoDescription: 'Info Totale prenotazioni'
          },
          {
            title: 'Prenotazioni settimanali',
            count: +homeStat.allPrenThisWeek,
            value: `${(+homeStat.diffPrenThisWeek)?.toFixed(0)}%`,
            isPercent: true,
            positive: +homeStat.diffPrenThisWeek >= 0,
            infoDescription: 'Info Prenotazioni settimanali'
          },
          {
            title: 'Prenotazioni giornaliere',
            count: +homeStat.allPrenToday,
            value: `${(+homeStat.diffPrentoday)?.toFixed(0)}%`,
            isPercent: true,
            positive: +homeStat.diffPrentoday >= 0,
            infoDescription: 'Info Prenotazioni giornaliere'
          },
        ]
      },
      {
        cardName: 'Performance',
        disabled: true,
        values: [
          {
            title: 'Media recensioni',
            count: 1157,
            value: false,
            isPercent: false,
            positive: false,
            infoDescription: 'Info Media recensioni'
          },
          {
            title: 'Record visite settimanali',
            count: 678,
            value: false,
            isPercent: false,
            positive: false,
            infoDescription: 'Info Record visite settimanali'
          },
          {
            title: 'Ordini ricevuti',
            count: 78,
            value: '+88%',
            isPercent: true,
            positive: true,
            infoDescription: 'Info Ordini ricevuti'
          },
        ]
      },
    ] : []
  return (
    !!Object.keys(homeStat).length &&
    <Swiper
      style={{overflow: 'visible'}}
      grabCursor={true}
      slidesPerView={3}
      breakpoints={{
        0: {
          slidesPerView: 1.2,
        },
        600: {
          slidesPerView: 2.2,
        },
        900: {
          slidesPerView: 3,
        },
      }}
      className={clx(styles.cards_container, {[styles.cards_container_mobile]: isMobile})}
    >
      {data.map(card => <SwiperSlide
        key={card.cardName}
        className={clx(styles.card_item_wrapper, {[styles.disabled_card]: !!card?.disabled})}
      >
        <div className={styles.card_name}>
          {card.cardName}
        </div>
        <div className={styles.card_values_container}>
          {
            card.values.map(value => (
              <div
                key={`${card.cardName}${value.title}`}
                className={styles.card_value_item}
              >
                <div className={styles.item_title}>
                  {value.title}
                </div>
                <div className={styles.item_values_wrapper}>
                  <div className={styles.item_count}>
                    {value.count}
                  </div>
                  <div className={styles.value_and_info_container}>
                    <div className={clx(styles.value_wrapper,
                      {[styles['disabled']]: !value.value},
                      {[styles['percent']]: value.isPercent},
                      {[styles['positive']]: value.positive}
                    )}>
                      <div className={styles.value_count}>
                        {value.value}
                      </div>
                    </div>
                    <HoveredInfoIcon
                      description={value.infoDescription}
                    />
                  </div>
                </div>
              </div>
            ))
          }
        </div>
        <div className={styles.disabled_card_value}>In sviluppo</div>
      </SwiperSlide>)}
    </Swiper>
  )
}

export default HomeInfoCards