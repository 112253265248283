import styles from './Mobile.module.scss';

import React, { useEffect, useMemo, useRef, useState } from 'react';
import CustomInput from '../../CustomInput';
import Modal from 'Containers/Common/Modal';
import { ReactComponent as CloseIcon } from '../../../../assets/svg/cross.svg';
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import clsx from 'clsx';
const InputComponent = React.forwardRef(
  (
    {
      InputProps,
      setIsOpen,
      placeholder,
      title,
      clearData,
      withIcon,
      icon,
      error,
      disabled,
      isMobile,
      value,
    },
    ref,
  ) => (
    <div ref={InputProps.ref} className={styles.picker_input_wrapper}>
      <div ref={ref}>
        <CustomInput
          {...InputProps}
          ref={null}
          onFocus={() => setIsOpen(true)}
          onBlur={() => setIsOpen(false)}
          placeholder={placeholder}
          inputValue={value || '--:--'}
          onAction={() => setIsOpen(true)}
          title={title}
          clearData={clearData}
          withIcon={withIcon}
          clearButton={false}
          error={error}
          disabled={disabled}
          height={isMobile ? '50px' : 'fit-content'}
        />
      </div>
    </div>
  ),
);
const CustomTimePickerMobile = ({
  value,
  setValue,
  placeholder,
  title,
  icon,
  timeStep = 5,
  withIcon,
  minTime,
  maxTime,
  open = false,
  error,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const maxHour = maxTime ? dayjs(maxTime).get('hour') : 24;
  const minHour = minTime ? dayjs(minTime).get('hour') : 0;
  const maxMinute = maxTime ? dayjs(maxTime).get('minute') : 60;
  const minMinute = minTime ? dayjs(minTime).get('minute') : 0;
  const [hour, setHour] = useState(value ? dayjs(value).get('hour') : minHour);
  const [minute, setMinute] = useState(value ? dayjs(value).get('minute') : minMinute);
  const hourRef = useRef(null);
  const minuteRef = useRef(null);
  const activeHourRef = useRef(null);
  const activeMinuteRef = useRef(null);
  const setValueComponent = () => {
    const time = dayjs(value).set('hour', hour).set('minute', minute).set('second', 0);
    setValue(time);
  };
  useEffect(() => {
    setHour(value ? dayjs(value).get('hour') : minHour);
    setMinute(value ? dayjs(value).get('minute') : minMinute);
  }, [value]);

  useEffect(() => {
    const currentTime = dayjs(value);
    // if (currentTime.isAfter(maxTime)) {
    //   if (maxMinute == 0)
    //     if (maxHour == 0) setHour(minHour);
    //     else setHour(maxHour - 1);
    //   else setMinute(maxMinute - step);
    // } else if (currentTime.isBefore(minTime)) {
    //   if (minMinute == 60 - step)
    //     if (minHour == 23) setHour(minHour);
    //     else setHour(minHour + 1);
    //   else setMinute(minMinute + step);
    // }
    // if (currentTime.isAfter(maxTime) || currentTime.isBefore(minTime)) setValueComponent();
  }, [minTime, maxTime]);
  useEffect(() => {
    if (minuteRef?.current && activeMinuteRef?.current)
      minuteRef.current.scrollTop =
        activeMinuteRef.current.offsetTop - minuteRef.current.getBoundingClientRect().height;

    if (hourRef?.current && activeHourRef?.current)
      hourRef.current.scrollTop =
        activeHourRef.current.offsetTop - hourRef.current.getBoundingClientRect().height;
  }, [isOpen, minuteRef, activeMinuteRef, hourRef, activeHourRef, minute, hour]);
  const hours = useMemo(() => {
    const options = [];
    for (let i = 0; i < 24; i++) options.push([i, i < minHour || i > maxHour]);
    return options;
  }, [minTime, maxTime]);
  const minutes = useMemo(() => {
    const options = [];
    for (let i = 0; i < 60; i += timeStep) {
      options.push([i, (hour == minHour && i < minMinute) || (hour == maxHour && i > maxMinute)]);
    }
    return options;
  }, [minTime, maxTime]);

  return (
    <div>
      <Modal isModalOpen={isOpen} setIsModalOpen={() => setIsOpen(false)}>
        <div className={styles.timer_picker}>
          <div className={styles.timer_picker_header}>
            <div className={styles.timer_picker_header_title}>{title}</div>

            <CloseIcon
              onClick={() => setIsOpen(false)}
              className={styles.timer_picker_header_close}
            />
          </div>
          <div className={styles.timer_picker_body}>
            <div className={styles.timer_picker_body_wrapper}>
              <div className={styles.timer_picker_body_wrapper_title}>Ora</div>
              <div className={styles.timer_picker_body_wrapper_options} ref={hourRef}>
                {hours.map(([h, disabled]) => (
                  <div
                    className={clsx(styles.timer_picker_body_wrapper_options_option, {
                      [styles.active]: hour == h,
                      [styles.disabled]: disabled,
                    })}
                    ref={hour == h ? activeHourRef : null}
                    onClick={() => !disabled && setHour(h)}
                  >
                    {`${h}`.padStart(2, '0')}
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.timer_picker_body_wrapper}>
              <div className={styles.timer_picker_body_wrapper_title}>Minuti</div>
              <div className={styles.timer_picker_body_wrapper_options} ref={minuteRef}>
                {minutes.map(([m, disabled]) => (
                  <div
                    className={clsx(styles.timer_picker_body_wrapper_options_option, {
                      [styles.active]: minute == m,
                      [styles.disabled]: disabled,
                    })}
                    ref={minute == m ? activeMinuteRef : null}
                    onClick={(e) => !disabled && setMinute(m)}
                  >
                    {`${m}`.padStart(2, '0')}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <footer className={styles.timer_picker_footer}>
            <Button
              variant='contained'
              color='primary'
              className={styles.confirm_button}
              onClick={() => {
                setIsOpen(false);
                setValueComponent();
              }}
            >
              Salva
            </Button>
          </footer>
        </div>
      </Modal>
      <CustomInput
        onAction={() => setIsOpen(true)}
        icon={icon}
        readOnly={true}
        withIcon={withIcon}
        title={title}
        placeholder={placeholder}
        setInputValue={(v) => setValue(v)}
        inputValue={dayjs(value)?.format('HH:mm') || '--:--'}
      />
    </div>
  );
};

export default CustomTimePickerMobile;
