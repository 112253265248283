import React from 'react';
import styles from "./SalonStartTimeStep.module.scss";
import CustomTimePicker from "../../customComponents/CustomTimePicker";
import {authActions} from "../../../../../store";
import {useDispatch, useSelector} from "react-redux";
import {salonWorkTimes} from "../../../../../store/selectors";
import {SalonWorkTimeStepWrapper} from "../SalonWorkTimeStepWrapper";

export const SalonStartTimeStepContainer = () => {
  const dispatch = useDispatch();
  const {startMorning} = useSelector(salonWorkTimes());

  const setValue = (startMorning) => {
    dispatch(authActions.SET_SALON_WORK_TIMES.SUCCESS({startMorning}))
  }

  return (
    <SalonWorkTimeStepWrapper
      title={'A che ora apri il tuo salone?'}
      actionDisabled={!startMorning}
      action={() => dispatch(authActions.GET_ACTIVE_SIGN_UP_STEP.SUCCESS(3))}
    >
      <div className={styles.salon_picker}>
        <CustomTimePicker
          title={'Imposta l’orario di apertura del tuo salone'}
          value={startMorning}
          setValue={setValue}
        />
      </div>
    </SalonWorkTimeStepWrapper>
  )
}

export const SalonStartTimeStepFirstPreview = () => {
  return (
    <div className={styles.salon_start_time_step_first_preview}>
      <h1>Impostiamo gli orari del <span>tuo salone</span></h1>
    </div>
  )
}

export const SalonStartTimeStepSecondPreview = () => {
  return (
    <div className={styles.salon_start_time_step_second_preview}>
      <h2>
        Questi saranno <span className={styles.blue_inject}>orari generici</span> per
        <br/>
        poter iniziare ad utilizzare
        <br/>
        ZetaBarber.
        <br/>
        Potrai in seguito <span className={styles.gold_inject}>modificarli a tuo
        <br/>
        piacimento</span>, e anche diversificarli
        <br/>
        per poltrona
      </h2>
    </div>
  )
}