import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './SchedulerWorker.module.scss';
import { schedulerActions } from '../../store';
import dayjs from 'dayjs';
import SchedulerTask from '../SchedulerTask';
import AvatarIcon from '../../../Common/AvatarIcon';
import { modalActions } from '../../../Modals/store';
import { ROUTES } from '../../../../routes/constants';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as PencilIcon } from '../../../../assets/svg/pencil_garay.svg';
import { ReactComponent as ArrowBlack } from '../../../../assets/svg/arrow-up.svg';
import ConfirmModal from '../../../Common/ConfirmModal';
import { authActions } from '../../../Auth/store';
import useWindowSize from 'utils/useWindowSize';
import useOutsideClick from 'utils/useOutsideClick';
import clx from 'classnames';
import { ReactComponent as CloseIcon } from '../../../../assets/svg/cross.svg';
import { ReactComponent as InstagramIcon } from '../../../../assets/svg/badgets/instagram.svg';
import LinearProgress from '../../../Common/LinearProgress/index.js';
import { getMyAccountStaff } from 'Containers/Settings/store/selectors';

const SchedulerWorker = ({ day, worker, setStartData, stepSize, startData, showHeader }) => {
  const [extraTask, setExtraTask] = useState({
    day,
    workerId: worker.id,
    task: {},
  });
  const [taskToDrag, setTaskToDrag] = useState(false);
  const dropdownWorkerRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const setValue = (position = 0, type) => {
    if (position >= 0) {
      const positionToTime = dayjs(day.startWork * 1000).add(position * 5, 'minutes');
      if (
        (startData.type || type) &&
        (day.date !== extraTask.day?.date ||
          worker.id !== extraTask.workerId ||
          position !== extraTask.task?.position)
      ) {
        switch (type || startData.type) {
          case 'create': {
            const newEndTaskTime = positionToTime.add(30, 'minutes');
            const isOverflow = extraTask.day?.endWork <= newEndTaskTime.unix();
            const task = {
              left: 0,
              hovered: true,
              color: 'gray',
              nome: worker.workerName,
            };
            if (isOverflow) {
              const maxPosition = (extraTask.day?.workTime - 1800) / 60 / 5;
              const maxEndTaskTime = dayjs(extraTask.day.endWork * 1000);
              const minEndTaskTime = dayjs((extraTask.day.endWork - 1800) * 1000);
              task.top = maxPosition * stepSize;
              task.position = maxPosition;
              task.orario_start = minEndTaskTime.format('HH:mm:ss');
              task.orario_end = maxEndTaskTime.format('HH:mm:ss');
              task.unixStartTime = minEndTaskTime.unix();
              task.unixEndTime = maxEndTaskTime.unix();
              task.size = ((task.unixEndTime - task.unixStartTime) / 60 / 5) * stepSize;
            } else {
              const minutes = positionToTime.get('minutes');
              const floorMinutes = Math.floor(minutes / 15) * 15;
              const roundedPosition = positionToTime.set('minutes', floorMinutes);
              const newEndTaskTime = roundedPosition.add(30, 'minutes');
              const diff = (floorMinutes - minutes) / 5;
              task.top = position * stepSize + stepSize * diff;
              task.position = position + diff;
              task.orario_start = roundedPosition.format('HH:mm:ss');
              task.orario_end = newEndTaskTime.format('HH:mm:ss');
              task.unixStartTime = roundedPosition.unix();
              task.unixEndTime = newEndTaskTime.unix();
              task.size = ((task.unixEndTime - task.unixStartTime) / 60 / 5) * stepSize;
            }
            dispatch(
              modalActions.OPEN_MODAL.SUCCESS({
                type: 'addTaskModal',
                props: {
                  ...extraTask,
                  task,
                  subtitle: dayjs(extraTask?.day?.date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
                },
              }),
            );
            setStartData({});
            return setExtraTask({
              day,
              workerId: worker.id,
              task: {},
            });
            // return setExtraTask((extraTask) => ({
            //   ...extraTask,
            //   task,
            // }));
          }
          case 'dragged': {
            const newEndTaskTime = positionToTime.add(startData.task.unixSize, 'seconds');
            const isOverflow = extraTask.day?.endWork <= newEndTaskTime.unix();
            const task = {
              ...startData.task,
              dragged: true,
            };
            if (isOverflow) {
              const maxPosition = (extraTask.day?.workTime - startData.task.unixSize) / 60 / 5;
              const maxEndTaskTime = dayjs(extraTask.day.endWork * 1000);
              const minEndTaskTime = dayjs(
                (extraTask.day.endWork - startData.task.unixSize) * 1000,
              );
              task.top = maxPosition * stepSize;
              task.position = maxPosition;
              task.orario_start = minEndTaskTime.format('HH:mm:ss');
              task.orario_end = maxEndTaskTime.format('HH:mm:ss');
              task.unixStartTime = minEndTaskTime.unix();
              task.unixEndTime = maxEndTaskTime.unix();
            } else {
              task.top = position * stepSize;
              task.position = position;
              task.orario_start = positionToTime.format('HH:mm:ss');
              task.orario_end = newEndTaskTime.format('HH:mm:ss');
              task.unixStartTime = positionToTime.unix();
              task.unixEndTime = newEndTaskTime.unix();
            }
            return setExtraTask((extraTask) => ({
              ...extraTask,
              task,
            }));
          }
          case 'resize': {
            const endTime = dayjs(startData.day.startWork * 1000).add(
              (position + 1) * 5,
              'minutes',
            );
            const size = (position + 1) * stepSize - startData.task.top;
            const endTimeFormat =
              endTime.unix() <= startData.task.unixStartTime
                ? dayjs((startData.task.unixStartTime + 300) * 1000).format('HH:mm:ss')
                : endTime.format('HH:mm:ss');
            return setExtraTask({
              ...startData,
              task: {
                ...startData.task,
                position: position + 1,
                dragged: true,
                orario_end: endTimeFormat,
                size,
              },
            });
          }
        }
      }
    }
  };

  const addTask = (position) => {
    //prmesso account staff allowInsert deve essere = 1 se 'staff' in localStorage
    if(searchParams.get('allowInsert') != 1 && localStorage.getItem('staff')) return;

    position = Math.floor(position / 3) * 3;
    if (extraTask.task.position === position) {
      dispatch(
        modalActions.OPEN_MODAL.SUCCESS({
          type: 'addTaskModal',
          props: extraTask,
        }),
      );
      setStartData({});
      setExtraTask({
        day,
        workerId: worker.id,
        task: {},
      });
      return;
    }
    setStartData({
      type: 'create',
      workerId: worker.id,
      task: {},
      day,
    });
    setValue(position, 'create');
  };

  const saveDraggedTask = () => {
    dispatch(
      schedulerActions.EDIT_CALENDAR_TASK.REQUEST(taskToDrag, () => {
        setExtraTask({ day, workerId: worker.id, task: {} });
        setStartData({});
        setTaskToDrag(false);
      }),
    );
  };

  /** Modal worker info && Sold out Modal **/
  const [modalData, setModalData] = useState({
    percent: 0,
    label: 'Non ci sono ancora prenotazioni',
    closed: false,
  });
  const [sOModalData, setSOModalData] = useState({
    worker: worker,
    day: day,
  });
  const tasks = worker.workerTasks.filter((t) => t.idprenotazione);
  const closures = worker.workerTasks.filter((t) => t.limitCard === true);

  useEffect(() => {
    let tasksMs = 0,
      timeDisable = 0,
      fulldayMs = 86400;
    tasks?.map((time) => {
      tasksMs += time.unixSize;
    });
    closures.map((time) => {
      timeDisable += time.unixSize;
    });
    if (fulldayMs - timeDisable <= 0)
      setModalData({
        ...modalData,
        closed: fulldayMs - timeDisable <= 0 ? true : false,
      });

    if (tasks.length == 0 && modalData.percent != 0) {
      setModalData({
        ...modalData,
        percent: 0,
        label: 'Non ci sono ancora prenotazioni',
      });
    }

    if (tasks.length > 0) {
      let percent = ((tasksMs / (fulldayMs - timeDisable)) * 100).toFixed();
      let dailyLeft = fulldayMs - timeDisable - tasksMs;

      let timelabel = '';
      if (dailyLeft > 0) {
        let minutes = Math.floor(dailyLeft / 60);
        let hours = Math.floor(minutes / 60);
        minutes = minutes % 60;
        hours = hours % 24;

        timelabel = `${hours > 1 ? hours + ' ore' : hours == 1 ? '1 ora' : ''} ${
          hours != 0 && minutes != 0 ? 'e' : ''
        } ${minutes > 1 ? minutes + ' minuti' : minutes == 1 ? '1 minuto' : ''}`;
      } else {
        let minutes = Math.floor(tasksMs / 60);
        let hours = Math.floor(minutes / 60);
        minutes = minutes % 60;
        hours = hours % 24;

        timelabel = `${hours > 1 ? hours + ' ore' : hours == 1 ? '1 ora' : ''} ${
          hours != 0 && minutes != 0 ? 'e' : ''
        } ${minutes > 1 ? minutes + ' minuti' : minutes == 1 ? '1 minuto' : ''}`;
      }
      setSOModalData({
        ...sOModalData,
        soldOut: percent < 100 ? false : true,
      });

      setModalData({
        ...modalData,
        percent: percent,
        label:
          percent < 100
            ? dailyLeft > 0
              ? `${percent}% mancano ${timelabel} al sold out`
              : `Hai ${timelabel} di straordinari`
            : `Sold Out ✨🚀`,
      });
    }
  }, [tasks.length]);
  /**                                     **/

  useOutsideClick(dropdownWorkerRef, () => {
    if (isDropdownOpen) {
      setIsDropdownOpen(false);
    }
  });

  const cardWidth = dropdownWorkerRef?.current?.clientWidth;
  const maxDropdownWidth = 250;

  const [sendNotifica, setSendNotifica] = useState(false);
  const BoxNotice = ({ sendNotifica, setSendNotifica = () => {} }) => {
    return (
      <div className={styles.notice}>
        Invia una notifica al cliente &nbsp;
        <input
          className={styles.boxNotice}
          checked={sendNotifica}
          onChange={(e) => {
            e.preventDefault();
            console.log(e.target.checked);
            setSendNotifica(e.target.checked);
            setTaskToDrag({ ...taskToDrag, sendNotificaUpdateUser: e.target.checked });
          }}
          type='checkbox'
        />
      </div>
    );
  };

  const myAccount = useSelector(getMyAccountStaff());

  useEffect(() => {
    if(localStorage.getItem('staff')){
      let salon_management = myAccount?.permessi?.length > 0 ? myAccount?.permessi.find(perm => perm.sezione === 2) : null;
      if( salon_management ) {
        searchParams.set('salon_management', 1);
      }

      let calendar = myAccount?.permessi?.length > 0 ? myAccount?.permessi.find(perm => perm.sezione === 1) : null;
      if( calendar.allowEdit==1 ) {
        searchParams.set('allowEdit', 1);
      }
      if( calendar.allowInsert==1 ) {
        searchParams.set('allowInsert', 1);
      }

      setSearchParams(searchParams);
    }

  }, [searchParams]);

  return (
    <>
      <ConfirmModal
        isModalOpen={!!taskToDrag}
        setIsModalOpen={() => {
          setExtraTask({ day, workerId: worker.id, task: {} });
          setStartData({});
          setTaskToDrag(false);
        }}
        title={'Sei sicuro di voler spostare la prenotazione?'}
        action={() => {
          saveDraggedTask();
          sendNotifica && setSendNotifica(false);
        }}
        content={
          extraTask?.task?.utente ? (
            <BoxNotice sendNotifica={sendNotifica} setSendNotifica={setSendNotifica} />
          ) : null
        }
      />
      <div className={styles.worker__wrapper}>
        <div
          data-nomediv={'worker_header'}
          draggable={false}
          className={styles.worker_header}
          style={{
            top: !showHeader ? '0px' : '50px',
            backgroundColor: !isDropdownOpen ? '#fff' : '#e5e5e5',
          }}
          ref={dropdownWorkerRef}
          onClick={() => {
            dispatch(schedulerActions.GET_CALENDAR_SLOTS.CLEAR());

            if (isMobile)
              dispatch(
                modalActions.OPEN_MODAL.SUCCESS({
                  type: 'workerRetailModal',
                  props: { worker, modalData: {...modalData, salon_management: searchParams.get('salon_management')}, tasks, sOModalData },
                }),
              );
            else {
              setIsDropdownOpen(!isDropdownOpen);
            }
          }}
        >
          {/* <span data-nomediv={'worker_header'}></span> */}
          {worker.icon ? (
            <div className={styles.value_icon}>
              {!isMobile && (
                <AvatarIcon icon={worker.icon} name={worker.workerName} size={'35px'} />
              )}{' '}
            </div>
          ) : null}
          <div className={styles.option_value} style={{ marginRight: 'auto' }}>
            {worker.workerName}
          </div>
          <div className={clx(styles.menu_icon, { [styles.option_badge]: sOModalData.soldOut })}>
            {sOModalData.soldOut && !isMobile ? 'Sold Out 🚀' : ''}
            <div
              className={clx(styles.menu_icon, {
                [styles['menu_icon_close']]: !isDropdownOpen,
              })}
            >
              <ArrowBlack height={'15px'} width={'20px'} />
            </div>
          </div>
          {isMobile ? (
            <></>
          ) : (
            !!isDropdownOpen && (
              <div
                className={clx(styles.worker_selector, {
                  [styles['worker_selector_close']]: !isDropdownOpen,
                })}
              >
                <div
                  className={styles.dropdown__menu}
                  style={{
                    width: cardWidth < maxDropdownWidth ? cardWidth - 17 : maxDropdownWidth,
                  }}
                >
                  <div className={styles.dropdown__menu_inner_list}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                      <div style={{ display: 'flex', gap: '5px' }}>
                        <span className={styles.text} style={{ fontWeight: '700' }}>
                          {tasks.length ?? 78}
                        </span>
                        <span className={styles.text}>
                          {tasks.length != 1 ? 'Prenotazioni' : 'Prenotazione'}{' '}
                        </span>
                      </div>
                      <div className={styles.linear_progress_box}>
                        <LinearProgress percent={modalData.percent} color={'#858AF9'} />
                        <div
                          className={styles.linear_progress_box_text}
                          style={{ color: modalData.percent < 100 ? 'black' : '#858AF9' }}
                        >
                          {modalData.label}
                        </div>
                      </div>
                    </div>
                    {!modalData.closed ? (
                      <div
                        className={styles.link}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          cursor: 'pointer',
                          gap: '5px',
                          alignItems: 'center',
                        }}
                        onClick={() => {
                          dispatch(
                            modalActions.OPEN_MODAL.SUCCESS({
                              type: 'workerSoldOutModal',
                              props: { worker, modalData: sOModalData, tasks },
                            }),
                          );
                        }}
                        >
                        <InstagramIcon className={styles.icon} />
                        <span className={styles.ltext}>{'Condividi su Instagram >'}</span>
                        </div>
                      ) : null}

                       {(searchParams.get('salon_management') == 1 || !localStorage.getItem('staff')) && <div
                        onClick={() => {
                        navigate(
                          `${ROUTES.SALON_MANAGEMENT}${ROUTES.WORKERS}${ROUTES.WORKING_TIME}/${worker.id}`,
                        )
                      }}
                      className={styles.dropdown__menu_btn}
                      style={cardWidth < maxDropdownWidth ? { padding: '10px 10px' } : {}}
                    >
                      {cardWidth < maxDropdownWidth ? 'Gestisci' : 'Gestione poltrona'}
                    </div>}
                  </div>
                </div>
              </div>
            )
          )}
        </div>

        <div
          onDragLeave={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setExtraTask((taskValue) => ({ ...taskValue, task: {} }));
          }}
          onDragOver={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (e.nativeEvent.target.className === 'cardBottom') {
              setValue(
                Math.floor(
                  (e.nativeEvent.offsetY + e.nativeEvent.target.offsetParent.offsetTop) / stepSize,
                ),
              );
            }
            if (e.nativeEvent.target.className === 'card') {
              setValue(
                Math.floor((e.nativeEvent.offsetY + e.nativeEvent.target.offsetTop) / stepSize),
              );
            }
            if (e.nativeEvent.target.className === 'worker') {
              setValue(Math.floor(e.nativeEvent.offsetY / stepSize));
            }
          }}
          onDragExit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setExtraTask((taskValue) => ({ ...taskValue, task: {} }));
          }}
          onDragEndCapture={(e) => {
            e.preventDefault();
            setExtraTask((taskValue) => ({ ...taskValue, task: {} }));
          }}
          onDrop={(e) => {
            const formedTask = {
              idPrenotazione: extraTask.task.idprenotazione,
              orario_start: extraTask.task.orario_start,
              orario_end: extraTask.task.orario_end,
              data_prenotazione: extraTask.day.date,
              poltrona: extraTask.workerId,
            };
            formedTask.idPrenotazione && setTaskToDrag(formedTask);
            e.preventDefault();
            e.stopPropagation();
          }}
          onDragEnd={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setExtraTask((taskValue) => ({ ...taskValue, task: {} }));
          }}
          className={styles.worker_container}
        >
          <div
            onClick={(e) => addTask(Math.floor(e.nativeEvent.offsetY / stepSize))}
            onMouseLeave={() => {
              if (startData.type === 'create') {
                setExtraTask((taskValue) => ({ ...taskValue, task: {} }));
                setStartData({});
              }
            }}
            className={'worker'}
            style={{
              position: 'absolute',
              height: '100%',
              top: 0,
              left: 0,
              zIndex: 1,
              width: '100%',
              backgroundColor: 'transparent',
              opacity: 0,
              cursor: 'pointer',
            }}
          ></div>
          {worker.id === extraTask.workerId &&
          day.date === extraTask.day.date &&
          !!Object.keys(extraTask.task).length ? (
            <SchedulerTask
              setStartData={setStartData}
              selectedTask={extraTask.task}
              day={day}
              workerId={worker.id}
              stepSize={stepSize}
              isDragStart={extraTask.task?.dragged}
              searchParams={searchParams}
            />
          ) : null}
          {worker?.workerTasks?.map((selectedTask, index) => (
            <SchedulerTask
              key={index}
              setStartData={setStartData}
              selectedTask={selectedTask}
              day={day}
              workerId={worker.id}
              stepSize={stepSize}
              isDragStart={extraTask.task?.dragged}
              searchParams={searchParams}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default SchedulerWorker;
