import { createSelector } from 'reselect';
import { getIconUrl, imageEntities } from '../../../utils/ImagesCreator';

const selectClientsState = (state) => state.clientReducer;

export const getPrenotazioneByID = () =>
  createSelector(selectClientsState, (state) => state.prenotazione);

export const getInvitiOnboarding = () =>
  createSelector(selectClientsState, (state) => state.inviti_onboarding);

export const getClient = () =>
  createSelector(selectClientsState, (state) => ({
    ...state?.activeClient?.cliente,
    name: `${state.activeClient?.cliente?.nome} ${state.activeClient?.cliente?.cognome}`,
    value: state.activeClient?.cliente?.idutente,
    icon: state.activeClient?.cliente?.img,
    phone: state.activeClient?.cliente?.cellulare,
    email: state.activeClient?.cliente?.email,
    registrationDate: state.activeClient?.cliente?.data_iscrizione,
    lastAccess: state.activeClient?.cliente?.ultimo_accesso,
    favoriteDay: state.activeClient?.giornoPreferito,
    lastAppointment: state.activeClient?.ultimaPrenotazione,
    favoriteService: state.activeClient?.servizioPreferito,
    workerAppointment: state.activeClient?.poltronePrenotate,
    appointmentPerMonth: state.activeClient?.prenPerMese,
    favoriteTimes: state.activeClient?.orarioPreferito,
    tipo: state.activeClient?.cliente?.tipo,
    appointments: state.activeClient?.prenotazioni?.map((appointment) => ({
      id: appointment.idprenotazione,
      startDate: appointment.data_prenotazione,
      startTime: appointment.orario_start,
      worker: appointment.poltrona,
      worker_id: appointment.poltrona,
      services: appointment.servizi.map((service) => service.nomeServizio),
    })),
    notes: state.activeClient?.notes,
  }));
export const getClientsShort = () =>
  createSelector(selectClientsState, (state) =>
    state.clients.map((client) => ({
      idutente: client.idutente,
      name: `${client.nome} ${client.cognome}`,
      value: client.idutente,
      icon: client.img,
      type: client.disabilitato ? 3 : client.attivo ? 1 : !client.attivo ? 2 : 0,
      phone: client.cellulare,
      email: client.email,
      category: client.tipo,
      totAppointments: client.totPren,
      lastAppointment: client.lastPren,
      createFromSalone: client.createFromSalone,
      verified: client.cellulare_verificato,
    })),
  );

export const getClientsStats = (searchedName) =>
  createSelector(selectClientsState, (state) =>
    state.clients
      .filter(
        (client) =>
          !searchedName ||
          `${client.nome} ${client.cognome}`.toLowerCase().includes(searchedName.toLowerCase()),
      )
      .reduce(
        (acc, client, _, array) => {
          acc.registeredClients = array.length;
          return client.disabilitato
            ? { ...acc, disabledClients: acc.disabledClients + 1 }
            : client.attivo
            ? { ...acc, activeClients: acc.activeClients + 1 }
            : !client.attivo
            ? { ...acc, nonActiveClients: acc.nonActiveClients + 1 }
            : acc;
        },
        {
          registeredClients: 0,
          activeClients: 0,
          nonActiveClients: 0,
          disabledClients: 0,
        },
      ),
  );

export const allBadClients = () => createSelector(selectClientsState, (state) => state.badClients);
