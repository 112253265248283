import React, { useEffect, useState } from "react";
import styles from './paymentDaysModal.module.scss';
import clsx from "clsx";
import { Button } from "@mui/material";
import IOSSwitch from "Containers/Common/CustomSwitch";
import SelectorMenu from "Containers/Common/CustomSelector/SelectorMenu";

const days = [{
        name: 'Domenica',
        value: 0,
    },
    {
        name: 'Lunedì',
        value: 1,
    }, 
    {
        name: 'Martedì',
        value: 2,
    }, 
    {
        name: 'Mercoledì',
        value: 3,
    }, 
    {
        name: 'Giovedì',
        value: 4,
    }, 
    {
        name: 'Venerdì',
        value: 5,
    }, 
    {
        name: 'Sabato',
        value: 6,
    }];

const WorkerPaymentDaysModal = ()=>{
    const [ selectedAll, setSelectedAll ] = useState(true);
    const [ selectedDays, setSelectedDays ] = useState([]);

    useEffect(()=>{
        setSelectedDays([]);
    }, [selectedAll])

    return (<>
        <div className={styles.payments_modal_wrapper}>
            <div className={styles.body}>
                <p className={styles.title_container}>
                    Seleziona i giorni in cui sarà obbligatorio pagare online per questa poltrona
                </p>
                <div className={styles.switcher_container}>
                    Tutti
                    <IOSSwitch checked={selectedAll} onChange={()=>setSelectedAll(!selectedAll)} defaultChecked/>
                </div>
                {!selectedAll && <div className={styles.days_container}>
                    <SelectorMenu
                    isMultiple={true}
                    fullHeight={true}
                    setIsDropdownOpen={() => {}}
                    options={days}
                    setValue={setSelectedDays}
                    value={selectedDays}
                    />
                </div>}

            </div>
            <div className={clsx(styles.actions_container, styles.footer)}>
                <Button className={styles.confirm_button} variant='contained' onClick={()=>console.log('salva')}>
                Salva
                </Button>
            </div>
        </div> 
    </>);

}

export default WorkerPaymentDaysModal;