import React, { useState } from 'react';
import styles from './WorkerItem.module.scss';
import { useDispatch } from 'react-redux';
import { salonManagementActions } from '../../../../store';
import AvatarIcon from '../../../../../Common/AvatarIcon';
import { ROUTES } from '../../../../../../routes/constants';
import { useNavigate } from 'react-router-dom';
import useWindowSize from '../../../../../../utils/useWindowSize';
import clx from 'classnames';
import { modalActions } from '../../../../../Modals/store';
import { ReactComponent as EditIconInCircle } from '../../../../../../assets/svg/icons/editBlueCircle.svg';
import { ReactComponent as Bin } from '../../../../../../assets/svg/icons/trash_red.svg';
import { ReactComponent as RightIcon } from '../../../../../../assets/svg/icons/arrow_l.svg';
import { ReactComponent as MenuIcon } from '../../../../../../assets/svg/icons/menu_gray.svg';
import { ReactComponent as ArrowLIcon } from '../../../../../../assets/svg/icons/arrow_l.svg';
import { ReactComponent as InstagramIcon } from '../../../../../../assets/svg/badgets/instagram.svg';

import HoveredInfoIcon from '../../../../../Common/HoveredInfoIcon';
import Switcher from '../../../../../Common/Switcher';
import { Button } from '@mui/material';
import { getActiveSalonePaymentInfo } from 'utils/salonToken';

export const WorkerItem = ({ worker, isDragged, setWorkerToDeleteId }) => {
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const paymentDisabled = getActiveSalonePaymentInfo();
  const [avatarImg, setAvatarImg] = useState({});
  const [ mustPayDays, setMustPayDays] = useState({
    label: 'Non attivi',
    type: 0
  })

  return (
    <details
      className={clx(styles.item, {
        [styles.item_mobile]: isMobile,
      })}
    >
      <summary className={styles.item_summary}>
        <button
          data-movable-handle
          style={{
            background: 'transparent',
            border: 'none',
            cursor: isDragged ? 'grabbcing' : 'grab',
          }}
          tabIndex={-1}
        >
          <MenuIcon
            style={{
              width: '20px',
              height: '20px',
            }}
          />
        </button>
        <AvatarIcon
          name={worker.name}
          icon={avatarImg.value && avatarImg.id === worker.id ? avatarImg.value : worker.icon}
          size={'50px'}
        />
        <div className={styles.item_summary_name}>{worker.name}</div>
        <div className={styles.item_summary_icon}>
          <RightIcon />
        </div>
      </summary>

      <div className={styles.item_container} key={worker.id}>
        <div className={styles.avatar_wrapper}>
          <div className={styles.avatar_container}>
            <EditIconInCircle
              onClick={() =>
                dispatch(
                  modalActions.OPEN_MODAL.SUCCESS({
                    type: 'editWorkerAvatarModal',
                    callback: (value) =>
                      setAvatarImg({
                        id: worker.id,
                        value,
                      }),
                    props: { workerId: worker.id },
                  }),
                )
              }
            />
            <AvatarIcon
              name={worker.name}
              icon={avatarImg.value && avatarImg.id === worker.id ? avatarImg.value : worker.icon}
              size={'115px'}
            />
          </div>
        </div>
        <div className={styles.worker_name_wrapper}>
          <div className={styles.worker_info_cell}>
            <div className={styles.info_title}>
              Nome della poltrona
              <EditIconInCircle
                onClick={() =>
                  dispatch(
                    modalActions.OPEN_MODAL.SUCCESS({
                      type: 'editWorkerNameModal',
                      props: worker,
                    }),
                  )
                }
              />
            </div>
            <div className={styles.info_value}>{worker.name}</div>
          </div>
        </div>
        <div className={styles.worker_description_wrapper}>
          <div className={styles.worker_info_cell}>
            <div className={styles.info_title}>
              Descrizione poltrona
              <EditIconInCircle
                onClick={() =>
                  dispatch(
                    modalActions.OPEN_MODAL.SUCCESS({
                      type: 'editWorkerDescriptionModal',
                      props: worker,
                    }),
                  )
                }
              />
            </div>
            <div className={styles.info_value}>{worker.description}</div>
          </div>
        </div>
        {!paymentDisabled && <div className={styles.main_block_payment_wrapper}>
          <div className={styles.title_wrapper} style={{marginBottom: '0px'}}>
            <div className={styles.title}>Pagamento online obbligatorio</div>
            <HoveredInfoIcon description={`
              Scegli se attivare i pagamenti obbligatori per tutti / alcuni giorni.
            `} />
          </div>
          <div className={styles.badge_pagamenti}>
                Non attivi
          </div>
          <div
            className={styles.link}
            onClick={() => {
              dispatch(
                modalActions.OPEN_MODAL.SUCCESS({
                  type: 'workerPaymentDaysModal',
                  props: { days: [0, 1, 6] },
                }),
              );
            }}
          >
            <InstagramIcon className={styles.icon} />
            <span className={styles.ltext}>{'Gestisci i giorni di pagamento'}</span>
            <RightIcon className={styles.icon} />
          </div>
        </div>}
        <div className={styles.main_block_action_wrapper}>
          <div className={styles.title_wrapper}>
            <div className={styles.title}>Visibile in app</div>
            <HoveredInfoIcon description={`
              Se disattivi questa opzione, la tua poltrona non sarà visibile nell'app.
            `} />
          </div>
          <Switcher
            value={!worker.visible}
            setValue={(value) => {
              dispatch(
                salonManagementActions.EDIT_WORKER.REQUEST({
                  idPoltrona: worker.id,
                  visible: +value,
                }),
              );
            }}
            isFullWidth={isMobile}
          />
        </div>
        <div
          className={styles.main_block_action_card}
          onClick={() =>
            navigate(
              `${ROUTES.SALON_MANAGEMENT}${ROUTES.WORKERS}${ROUTES.WORKING_TIME}/${worker.id}`,
            )
          }
        >
          <div className={styles.main_block_action_card_info}>
            <div className={styles.action_card_info_title}>Orari</div>
            <div className={styles.action_card_info_value}>
              Gestisci gli orari della tua poltrona
            </div>
          </div>
          <div className={styles.main_block_action_card_action}>
            <ArrowLIcon />
          </div>
        </div>
        <div
          className={styles.main_block_action_card}
          onClick={() =>
            navigate(`${ROUTES.SALON_MANAGEMENT}${ROUTES.WORKERS}${ROUTES.SERVICES}/${worker.id}`)
          }
        >
          <div className={styles.main_block_action_card_info}>
            <div className={styles.action_card_info_title}>Servizi</div>
            <div className={styles.action_card_info_value}>
              Gestisci i servizi della tua poltrona
            </div>
          </div>
          <div className={styles.main_block_action_card_action}>
            <ArrowLIcon />
          </div>
        </div>
        <div
          className={clx(styles.worker_disable_container, {
            [styles.worker_enable_container]: worker.disabilitato,
          })}
        >
          <div className={styles.title_wrapper}>
            <div className={styles.title}>Disabilita poltrona</div>
            <HoveredInfoIcon description={`
                La poltrona disabilitata non sarà visibile nell'app e anche non sarà possibile modificare i suoi orari e servizi.
              `} />
          </div>
          <Button onClick={() => setWorkerToDeleteId(worker.id)} className={styles.delete_button}>
            <Bin />
            Disabilita
          </Button>
          <Button
            onClick={() =>
              dispatch(
                salonManagementActions.ENABLE_WORKER.REQUEST({
                  disabilitato: 0,
                  idPoltrona: worker.id,
                }),
              )
            }
            className={styles.enable_button}
          >
            Abilita
          </Button>
        </div>
      </div>
    </details>
  );
};
