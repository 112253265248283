export const errorTranslations = {
  DATE_IS_TOO_OLD: {
    title: "La data è troppo vecchia",
    subtitle: "",
  },
  mailNotFound: {
    title: "Non è stato possibile effettuare il login.",
    subtitle: "Assicurati che le credenziali inserite siano corrette.",
  },
  mailNotFoundRecovery: {
    title: "Email non trovata!",
    subtitle: "Perfavore inserisci un'email verificata.",
  },
  unauthorized: {
    title: "Non è stato possibile effettuare il login.",
    subtitle: "Assicurati che le credenziali inserite siano corrette.",
  },
  notAuthorized: {
    title: "Non sei autorizzato a compiere questa azione.",
    subtitle: "",
  },
  userExists: {
    title: "Email già in uso",
    subtitle: "",
  },
  phoneAlreadyUsed: {
    title: "Cellulare già in uso",
    subtitle: "",
  },
  orarioNonDisponibile: {
    title: "Una prenotazione è già presente per quest'orario.",
    subtitle: "",
  },
  mailAlreadyRegistered: {
    title: "Email già registrata",
    subtitle: "",
  },
  emptyFields: {
    title: "Assicurati che tutti i campi siano compilati",
    subtitle: "",
  },
  dbError: {
    title: "Si è verificato un errore durante la connessione al database.",
    subtitle: "Contatta il team di ZetaBarber che risolverà al più presto.",
  },
  "errore login fb": {
    title: "Si è verificato un errore durante il login con Facebook.",
    subtitle: "Contatta il team di ZetaBarber che risolverà al più presto.",
  },
  emailNotFound: {
    title: "Email non trovata",
    subtitle: "",
  },
  userNotFound: {
    title: "Utente non trovato",
    subtitle: "",
  },
  errorDisabled: {
    title: "Si è verificato un errore durante la disabilitazione del cliente.",
    subtitle: "Contatta il team di ZetaBarber che risolverà al più presto.",
  },
  CAMPI_VUOTI: {
    title: "Assicurati che tutti i campi siano compilati",
    subtitle: "",
  },
  ERROR_DELETE: {
    title: "Si è verificato un errore durante l'eliminazione della chiusura.",
    subtitle: "Contatta il team di ZetaBarber che risolverà al più presto.",
  },
  errorDataInizio: {
    title: "Si è verificato un errore durante l'impostazione della data di apertura.",
    subtitle: "Contatta il team di ZetaBarber che risolverà al più presto.",
  },
  alreadyHaveOrario: {
    title: "Hai già un orario impostato per questa data",
    subtitle: "",
  },
  alreadyHaveChiusura: {
    title: "Hai già una chiusura impostata per questa data",
    subtitle: "",
  },
  errorAddOrario: {
    title: "Si è verificato un errore durante l'aggiunta dell'orario.",
    subtitle: "Contatta il team di ZetaBarber che risolverà al più presto.",
  },
  servizioNotFound: {
    title: "Servizio non trovato.",
    subtitle: "",
  },
  erroreInserimentoPoltrona: {
    title: "Si è verificato un errore durante l'inserimento della poltrona.",
    subtitle: "Contatta il team di ZetaBarber che risolverà al più presto.",
  },
  errorDeleteOrario: {
    title: "Si è verificato un errore durante l'eliminazione dell'orario",
    subtitle: "Contatta il team di ZetaBarber che risolverà al più presto.",
  },
  notYourPoltrona: {
    title: "Non puoi eliminare una poltrona che non ti appartiene",
    subtitle: "",
  },
  noServiceFound: {
    title: "Servizio non trovato.",
    subtitle: "",
  },
  noPoltroneFound: {
    title: "Nessuna poltrona trovata.",
    subtitle: "",
  },
  noPrenFound: {
    title: "Nessuna prenotazione trovata.",
    subtitle: "",
  },
  notYourPren: {
    title: "Prenotazione che non ti appartiene.",
    subtitle: "",
  },
  erroreDisablePren: {
    title: "Si è verificato un errore durante la disabilitazione della prenotazione.",
    subtitle: "Contatta il team di ZetaBarber che risolverà al più presto.",
  },
  errorePrenotazione: {
    title: "Si è verificato un errore durante la prenotazione.",
    subtitle: "Contatta il team di ZetaBarber che risolverà al più presto.",
  },
  cannotPrenInThePast: {
    title: "Non puoi prenotare in passato.",
    subtitle: "",
  },
  prenNotFound: {
    title: "Prenotazione non trovata.",
    subtitle: "",
  },
  errorUpdatePren: {
    title: "Si è verificato un errore durante l'aggiornamento della prenotazione.",
    subtitle: "Contatta il team di ZetaBarber che risolverà al più presto.",
  },
  prenotazioneRifiutata: {
    title: "Prenotazione rifiutata.",
    subtitle: "",
  },
  errorUpdate: {
    title: "Si è verificato un errore durante l'aggiornamento.",
    subtitle: "Contatta il team di ZetaBarber che risolverà al più presto.",
  },
  saloneNotFound: {
    title: "Salone non trovato.",
    subtitle: "",
  },
  noMailToSend: {
    title: "Nessuna mail da inviare.",
    subtitle: "",
  },
  ERROR_MOD_POLTRONA: {
    title: "Si è verificato un errore durante la modifica della poltrona.",
    subtitle: "Contatta il team di ZetaBarber che risolverà al più presto.",
  },
  dataTooOld: {
    title: "La data è troppo vecchia.",
    subtitle: "",
  },
  alreadySet: {
    title: "Orario già impostato.",
    subtitle: "",
  },
  errorAddLista: {
    title: "Si è verificato un errore durante l'aggiunta alla lista d'attesa.",
    subtitle: "Contatta il team di ZetaBarber che risolverà al più presto.",
  },
  richiestaNotFound: {
    title: "Richiesta non trovata.",
    subtitle: "",
  },
  ERROR_SET_READ_NOTIFY: {
    title: "Si è verificato un errore durante l'impostazione di lettura e notifica.",
    subtitle: "Contatta il team di ZetaBarber che risolverà al più presto.",
  },
  errorScelta: {
    title: "Si è verificato un errore durante la scelta.",
    subtitle: "Contatta il team di ZetaBarber che risolverà al più presto.",
  },
  insufficentCredits: {
    title: "Crediti insufficienti.",
    subtitle: "",
  },
  genericError: {
    title: "Si è verificato un errore.",
    subtitle: "Contatta il team di ZetaBarber che risolverà al più presto.",
  },
  invalidToken: {
    title: "Token non valido.",
    subtitle: "",
  },
  emptyToken: {
    title: "Token vuoto.",
    subtitle: "",
  },
  error: {
    title: "Si è verificato un errore.",
    subtitle: "Contatta il team di ZetaBarber che risolverà al più presto.",
  },
  sessionExpired: {
    title: "Sessione scaduta.",
    subtitle: "",
  },
  sessionAlreadyPayed: {
    title: "Sessione già pagata.",
    subtitle: "",
  },
  StripeInvalidRequestError: {
    title: "Si è verificato un errore con Stripe.",
    subtitle: "Contatta il team di ZetaBarber che risolverà al più presto.",
  },
  sessionNotFound: {
    title: "Sessione non trovata.",
    subtitle: "",
  },
  scadenzaNotUpdated: {
    title: "Scadenza non trovata.",
    subtitle: "",
  },
  ERRORE_OLD_PASS: {
    title: "Vecchia password errata.",
    subtitle: "",
  },
  noUser: {
    title: "Utente non trovato.",
    subtitle: "",
  },
  userDisabled: {
    title: "Utente disabilitato.",
    subtitle: "",
  },
  nMaxPrenotazioniRaggiunto: {
    title: "Numero massimo di prenotazioni raggiunto.",
    subtitle: "",
  },
};
