import React, {useEffect, useRef, useState} from "react";
import styles from "./WorkerAvatarModal.module.scss";
import CropImageComponent from "../../../../../Common/CropImageComponent";
import UploadFileModal from "../../../../../Common/UploadFileModal";
import {Button} from "@mui/material";
import clx from "classnames";
import {salonManagementActions} from "../../../../store";
import {useDispatch} from "react-redux";

const WorkerAvatarModal = ({callback, closeModal, isEditForm, workerValue}) => {
  const dispatch = useDispatch();
  const [imgValue, setImageValue] = useState('');
  const [fullImg, setFullImg] = useState('')
  const childRef = useRef();

  useEffect(() => {
    if (imgValue) {
      if (isEditForm) {
        callback(imgValue)
        dispatch(salonManagementActions.EDIT_WORKER_AVATAR.REQUEST({
          avatarImg: imgValue,
          idPoltrona: workerValue.workerId,
        }, closeModal))
      } else {
        callback(imgValue)
        closeModal()
      }
    }
  }, [imgValue])

  return (
    <div className={styles.worker_avatar_modal_container}>
      <UploadFileModal isMultiple={false} setUploadFiles={setFullImg}/>
      <CropImageComponent
        setImageValue={setImageValue}
        url={fullImg.url}
        ref={childRef}
      />
      <div className={clx(styles.button_block, {[styles.confirm_active]: !!fullImg?.url})}>
        <Button
          onClick={closeModal}
          className={styles.return_button}>
          Annulla &gt;
        </Button>
        <Button
          className={styles.confirm_button}
          onClick={() => childRef.current.setCroppedImage()}
          variant="contained"
        >
          Salva
        </Button>
      </div>
    </div>
  );
}

export default WorkerAvatarModal;