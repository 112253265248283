import styles from './Spinner.module.scss';

const SpinnerSmall = () => (
  <div className={styles.loader}>
    <div className={styles.spinner__wrapper}>
      <div className={styles.spinner} />
    </div>
  </div>
);

export default SpinnerSmall;
