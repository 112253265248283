import React from "react";
import styles from './instagram.module.scss';
import clsx from "clsx";
import useWindowSize from "utils/useWindowSize";
import { ReactComponent as InstagramIcon } from 'assets/svg/badgets/instagram.svg';
import { ReactComponent as WhatsapIcon } from 'assets/svg/icons/whatsapp.svg';
import IconButton from "Containers/Common/Buttons/IconButton";
import igStoriesImg from 'assets/images/payments_info/6.jpg';

const InstagramForm = () =>{
    const {isMobile} = useWindowSize();

    return (<>
        <div className={clsx(styles.container, {[styles.container_mobile]: isMobile})}>
            <div className={styles.body}>
                <div className={styles.field}>
                    <div className={styles.text_ul}>
                        1.
                    </div>
                    <p className={styles.text} style={{fontFamily: 'Antonio', fontSize:'23px', fontWeight:'600'}}>
                        Seguici su instagram
                    </p>
                    <div className={styles.icon}>
                        <IconButton
                            svg={<InstagramIcon/>}
                            type={'instagram'}
                            size={'40px'}
                            isActive={true}
                        />
                    </div>
                </div>
                <div className={styles.field}>
                <div className={styles.text_ul}>
                        2.
                    </div>
                    <p className={styles.text}>
                        Condividi nelle tue stories una delle grafiche che ti abbiamo inviato su Whatsapp e taggaci per ricevere la promozione.
                    </p>
                </div>
                {!isMobile && <div className={styles.field} style={{marginTop:'23%'}}>
                    <p className={styles.text}>
                        Se hai già completato questo passaggio contattaci.
                    </p>
                    <div className={styles.icon}>
                        <IconButton
                            svg={<WhatsapIcon/>}
                            type={'whatsapp'}
                            value={'3533357748'}
                            size={'40px'}
                            isActive={true}
                        />
                    </div>
                </div>}
            </div>
            <div className={styles.imgSection}>
                <img className={styles.img} src={igStoriesImg} alt='' />
            </div>
            {isMobile && <div className={styles.footer}>
                    <p className={styles.text}>
                        Se hai già completato questo passaggio contattaci su whatsapp
                    </p>
                    <div className={styles.icon}>
                        <IconButton
                            svg={<WhatsapIcon/>}
                            type={'whatsapp'}
                            value={'3533357748'}
                            size={'40px'}
                            isActive={true}
                        />
                    </div>
            </div>}
        </div>
    </>)
}

export default InstagramForm;
