import * as React from 'react';
import styles from './LinearProgress.module.scss';

const LinearProgress = ({
    percent,
    color = '#65CE8F',
}) => {

    return (
        <div className={styles.mainProgressBarDiv} >
            <div className={styles.emptyProgressBar} style={{ width: "100%" }}>
                <div
                    className={styles.fillingProgressBar}
                    style={{
                        background: color,
                        width: `${percent}%`,
                        transition: "3s"
                    }}
                />
            </div>
        </div>
    );

};
export default LinearProgress;