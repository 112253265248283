import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import isBetweenPlugin from 'dayjs/plugin/isBetween';

dayjs.extend(isBetweenPlugin);
import CustomStaticPeriodDatePicker from '../../../../../Common/CustomStaticPeriodDatePicker';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import styles from './ExtraWorkingTimeModal.module.scss';
import { Field, Form, Formik } from 'formik';
import { getWorker, getWorkers } from '../../../../store/selectors';
import { Button } from '@mui/material';
import { salonManagementActions } from '../../../../store';
import CustomTimePicker from '../../../../../Common/CustomTimePicker';
import WeekDaySelector from '../../../../../Common/WeekDaySelector';
import { ReactComponent as Bin } from '../../../../../../assets/svg/icons/trash_red.svg';
import clx from 'classnames';
import SelectorMenu from '../../../../../Common/CustomSelector/SelectorMenu';
import { schedulerActions } from '../../../../../Scheduler/store';
import useWindowSize from '../../../../../../utils/useWindowSize';
import TextField from '../../../../../Common/TextField';
import ConfirmModal from 'Containers/Common/ConfirmModal';
import Switcher from 'Containers/Common/Switcher';
import HoveredInfoIcon from 'Containers/Common/HoveredInfoIcon';
import { getActiveSalonePaymentInfo } from 'utils/salonToken';


const findBlockedDates = (workers, activeWorkers = []) => {
  const filteredWorkers = workers.filter((worker) => activeWorkers.includes(worker.id));
  const closuresDates = filteredWorkers.reduce((acc, worker) => {
    const workerClosuresDates =
      worker.closures?.map(({ data_chiusura }) => dayjs(data_chiusura).format('YYYY-MM-DD')) || [];
    return [...acc, ...workerClosuresDates];
  }, []);

  const extraWorkingDates = filteredWorkers.reduce((acc, worker) => {
    const workerExtraWorkingDates =
      worker.extraWorkingTime?.reduce((acc, { dataInizio, dataFine, giorno }) => {
        const dif = dataInizio && dataFine && dayjs(dataFine).diff(dataInizio, 'day');
        if (dif >= 0) {
          const days =
            Array.from({ length: dif + 1 }, (_, i) =>
              dayjs(dataInizio).add(i, 'days').format('YYYY-MM-DD'),
            ).filter((day) => dayjs(day).day() === +giorno) || [];
          return [...acc, ...days];
        }
        return acc;
      }, []) || [];
    return [...acc, ...workerExtraWorkingDates];
  }, []);

  return [...new Set([...closuresDates, ...extraWorkingDates])];
};

const findBlockedWorkers = (workers, startDay) => {
  const closuresWorkers = workers.reduce((acc, worker) => {
    const workerClosure = worker.closures?.find(({ data_chiusura }) =>
      dayjs(data_chiusura).isSame(startDay, 'day'),
    )?.poltrona;
    return workerClosure ? [...acc, workerClosure] : acc;
  }, []);

  const extraWorkingTimeWorkers = workers.reduce((acc, worker) => {
    const workerExtraWorkingTime = worker.extraWorkingTime?.find(
      ({ dataInizio, dataFine, giorno }) =>
        dataInizio &&
        dataFine &&
        +giorno === dayjs(startDay).day() &&
        dayjs(startDay).isBetween(dataInizio, dataFine, 'day', '[]'),
    )?.idPoltrona;
    return workerExtraWorkingTime ? [...acc, workerExtraWorkingTime] : acc;
  }, []);

  return [...new Set([...closuresWorkers, ...extraWorkingTimeWorkers])];
};


export default ({ closeModal, isOneDay = false, dayValue }) => {
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const worker = useSelector(getWorker());
  const workers = useSelector(getWorkers());
  const ref = useRef(null);
  const formedWorkers = workers.map((worker) => ({
    name: worker.name,
    value: worker.id,
    icon: worker.icon,
  }));
  const [ openConfirmModal, setOpenConfirmModal ] = useState(false);
  const [ extraWorkingTime, setExtraWorkingTime ] = useState({});
  const paymentDisabled = getActiveSalonePaymentInfo();

  useEffect(() => {
    dispatch(salonManagementActions.GET_WORKERS.REQUEST({ query: { workTime: true } }));
  }, []);

  const initialValues = {
    datePeriod: {
      start: isOneDay ? dayjs(dayValue.startDay) : null,
      end: isOneDay ? dayjs(dayValue.startDay) : null,
      blockedDates: isOneDay ? [] : findBlockedDates(workers, [worker?.idpoltrona]),
      daysIndex: isOneDay ? [dayjs(dayValue.startDay).day()] : [],
    },
    morningDateFrom: dayjs().set('hour', 7).set('minute', 0).set('second', 0),
    morningDateTo: dayjs().set('hour', 0).set('minute', 0).set('second', 0),
    eveningDateFrom: dayjs().set('hour', 0).set('minute', 0).set('second', 0),
    eveningDateTo: dayjs().set('hour', 20).set('minute', 0).set('second', 0),
    isFullDay: true,
    activeWorkersIds: worker?.idpoltrona ? [worker?.idpoltrona] : [],
    blockedWorkers: isOneDay ? findBlockedWorkers(workers, dayValue.startDay) : [],
  };

  const validationSchema = {
    datePeriod: yup
      .object()
      .shape({
        start: yup.date().typeError('Select date').required('Seleziona la data'),
        end: yup.date().typeError('Select date').required(),
        daysIndex: yup
          .array()
          .typeError('Select day')
          .min(1, 'Seleziona almeno un giorno della settimana')
          .required(),
      })
      .test('checkDate', 'This date already taken', (val) => {
        const { start, end, daysIndex = [], blockedDates } = val;
        const matchedDate = blockedDates.find(
          (closureDate) =>
            daysIndex.includes(dayjs(closureDate).day()) &&
            start &&
            end &&
            dayjs(closureDate).isBetween(start, end, null, '[]'),
        );

        return !matchedDate;
      }),
    morningDateFrom: yup
      .date()
      .typeError('Select time')
      .when('isFullDay', ([isFullDay], schema) => {
        return schema.required("Inserisci l'orario");
      }),
    morningDateTo: yup
      .date()
      .typeError('Select time')
      .when('isFullDay', ([isFullDay], schema) => {
        return isFullDay ? schema.nullable(true) : schema.required("Inserisci l'orario");
      }),
    eveningDateFrom: yup
      .date()
      .typeError('Select time')
      .when('isFullDay', ([isFullDay], schema) => {
        return isFullDay ? schema.nullable(true) : schema.required("Inserisci l'orario");
      }),
    eveningDateTo: yup
      .date()
      .typeError('Select time')
      .when('isFullDay', ([isFullDay], schema) => {
        return schema.required("Inserisci l'orario");
      }),
    morningTimeDisabled: yup.bool(),
    eveningTimeDisabled: yup.bool(),
    activeWorkersIds: yup.array().typeError('Select worker').min(1, 'Select worker').required(),
  };
  useEffect(() => {}, []);
  return (<>
      <ConfirmModal
        isModalOpen={openConfirmModal}
        setIsModalOpen={() => {
          let req = {...extraWorkingTime, mustPay: false};
          if (isOneDay) {
            return dispatch(
              schedulerActions.SET_CALENDAR_WORKER_EXTRA_WORKING_TIME.REQUEST(req, closeModal),
            );
          }
          return dispatch(
            salonManagementActions.SET_WORKER_EXTRA_WORKING_TIME.REQUEST(req, closeModal),
          );
        }}
        title={"Attiva il pagamento anticipato per questa apertura straordinaria!"}
        action={() => {
          let req = {...extraWorkingTime, mustPay: true};
          if (isOneDay) {
            return dispatch(
              schedulerActions.SET_CALENDAR_WORKER_EXTRA_WORKING_TIME.REQUEST(req, closeModal),
            );
          }
          return dispatch(
            salonManagementActions.SET_WORKER_EXTRA_WORKING_TIME.REQUEST(req, closeModal),
          );
        }}
        content={
          <div className={styles.confirm_body} style={{marginBottom: isMobile ? '60px' : '0px'}}>
            <p>Vuoi che i tuoi clienti paghino esclusivamente online nei giorni selezionati?</p>
            <p>Se hai aggiunto tempo extra, attivando il pagamento anticipato ti assicurerai il guadagno che ti spetta.</p>
            <p>Il tuo tempo è prezioso ✨</p>
          </div>
        }
      />

    <div
      className={clx(styles.worker_extra_time_container, {
        [styles.worker_extra_time_container_one_day]: isOneDay,
        [styles.worker_extra_time_container_mobile]: isMobile,
      })}
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={yup.object().shape(validationSchema)}
        onSubmit={(values) => {
          const req = {
            activeWorkersIds: values.activeWorkersIds,
            idPoltrona: worker.idpoltrona,
            dataInizio: values.datePeriod?.start.format('YYYY-MM-DD'),
            dataFine: values.datePeriod?.end.format('YYYY-MM-DD'),
            oraInizioMattina: values.morningDateFrom.format('HH:mm:ss'),
            oraFineMattina: values.isFullDay ? '00:00:00' : values.morningDateTo.format('HH:mm:ss'),
            oraInizioPomeriggio: values.isFullDay
              ? '00:00:00'
              : values.eveningDateFrom.format('HH:mm:ss'),
            oraFinePomeriggio: values.eveningDateTo.format('HH:mm:ss'),
            daysIndex: values.datePeriod?.daysIndex,
            note: values.note,
          };
          if(paymentDisabled==1){
            if (isOneDay) {
              return dispatch(
                schedulerActions.SET_CALENDAR_WORKER_EXTRA_WORKING_TIME.REQUEST(req, closeModal),
              );
            }
            return dispatch(
              salonManagementActions.SET_WORKER_EXTRA_WORKING_TIME.REQUEST(req, closeModal),
            );
          }
          else {
            setExtraWorkingTime(req);
            setOpenConfirmModal(true);
          }        
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form className={styles.closures_modal_wrapper}>
            <div className={styles.body} ref={ref}>
              <div className={styles.week_day_selector_container}>
                <WeekDaySelector
                  dayIndex={values.datePeriod?.daysIndex}
                  setDayIndex={(index) => setFieldValue('datePeriod.daysIndex', index)}
                  title={'Scegli i giorni'}
                  isMultiply={true}
                  error={errors?.datePeriod?.daysIndex}
                />
              </div>
              <div className={styles.block}>
                <div className={styles.range_picker_container}>
                  <div className={styles.date_selector_title}>Seleziona il periodo</div>
                  <div style={{ width: 'fit-content' }}>
                    <CustomStaticPeriodDatePicker
                      period={values.datePeriod}
                      setPeriod={(period) =>
                        setFieldValue('datePeriod', { ...values.datePeriod, ...period })
                      }
                      error={errors.datePeriod?.start || errors.datePeriod?.end}
                      touched={touched.datePeriod}
                      disabledDates={values.datePeriod.blockedDates}
                    />
                  </div>
                </div>
                <div className={styles.time_selector_wrapper}>
                  <div className={styles.time_selector_title}>Imposta un orario</div>
                  <div
                    className={clx(styles.time_selector_container, {
                      [styles.time_selector_container_full_day]: values.isFullDay,
                    })}
                  >
                    {touched.morningDateFrom && errors.morningDateFrom
                      ? ref.current.scrollTo({
                          top: isMobile ? 450 : 0,
                          behavior: 'smooth',
                        })
                      : null}
                    <div
                      className={clx(styles.time_selector_container)}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: !values.isFullDay ? '30px' : '5px',
                      }}
                    >
                      <div
                        className={clx(
                          styles.time_selector_item_container,
                          styles.time_selector_item_container_full_day,
                        )}
                      >
                        <div className={styles.time_selector_item}>
                          <CustomTimePicker
                            title={'Dalle'}
                            value={values.morningDateFrom}
                            setValue={(time) => setFieldValue(`morningDateFrom`, time)}
                            maxTime={
                              !values.isFullDay ? values.morningDateTo : values.eveningDateTo
                            }
                            error={touched.morningDateFrom && errors.morningDateFrom}
                          />
                        </div>
                        <div
                          className={clx(
                            styles.time_selector_item,
                            styles.time_selector_item_delete,
                          )}
                        >
                          <CustomTimePicker
                            title={'Alle'}
                            value={values.eveningDateTo}
                            setValue={(time) => setFieldValue(`eveningDateTo`, time)}
                            minTime={values.eveningDateFrom}
                            error={touched.eveningDateTo && errors.eveningDateTo}
                          />
                          <Bin
                            className={styles.svg}
                            onClick={() => {
                              !values.isFullDay && setFieldValue('eveningTimeDisabled', true);
                            }}
                          />
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          values.isFullDay && setFieldValue('isFullDay', false);
                        }}
                        style={{
                          display: !values.isFullDay ? 'none' : 'flex',
                        }}
                        className={clx(styles.time_selector_add_new_field, {
                          [styles.time_selector_add_new_field_hide]: !values.isFullDay,
                        })}
                      >
                        Aggiungi un orario
                      </div>
                      <div
                        className={clx(
                          styles.time_selector_item_container,
                          styles.time_selector_item_container_all_fields,
                        )}
                      >
                        <div className={styles.time_selector_item}>
                          <CustomTimePicker
                            title={'Dalle'}
                            value={values.morningDateFrom}
                            setValue={(time) => setFieldValue(`morningDateFrom`, time)}
                            maxTime={values.morningDateTo}
                            error={touched.morningDateFrom && errors.morningDateFrom}
                          />
                        </div>
                        <div
                          className={clx(
                            styles.time_selector_item,
                            styles.time_selector_item_delete,
                          )}
                        >
                          <CustomTimePicker
                            title={'Alle'}
                            value={values.morningDateTo}
                            setValue={(time) => setFieldValue(`morningDateTo`, time)}
                            minTime={values.morningDateFrom}
                            error={touched.morningDateTo && errors.morningDateTo}
                          />
                          <Bin
                            className={styles.svg}
                            onClick={() => {
                              !values.isFullDay && setFieldValue('isFullDay', true);
                            }}
                          />
                        </div>
                      </div>

                      {!values.isFullDay && (
                        <div
                          className={clx(
                            styles.time_selector_item_container,
                            styles.time_selector_item_container_all_fields,
                          )}
                        >
                          <div className={styles.time_selector_item}>
                            <CustomTimePicker
                              title={'Dalle'}
                              value={values.eveningDateFrom}
                              setValue={(time) => setFieldValue(`eveningDateFrom`, time)}
                              maxTime={values.eveningDateTo}
                              error={touched.eveningDateFrom && errors.eveningDateFrom}
                            />
                          </div>
                          <div
                            className={clx(
                              styles.time_selector_item,
                              styles.time_selector_item_delete,
                            )}
                          >
                            <CustomTimePicker
                              title={'Alle'}
                              value={values.eveningDateTo}
                              setValue={(time) => setFieldValue(`eveningDateTo`, time)}
                              minTime={values.eveningDateFrom}
                              error={touched.eveningDateTo && errors.eveningDateTo}
                            />
                            <Bin
                              className={styles.svg}
                              onClick={() => {
                                !values.isFullDay && setFieldValue('isFullDay', true);
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.workers__menu}>
                <div className={styles.workers__menu_title}>Poltrone</div>
                <SelectorMenu
                  isMultiple={true}
                  fullHeight={true}
                  setIsDropdownOpen={() => {}}
                  options={formedWorkers}
                  setValue={(value) => {
                    setFieldValue('activeWorkersIds', value);
                    setFieldValue('datePeriod.blockedDates', findBlockedDates(workers, value));
                  }}
                  value={values.activeWorkersIds}
                  error={touched.activeWorkersIds && errors.activeWorkersIds}
                  blockedValues={values.blockedWorkers}
                />
              </div>
              <div className={styles.comment}>
                <div className={styles.comment__title}>
                  Commento<span> (Facoltativo)</span>
                </div>
                <Field
                  name={'note'}
                  id={'note'}
                  textarea={true}
                  placeholder='Campo di testo da compilare'
                  height='100px'
                  className={styles.input}
                  component={TextField}
                />
              </div>
            </div>
            <hr className={styles.line} />
            <div className={styles.footer}>
              <div className={styles.actions_container}>
                <Button className={styles.confirm_button} variant='contained' type={'submit'}>
                  Salva
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  </>);
};
