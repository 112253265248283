import constants from './constants';

const initialState = {
  loaders: {
    LOADER: 0,
  },
};

const reducer = (state = initialState, action) => {
  const type = action?.type;
  const slicedType = type?.slice(0, 5);
  const typeRest = type?.split('_')?.slice(1)?.join('_');
  const isPlay = slicedType == 'START';
  const isStop = slicedType == 'STOP_';

  if (isPlay) {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        [typeRest]: true,
      },
    };
  }
  if (isStop) {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        [typeRest]: false,
      },
    };
  }

  return {
    ...state,
  };
};

export { reducer as LoaderReducer };
