import styles from './CustomDatePicker.module.scss';

import { LocalizationProvider } from '@mui/x-date-pickers1/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers1/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers1/DatePicker';
import React, { useState } from 'react';
import CustomInput from '../CustomInput';
import clx from 'classnames';
import useWindowSize from '../../../utils/useWindowSize';

const DatePikerInputAsButton = ({ inputValue, onAction, placeholder, isOpen }) => {
  return (
    <div
      onClick={onAction}
      className={clx(styles.selector_container, { [styles['selector_container_close']]: !isOpen })}
    >
      <div className={styles.value}>{inputValue || placeholder}</div>
      <div className={styles.arrow} />
    </div>
  );
};

const CustomDatePicker = ({
  value,
  setValue,
  placeholder,
  title,
  icon,
  withIcon,
  minDate,
  disablePast = false,
  type = 'input',
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isMobile } = useWindowSize();
  const clearData = () => {
    setValue(null);
  };

  const selectInputByType = ({ type, InputProps, inputProps }) => {
    switch (type) {
      case 'input':
        return (
          <CustomInput
            {...InputProps}
            placeholder={placeholder}
            inputValue={inputProps.value}
            setInputValue={() => {}}
            onAction={() => setIsOpen(true)}
            title={title}
            clearData={clearData}
            withIcon={withIcon}
            icon={icon}
            readOnly={true}
            clearButton={false}
            height={isMobile ? '50px' : 'fit-content'}
          />
        );
      case 'button':
        return (
          <DatePikerInputAsButton
            inputValue={inputProps.value}
            onAction={() => setIsOpen(true)}
            placeholder={placeholder}
            isOpen={isOpen}
          />
        );
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        open={isOpen}
        onClose={() => setIsOpen(false)}
        minDate={minDate}
        renderInput={({ inputRef, inputProps, InputProps }) => {
          return (
            <div ref={inputRef} className={styles.picker_input_wrapper}>
              {selectInputByType({ type, inputProps, InputProps })}
            </div>
          );
        }}
        label='DatePicker'
        value={value}
        disablePast={disablePast}
        ampm={false}
        onChange={(newValue) => {
          setValue(newValue);
        }}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
