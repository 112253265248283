import React from 'react';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../Containers/Common/Modal';
import styles from './Error.module.scss';
import { actions } from './store';
import { getError } from './store/selectors';
import { errorTranslations } from './constants/translations';
import { ReactComponent as ErrorIcon } from '../../assets/svg/icons/error.svg'
import { ReactComponent as CrossIcon } from '../../assets/svg/cross.svg'
import useWindowSize from 'utils/useWindowSize';
import clx from 'classnames';
import useOutsideClick from 'utils/useOutsideClick';

export default () => {
  const error = useSelector(getError());
  const dispatch = useDispatch();
  const clear = () => {
    dispatch(actions.clearError());
  };
  const { isMobile } = useWindowSize()
  const minHeightModal = '200px';
  const minWidthModal = '310px';
  const maxHeightModal = (window.innerHeight / 4.5) > minHeightModal ? (window.innerHeight / 4.5) : minHeightModal;
  const maxWidthModal = (window.innerWidth - 100) > minWidthModal ? (window.innerWidth - 100) : minWidthModal;

  const modalRef = useRef(null);
  useOutsideClick(modalRef, () => {
    clear();
  });

  return (
    <Modal width={!isMobile ? "400px" : maxWidthModal} height={!isMobile ? '210px' : maxHeightModal} isModalOpen={error} isError={true} setIsModalOpen={clear}>
      <div
        ref={modalRef}
        className={clx(styles['modal__content'], {
          [styles.modal__content_mobile]: isMobile,
        })}>
        <div className={styles.close_btn} onClick={clear}><CrossIcon /></div>
        <div className={styles.head}>
          <ErrorIcon size={!isMobile ? '30px' : '25px'} />
          <h2 className={styles.title}>{errorTranslations[error]?.title || error}</h2>
        </div>
        {errorTranslations[error]?.subtitle ? <span className={styles.label}>{errorTranslations[error].subtitle}</span> : null}
      </div>
    </Modal>
  );
};


{/* <div className={styles.footer}>
          <hr />
          <div className={styles.btn}>
            <PrimaryButton
              text="Riprova"
              background="transparent"
              padding="8px"
              height="auto"
              fontSize="15px"
              width="100%"
              color="#858AF9"
              onClick={clear}
            />
          </div>
        </div> */}
