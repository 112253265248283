export const ROUTES = {
  SIGN_IN: '/login',
  HOME: '/home',
  SCHEDULER: '/scheduler',
  SALON_MANAGEMENT: '/salon_management',
  COMMUNICATIONS: '/communications',
  NOTIFICATION: '/notifications',
  STATISTICS: '/statistics',
  SHOP: '/shop',
  ARTICLES: '/articles',
  SETTINGS: '/settings',
  SERVICES: '/services',
  WORKERS: '/workers',
  SALONE: '/salone',
  CLIENTS: '/clients',
  SECURITY: '/security',
  WORKING_TIME: '/working_time',
  PAYMENTS: '/payments',
  EXTRA_WORKING_TIME: '/extra_working_time',
  CLOSURES: '/closures',
  ICONS: '/icons',
  SHARING: '/sharing',
  STEPS: '/steps',
  INFORMATIONS: '/informations',
  FORM: '/form',
  RECOVERY: '/recovery',
  ACCOUNTS: '/accounts_management',
};
