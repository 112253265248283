import styles from './Toast.module.scss';
import { badgetList, selectBadges } from '../../constants';
import { Badgets } from '../../../Common/Badgets';
import dayjs from 'dayjs';

export const ToastSuccess = ({ booking: { event: booking }, type = 'new' }) => {
  const title = {
    new: 'Nuova prenotazione!',
    update: 'Prenotazione aggiornata!',
    delete: 'Prenotazione cancellata!',
  };
  const day = dayjs(booking?.data_prenotazione.split('T')[0])
    .set('hour', booking?.orario_start.split(':')[0])
    .set('minute', booking?.orario_start.split(':')[1]);
  const name = booking?.nome
    ? `${booking?.nome} ${booking?.cognome || ''}`
    : booking?.nota
      ? booking?.nota
      : booking?.self_prenotatione 
        ? 'Da salone' 
        : 'Utente ' + booking?.idutente;
  const body = {
    new: `${name} si è prenotato alle ${day.format('HH.mm')} il 
    ${day.format('DD MMMM YY')}`,
    update: `${name} ha modificato la prenotazione alle ${day.format('HH.mm')} il
    ${day.format('DD MMMM YY')}`,
    delete: `${name} ha cancellato la prenotazione alle ${day.format('HH.mm')} il
    ${day.format('DD MMMM YY')}`,
  };

  return (
    <div className={styles.container_toast}>
      <h3>{title[type]}</h3>
      <h4>{body[type]}</h4>
      <div className={styles.container_toast_icons}>
        {Object.entries(selectBadges(booking))?.map(([key, value]) => {
          if (value) {
            return (
              <Badgets icon={badgetList[key]?.icon} text={badgetList[key]?.text} type='Large' />
            );
          }
        })}
      </div>
    </div>
  );
};
