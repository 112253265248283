import React from 'react';
import styles from './informationsPage.module.scss';
import blankPayBg from '../../../../../assets/images/payments_info/1.png';
import { ReactComponent as MetodsIcon } from '../../../../../assets/svg/icons/payment_metods.svg';
import { ReactComponent as MetodPPAy } from '../../../../../assets/svg/icons/logo_ppay.svg';
import { Button } from '@mui/material';
import clsx from 'clsx';
import useWindowSize from 'utils/useWindowSize';
import { ROUTES } from 'routes/constants';
import { useNavigate } from 'react-router-dom';

const InformationsPage = ()=>{
    const { isMobile } = useWindowSize();
    const navigate = useNavigate();
  
    return(
        <div className={clsx(styles.container, { [styles.container_mobile]: isMobile })}>
        <img className={styles.img_background} src={blankPayBg} />
        <div className={styles.text_section}>
          <h1 className={styles.h1}>Una Nuova Era di Comodità e Sicurezza!</h1>
          <p className={styles.p}>
            Rilassati e goditi pagamenti <span style={{ fontSize: '15px', fontFamily: 'Poppins', fontWeight: 600}}>facili e sicuri</span>
            , direttamente collegati al tuo account ZetaBarber. {!(`Ricevi gli accrediti settimanali
            direttamente sul tuo conto corrente, rendendo la gestione finanziaria un gioco da ragazzi.`)}
          </p>
         {!( <p className={styles.p}>
            Con la possibilità di verificare e approvare nuovi clienti, hai il controllo totale sulla
            tua clientela. Decidi chi deve pagare in anticipo per garantire il servizio prenotato e
            riduci al minimo le{' '}
            <span style={{ fontSize: '15px', color: '#FF6A88' }}>
              {' '}
              disdette {"dell'ultimo"} minuto
            </span>
            .
          </p>)}
          <div className={styles.steps_wrapper}>
          <p className={styles.point_step}>
            <span className={styles.text_special}>Elimina</span> le disdette
          </p>
          <p className={styles.point_step}>
            <span className={styles.text_special}>100%</span> prenotazioni verificate
          </p>
          <p className={styles.point_step}>
            <span className={styles.text_special}>Imposta</span> le tue regole
          </p>
          <p className={styles.point_step}>
            <span className={styles.text_special}>Accedi</span> ai tuoi guadagni in ogni momento
          </p>
          </div>
          <p className={styles.p} style={{ fontSize: '14px' }}>
            <span style={{ fontSize: '15px', fontFamily: 'Poppins', fontWeight: 600}}>Per te, nulla cambierà:</span> il costo del servizio attuale
            rimarrà invariato e i clienti potranno continuare a pagare in modo familiare direttamente
            in salone.
          </p>
  
          <section className={styles.commission_table}>
            <div className={styles.row}>
              <div className={styles.left}> 
                Tariffa per i principali circuiti:
                <div style={{display:'flex', justifyContent:'flex-start', gap: '10px'}}><MetodsIcon height={'25px'} width={'auto'} />
                <MetodPPAy height={'25px'} width={'auto'}/></div>
              </div>
              <div style={{borderLeft: '2px solid #858AF9'}}/>
              <div className={styles.right}>
                <span style={{ fontSize: '14px' }}>2.9% + 0.15 €<sup>*</sup></span> 
                <span style={{ fontSize: '14px' }}>per transazione</span>
              </div>
            </div>
            <span style={{ fontSize: '9px', textAlign:'right' }}><sup>*</sup> + 22%</span>
          </section>
          <br></br>
          <p className={styles.p} style={{fontWeight:500}}>
            Scegli di offrire un servizio di eccellenza, portando il tuo salone verso nuovi livelli di
            successo nel settore!
          </p>
          {!(<p className={styles.p} style={{ fontSize: '14px' }}>
            Ricorda, il servizio è ancora in fase di test, ma presto sarà disponibile su tutte le app
            ZetaBarber.
          </p>)}
          {!(<p className={styles.p} style={{ fontSize: '14px' }}>
            Siamo entusiasti di condividere che i tuoi colleghi stanno già sperimentando questa innovazione.Fino ad ora, solo {"l'1%"} dei clienti che ha pagato online ha successivamente disdetto la prenotazione.
          </p>)}
        </div>
        <div className={styles.actions_section}>
          <div className={styles.buttons}>
            <Button
              className={styles.confirm_button}
              onClick={() => navigate(`${ROUTES.PAYMENTS}${ROUTES.STEPS}`)
              }
              style={{fontSize:'14px'}}
              variant='contained'
            >
              Inizia subito
            </Button> 
          </div>
        </div>
      </div>  
    );
}

export default InformationsPage;