import clx from 'classnames';

import styles from './HeaderInject.module.scss';

const HeaderInject = ({ children, className }) => (
	<div className={clx(styles.header_action_container, styles[className])}>
		{children}
	</div>
);

export default HeaderInject;
