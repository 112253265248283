import actionCreator from '../../../utils/actionCreator';

const paymentActionTypes = [
  'GET_PAYMENTS',
  'REQUEST_REFUND',
  'GET_PAYMENT',
  'GET_BONIFICI',
  'SEND_REQUEST',
];

export const { types: paymentTypes, actions: paymentActions } = actionCreator(paymentActionTypes);
