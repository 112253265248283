import React, { useState } from 'react';
import styles from './qrCode.module.scss';
import useWindowSize from 'utils/useWindowSize';
import clsx from 'clsx';
import { ReactComponent as DownloadIcon } from '../../../../../../assets/svg/icons/scarica.svg';
import { ReactComponent as Zlogo } from '../../../../../../assets/svg/zb-logo.svg';
import AvatarIcon from 'Containers/Common/AvatarIcon';
import html2canvas from 'html2canvas';
import LinearLoader from 'Containers/Common/LinearLoader';

const QRcodeImg = ({ activeSalon, downloadImage = () => {}, closeModal }) => {
  const qrImg = `https://api.qrserver.com/v1/create-qr-code/?size=95x95&data=https://application.zetabarber.it/barber/${activeSalon.username}`;
  const { isMobile } = useWindowSize();
  const [isLoading, setIsLoading] = useState(false);

  const generateShare = async () => {
    const elementToCapture = document.querySelector('#qrCode');
    //if (!elementToCapture) alert('Qualcosa è andato storto. Riprova.');

    const canvas = await html2canvas(elementToCapture, {
      allowTaint: true,
      useCORS: true,
    });
    canvas.toBlob(async (blob) => {
      const href = URL.createObjectURL(blob);

      const anchorElement = document.createElement('a');
      anchorElement.href = href;
      anchorElement.download = `${activeSalon.username}.png`;

      document.body.appendChild(anchorElement);
      anchorElement.click();

      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(href);
      setIsLoading(false);
      closeModal();
    });
  };

  return (
    <>
      <div className={clsx(styles.container, { [styles.container_mbl]: isMobile })}>
        <section className={styles.body}>
          <div id={'qrCode'} className={styles.card}>
            <header className={styles.head} style={{ flexDirection: 'column' }}>
              <div
                style={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <AvatarIcon
                  icon={activeSalon.icon}
                  size={'90px'}
                  style={{ position: 'absolute' }}
                />
              </div>
            </header>
            <div className={styles.center}>
              <div className={styles.qr_container}>
                <div className={styles.border} />
                <img src={qrImg} />
              </div>
              <Zlogo className={styles.logo} width={'100px'} />
            </div>
            <div className={styles.foot}>
              <h1 className={styles.big_text}>Scannerizza e prenota</h1>
            </div>
          </div>
        </section>
        {
          !(
            <section className={styles.footer}>
              {isLoading ? <LinearLoader /> : ''}
              <button
                onClick={() => {
                  setIsLoading(true);
                  generateShare();
                }}
                className={clsx(styles.action_btn, { [styles.disable]: false })}
              >
                Scarica <DownloadIcon width={'24px'} height={'24px'} className={styles.icon} />
              </button>
            </section>
          )
        }
      </div>
    </>
  );
};

export default QRcodeImg;
