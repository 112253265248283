export const fileReaderToBase64 = async (file) => {
  const prom = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const uploadedFile = await prom(file[0]);
  return uploadedFile;
};

export const objectToQuery = (obj) =>
  obj
    ? Object.entries(obj)
      .reduce((acc, [key, value]) => (value ? `${acc}&${key}=${value}` : acc), '')
      .replace('&', '?')
    : '';