import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useWindowSize from "utils/useWindowSize";
import styles from './stepForm.module.scss';
import clsx from "clsx";
import { Button } from "@mui/material";
import TitleSectionBack from "Containers/Common/TitleSectionBack";
import { authActions } from "Containers/Auth/store";
import { getActiveSalon } from "Containers/Auth/store/selectors";
import TextField from "Containers/Common/TextField";
import { Form, Formik, Field } from "formik";
import * as yup from 'yup';
import { useNavigate } from "react-router-dom";
import { ROUTES } from "routes/constants";
import Spinner from "Containers/Common/Spinner";


const StepForm = () =>{
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const navigate = useNavigate();
  const activeSalon = useSelector(getActiveSalon());
  const [isLoading, setIsLoading] = useState(false);

  const ibanRegex =
    /^([A-Z]{2}[0-9]{2})(?=(?:[A-Z0-9]){9,30}$)((?:[A-Z0-9]{3,5}){2,7})([A-Z0-9]{1,3})?$/;

  const validationSchema = {
    titolare_iban: yup
      .string("Inserisci l' intestatario del conto")
      .required('Questo campo è obbligatorio'),
    iban: yup.string('Inserisci numero iban').matches(ibanRegex, 'Iban non valido'),
  };

  const initialValues = {
    salone: activeSalon.idsalone,
    titolare_iban: activeSalon.titolare_iban ?? '',
    iban: activeSalon.iban ?? '',
    nomeSalone: activeSalon.nome_salone,
  };

    return (<>
    {!isLoading ? 
    <div className={styles.page}>
        <div className={styles.header}>
        <TitleSectionBack />
        </div>
        <div className={clsx(styles.container, {[styles.container_mobile]: isMobile})}>
            <div className={styles.text_section}>
                <span className={styles.title}>Comincia adesso</span>
                <p className={styles.p} >
                    Prima di procedere {"all'"}attivazione del servizio aiutaci a migliorare la tua esperienza.
                </p>
            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={yup.object().shape(validationSchema)}
                onSubmit={(values) => {
                    const req = {
                        salone: values.salone,
                        titolare_iban: values.titolare_iban ?? '',
                        iban: values.iban ?? '',
                        nomeSalone: values.nomeSalone,
                    };
                    setIsLoading(true);
                    dispatch(authActions.EDIT_SALON_INFO.REQUEST(
                        req,
                        dispatch(authActions.ACTIVE_PAYMENT.REQUEST({ disable_payment: 0 },
                            setTimeout(navigate(`${ROUTES.PAYMENTS}`), 1000)
                            ))
                    ));
                    
                }}
            >
                {({ errors }) => (
                <Form
                    className={clsx(styles.client_modal_wrapper, {
                    [styles.client_modal_wrapper_mobile]: isMobile,
                    })}
                >
                    <div className={styles.body}>
                    <p className={styles.p} >
                        Inserisci i tuoi dati bancari per completare {"l'"}attivazione.
                    </p>
                    <div style={{borderBottom: '1px solid #7878784d', margin: '20px 0px'}} />
                    <Field
                        name={'titolare_iban'}
                        id={'titolare_iban'}
                        label='Intestatario del conto'
                        component={TextField}
                        height={'50px'}
                        margin={'0px 0px 30px 0px'}
                        placeholder={"Inserisci l'intestatario del conto"}
                    />
                    <Field
                        name={'iban'}
                        id={'iban'}
                        label='Iban'
                        component={TextField}
                        height={'50px'}
                        margin={'0px 0px 30px 0px'}
                        placeholder={'Inserisci numero iban'}
                    />
                    </div>
                    <div className={clsx(styles.actions_container, styles.footer)}>
                    <Button className={styles.confirm_button} variant='contained' type={'submit'}>
                        Attiva ora
                    </Button>
                    </div>
                </Form>
                )}
            </Formik>
        </div>
    </div>
    : <Spinner/>}

    </>);
}

export default StepForm;