import {useDispatch} from "react-redux";
import Modal from "../../../../Common/Modal";
import styles from './EditPhone.module.scss';
import { Button } from '@mui/material';
import React, {useState, useEffect} from 'react';
import cls from 'classnames';
import TextField from "../../../../Common/TextField";
import {Field} from "formik";
import {modalActions} from "../../../../Modals/store";
import {clientActions} from "../../../store";

const EditPhone = ({phone, verified, client_id, isModalOpen, setIsModalOpen, triggerEdit}) => {
    const dispatch = useDispatch();
    const [editedPhone, setEditedPhone] = useState(phone);
    const [isVerified, setIsVerified] = useState(verified);

    useEffect(() => {
        setEditedPhone(phone);
        setIsVerified(verified);
    }, [phone, verified]);

    const handlePhoneChange = (event) => {
        setEditedPhone(event.target.value);
    };
    const handleVerifiedChange = () => {
        setIsVerified(!isVerified);
    };

    return (
        <Modal
            // height={content ? '300px' : '230px'}
            isModalOpen={isModalOpen}
            setIsModalOpen={() => {
                setEditedPhone(phone);
                setIsVerified(verified);
                setIsModalOpen(false)
            }}
            title={'Modifica numero di telefono'}
            maxWidth={'380px'}
            showCloseBtn={true}

        >
            <div className={styles.main}>
                <div className={styles.body}>
                    <div className={styles.phone}>
                        <label>Numero</label>
                        <input type="text" className={styles.input_phone} value={editedPhone} onChange={handlePhoneChange}/>
                    </div>
                    <div className={styles.verified}>
                        <label>Numero verificato?</label>
                        <div className={styles.verified_input}>
                            <p className={styles.verified_text}>Verificato</p>
                            <div className={styles.checkbox_wrapper_17}>
                                <input type="checkbox" id="switch_17" checked={isVerified} onChange={handleVerifiedChange}/><label htmlFor="switch_17"></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.footer}>
                    <Button
                        variant='contained'
                        className={styles.confirm_button}
                        onClick={() => {
                            dispatch(
                                clientActions.EDIT_CLIENT_PHONE.REQUEST(
                                    {
                                        user: {
                                            idutente: client_id,
                                        },
                                        phone: editedPhone,
                                        verified: isVerified
                                    },() => {
                                        dispatch(clientActions.GET_CLIENT.REQUEST({ idcliente: client_id }, () => setIsModalOpen(false)));

                                    }
                                ),
                            )
                        }}
                    >
                        Modifica
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export default EditPhone;
