import React, { useEffect, useState } from "react";
import styles from "./SharedWorkersModal.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { getWorkers } from "../../../../../SalonManagement/store/selectors";

import SelectorMenu from "../../../../../Common/CustomSelector/SelectorMenu";
import { Button } from "@mui/material";
import { salonManagementActions } from "../../../../../SalonManagement/store";
import useWindowSize from "../../../../../../utils/useWindowSize";
import clx from "classnames";
import { getActiveSalon } from "Containers/Auth/store/selectors";

const SharedWorkersModal = ({ closeModal, callback }) => {
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const workers = useSelector(getWorkers());
  const salone = useSelector(getActiveSalon())
  const formedWorkers = workers.map(worker => ({
    name: worker.name,
    value: worker.id,
    icon: worker.icon
  }))

  const [activeWorker, setActiveWorker] = useState('')

  useEffect(() => {
    dispatch(salonManagementActions.GET_WORKERS.REQUEST())
  }, [])

  useEffect(() => {
    if (activeWorker) {
      closeModal()
      console.log(activeWorker)
      callback(`https://application.zetabarber.it/prenotazione/${salone.username}/servizi/${activeWorker}`)
    }
  }, [activeWorker])

  return (
    <div className={clx(styles.shared_workers_modal, {
      // [styles.shared_workers_modal_mobile] : isMobile
    })}>
      <div className={styles.list_container}>
        <div className={styles.selector_header}>
          Poltrone
        </div>
        <SelectorMenu
          isMultiple={false}
          options={formedWorkers}
          setIsDropdownOpen={() => {
          }}
          setValue={setActiveWorker}
          value={[activeWorker]}
          fullHeight={true}
          border={false}
        />
      </div>
    </div>
  );
}

export default SharedWorkersModal;