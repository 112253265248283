import styles from './SalonManagementList.module.scss';
import { ReactComponent as Worker } from '../../../../assets/svg/icons/poltrona.svg';
import { ReactComponent as Scissors } from '../../../../assets/svg/icons/forbici.svg';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../routes/constants';
import TitleSectionBack from '../../../Common/TitleSectionBack';
import { ReactComponent as ArrowLIcon } from '../../../../assets/svg/icons/arrow_l.svg';
import useWindowSize from "../../../../utils/useWindowSize";
import clx from "classnames";

const salonManagementCardData = [
  {
    id: 1,
    icon: <Scissors />,
    title: 'Servizi',
    description: 'Crea o modifica i servizi del tuo salone',
    link: `${ROUTES.SALON_MANAGEMENT}${ROUTES.SERVICES}`,
  },
  {
    id: 2,
    icon: <Worker />,
    title: 'Poltrone',
    description: 'Crea o modifica le poltrone del tuo salone',
    link: `${ROUTES.SALON_MANAGEMENT}${ROUTES.WORKERS}`,
  },
];

const SalonManagementList = () => {
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();
  return (
    <div className={clx(styles.salon_management_list_container, {
      [styles.salon_management_list_container_mobile]: isMobile
    })}>
      <TitleSectionBack
        title={'Gestione Salone'}
        description={'Crea i servizi, aggiungi i tuoi collaboratori e gestisci gli orari di apertura e chiusura del tuo Barber Shop'}
      />
      {/* <div className={styles.title}>Gestione Salone</div>
      <div className={styles.description}>
        Crea i servizi, aggiungi i tuoi collaboratori e gestisci gli orari di apertura e chiusura
        del tuo Barber Shop
      </div> */}
      <div className={styles.list_container}>
        {salonManagementCardData.map((item) => {
          return (
            <div
              onClick={() => navigate(item.link)}
              className={styles.list_item_container}
              key={item.id}
            >
              <div className={styles.info_block}>
                {item.icon}
                <div className={styles.description_block}>
                  <div className={styles.item_title}>{item.title}</div>
                  <div className={styles.item_description}>{item.description}</div>
                </div>
              </div>
              <div className={styles.list_item_container_icon}>
                <ArrowLIcon />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SalonManagementList;
