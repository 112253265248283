import React from 'react';

import styles from './Switcher.module.scss';
import clx from 'classnames';

export default ({
  value,
  setValue,
  label,
  isFullWidth = false,
  darkMode = false,
  miniSize = false,
}) => (
  <>
    <label className={styles['field__label']}>{label}</label>
    <div
      className={clx(styles.switcher__wrapper, {
        [styles.switcher__wrapper_full_width]: isFullWidth,
        [styles.switcher__wrapper_dark_mode]: darkMode,
        [styles.switcher__wrapper_mini]: miniSize,
      })}
    >
      <div
        className={clx(styles['switcher__button'], { [styles.is_active]: value })}
        onClick={() => !!value && setValue(true)}
      >
        <div className={styles.switcher__value}>Si</div>
      </div>
      <div
        className={clx(styles['switcher__button'], { [styles.is_active]: !value })}
        onClick={() => !value && setValue(false)}
      >
        <div className={styles.switcher__value}>No</div>
      </div>
    </div>
  </>
);
